/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import BrandLogo from "components/headers/brand-logo";
import CrumbTrail from "components/headers/crumb-trail";
import Selectors from "selectors";
const { pageSelector } = Selectors;
import { Container, Navbar, Nav } from "react-bootstrap";
import { browserHistory, withRouter } from "react-router";
import Actions from "actions";

const AppHeader = React.createClass({
  propTypes: {
    module: React.PropTypes.object,
    page: React.PropTypes.object,
    params: React.PropTypes.object.isRequired,
    section: React.PropTypes.object,
    tool: React.PropTypes.object,
  },

  getNavigation() {
    const { module, page, section, tool, routing, subscriptions, user } =
      this.props;
    const navigationArray = [];
    if (!_.isEmpty(tool)) {
      const navigation = _.get(tool, "navigation", {});
      navigationArray.push({
        currentPath: "/" + tool.slug,
        lastSegment: tool.slug,
        items: _.get(navigation, "tool_menu"),
      });
      if (!_.isEmpty(module)) {
        navigationArray.push({
          currentPath: "/" + tool.slug + "/" + module.slug,
          lastSegment: module.slug,
          items: _.get(navigation, "module_menu"),
        });
      }
      if (!_.isEmpty(section)) {
        navigationArray.push({
          currentPath: "/" + tool.slug + "/" + module.slug + "/" + section.slug,
          lastSegment: section.slug,
          items: _.get(navigation, "section_menus." + module.id),
        });
      }
      if (!_.isEmpty(page)) {
        navigationArray.push({
          currentPath:
            "/" +
            tool.slug +
            "/" +
            module.slug +
            "/" +
            section.slug +
            "/" +
            page.slug,
          lastSegment: page.slug,
          items: _.get(navigation, "page_menus." + section.id),
        });
      }
    } else {
      const path = routing.path;
      const topMenu = [
        { path: "/", title: { defaultMessage: "Dashboard", id: "dashboard" } },
      ];
      const isLoggedIn =
        user.status === "normal" || user.status === "unsubscribed";

      if (isLoggedIn) {
        topMenu.push({
          path: "/account",
          title: { defaultMessage: "My Account", id: "account" },
        });
      }

      const accountMenu = [
        {
          path: "/account/profile",
          title: { defaultMessage: "Profile", id: "profile" },
        },
      ];
      if (user.status !== "normal" || !_.isEmpty(subscriptions)) {
        accountMenu.push({
          path: "/account/plans",
          title: { defaultMessage: "Plans", id: "plans" },
        });
        accountMenu.push({
          path: "/account/payment",
          title: { defaultMessage: "Payment", id: "payment" },
        });
      }

      switch (path) {
        case "/":
          navigationArray.push({
            currentPath: "/",
            lastSegment: "",
            items: topMenu,
          });
          break;
        case "/account":
          navigationArray.push({
            currentPath: "/account",
            lastSegment: "account",
            items: topMenu,
          });
          break;
        case "/account/payment":
          navigationArray.push({
            currentPath: "/account",
            lastSegment: "account",
            items: topMenu,
          });
          navigationArray.push({
            currentPath: "/account/payment",
            lastSegment: "payment",
            items: accountMenu,
          });
          break;
        case "/account/profile":
          navigationArray.push({
            currentPath: "/account",
            lastSegment: "account",
            items: topMenu,
          });
          navigationArray.push({
            currentPath: "/account/profile",
            lastSegment: "profile",
            items: accountMenu,
          });
          break;
        case "/account/plans":
          navigationArray.push({
            currentPath: "/account",
            lastSegment: "account",
            items: topMenu,
          });
          navigationArray.push({
            currentPath: "/account/plans",
            lastSegment: "plans",
            items: accountMenu,
          });
          break;
      }
    }
    return navigationArray;
  },

  onBrandClick(e) {
    e.preventDefault();
    if (
      this.props.routing.path === "/screener" ||
      this.props.routing.path === "/screener/"
    ) {
      window.location.href = "https://checkupandchoices.com/";
    } else {
      browserHistory.push("/");
    }
  },

  onMenuButtonClick(item) {
    if (item.path === "/feedback") {
      this.props.dispatch(
        Actions.showModal("appModal", {
          modalNotifications: "helpFeedback",
          modalContent: true,
        }),
      );
    }
  },

  activeMenuItem() {
    const { pathname } = this.props.router?.location ?? {};
    if (["/urges", "/moods"].includes(pathname)) return "/drinks";
    return pathname;
  },

  render() {
    const { routing, user } = this.props;
    const navigation = this.getNavigation();

    return (
      <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: "white" }}>
        <Container fluid>
          {!CUAC_SETTINGS.GROUP.isDisabled ? (
            <>
              <Navbar.Brand href="/" onClick={this.onBrandClick}>
                <BrandLogo />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ms-auto" activeKey={this.activeMenuItem()}>
                  <CrumbTrail
                    navigation={navigation}
                    routing={routing}
                    user={user}
                    onMenuButtonClick={this.onMenuButtonClick}
                  />
                </Nav>
              </Navbar.Collapse>
            </>
          ) : (
            <>
              <BrandLogo />
            </>
          )}
        </Container>
      </Navbar>
    );
  },
});

export default connect((state, props) => ({
  user: state.auth.user,
  routing: state.routing,
  subscriptions: _.get(state, "subscriptions.subscriptions.data"),
  ...pageSelector(state, props),
}))(withRouter(AppHeader));
