"use strict";

import { updateUser as updateUserAPI } from "api/users";

import {
  REQUEST_SET_RESPONSE,
  REQUEST_SET_RESPONSE_SUCCESS,
  REQUEST_SET_RESPONSE_FAILURE,
} from "actions/types";

function requestSetResponse(responseId) {
  return {
    type: REQUEST_SET_RESPONSE,
    payload: {
      responseId: responseId,
    },
  };
}

function requestSetResponseSuccess(responseId) {
  return {
    type: REQUEST_SET_RESPONSE_SUCCESS,
    payload: {
      responseId: responseId,
    },
  };
}

function requestSetResponseFailure(error) {
  return {
    type: REQUEST_SET_RESPONSE_FAILURE,
    payload: error,
    error: true,
  };
}

export default function setResponse(token, userId, responseId) {
  return (dispatch) => {
    dispatch(requestSetResponse(responseId));
    return updateUserAPI(token, userId, { active_response_id: responseId })
      .then(() => {
        return dispatch(requestSetResponseSuccess(responseId));
      })
      .catch((error) => {
        return dispatch(requestSetResponseFailure(error));
      });
  };
}
