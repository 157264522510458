export const FOREST_GREEN = "#4F913E";
export const LIGHT_FOREST_GREEN = "#72A764";
export const LIGHTER_FOREST_GREEN = "#D0DFC4";
export const YELLOW = "#F6CD6C";
export const LIGHT_YELLOW = "#FFE29C";
export const BLUE = "#268EC2";
export const LIGHT_BLUE = "#57b1de";
export const LIGHTEST_BLUE = "#cde4f4";
export const ORANGE = "#F68E6F";
export const LIGHT_GRAY = "#F1F2F3";
export const DARK_GRAY = "#879098";
export const BLACK = "#0E323E";
