/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import { reduxForm } from "redux-form";
import { FormattedMessage, injectIntl } from "react-intl";

import { eventPreventDefault as prevent, scrollTo } from "utils/tools";

import { TrackerTypes } from "api/tracker-entries";

import ABCTracker from "components/forms/abc-tracker";
import DrinkTracker from "components/forms/drink-tracker";
import GoalTracker from "components/forms/goal-tracker";
import HappinessTracker from "components/forms/happiness-tracker";
import LapseTracker from "components/forms/lapse-tracker";
import MoodTracker from "components/forms/mood-tracker";
import PlanTracker from "components/forms/plan-tracker";
import SolutionTracker from "components/forms/solution-tracker";
import UrgeTracker from "components/forms/urge-tracker";
import ValueTracker from "components/forms/value-tracker";
import AgreementTracker from "components/forms/agreement-tracker";

import { ABCList } from "components/trackers/abc-list";
import DrinkList from "components/trackers/drink-list";
import GoalList from "components/trackers/goal-list";
import { HappinessList } from "components/trackers/happiness-list";
import { LapseList } from "components/trackers/lapse-list";
import MoodList from "components/trackers/mood-list";
import { PlanList } from "components/trackers/plan-list";
import SolutionList from "components/trackers/solution-list";
import UrgeList from "components/trackers/urge-list";
import { ValueList } from "components/trackers/value-list";
import AgreementList from "components/trackers/agreement-list";

export const Tracker = React.createClass({
  propTypes: {
    addTrackerEntry: React.PropTypes.func.isRequired,
    tag: React.PropTypes.string,
    trackerEntries: React.PropTypes.object.isRequired,
    tracker_type_name: React.PropTypes.string.isRequired,
  },

  getInitialState() {
    return { trackerEntry: null };
  },

  setTrackerEntry(type, entry) {
    this.setState({ trackerEntry: entry }, () => {
      if (type) {
        const el = document.querySelector(".section-" + type);
        if (el) {
          const top = el.offsetTop;
          scrollTo(document.body, top, 500);
        }
      }
    });
  },

  clearTrackerEntry() {
    this.setState({ trackerEntry: null });
  },

  render() {
    const {
      addTrackerEntry,
      tag,
      trackerEntries,
      isFetching,
      tracker_type_name: trackerTypeName,
      params,
    } = this.props;
    const trackerEntry = this.state.trackerEntry;
    let initialValues = {};
    switch (trackerTypeName) {
      case TrackerTypes.ABC:
        return (
          <div className="tracker">
            <ABCTracker
              onSubmit={(data) => addTrackerEntry(data, TrackerTypes.ABC, tag)}
              isFetching={isFetching}
              initialValues={initialValues}
            />
            <ABCList trackerEntries={trackerEntries} tag={tag} />
            <div className="clearfix" />
          </div>
        );
      case TrackerTypes.DRINK:
        initialValues = {
          dt_occurred: new Date().toISOString(),
          std_drink_count: 1,
        };
        return (
          <div className="tracker">
            <DrinkList trackerEntries={trackerEntries} tag={tag} />
            <div className="clearfix" />
          </div>
        );
      case TrackerTypes.GOAL:
        return (
          <div className="tracker">
            <GoalTracker
              onSubmit={(data) => addTrackerEntry(data, TrackerTypes.GOAL, tag)}
              isFetching={isFetching}
              initialValues={initialValues}
            />
            <GoalList trackerEntries={trackerEntries} tag={tag} />
            <div className="clearfix" />
          </div>
        );
      case TrackerTypes.HAPPINESS:
        return (
          <div className="tracker">
            <HappinessTracker
              onSubmit={(data) =>
                addTrackerEntry(data, TrackerTypes.HAPPINESS, tag)
              }
              isFetching={isFetching}
              initialValues={initialValues}
            />
            <HappinessList trackerEntries={trackerEntries} tag={tag} />
            <div className="clearfix" />
          </div>
        );
      case TrackerTypes.LAPSE:
        return (
          <div className="tracker">
            <LapseTracker
              onSubmit={(data) =>
                addTrackerEntry(data, TrackerTypes.LAPSE, tag)
              }
              isFetching={isFetching}
              initialValues={initialValues}
              trackerEntry={trackerEntry}
              clearTrackerEntry={this.clearTrackerEntry}
            />
            <LapseList
              trackerEntries={trackerEntries}
              tag={tag}
              setTrackerEntry={this.setTrackerEntry}
            />
            <div className="clearfix" />
          </div>
        );
      case TrackerTypes.MOOD:
        initialValues = {
          dt_occurred: new Date().toISOString(),
        };
        return (
          <div className="tracker">
            <MoodList trackerEntries={trackerEntries} tag={tag} />
            <div className="clearfix" />
          </div>
        );
      case TrackerTypes.PLAN:
        return (
          <div className="tracker">
            <PlanTracker
              onSubmit={(data) => addTrackerEntry(data, TrackerTypes.PLAN, tag)}
              isFetching={isFetching}
              initialValues={initialValues}
            />
            <PlanList trackerEntries={trackerEntries} tag={tag} />
            <div className="clearfix" />
          </div>
        );
      case TrackerTypes.VALUE:
        return (
          <div className="tracker">
            <ValueTracker
              onSubmit={(data) =>
                addTrackerEntry(data, TrackerTypes.VALUE, tag)
              }
              isFetching={isFetching}
              initialValues={initialValues}
            />
            <ValueList trackerEntries={trackerEntries} tag={tag} />
            <div className="clearfix" />
          </div>
        );
      case TrackerTypes.SOLUTION:
        return (
          <div className="tracker">
            <SolutionList trackerEntries={trackerEntries} tag={tag} />
            <div className="clearfix" />
          </div>
        );
      case TrackerTypes.URGE:
        initialValues = {
          dt_occurred: new Date().toISOString(),
        };
        return (
          <div className="tracker">
            <UrgeList
              trackerEntries={trackerEntries}
              tag={tag}
              params={params}
            />
            <div className="clearfix" />
          </div>
        );
      case TrackerTypes.AGREEMENT:
        return (
          <div className="tracker">
            <AgreementList
              trackerEntries={trackerEntries}
              tag={tag}
              params={params}
            />
            <div className="clearfix" />
          </div>
        );
      default:
        return null;
    }
  },
});
