"use strict";

import { connect } from "react-redux";
import FeedbackBanner from "components/headers/FeedbackBanner.component.web";
import Actions from "actions";

const mapStateToProps = (state) => {
  return {
    isDismissed: state.welcomeBanner,
  };
};

function dismiss() {
  return {
    type: "WELCOME_BANNER_DISMISS",
  };
}

export default connect(mapStateToProps, {
  dismiss,
  showModal: Actions.showModal,
})(FeedbackBanner);
