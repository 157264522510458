"use strict";

import _ from "lodash";
import { updateUser as updateUserAPI, getSelf as getSelfAPI } from "api/users";
import notify from "actions/notify";

import {
  REQUEST_UPDATE_USER, // TODO: VERIFY THIS IS CORRECT
  REQUEST_UPDATE_USER_SUCCESS,
  REQUEST_UPDATE_USER_FAILURE,
  REQUEST_LOG_IN_USER_SUCCESS,
} from "actions/types";
import Cookies from "cookies-js";

const dataLayer = [];

function requestUpdateUser(user) {
  return {
    type: REQUEST_UPDATE_USER,
    payload: { user },
  };
}

function requestUpdateUserSuccess(user) {
  return {
    type: REQUEST_UPDATE_USER_SUCCESS,
    payload: { user },
  };
}

function requestUpdateUserFailure(error) {
  return {
    type: REQUEST_UPDATE_USER_FAILURE,
    payload: error,
    error: true,
  };
}

function silentTokenUpdate(pw, email) {
  return (dispatch) =>
    getSelfAPI(email, pw).then((json) => {
      Cookies.set("userData", json._id + ":" + json.token);
      dispatch({ type: REQUEST_LOG_IN_USER_SUCCESS, payload: { user: json } });
    });
}

export default function updateUser(token, userId, updates) {
  return (dispatch) => {
    dispatch(requestUpdateUser(updates));
    return updateUserAPI(token, userId, updates)
      .then((json) => {
        dispatch(
          notify({
            title: "Subscriber Update",
            message: "Profile updated",
            level: "success",
          }),
        );
        const trackedUpdates = _.pick(updates, ["gender", "weight"]);
        const dtDOB = _.get(updates, "dt_dob");
        if (dtDOB) {
          trackedUpdates["birthYear"] = new Date(dtDOB).getFullYear();
        }

        // update session if user linked their anon account
        const { email, password } = updates;
        if (email && password) dispatch(silentTokenUpdate(password, email));

        window.dataLayer.push({
          event: "userUpdated",
          userId: userId,
          groupCode: CUAC_SETTINGS.GROUP.code,
          ...trackedUpdates,
        });
        return dispatch(requestUpdateUserSuccess(json));
      })
      .catch((error) => {
        dispatch(
          notify({
            title: "Network Error",
            message:
              "Your profile didn't update.  Please try again in a few minutes.",
            level: "error",
            autoDismiss: 0,
          }),
        );
        return dispatch(requestUpdateUserFailure(error));
      });
  };
}
