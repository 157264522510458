"use strict";

import { handleResponse } from "utils/api";

export const updateUserEmailEntry = (token, userId, toolId, allowEmail) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT +
      "/users/email-entry?user_id=" +
      userId +
      "&tool_id=" +
      toolId +
      "&group_code=" +
      CUAC_SETTINGS.GROUP.code,
    {
      method: "PATCH",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        allow_email: allowEmail,
      }),
    },
  ).then(handleResponse);
};

export const getUserEmailEntry = (token, userId, toolId) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT +
      "/users/email-entry?user_id=" +
      userId +
      "&tool_id=" +
      toolId,
    {
      method: "GET",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
    },
  ).then(handleResponse);
};
