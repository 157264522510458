import React, { useMemo, useState } from "react";
import Header from "../components/admin/Header";
import { Col, Container, Row } from "react-bootstrap";
import Dashboard from "../components/admin/Dashboard";
import Users from "../components/admin/Users";
import ActivityLog from "../components/admin/ActivityLog";
import Tools from "../components/admin/Tools";
import { connect } from "react-redux";
import Sidenav, { AdminPage } from "../components/admin/Sidenav";
import { Group, User } from "../types";
import Actions from "../actions";

interface Props {
  user?: User;
  logInUser: (email: string, password: string) => Promise<void>;
}

const Page = (props: Props) => {
  const [group, setGroup] = useState<Group>();
  const [page, setPage] = useState<AdminPage>(AdminPage.Dashboard);

  const adminPage = useMemo(() => {
    switch (page) {
      case AdminPage.Dashboard:
        return <Dashboard />;
      case AdminPage.Users:
        return <Users group={group} />;
      case AdminPage.ActivityLog:
        return <ActivityLog />;
      case AdminPage.Tools:
        return <Tools />;
      default:
        return null;
    }
  }, [page, group]);

  return (
    <div className="h-100 d-flex flex-column align-items-stretch position-relative">
      <div
        className="w-100 position-absolute top-0 right-0"
        style={{ height: 65 }}
      >
        <Header user={props.user} group={group} setGroup={setGroup} />
      </div>
      <div className="flex-grow-1 bg-light" style={{ marginTop: 65 }}>
        <Container fluid>
          <Row>
            <Col xs={2}>
              <Sidenav setPage={setPage} />
            </Col>
            <Col className="bg-white" xs={page === AdminPage.Users ? 10 : 8}>
              {adminPage}
            </Col>
            <Col xs={page === AdminPage.Users ? 0 : 2}></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  {
    logInUser: Actions.logInUser,
  },
)(Page);
