"use strict";

export const SET_TOOL_TOC_OPEN = "SET_TOOL_TOC_OPEN";

export const CLEAR_SELECTED_PLAN = "CLEAR_SELECTED_PLAN";

export const DEVELOP_HACK_USER = "DEVELOP_HACK_USER";

export const DISMISS_MESSAGE = "DISMISS_MESSAGE";

export const NOTIFY = "NOTIFY";

export const APPLY_RESPONSE = "APPLY_RESPONSE";

export const REQUEST_ADD_RESPONSE = "REQUEST_ADD_RESPONSE";
export const REQUEST_ADD_RESPONSE_SUCCESS = "REQUEST_ADD_RESPONSE_SUCCESS";
export const REQUEST_ADD_RESPONSE_FAILURE = "REQUEST_ADD_RESPONSE_FAILURE";

export const REQUEST_SET_RESPONSE = "REQUEST_SET_RESPONSE";
export const REQUEST_SET_RESPONSE_SUCCESS = "REQUEST_SET_RESPONSE_SUCCESS";
export const REQUEST_SET_RESPONSE_FAILURE = "REQUEST_SET_RESPONSE_FAILURE";

export const REQUEST_ADD_SUBSCRIPTION = "REQUEST_ADD_SUBSCRIPTION";
export const REQUEST_ADD_SUBSCRIPTION_SUCCESS =
  "REQUEST_ADD_SUBSCRIPTION_SUCCESS";
export const REQUEST_ADD_SUBSCRIPTION_FAILURE =
  "REQUEST_ADD_SUBSCRIPTION_FAILURE";

export const REQUEST_ADD_TRACKER_ENTRY = "REQUEST_ADD_TRACKER_ENTRY";
export const REQUEST_ADD_TRACKER_ENTRY_SUCCESS =
  "REQUEST_ADD_TRACKER_ENTRY_SUCCESS";
export const REQUEST_ADD_TRACKER_ENTRY_FAILURE =
  "REQUEST_ADD_TRACKER_ENTRY_FAILURE";

export const REQUEST_CHANGE_PASSWORD = "REQUEST_CHANGE_PASSWORD";
export const REQUEST_CHANGE_PASSWORD_SUCCESS =
  "REQUEST_CHANGE_PASSWORD_SUCCESS";
export const REQUEST_CHANGE_PASSWORD_FAILURE =
  "REQUEST_CHANGE_PASSWORD_FAILURE";

export const REQUEST_DELETE_SUBSCRIPTION = "REQUEST_DELETE_SUBSCRIPTION";
export const REQUEST_DELETE_SUBSCRIPTION_SUCCESS =
  "REQUEST_DELETE_SUBSCRIPTION_SUCCESS";
export const REQUEST_DELETE_SUBSCRIPTION_FAILURE =
  "REQUEST_DELETE_SUBSCRIPTION_FAILURE";

export const REQUEST_DELETE_TRACKER_ENTRY = "REQUEST_DELETE_TRACKER_ENTRY";
export const REQUEST_DELETE_TRACKER_ENTRY_SUCCESS =
  "REQUEST_DELETE_TRACKER_ENTRY_SUCCESS";
export const REQUEST_DELETE_TRACKER_ENTRY_FAILURE =
  "REQUEST_DELETE_TRACKER_ENTRY_FAILURE";

export const REQUEST_GET_PASSWORD_RESET = "REQUEST_GET_PASSWORD_RESET";
export const REQUEST_GET_PASSWORD_RESET_SUCCESS =
  "REQUEST_GET_PASSWORD_RESET_SUCCESS";
export const REQUEST_GET_PASSWORD_RESET_FAILURE =
  "REQUEST_GET_PASSWORD_RESET_FAILURE";

export const REQUEST_GET_PLANS = "REQUEST_GET_PLANS";
export const REQUEST_GET_PLANS_SUCCESS = "REQUEST_GET_PLANS_SUCCESS";
export const REQUEST_GET_PLANS_FAILURE = "REQUEST_GET_PLANS_FAILURE";

export const REQUEST_GET_RESPONSES = "REQUEST_GET_RESPONSES";
export const REQUEST_GET_RESPONSES_SUCCESS = "REQUEST_GET_RESPONSES_SUCCESS";
export const REQUEST_GET_RESPONSES_FAILURE = "REQUEST_GET_RESPONSES_FAILURE";

export const REQUEST_GET_SUBSCRIPTIONS = "REQUEST_GET_SUBSCRIPTIONS";
export const REQUEST_GET_SUBSCRIPTIONS_SUCCESS =
  "REQUEST_GET_SUBSCRIPTIONS_SUCCESS";
export const REQUEST_GET_SUBSCRIPTIONS_FAILURE =
  "REQUEST_GET_SUBSCRIPTIONS_FAILURE";

export const REQUEST_GET_TOOL = "REQUEST_GET_TOOL";
export const REQUEST_GET_TOOL_SUCCESS = "REQUEST_GET_TOOL_SUCCESS";
export const REQUEST_GET_TOOL_FAILURE = "REQUEST_GET_TOOL_FAILURE";

export const REQUEST_GET_TOOLS = "REQUEST_GET_TOOLS";
export const REQUEST_GET_TOOLS_SUCCESS = "REQUEST_GET_TOOLS_SUCCESS";
export const REQUEST_GET_TOOLS_FAILURE = "REQUEST_GET_TOOLS_FAILURE";

export const REQUEST_GET_TRACKER_ENTRIES = "REQUEST_GET_TRACKER_ENTRIES";
export const REQUEST_GET_TRACKER_ENTRIES_SUCCESS =
  "REQUEST_GET_TRACKER_ENTRIES_SUCCESS";
export const REQUEST_GET_TRACKER_ENTRIES_FAILURE =
  "REQUEST_GET_TRACKER_ENTRIES_FAILURE";

export const REQUEST_GET_TRACKER_ENTRY_STATS =
  "REQUEST_GET_TRACKER_ENTRY_STATS";
export const REQUEST_GET_TRACKER_ENTRY_STATS_SUCCESS =
  "REQUEST_GET_TRACKER_ENTRY_STATS_SUCCESS";
export const REQUEST_GET_TRACKER_ENTRY_STATS_FAILURE =
  "REQUEST_GET_TRACKER_ENTRY_STATS_FAILURE";

export const REQUEST_GET_USER = "REQUEST_GET_USER";
export const REQUEST_GET_USER_SUCCESS = "REQUEST_GET_USER_SUCCESS";
export const REQUEST_GET_USER_FAILURE = "REQUEST_GET_USER_FAILURE";

export const REQUEST_GET_WEARABLE_LOGIN_CODE =
  "REQUEST_GET_WEARABLE_LOGIN_CODE";
export const REQUEST_GET_WEARABLE_LOGIN_CODE_SUCCESS =
  "REQUEST_GET_WEARABLE_LOGIN_CODE_SUCCESS";
export const REQUEST_GET_WEARABLE_LOGIN_CODE_FAILURE =
  "REQUEST_GET_WEARABLE_LOGIN_CODE_FAILURE";

export const REQUEST_LOG_IN_USER = "REQUEST_LOG_IN_USER";
export const REQUEST_LOG_IN_USER_SUCCESS = "REQUEST_LOG_IN_USER_SUCCESS";
export const REQUEST_LOG_IN_USER_FAILURE = "REQUEST_LOG_IN_USER_FAILURE";

export const REQUEST_LOG_OUT_USER = "REQUEST_LOG_OUT_USER";
export const REQUEST_LOG_OUT_USER_SUCCESS = "REQUEST_LOG_OUT_USER_SUCCESS";
export const REQUEST_LOG_OUT_USER_FAILURE = "REQUEST_LOG_OUT_USER_FAILURE";

export const REQUEST_REGISTER_USER = "REQUEST_REGISTER_USER";
export const REQUEST_REGISTER_USER_SUCCESS = "REQUEST_REGISTER_USER_SUCCESS";
export const REQUEST_REGISTER_USER_FAILURE = "REQUEST_REGISTER_USER_FAILURE";

export const REQUEST_REQUEST_PASSWORD_RESET = "REQUEST_REQUEST_PASSWORD_RESET";
export const REQUEST_REQUEST_PASSWORD_RESET_SUCCESS =
  "REQUEST_REQUEST_PASSWORD_RESET_SUCCESS";
export const REQUEST_REQUEST_PASSWORD_RESET_FAILURE =
  "REQUEST_REQUEST_PASSWORD_RESET_FAILURE";

export const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD";
export const REQUEST_RESET_PASSWORD_SUCCESS = "REQUEST_RESET_PASSWORD_SUCCESS";
export const REQUEST_RESET_PASSWORD_FAILURE = "REQUEST_RESET_PASSWORD_FAILURE";

export const REQUEST_UPDATE_RESPONSE = "REQUEST_UPDATE_RESPONSE";
export const REQUEST_UPDATE_RESPONSE_SUCCESS =
  "REQUEST_UPDATE_RESPONSE_SUCCESS";
export const REQUEST_UPDATE_RESPONSE_FAILURE =
  "REQUEST_UPDATE_RESPONSE_FAILURE";
export const CACHE_UPDATE = "CACHE_UPDATE";
export const REQUEST_SUBMIT_UPDATES = "REQUEST_SUBMIT_UPDATES";
export const REQUEST_SUBMIT_UPDATES_SUCCESS = "REQUEST_SUBMIT_UPDATES_SUCCESS";
export const REQUEST_SUBMIT_UPDATES_FAILURE = "REQUEST_SUBMIT_UPDATES_FAILURE";

export const REQUEST_UPDATE_SUBSCRIPTION = "REQUEST_UPDATE_SUBSCRIPTION";
export const REQUEST_UPDATE_SUBSCRIPTION_SUCCESS =
  "REQUEST_UPDATE_SUBSCRIPTION_SUCCESS";
export const REQUEST_UPDATE_SUBSCRIPTION_FAILURE =
  "REQUEST_UPDATE_SUBSCRIPTION_FAILURE";

export const REQUEST_UPDATE_TRACKER_ENTRY = "REQUEST_UPDATE_TRACKER_ENTRY";
export const REQUEST_UPDATE_TRACKER_ENTRY_SUCCESS =
  "REQUEST_UPDATE_TRACKER_ENTRY_SUCCESS";
export const REQUEST_UPDATE_TRACKER_ENTRY_FAILURE =
  "REQUEST_UPDATE_TRACKER_ENTRY_FAILURE";

export const REQUEST_UPDATE_USER = "REQUEST_UPDATE_USER";
export const REQUEST_UPDATE_USER_SUCCESS = "REQUEST_UPDATE_USER_SUCCESS";
export const REQUEST_UPDATE_USER_FAILURE = "REQUEST_UPDATE_USER_FAILURE";

export const REQUEST_GET_REMINDERS = "REQUEST_GET_REMINDERS";
export const REQUEST_GET_REMINDERS_SUCCESS = "REQUEST_GET_REMINDERS_SUCCESS";
export const REQUEST_GET_REMINDERS_FAILURE = "REQUEST_GET_REMINDERS_FAILURE";

export const REQUEST_ADD_REMINDER = "REQUEST_ADD_REMINDER";
export const REQUEST_ADD_REMINDER_SUCCESS = "REQUEST_ADD_REMINDER_SUCCESS";
export const REQUEST_ADD_REMINDER_FAILURE = "REQUEST_ADD_REMINDER_FAILURE";

export const REQUEST_UPDATE_REMINDER = "REQUEST_UPDATE_REMINDER";
export const REQUEST_UPDATE_REMINDER_SUCCESS =
  "REQUEST_UPDATE_REMINDER_SUCCESS";
export const REQUEST_UPDATE_REMINDER_FAILURE =
  "REQUEST_UPDATE_REMINDER_FAILURE";

export const REQUEST_DELETE_REMINDER = "REQUEST_DELETE_REMINDER";
export const REQUEST_DELETE_REMINDER_SUCCESS =
  "REQUEST_DELETE_REMINDER_SUCCESS";
export const REQUEST_DELETE_REMINDER_FAILURE =
  "REQUEST_DELETE_REMINDER_FAILURE";

export const REQUEST_ADD_UNREGISTERED_USER = "REQUEST_ADD_UNREGISTERED_USER";
export const REQUEST_ADD_UNREGISTERED_USER_SUCCESS =
  "REQUEST_ADD_UNREGISTERED_USER_SUCCESS";
export const REQUEST_ADD_UNREGISTERED_USER_FAILURE =
  "REQUEST_ADD_UNREGISTERED_USER_FAILURE";

export const REQUEST_ADD_FEEDBACK = "REQUEST_ADD_FEEDBACK";
export const REQUEST_ADD_FEEDBACK_SUCCESS = "REQUEST_ADD_FEEDBACK_SUCCESS";
export const REQUEST_ADD_FEEDBACK_FAILURE = "REQUEST_ADD_FEEDBACK_FAILURE";

export const SELECT_PLAN = "SELECT_PLAN";

export const SET_COUPON = "SET_COUPON";

export const SHOW_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const UPDATE_DISPLAY = "UPDATE_DISPLAY";

export const REQUEST_GET_TERMS = "REQUEST_GET_TERMS";
export const REQUEST_GET_TERMS_SUCCESS = "REQUEST_GET_TERMS_SUCCESS";
export const REQUEST_GET_TERMS_FAILURE = "REQUEST_GET_TERMS_FAILURE";

export const REQUEST_SEND_COMPLETION_EMAIL = "REQUEST_SEND_COMPLETION_EMAIL";
export const REQUEST_SEND_COMPLETION_EMAIL_SUCCESS =
  "REQUEST_SEND_COMPLETION_EMAIL_SUCCESS";
export const REQUEST_SEND_COMPLETION_EMAIL_FAILURE =
  "REQUEST_SEND_COMPLETION_EMAIL_FAILURE";

export const REQUEST_GET_USER_EMAIL_ENTRY = "REQUEST_GET_USER_EMAIL_ENTRY";
export const GET_USER_EMAIL_ENTRY_SUCCESS = "GET_USER_EMAIL_ENTRY_SUCCESS";
export const GET_USER_EMAIL_ENTRY_FAILURE = "GET_USER_EMAIL_ENTRY_FAILURE";

export const REQUEST_UPDATE_USER_EMAIL_ENTRY =
  "REQUEST_UPDATE_USER_EMAIL_ENTRY";
export const UPDATE_USER_EMAIL_ENTRY_SUCCESS =
  "UPDATE_USER_EMAIL_ENTRY_SUCCESS";
export const UPDATE_USER_EMAIL_ENTRY_FAILURE =
  "UPDATE_USER_EMAIL_ENTRY_FAILURE";

export const REQUEST_DEACTIVATE_USER = "REQUEST_DEACTIVATE_USER";
export const REQUEST_DEACTIVATE_USER_SUCCESS =
  "REQUEST_DEACTIVATE_USER_SUCCESS";
export const REQUEST_DEACTIVATE_USER_FAILURE =
  "REQUEST_DEACTIVATE_USER_FAILURE";

export const REQUEST_NORMS = "REQUEST_NORMS";
export const REQUEST_NORMS_SUCCESS = "REQUEST_NORMS_SUCCESS";
export const REQUEST_NORMS_FAILURE = "REQUEST_NORMS_FAILURE";
