"use strict";

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import Actions from "actions";

import DrinkCalculator from "components/forms/drink-calculator";

const DrinkCalculatorModal = React.createClass({
  closeModal() {
    if (
      this.props.modal.previousModalNotifications !== "" &&
      _.get(this.props.modal, "previousModalNotifications")
    ) {
      const that = this;
      this.props.dispatch(
        Actions.showModal("appModal", {
          modalNotifications: that.props.modal.previousModalNotifications,
          modalContent: true,
        }),
      );
    } else {
      this.props.dispatch(
        Actions.closeModal("appModal", {
          modalNotifications: "",
          modalContent: false,
        }),
      );
    }
  },

  render() {
    let buttonText = {
      id: "close_modal_drink_calculator",
      description: "Close Drink Calculator Modal",
      defaultMessage: "Close",
    };
    if (
      this.props.modal.previousModalNotifications !== "" &&
      _.get(this.props.modal, "previousModalNotifications")
    ) {
      buttonText = {
        id: "back_modal_drink_calculator",
        description: "Back Drink Calculator Modal",
        defaultMessage: "Back",
      };
    }
    return (
      <div className="container">
        <DrinkCalculator closeModal={this.closeModal} buttonText={buttonText} />
      </div>
    );
  },
});

export default connect((state) => ({
  modal: state.modal,
}))(DrinkCalculatorModal);
