/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React from "react";
import ReactDOM from "react-dom";
let findDOMNode = ReactDOM.findDOMNode;
import Button from "components/ux/mdl/button";

const ButtonSpinner = React.createClass({
  displayName: "ButtonSpinner",

  propTypes: {
    spinner: React.PropTypes.bool,

    /*  additional props --  all other relevant props that will pass through to the
            Material UI "RaisedButton" component rendered inside this component.

            The [prop.spinner] should be set to true when the upstream component
            calls a flux action.  And it should be set to false whenever the
            upstream component receives a state change from the flux stores.
            Since flux actions are (sometimes) asynchronous, the spinner will show
            as long as we are waiting for a response.  */
  },

  // ======= REACT LIFECYCLE ============================

  getInitialState: function () {
    return {};
  },

  UNSAFE_componentWillReceiveProps: function (nextProps) {
    if (nextProps.spinner) {
      this.spinClick();
    } else {
      this.killSpinClick();
    }
  },

  // ======= LOCAL METHODS ==============================

  spinClickActive: false,

  spinClick: function () {
    if (this.spinClickActive) {
      return false;
    }
    this.spinClickActive = true;

    var spinTarget = findDOMNode(this.refs.buttonTarget);
    var spinner = document.createElement("div");
    spinner.classList.add("internal-spinner");
    spinner.innerHTML =
      '<div class="spin-wrapper">' +
      '<div class="bounce1"></div>' +
      '<div class="bounce2"></div>' +
      '<div class="bounce3"></div>' +
      "</div>";

    spinTarget.appendChild(spinner);

    setTimeout(function () {
      document.activeElement.blur();
    }, 150);
  },

  killSpinClick: function () {
    if (this.spinClickActive) {
      this.spinClickActive = false;
      var spinTarget = findDOMNode(this.refs.buttonTarget);
      var spinner = spinTarget.querySelector(".internal-spinner");
      if (spinner) {
        spinTarget.removeChild(spinner);
      }
    }
  },

  // ======== RENDER ====================================

  render: function () {
    let buttonStyle = this.props.spinner
      ? { pointerEvents: "none", opacity: "0.8" }
      : {};
    let style = _.assign(buttonStyle, this.props.style);
    let disabled = this.props.spinner || this.props.disabled;
    let className = `${this.props.className ? this.props.className : ""} ${this.props["class"] ? this.props["class"] : ""} btn-primary`;
    const blacklist = ["className", "class"];
    const htmlProps = Object.entries(this.props).reduce((atts, [key, val]) => {
      if (blacklist.includes(key)) {
        return atts;
      } else {
        return Object.assign({}, atts, {
          [key]: typeof val === "boolean" ? val.toString() : val,
        });
      }
    }, {});
    return (
      <Button
        className={className}
        {...htmlProps}
        ref="buttonTarget"
        disabled={disabled}
        style={style}
      />
    );
  },
});

export default ButtonSpinner;
export { ButtonSpinner };
