"use strict";

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Actions from "actions";
import Moment from "moment";
Moment.locale("en");

import GetWearableLoginCode from "components/forms/wearable-login-code";

const WearableLoginModal = React.createClass({
  propTypes: {
    auth: React.PropTypes.object.isRequired,
    dispatch: React.PropTypes.func.isRequired,
  },

  getInitialState: function () {
    const { auth } = this.props;
    const user_timezone = _.get(auth, "user.user_timezone");

    return {
      isFetching: false,
    };
  },

  closeModal() {
    this.props.dispatch(
      Actions.closeModal("appModal", {
        modalNotifications: "",
        modalContent: false,
      }),
    );
  },

  render() {
    return (
      <div className="container">
        <GetWearableLoginCode isFetching={this.state.isFetching} />
      </div>
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
}))(WearableLoginModal);
