/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import React from "react";
import { connect } from "react-redux";
import { initialize } from "redux-form";
import { browserHistory } from "react-router";
import Actions from "actions";
import UserProfile from "components/forms/user-profile";
import { Container, Card, Row, Col } from "react-bootstrap";
import { Image } from "react-native";
import ChangePasswordForm from "components/forms/ChangePassword.component";
import { Modal } from "react-bootstrap";
import BrandedImage from "../components/blocks/branded-image";

const UserProfilePage = React.createClass({
  propTypes: {
    auth: React.PropTypes.object.isRequired,
    display: React.PropTypes.object,
    dispatch: React.PropTypes.func.isRequired,
    messages: React.PropTypes.object,
  },

  getInitialState() {
    return {
      changePasswordShown: false,
      profile: true,
      showPWModal: false,
      isNewUser: false,
    };
  },

  componentDidMount() {
    this.updateProfileForm();
    const { weight, gender, dt_dob } = this.props.auth?.user ?? {};
    this.setState({ isNewUser: !weight || !gender || !dt_dob });
  },

  UNSAFE_componentWillReceiveProps(newProps) {
    this.updateProfileForm(newProps);
  },

  // =========== LOCAL METHODS ===================
  checkNewUser(isNewUser) {
    this.setState({ isNewUser });
  },

  updateProfileForm(props) {
    props = props || this.props;
    const user = props.auth.user;
    this.props.dispatch(
      initialize("userProfile", {
        name: user.name,
        email: user.email,
        dt_dob: user.dt_dob,
        weight: user.weight,
        weight_unit: user.weight_unit,
        gender: user.gender,
        phone_number: user.phone_number || "",
        user_timezone:
          user.user_timezone ||
          CUAC_SETTINGS.GROUP.default_timezone ||
          "US/Eastern",
        two_factor_auth: user.two_factor_auth,
        medical_history: user.medical_history,
      })
    );
  },

  async onUserProfileUpdate(data) {
    const {
      auth: { user },
    } = this.props;
    const token = _.get(user, "token");
    const userId = _.get(user, "_id");
    this.props.dispatch(Actions.updateDisplay("appSpinner", "PLEASE_WAIT"));
    const res = await this.props.dispatch(
      Actions.updateUser(token, userId, data)
    );
    this.props.dispatch(Actions.updateDisplay("appSpinner", "STOP"));
    browserHistory.push(`/account/medical-history?new=${this.state.isNewUser}`);
  },

  async onPasswordChange(data) {
    const token = _.get(this.props, "auth.user.token");
    const userId = _.get(this.props, "auth.user._id");
    const email = _.get(this.props, "auth.user.email");
    if (!data.newPassword) {
      this.props.dispatch(
        Actions.notify({
          title: "Change Password",
          message: "To change your password, please provide a new password.",
          level: "warning",
          autoDismiss: 3,
        })
      );
      return;
    }
    if (data.newPassword.length < 6) {
      this.props.dispatch(
        Actions.notify({
          title: "Change Password",
          message: "Your new password must be at least 6 characters.",
          level: "warning",
          autoDismiss: 3,
        })
      );
      return;
    }
    if (!data.password) {
      this.props.dispatch(
        Actions.notify({
          title: "Change Password",
          message: "You must provide your old password for verification",
          level: "warning",
          autoDismiss: 3,
        })
      );
      return;
    }
    try {
      const res = await this.props.dispatch(
        Actions.changePassword(
          token,
          userId,
          data.password,
          data.newPassword,
          email
        )
      );
      this.setState({ showPWModal: false });
    } catch (e) {}
  },
  render() {
    const isFetching = _.get(this, "props.auth.isFetching"),
      message = _.get(this, "props.messages"),
      medicalHistory = _.get(this, "props.auth.user.medical_history");

    let user = this.props.auth.user;
    if (
      _.get(user, "weight_unit") === "kilograms" &&
      !_.get(user, "converted")
    ) {
      user.weight = Math.round(user.weight / 2.20462);
      user.converted = true;
    }

    return (
      <Container>
        <div className="mt-4 d-flex flex-column flex-lg-row">
          <div style={{ flex: 1 }}>
            <Card style={{ border: "none" }}>
              <Card.Body>
                <UserProfile
                  onSubmit={this.onUserProfileUpdate}
                  onToggleChangePassword={() =>
                    this.setState({ showPWModal: true })
                  }
                  isFetching={isFetching}
                  medicalHistory={medicalHistory}
                  user={user}
                  checkNewUser={this.checkNewUser}
                  message={message}
                  kilogramWeight={
                    _.has(CUAC_SETTINGS, "GROUP.weight_in_kg")
                      ? _.get(CUAC_SETTINGS, "GROUP.weight_in_kg")
                      : false
                  }
                  showProfile={true}
                />
              </Card.Body>
            </Card>
          </div>
          <div style={{ flex: 1 }}>
            <BrandedImage
              image_type="profile_image"
              default_image={require("assets/profile.png")}
            />
          </div>
        </div>
        <Modal
          show={this.state.showPWModal}
          onHide={() => this.setState({ showPWModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ChangePasswordForm onSubmit={this.onPasswordChange} />
          </Modal.Body>
        </Modal>
      </Container>
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
  display: state.display,
  messages: state.messages,
  tools: state.tools,
}))(UserProfilePage);
