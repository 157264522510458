"use strict";

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { initialize } from "redux-form";
import { browserHistory } from "react-router";
import Actions from "actions";
import { param } from "lib/query-params";
import { REQUEST_ADD_SUBSCRIPTION_SUCCESS } from "actions/types";
import { PlanList } from "components/account/plan-list";

const PlanSelectionPage = React.createClass({
  propTypes: {
    plans: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    auth: React.PropTypes.object,
    subscriptions: React.PropTypes.arrayOf(React.PropTypes.object),
  },

  selectPlan(plan) {
    this.props.dispatch(Actions.selectPlan(plan)).then((result) => {
      if (this.props.auth.user.status == "unregistered") {
        browserHistory.push("/register");
      } else {
        browserHistory.push("/account/payment");
      }
    });
  },

  componentDidUpdate(prevProps) {
    const { plans, subscriptions } = this.props;
    if (plans.length > (prevProps?.plans?.length || 0)) {
      const hasSubscription = !_.isEmpty(subscriptions);

      const plan_selection = param("plan");
      const onSelectPlan = hasSubscription ? this.changePlan : this.selectPlan;
      if (
        CUAC_SETTINGS.GROUP.plan_select_url &&
        plan_selection &&
        plans.filter((plan) => plan._id === plan_selection).length > 0
      ) {
        onSelectPlan(plans.filter((plan) => plan._id == plan_selection)[0]);
      }
    }
  },

  addFreeSubscription(plan) {
    const { auth } = this.props;
    const amount = _.get(plan, "stripe_data.amount");
    if (amount === 0) {
      const token = _.get(auth, "user.token");
      const userId = _.get(auth, "user._id");
      this.spinnerStart("ADDING_SUBSCRIPTION");
      this.props
        .dispatch(Actions.addSubscription(token, userId, plan))
        .then((result) => {
          this.spinnerStop();
          if (result.type === REQUEST_ADD_SUBSCRIPTION_SUCCESS) {
            browserHistory.push("/account");
          }
        });
    }
  },

  changePlan(plan) {
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "confirmPlanChange",
        modalContent: true,
        modalProps: {
          plan: plan,
        },
      }),
    );
  },

  clearCoupon() {
    this.props.dispatch(Actions.setCoupon(""));
  },

  saveCoupon(data) {
    this.props.dispatch(Actions.setCoupon(data.coupon_code.toLowerCase()));
    this.props.dispatch(initialize("coupon"));
  },

  spinnerStart(messageKey) {
    this.props.dispatch(Actions.updateDisplay("appSpinner", messageKey));
  },

  spinnerStop() {
    this.props.dispatch(Actions.updateDisplay("appSpinner", "STOP"));
  },

  render() {
    const { plans, subscriptions } = this.props;
    const hasSubscription = !_.isEmpty(subscriptions);
    const onSelectPlan = hasSubscription ? this.changePlan : this.selectPlan;
    return (
      <div className="container screener-plans">
        <div className="row">
          <div className="col">
            <PlanList
              plans={plans}
              onSelectPlan={onSelectPlan}
              onAddFreeSubscription={this.addFreeSubscription}
              userRegistered={true}
              showCouponCapture={
                this.props.hasCoupons && _.isEmpty(this.props.subscriptions)
              }
            />
          </div>
        </div>
      </div>
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
  hasCoupons: state.plans.hasCoupons,
  plans: _.filter(state.plans.plans, (plan) => {
    return (
      _.get(plan, "coupon_code", "").toLowerCase() === state.plans.couponCode
    );
  }),
  subscriptions: _.get(state, "subscriptions.subscriptions.data"),
}))(PlanSelectionPage);
