"use strict";

import _ from "lodash";
import React from "react";
import { findDOMNode } from "react-dom";

import classnames from "classnames";

const baseClasses = {
  accordion: true,
};

const Accordion = React.createClass({
  propTypes: {
    className: React.PropTypes.string,
    style: React.PropTypes.object,
    collapsed: React.PropTypes.bool.isRequired,
  },

  getInitialState() {
    return { height: 100 };
  },

  componentDidMount() {
    this.setHeight();
    window.addEventListener("resizeend", this.setHeight);
  },

  componentWillUnmount() {
    window.removeEventListener("resizeend", this.setHeight);
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.collapsed) {
      setTimeout(this.setHeight, 50);
    }
  },

  setHeight() {
    const inner = findDOMNode(this.refs.inner);
    if (inner) {
      const innerDiv = inner.getBoundingClientRect();
      const innerHeight = Number(innerDiv.height + 12);
      this.setState({ height: innerHeight });
    }
  },

  render() {
    const { collapsed, className } = this.props,
      stateHeight = this.state.height,
      accordionClass = classnames(baseClasses, className),
      accordionStyle = {
        overflow: "hidden",
        position: "relative",
        transition: "height 300ms",
        height: collapsed ? "0px" : stateHeight + "px",
        display: collapsed ? "none" : "block",
      };

    return (
      <div className={accordionClass} style={accordionStyle}>
        <div className="accordion-inner" ref="inner">
          {this.props.children}
        </div>
      </div>
    );
  },
});

export default Accordion;
