import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Field } from "redux-form";
import { range } from "range";
import Moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesome } from "@expo/vector-icons";
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

class DateInputReduxForm extends React.Component {
  render() {
    if (this.props.labelText) {
      return (
        <Field
          name={this.props.name}
          component={(field) => {
            return (
              <div>
                <div
                  className={`input-group mb-3 input-group-fancy flex-nowrap ${!!field.meta?.error ? " has-validation" : ""}`}
                >
                  {this.renderDom71(field)}
                </div>
                {!!field.meta?.error && (
                  <small className="text-danger">{field.meta.error}</small>
                )}
              </div>
            );
          }}
        />
      );
    } else {
      return (
        <Field name={this.props.name} component={this.renderDom71.bind(this)} />
      );
    }
  }

  renderCustomHeader({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) {
    const years = range(
      new Date().getFullYear() - 120,
      new Date().getFullYear() + 1,
      1,
    );
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      <div
        style={{
          margin: 10,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            decreaseMonth();
          }}
          disabled={prevMonthButtonDisabled}
        >
          {"<"}
        </button>
        <select
          value={date.getFullYear()}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <select
          value={months[date.getMonth()]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <button
          onClick={(e) => {
            e.preventDefault();
            increaseMonth();
          }}
          disabled={nextMonthButtonDisabled}
        >
          {">"}
        </button>
      </div>
    );
  }

  renderDom71(field) {
    const renderDatePicker = (startDate, setStartDate) => {
      if (this.props.showTimeInput || this.props.showTimeSelect) {
        return (
          <DateTimePicker
            label={this.props.labelText}
            defaultValue={startDate}
            className="form-control"
            onChange={(date) => {
              setStartDate(date);
              field.input.onChange(date);
              if (this.props.onChange) this.props.onChange(date);
            }}
            maxDate={Moment().add(1, "y").toDate()}
            // {...(this.props.customInput ? { customInput: this.props.customInput } : {})}
          />
        );
      } else if (this.props.showTimeSelectOnly) {
        return (
          <TimePicker
            label={this.props.labelText}
            defaultValue={startDate}
            className="form-control"
            onChange={(date) => {
              setStartDate(date);
              field.input.onChange(date);
              if (this.props.onChange) this.props.onChange(date);
            }}
          />
        );
      } else {
        return (
          <DatePicker
            label={this.props.labelText}
            defaultValue={startDate}
            className="form-control"
            onChange={(date) => {
              setStartDate(date);
              field.input.onChange(date);
              if (this.props.onChange) this.props.onChange(date);
            }}
            maxDate={Moment().add(1, "y")}
          />
        );
      }
    };
    let existingDate = this.props.value || field.input.value;
    if (!existingDate) {
      existingDate = new Date();
      existingDate.setFullYear(existingDate.getFullYear() - 41);
      field.input.onChange(existingDate);
    } else if (typeof existingDate === "string") {
      existingDate = Moment(existingDate).toDate();
    }
    const [startDate, setStartDate] = useState(Moment(existingDate));
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className={this.props.wrapperClassName}>
          {renderDatePicker(startDate, setStartDate)}
        </div>
      </LocalizationProvider>
    );
  }
}

export default DateInputReduxForm;
export { DateInputReduxForm };
