"use strict";

import { handleResponse } from "utils/api";

export const getPlans = (token, groupId) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/plans?group_id=" + groupId, {
    method: "GET",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};
