import React from "react";
import { BLUE, LIGHTER_FOREST_GREEN } from "../../stylesheets/colors";
import { FormCheck } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Field } from "redux-form";

type InputProps = {
  input?: {
    name: string;
    value?: string;
    onChange: (data: any) => void;
  };
  value?: string;
  name?: string;
  onChange?: (data: any) => void;
  option: any;
  fieldContainerClassName?: string;
  inputClassName?: string;
  label: string;
};

const Input = (props: InputProps) => {
  const id = () => {
    const name = "name" in props ? props.name : props.input.name;
    return `${name}_${props.option.value}`;
  };

  const checked = () => {
    const val = "value" in props ? props.value : props.input.value;
    if (val === undefined) return false;
    return val.toString() === props.option.value.toString();
  };

  return (
    <div className={props.fieldContainerClassName || ""}>
      <style>
        {`
                  .form-check-input:checked {
                    background-color: ${BLUE};
                    border-color: ${BLUE};
                  }
                  `}
      </style>
      <FormCheck.Input
        type="radio"
        onChange={"onChange" in props ? props.onChange : props.input.onChange}
        value={props.option?.value}
        id={id()}
        checked={checked()}
        className={props.inputClassName || ""}
        name={props.name}
      />
      <FormCheck.Label htmlFor={id()}>{props.label}</FormCheck.Label>
    </div>
  );
};

type Props = {
  labelClassName?: string;
  fieldsetClassName?: string;
  title?: string;
  name: string;
  options: any[];
  containerClassName?: string;
  fieldContainerClassName?: string;
  inputClassName?: string;
  key?: string;
  errorText?: string;
  extreme_first?: React.ReactNode | string;
  extreme_last?: React.ReactNode | string;
};

export const RadioGroup = (props: Props) => {
  return (
    <fieldset
      role="group"
      className={`justify-content-between ${props.fieldsetClassName}`}
    >
      {props.extreme_first && (
        <div className="me-2 p-2 rounded text-small w-sm-100">
          {props.extreme_first}
        </div>
      )}
      {props.options.map((item, idx) => (
        <div key={`${props.name}_${item.value}`}>
          <Input
            option={item}
            label={<FormattedMessage {...item.text} />}
            name={props.name}
            onChange={props.onChange}
            value={props.value}
            fieldContainerClassName={props.fieldContainerClassName}
            inputClassName={props.inputClassName}
          />
        </div>
      ))}
      {props.extreme_last && (
        <div className="ms-2 p-2 rounded text-small w-sm-100 text-end text-md-start">
          {props.extreme_last}
        </div>
      )}
    </fieldset>
  );
};

const RadioGroupReduxForm = (props: Props) => {
  return (
    <div
      key={props.key}
      className={`input-group d-flex justify-content-between align-items-center mb-3 ${props.errorText && props.errorText.length ? "invalid" : ""} ${props.containerClassName || ""}`}
    >
      {props.title && (
        <label
          className={`d-flex flex-column ${props.labelClassName || "form-label"}`}
        >
          {props.title}
          {props.errorText && (
            <small className="text-danger">{props.errorText}</small>
          )}
        </label>
      )}
      <fieldset role="group" className={props.fieldsetClassName}>
        {props.extreme_first && (
          <div className="me-2 p-2 rounded text-small">
            {props.extreme_first}
          </div>
        )}
        {props.options.map((item, idx) => (
          <div key={`${item.value}_${idx}`}>
            <Field
              name={props.name}
              component={Input}
              label={<FormattedMessage {...item.text} />}
              {...props}
              option={item}
              fieldContainerClassName={props.fieldContainerClassName}
              inputClassName={props.inputClassName}
            />
          </div>
        ))}
        {props.extreme_last && (
          <div className="ms-2 p-2 rounded text-small">
            {props.extreme_last}
          </div>
        )}
      </fieldset>
    </div>
  );
};

export default RadioGroupReduxForm;
