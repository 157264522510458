"use strict";

import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import { validator } from "utils/tools";
import BSInput from "../blocks/BSInput.component.web";
import BSButton from "../blocks/BSButton.component.web";
import { FontAwesome } from "@expo/vector-icons";

const messages = defineMessages({
  forgot_password_label_email: {
    id: "forgot_password_label_email",
    description: "Label for forgot password input",
    defaultMessage: "Email Address",
  },
});

const ForgotPassword = React.createClass({
  propTypes: {
    fields: React.PropTypes.object.isRequired,
    handleSubmit: React.PropTypes.func.isRequired,
    onRequestAgain: React.PropTypes.func.isRequired,
    message: React.PropTypes.string,
  },

  componentDidMount() {
    const {
      fields: { email },
      emailValue,
    } = this.props;
    if (emailValue) this.props.change("email", emailValue);
  },

  renderRequestSent: function () {
    return (
      <>
        <p className="h5">
          <FormattedMessage
            id="forgot_password_sent_title"
            defaultMessage="Request Sent"
          />
        </p>
        <p>
          <FormattedMessage
            id="forgot_password_sent_email_shortly"
            defaultMessage="We will send you an email shortly. "
          />
          <FormattedMessage
            id="forgot_password_sent_please_check"
            defaultMessage="Please check your email and follow the provided link."
          />
        </p>
        <div className="">
          <BSButton variant="green" onClick={this.props.onRequestAgain}>
            <FontAwesome name="refresh" color="white" size={20} />
            <div className="ms-2">
              <FormattedMessage
                id="forgot_password_sent_again"
                defaultMessage="Request Again"
              />
            </div>
          </BSButton>
        </div>
      </>
    );
  },

  isValid() {
    const { errors, email } = this.props;
    return email && email.length && !Object.keys(errors || {}).length;
  },

  renderRequestForm() {
    const {
      handleSubmit,
      intl: { formatMessage },
    } = this.props;
    return (
      <form
        id="forgot_password"
        name="forgot_password"
        className="d-flex flex-column"
      >
        <p className="text-muted">
          <FormattedMessage
            id="forgot_password_instructions_1"
            defaultMessage="Request a password reset. "
          />
          <FormattedMessage
            id="forgot_password_instructions_2"
            defaultMessage="We will send you an email with directions."
          />
        </p>
        <Field
          name="email"
          component={BSInput}
          label={formatMessage(messages.forgot_password_label_email)}
        />
        <div className="mt-3 d-flex align-items-center">
          <BSButton
            variant="green"
            disabled={!this.isValid()}
            loading={this.props.isFetching}
            onClick={handleSubmit}
          >
            <FontAwesome name="star-o" color="white" size={20} />
            <div className="ms-2">
              <FormattedMessage
                id="forgot_password_button_submit"
                description="Submit the password forgot form"
                defaultMessage="Submit Request"
              />
            </div>
          </BSButton>
          <BSButton className="ms-2" variant="link-green" linkTo="/login">
            <FormattedMessage
              id="forgot_password_back"
              description="Forgot password back"
              defaultMessage="Back to Login"
            />
          </BSButton>
        </div>
      </form>
    );
  },

  render: function () {
    const submitted = this.props.message === "SUBMITTED",
      renderRequestForm = this.renderRequestForm,
      renderRequestSent = this.renderRequestSent;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2">
            <div className="card mt-4" style={{ border: "none" }}>
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <h3 className="card-title">
                    <FormattedMessage
                      id="forgot_password_title"
                      description="Title for Password Request page"
                      defaultMessage="Password Reset"
                    />
                  </h3>
                </div>
                {submitted ? renderRequestSent() : renderRequestForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

const formFields = ["email"];
let form = reduxForm({
  form: "forgot_password",
  fields: formFields,
  validate: validator({
    email: { required: "Required", email: "Please enter a valid email" },
  }),
})(ForgotPassword);
const selector = formValueSelector("forgot_password"); // <-- same as form name

form = connect((state) => ({
  ...formFields.reduce(
    (acc, key) => ({ ...acc, [key]: selector(state, key) }),
    {},
  ),
  errors: state.form?.forgot_password?.syncErrors,
}))(form);

export default injectIntl(form);
