/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, Stripe */
"use strict";

import React from "react";

import { calculateResponses } from "utils/formulas";
import { getScoreOptionIndex } from "utils/render";
import { Block } from "components/blocks/block";

export const Switch = React.createClass({
  propTypes: {
    addTrackerEntry: React.PropTypes.func.isRequired,
    formula: React.PropTypes.object.isRequired,
    options: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    responses: React.PropTypes.object.isRequired,
    trackerEntries: React.PropTypes.object.isRequired,
    updateResponse: React.PropTypes.func.isRequired,
  },

  render() {
    const {
      addTrackerEntry,
      formula,
      norms,
      options,
      responses,
      trackerEntries,
      updateResponse,
      userProfile,
      updateProgressManually,
      module,
      section,
      tool,
      tools,
      auth,
      dispatch,
      allResponses,
    } = this.props;
    const mergedResponses = {
      ...responses,
      ...userProfile,
    };
    const calculatedResponses = calculateResponses(
      [formula],
      {},
      mergedResponses,
    );
    const score = _.get(calculatedResponses, formula.data_key, 0);
    const scoreOptionIndex = getScoreOptionIndex(score, options);

    return (
      <div className="conditional-options">
        {options[scoreOptionIndex].components.map((component, index) => {
          return (
            <div className="my-4" key={"block_switch_" + index}>
              <Block
                component={component}
                index={index}
                norms={norms}
                responses={responses}
                allResponses={allResponses}
                userProfile={userProfile}
                updateResponse={updateResponse}
                addTrackerEntry={addTrackerEntry}
                trackerEntries={trackerEntries}
                updateProgressManually={updateProgressManually}
                module={module}
                section={section}
                tool={tool}
                tools={tools}
                auth={auth}
                dispatch={dispatch}
              />
            </div>
          );
        })}
      </div>
    );
  },
});
