/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import { StyleSheet, View } from "react-native";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { getScoreOptionIndex } from "utils/render";
import Actions from "actions";
import { ProgressBoxes } from "components/elements/progress-boxes";
import Selectors from "selectors";
import { calculateResponses } from "utils/formulas";
import { Block } from "components/blocks/block";
import { Ionicons } from "@expo/vector-icons";
import { FOREST_GREEN } from "../stylesheets/colors";
import BSButton from "../components/blocks/BSButton.component";
import { Spinner } from "react-bootstrap";
import { applyResponse } from "../actions/add-response";
import SaveToolButton from "../components/blocks/SaveToolButton";
import { submitUpdates } from "../actions/update-response";
import { getResponses } from "../api/responses";
import Cookies from "universal-cookie";
import { Helmet } from "react-helmet";

let renderedCallback = function () {};
const { screenerPageSelector, userProfileSelector } = Selectors;
const rendered = new Promise((good) => (renderedCallback = good));

const cookies = new Cookies();

const styles = StyleSheet.create({
  titleMargin: {
    marginTop: "10vh",
    marginBottom: "3vh",
  },
});

export const ScreenerPage = React.createClass({
  propTypes: {
    activeResponseId: React.PropTypes.string,
    activeResponse: React.PropTypes.object,
    auth: React.PropTypes.object,
    page: React.PropTypes.object,
    tool: React.PropTypes.object.isRequired,
  },

  getCookieName() {
    return `user-token-${_.get(this.props, "tool._id")}`;
  },

  createUserTokenCookie() {
    const { page } = this.props;
    const cookie_name = this.getCookieName();
    const cookie = {
      id: _.get(this.props, "auth.user._id"),
      token: _.get(this.props, "auth.user.token"),
      page: page.slug,
    };
    const expires = new Date();
    expires.setSeconds(expires.getSeconds() + 2 * 24 * 60 * 60); // 2 days for expire
    cookies.remove(cookie_name);
    cookies.set(cookie_name, cookie, {
      path: "/",
      expires: expires,
      sameSite: true,
    });
    return cookies.get(cookie_name);
  },

  getInitialState() {
    return {
      isSaving: false,
    };
  },

  componentDidMount() {
    const cookie_name = this.getCookieName();
    // Name of stored cookie reflects the screener being used
    let cookie_data = cookies.get(cookie_name);

    // Cookie does not exist and is not empty data
    if (!!!cookie_data || Object.keys(cookie_data).length === 0) {
      cookie_data = this.createUserTokenCookie(cookie_name);
    }

    const id = cookie_data.id;
    const token = cookie_data.token;
    let page = null;
    getResponses(token, id)
      .then((res) => {
        if (_.isEmpty(res[0].responses)) {
          // User has probably registered the ID associated with this cookie
          // Cannot access past data if registered w/o an auth key -- Just make a new cookie
          this.createUserTokenCookie(cookie_name);
        } else {
          //Grab the last known page, default will be the landing page
          page = cookie_data.page;
          this.props.dispatch(
            applyResponse(this.props.activeResponseId, res[0].responses)
          );
        }
      })
      .catch(() => {
        // The cookie data is old
        // In this case we will just create a valid token and continue as normal
        this.createUserTokenCookie(cookie_name);
      })
      .finally(() =>
        rendered.then(() => {
          this.ensureResponse();
          if (page) browserHistory.push(`screener/${page}`);
        })
      );
  },

  componentDidUpdate(prevProps, prevState) {
    // check if our page changed
    if (prevProps.page !== this.props.page) {
      this.createUserTokenCookie();
      // Remove focus from any focused element
      if (document.activeElement) {
        document.activeElement.blur();
        // document.getElementById('homeNavButton').focus();
      }
    }
  },

  showHelpFeedbackModal() {
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "helpFeedback",
        modalContent: true,
      })
    );
  },

  addResponse(props) {
    props = props || this.props;
    const { tool } = props;
    const token = _.get(props, "auth.user.token");
    const userId = _.get(props, "auth.user._id");
    this.props.dispatch(Actions.addResponse(token, userId, tool));
  },

  ensureResponse(props) {
    props = props || this.props;
    const { activeResponse } = props;
    if (!activeResponse) {
      this.addResponse(props);
    } else if (
      this.props.tool &&
      this.props.tool._id &&
      this.props.tool._id !== activeResponse.tool_id
    ) {
      this.addResponse(props);
    }
  },

  updateResponse(data, formulas) {
    // build an array of the immediately impacted formulas, as well as any that need to be
    // re-calculated upstream
    const impactedFormulas = [];
    (formulas || []).forEach((formula) => {
      impactedFormulas.push(formula);
      const { impacts_formulas } = formula;
      if (Array.isArray(impacts_formulas)) {
        impacts_formulas.forEach((dataKey) => {
          const { [dataKey]: x } = (this.props.tool || {}).formulas || {};
          if (x) impactedFormulas.push(x);
        });
      }
    });
    for (const key in data) {
      // check for elements in case of prepopulated answers
      if (typeof document.getElementsByName(key)[0] !== "undefined") {
        const element =
          document.getElementsByName(key)[0].parentElement.parentElement
            .parentElement;
        element.className = element.className.replace("needs-answer", "");
      }
    }
    const { activeResponse, activeResponseId, auth, dispatch, tool } =
      this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    const calculatedData = calculateResponses(
      impactedFormulas,
      _.get(activeResponse, "responses", {}),
      data
    );
    this.props.dispatch(applyResponse(activeResponseId, calculatedData));
    this.props.dispatch(
      Actions.updateResponse(
        token,
        userId,
        activeResponseId,
        calculatedData,
        tool
      )
    );
  },

  onClickLink: function (target) {
    const { dispatch } = this.props;
    // dispatch(push(target));
    browserHistory.push(target);
  },

  async submitUpdates() {
    const { token, _id: userId } = this.props.auth?.user ?? {};
    const { _id: responseId } = this.props.activeResponse ?? {};
    if (!token || !userId || !responseId) return;

    return this.props.dispatch(
      submitUpdates(
        token,
        userId,
        responseId,
        this.props.tool,
        this.props.tool,
        this.props.page
      )
    );
  },

  async saveButtonClick() {
    this.setState({ isSaving: true });
    let res;
    try {
      res = await this.submitUpdates();
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isSaving: false });
    }
    return res;
  },

  checkAnswers(event, next_page) {
    if (event) event.preventDefault();
    const page = this.props.page;
    const responses = this.props.activeResponse?.responses ?? {};
    let requireIf = true;

    let unresponsedElement = null;

    for (let i = 0; i < page.components.length; i++) {
      const components = page.components[i];
      if (_.get(components, "fields")) {
        _.forEach(components.fields, function (field) {
          const isAnsweredField = !!_.get(responses, field.id, false);

          let element = document.getElementsByName(field.id);
          if (element && element.length) {
            element = element[0].parentElement?.parentElement?.parentElement;
            if (element) {
              // remove answer class
              element.className = element.className.replace("needs-answer", "");

              if (!isAnsweredField && !unresponsedElement) {
                requireIf = false;
                // add the invalid class
                element.className += " needs-answer";
                unresponsedElement = element;
              }
            }
          }
        });
      }
    }
    if (!requireIf) {
      if (unresponsedElement) {
        unresponsedElement.scrollIntoView({ behavior: "smooth" });
      }

      this.props.dispatch(
        Actions.notify({
          title: "Page not complete",
          message: "Please answer all the questions.",
          level: "error",
          autoDismiss: 4,
        })
      );
    } else {
      // this.props.dispatch(push(next_page));
      browserHistory.push(next_page);
    }
  },

  getNextPage() {
    const { page, activeResponse } = this.props;
    let next_page = null;
    if (
      this.props.location.search.indexOf("?plan=") > -1 &&
      _.get(page, "next_page")
    ) {
      const plan_code = this.props.location.search.replace("?plan=", "");
      next_page = _.get(page, "next_page") + "?plan=" + plan_code;
    } else if (_.get(page, "next_page_parameter")) {
      // if the page has a next page parameter, we need to get the right response
      // set up our variables
      const next_page_parameter = _.get(page, "next_page_parameter");
      const score = _.get(activeResponse, next_page_parameter);
      const options = _.get(page, "next_page_options");
      // figure out which option we want
      const scoreOptionIndex = getScoreOptionIndex(score, options);
      // set our next page url
      next_page = options[scoreOptionIndex].next_page;
    } else if (_.get(page, "next_page")) {
      // if we only have a next page parameter, just use that
      next_page = _.get(page, "next_page");
    }
    return next_page;
  },

  navBtns() {
    const { activeResponse, page, tool, userProfile } = this.props,
      btns = [];
    if (_.get(page, "previous_page")) {
      btns.push(
        <BSButton
          variant="link-green"
          linkTo={page.previous_page}
          disabled={CUAC_SETTINGS.GROUP.isDisabled}
        >
          <Ionicons name="arrow-back" size={24} color={FOREST_GREEN} />
          <div className="ps-1">
            <FormattedMessage
              id="screener_button_previous"
              className="button-text"
              description="Take the user to the previous page of the screener"
              defaultMessage="Previous"
            />
          </div>
        </BSButton>
      );
    }
    let next_page = this.getNextPage();
    if (!!CUAC_SETTINGS.GROUP.save_enabled) {
      btns.push(
        <SaveToolButton
          save={this.saveButtonClick}
          disabled={this.state.isSaving}
        />
      );
    }

    if (next_page) {
      btns.push(
        <BSButton
          disabled={this.state.isSaving || CUAC_SETTINGS.GROUP.isDisabled}
          variant="green"
          onClick={(e) => this.checkAnswers(e, next_page)}
        >
          <div className="pe-1">
            <FormattedMessage
              id="screener_button_next"
              description="Take the user to the next page of the screener"
              defaultMessage="Next"
            />
          </div>
          <Ionicons name="arrow-forward" size={24} color="white" />
        </BSButton>
      );
    }
    return (
      <div className="sequence-nav">
        <div
          className={`sequence-buttons d-flex align-items-center ${
            btns.length > 1 ? "justify-content-between" : "justify-content-end"
          }`}
        >
          {btns}
        </div>
      </div>
    );
  },

  render() {
    if (!this.props.tool) {
      return (
        <div>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    } else {
      renderedCallback();
    }
    const { activeResponse, page, tool, userProfile } = this.props;
    const { metadata: metadata } = tool;

    const navBtns = this.navBtns();

    return (
      <div className="container mb-4 page-screener screener">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Free Assessment</title>
          <meta
            name="description"
            content="Answer these ten simple questions to scientifically determine if your alcohol use is putting you at risk."
          />
        </Helmet>

        <div className="section section-screener row justify-content-center">
          <div className="material-input-wrap col-md-7">
            <div className="mb-3">
              <ProgressBoxes
                linkPrefix="/screener"
                order={metadata.order}
                page={page}
                pages={metadata.pages}
                onClickLink={this.onClickLink}
                response={activeResponse}
              />
            </div>
            <h1 className="section-title my-4">
              <FormattedMessage {...page.long_title} />
            </h1>
            {
              // If this is throwing an error you likely need to upload/update page components to your mongo DB. If you used a dump please check to make sure all datapoints have data
              page.components.map((component, index) => {
                return (
                  <Block
                    key={"block_" + index}
                    component={component}
                    index={index}
                    responses={_.get(activeResponse, "responses", {})}
                    userProfile={userProfile}
                    updateResponse={this.updateResponse}
                  />
                );
              })
            }
            <div className="mt-3">{navBtns}</div>
          </div>
        </div>
      </div>
    );
  },
});

export default connect((state, props) => ({
  auth: state.auth,
  activeResponseId: state.responses.activeResponseId,
  activeResponse: _.get(
    state.responses[state.responses.activeResponseId],
    "response"
  ),
  ...screenerPageSelector(state, props),
  ...userProfileSelector(state),
}))(ScreenerPage);
