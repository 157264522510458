"use strict";
import _ from "lodash";

export const isToolInPlan = (user, plans, tool_id) => {
  const plan_id = _.get(user, "stripe_customer.subscriptions.data[0].plan.id");
  let tool_ids = [];
  if (plan_id && Array.isArray(plans)) {
    // get our tool ids
    for (var i = 0; i < plans.length; i++) {
      if (plans[i].stripe_key === plan_id) {
        tool_ids = plans[i].tool_ids;
      }
    }
    if (tool_ids.indexOf(tool_id) === -1) {
      return false;
    } else {
      return true;
    }
  } else {
    // defaults to true
    return true;
  }
};
