"use strict";

import React from "react";
import { connect } from "react-redux";
import Actions from "actions";
import PasswordResetRequest from "components/forms/password-reset-request";

export const PasswordResetRequestPage = React.createClass({
  //mixins: [],
  //statics: {},
  //propTypes: {},
  //getInitialState() {},
  //getDefaultProps() {},
  //componentWillMount() {},
  //componentDidMount() {},
  //componentWillReceiveProps(nextProps) {},
  //shouldComponentUpdate(nextProps, nextState) {},
  //componentWillUpdate(nextProps, nextState) {},
  //componentDidUpdate(prevProps, prevState) {},
  //componentWillUnmount() {},

  onSubmit(data) {
    this.props.dispatch(Actions.requestPasswordReset(data.email));
  },

  onRequestAgain() {
    this.props.dispatch(Actions.dismissMessage("requestReset"));
  },

  render() {
    const isFetching = _.get(this, "props.auth.isFetching"),
      message = _.get(this, "props.messages.requestReset");
    return (
      <PasswordResetRequest
        isFetching={isFetching}
        message={message}
        onSubmit={this.onSubmit}
        emailValue={this.props.location.query.email}
        onRequestAgain={this.onRequestAgain}
      />
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
  messages: state.messages,
}))(PasswordResetRequestPage);
