"use strict";

import React from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

export const PrintProgress = React.createClass({
  render() {
    const { responses, tool, userProfile } = this.props;
    let activeResponse = {};
    for (var key in responses) {
      if (_.get(responses[key], "response.tool_id") == tool._id) {
        activeResponse = responses[key].response;
      }
    }
    const progress = _.get(activeResponse, "progress");
    let trs = [];
    tool.metadata.order.map((moduleId, moduleIndex) => {
      const module = tool.metadata.modules[moduleId];
      const moduleProgress = _.get(progress, "modules." + moduleId),
        responses = _.get(moduleProgress, "response_count", 0),
        questions = _.get(moduleProgress, "question_count", 0),
        visited = _.get(moduleProgress, "visited"),
        completed = questions === responses && visited,
        dt_updated = new Date(_.get(moduleProgress, "dt_updated"));
      trs.push(
        <tr key={module.long_title.id + "-" + moduleIndex}>
          <td>
            <FormattedMessage {...module.long_title} />
          </td>
          <td>
            {completed ? "Completed" : visited ? "In Progress" : "Not Started"}
          </td>
          <td>
            {!isNaN(dt_updated.getTime())
              ? dt_updated.getMonth() +
                1 +
                "/" +
                dt_updated.getDate() +
                "/" +
                dt_updated.getFullYear()
              : null}
          </td>
        </tr>,
      );
      module.order.map((sectionId, sectionIndex) => {
        const section = module.sections[sectionId];
        const sectionProgress = _.get(moduleProgress, "sections." + sectionId),
          responses = _.get(sectionProgress, "response_count", 0),
          questions = _.get(sectionProgress, "question_count", 0),
          visited = _.get(sectionProgress, "visited"),
          completed = questions === responses && visited,
          dt_updated = new Date(_.get(sectionProgress, "dt_updated"));
        trs.push(
          <tr
            key={section.long_title.id + "-" + moduleIndex + "-" + sectionIndex}
          >
            <td>
              <FormattedMessage {...section.long_title} />
            </td>
            <td>
              {completed
                ? "Completed"
                : visited
                  ? "In Progress"
                  : "Not Started"}
            </td>
            <td>
              {!isNaN(dt_updated.getTime())
                ? dt_updated.getMonth() +
                  1 +
                  "/" +
                  dt_updated.getDate() +
                  "/" +
                  dt_updated.getFullYear()
                : null}
            </td>
          </tr>,
        );

        section.order.map((pageId, pageIndex) => {
          const page = section.pages[pageId];
          const pageProgress = _.get(sectionProgress, "pages." + pageId),
            responses = _.get(pageProgress, "response_count", 0),
            questions = _.get(pageProgress, "question_count", 0),
            visited = _.get(pageProgress, "visited"),
            completed = questions === responses && visited,
            dt_updated = new Date(_.get(pageProgress, "dt_updated"));
          trs.push(
            <tr
              key={
                page.long_title.id +
                "-" +
                moduleIndex +
                "-" +
                sectionIndex +
                "-" +
                pageIndex
              }
            >
              <td>
                <FormattedMessage {...page.long_title} />
              </td>
              <td>
                {completed
                  ? "Completed"
                  : visited
                    ? "In Progress"
                    : "Not Started"}
              </td>
              <td>
                {!isNaN(dt_updated.getTime())
                  ? dt_updated.getMonth() +
                    1 +
                    "/" +
                    dt_updated.getDate() +
                    "/" +
                    dt_updated.getFullYear()
                  : null}
              </td>
            </tr>,
          );
        });
      });
    });
    const dateTime = new Date();
    const date =
      dateTime.getMonth() +
      1 +
      "/" +
      dateTime.getDate() +
      "/" +
      dateTime.getFullYear();
    return (
      <div>
        <h4>
          <FormattedMessage
            id="progress_report_date"
            defaultMessage={"Date of Report: {date}"}
            values={{ date: date }}
          />
        </h4>
        <h4>
          <FormattedMessage
            id="progress_report_name"
            defaultMessage={"Name: {name}"}
            values={{ name: userProfile.name }}
          />
        </h4>
        <table className="print-table">
          <tbody>
            <tr>
              <th>Section</th>
              <th>Status</th>
              <th>Last Completed</th>
            </tr>
            {trs}
          </tbody>
        </table>
      </div>
    );
  },
});
