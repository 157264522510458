/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React from "react";
import { FlatList } from "react-native";

const BrandedString = React.createClass({
  propTypes: {
    string_type: React.PropTypes.string,
    default_string: React.PropTypes.string,
    header: React.PropTypes.bool,
  },

  elementWrap: function (string) {
    const { header } = this.props;

    if (!!header) return <h1 className="my-3 text-center">{string}</h1>;
    return <p>{string}</p>;
  },

  render: function () {
    const { default_string, string_type } = this.props;
    const group_setting = CUAC_SETTINGS.GROUP[string_type];
    const str = group_setting ? group_setting : default_string;
    if (!str) return null;
    const strArr = Array.isArray(str) ? str : [str];

    return (
      <FlatList
        keyExtractor={(str) => str}
        data={strArr}
        renderItem={({ item }) => this.elementWrap(item)}
      />
    );
  },
});

export default BrandedString;
