"use strict";

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Actions from "actions";
import { TrackerTypes } from "api/tracker-entries";
import SolutionTracker from "components/forms/solution-tracker";
import Moment from "moment";
Moment.locale("en");

const NewSolutionTracker = React.createClass({
  propTypes: {
    auth: React.PropTypes.object.isRequired,
    dispatch: React.PropTypes.func.isRequired,
  },

  getInitialState: function () {
    const { auth } = this.props;
    const user_timezone = _.get(auth, "user.user_timezone");
    var isNew = true;
    var solutionTrackerEntry = this.props.solutionTrackerEntry || {};
    var initialValues = {};

    if (solutionTrackerEntry._id) {
      isNew = false;
      initialValues = solutionTrackerEntry.entry;
    }
    return {
      isNew: isNew,
      initialValues: initialValues,
    };
  },

  addSolution(data) {
    const { auth, dispatch } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    dispatch(
      Actions.addTrackerEntry(token, userId, data, TrackerTypes.SOLUTION),
    ).then(() => {
      this.closeModal();
    });
  },

  editSolution(data, trackerEntryId) {
    const { auth, dispatch } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    dispatch(
      Actions.updateTrackerEntry(token, userId, trackerEntryId, data),
    ).then(() => {
      this.closeModal();
    });
  },

  closeModal() {
    this.props.dispatch(
      Actions.closeModal("appModal", {
        modalNotifications: "",
        modalContent: false,
      }),
    );
  },

  onFormSubmit(data) {
    const { auth } = this.props;
    const user_timezone = _.get(auth, "user.user_timezone");

    if (this.state.isNew) {
      this.addSolution(data);
    } else {
      this.editSolution(data, this.props.solutionTrackerEntry._id);
    }
  },

  onAltClick() {
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "newSolutionTracker",
        modalContent: true,
      }),
    );
  },

  render() {
    const { trackerEntries } = this.props;
    const isFetching = _.get(trackerEntries, "isFetching");

    return (
      <div className="container">
        <SolutionTracker
          onSubmit={this.onFormSubmit}
          onClick={this.onAltClick}
          isFetching={isFetching}
          initialValues={this.state.initialValues}
          isNew={this.state.isNew}
        />
      </div>
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
  trackerEntries: state.trackerEntries,
  solutionTrackerEntry: state.modal.solutionTrackerEntry,
}))(NewSolutionTracker);
