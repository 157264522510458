/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, Stripe */
"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "components/ux/mdl/button";

export const ScoreChart = React.createClass({
  propTypes: {
    isIncomplete: React.PropTypes.bool.isRequired,
    options: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    score: React.PropTypes.number.isRequired,
    scoreOptionIndex: React.PropTypes.number.isRequired,
  },

  renderOptionRange(minScore, maxScore) {
    if (typeof minScore !== "undefined") {
      if (typeof maxScore !== "undefined") {
        if (minScore !== maxScore) {
          return minScore + " - " + maxScore;
        } else {
          return minScore;
        }
      } else {
        return minScore + " +";
      }
    } else {
      if (typeof maxScore !== "undefined") {
        return "< " + maxScore;
      } else {
        return "";
      }
    }
  },

  renderOption(option, index) {
    const { options, score, scoreOptionIndex, mode, reverseSpectrum } =
      this.props;
    const categoryClass =
      "category" + (index === scoreOptionIndex ? " active" : "");
    const optionsLength = Math.min(options.length, 5);
    const width = Math.round(100 / optionsLength);
    const widthStyle = { width: width + "%" };

    return (
      <div
        className={categoryClass}
        key={"category-" + index}
        style={widthStyle}
        aria-hidden={true}
      >
        <h5 className="label">
          {option.min_score}
          {option.max_score}
          <FormattedMessage {...option.title} />
          <div className="range">
            {mode !== "normal"
              ? "\u00a0"
              : this.renderOptionRange(option.min_score, option.max_score)}
          </div>
        </h5>
        {index === scoreOptionIndex &&
          (reverseSpectrum ? (
            <div
              className="score"
              style={{
                right:
                  ((parseInt(score) - parseInt(option.min)) /
                    (parseInt(option.max) - parseInt(option.min))) *
                    100 +
                  "%; transform: translateX(50%)",
              }}
            >
              <h4 className="label">{mode !== "normal" ? "\u00a0" : score}</h4>
            </div>
          ) : (
            <div
              className="score"
              style={{
                left:
                  ((parseInt(score) - parseInt(option.min)) /
                    (parseInt(option.max) - parseInt(option.min))) *
                    100 +
                  "%",
              }}
            >
              <h4 className="label">{mode !== "normal" ? "\u00a0" : score}</h4>
            </div>
          ))}
        <div className="clearfix">&nbsp;</div>
      </div>
    );
  },

  render() {
    const { isIncomplete, options, mode, scoreOptionIndex, score, title } =
      this.props;

    const chartMeasure = this.props.options[0].title.defaultMessage;

    let chartMeasureName = "Your Score";

    if (chartMeasure.includes("Risk")) {
      chartMeasureName = "Your Risk";
    }

    if (title) {
      chartMeasureName = title;
    }

    return isIncomplete ? null : (
      <div className="score-feedback-visual-wrap">
        <h4>{chartMeasureName}</h4>
        <div
          className={
            "score-feedback-visual" + (mode === "no_score" ? " no-score" : "")
          }
        >
          <img
            src={CUAC_SETTINGS.S3_ROOT + "/app/img/ux/feedback-bar.png"}
            alt={
              "Score Spectrum showing your score of " +
              score +
              " in the " +
              options[scoreOptionIndex].title.defaultMessage +
              " range of " +
              options[scoreOptionIndex].min +
              " through " +
              options[scoreOptionIndex].max
            }
          />
          {options.map(this.renderOption)}
        </div>
      </div>
    );
  },
});
