"use strict";

import _ from "lodash";
import React from "react";
import Moment from "moment";
Moment.locale("en");
import { FormattedMessage } from "react-intl";

import {
  getModuleStatus,
  getPageStatus,
  getSectionStatus,
  getToolStatus,
} from "utils/progress";

const ProgressBar = React.createClass({
  propTypes: {
    dt_updated: React.PropTypes.string,
    modules: React.PropTypes.object,
    pages: React.PropTypes.object,
    question_count: React.PropTypes.number,
    response_count: React.PropTypes.number,
    sections: React.PropTypes.object,
    style: React.PropTypes.object,
    visited: React.PropTypes.bool,
    hideLastActivity: React.PropTypes.bool,
  },

  render() {
    const {
      dt_updated,
      modules,
      pages,
      question_count,
      response_count,
      sections,
      style,
      visited,
      hideLastActivity,
    } = this.props;
    const dateString = dt_updated
      ? Moment(dt_updated).format("D MMM YYYY")
      : "";
    let status;

    if (!_.isEmpty(modules)) {
      status = getToolStatus(_.values(modules));
    } else if (!_.isEmpty(sections)) {
      status = getModuleStatus(_.values(sections));
    } else if (!_.isEmpty(pages)) {
      status = getSectionStatus(_.values(pages));
    } else {
      status = getPageStatus({
        visited,
        question_count,
        response_count,
      });
    }

    const completed = status.completed;
    const percent = completed
      ? 100
      : status.totalCount
        ? Math.round((status.completedCount / status.totalCount) * 100)
        : 100;
    const width = { width: percent + "%" };
    const zeroWidth = { width: "0%" };

    if (visited) {
      if (completed) {
        return (
          <div
            className="progress-wrap last-visited"
            style={style}
            role="progressbar"
            aria-valuetext="Completed."
            tabIndex="0"
          >
            {!this.props.hideLastActivity ? (
              <div className="last-activity">
                <span className="date">{dateString}</span>
              </div>
            ) : null}
            <div className="progress-bar" style={width}>
              <div className="info-text">
                <span className="percent">
                  <FormattedMessage
                    id="progress_bar_completed"
                    defaultMessage="Completed"
                  />
                </span>
                {!status.totalCount ? null : (
                  <span className="responses">
                    {status.completedCount} / {status.totalCount} {status.text}
                  </span>
                )}
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            className="progress-wrap"
            style={style}
            role="progressbar"
            aria-valuenow={percent}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {!this.props.hideLastActivity ? (
              <div className="last-activity">
                <span className="date">{dateString}</span>
              </div>
            ) : null}
            {!question_count ? (
              <div className="progress-bar" style={zeroWidth}>
                <div className="info-text">
                  <span className="percent">
                    <FormattedMessage
                      id="progress_bar_started"
                      defaultMessage="Started"
                    />
                  </span>
                </div>
              </div>
            ) : (
              <div className="progress-bar" style={width}>
                <div className="info-text">
                  <span className="percent">{percent} % complete</span>
                  <span className="responses">
                    {status.completedCount} / {status.totalCount} {status.text}
                  </span>
                </div>
              </div>
            )}
          </div>
        );
      }
    } else {
      return null;
    }
  },
});

export default ProgressBar;
