"use strict";

import { DISMISS_MESSAGE } from "actions/types";

export default function dismissMessage(key) {
  return {
    type: DISMISS_MESSAGE,
    payload: {
      key,
    },
  };
}
