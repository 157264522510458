import Moment from "moment";
import _ from "lodash";

export const checkForRecurring = (tool, responseArray) => {
  const responseCount = _.countBy(responseArray, (r) => r.tool_id === tool._id);
  // const { tools } = props;
  if (_.get(tool, "recurring")) {
    // go through our rules
    for (let j = 0; j < tool.recurring.length; j++) {
      for (let i = 0; i < responseArray.length; i++) {
        if (responseArray[i].tool_complete) {
          // see if the response is in the slugs listed in the rule
          // const slug = _.get(tools[responseArray[i].tool_id], "slug");
          const slug = tool?.slug;
          if (tool.recurring[j].slug == slug) {
            // if so, check if it is after the interval
            const timeToCompare = responseArray[i].completion_time
              ? responseArray[i].completion_time
              : responseArray[i].dt_u;
            const newDate = Moment(timeToCompare, "YYYY-MM-DD hh:mm A").add(
              tool.recurring[j].interval,
              tool.recurring[j].interval_type
            );
            const today = Moment();
            if (newDate.isBefore(today)) {
              // if we have a tool that has started + the interval before today, then we should recur
              // but first we should check the total numbers of recurrances for that tool slug
              if (
                _.get(responseCount, tool.slug, 0) < tool.recurring[j].times
              ) {
                return true;
              }
            }
          }
        }
      }
    }
  }
  return false;
};
