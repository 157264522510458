"use strict";

import _ from "lodash";
import { FormattedMessage } from "react-intl";
import Moment from "moment";
Moment.locale("en");

import React from "react";

export const FormattedDateMessage = React.createClass({
  propTypes: {
    defaultMessage: React.PropTypes.string,
  },

  render() {
    let newValues = null;

    if (this.props.values) {
      _.forOwn(this.props.values, (val, key) => {
        if (key.startsWith("dt_") || key.endsWith("_date")) {
          const timestamp = Date.parse(val);
          if (!isNaN(timestamp)) {
            newValues = {};
          }
        }
      });

      if (newValues) {
        _.forOwn(this.props.values, (val, key) => {
          if (key.startsWith("dt_") || key.endsWith("_date")) {
            const timestamp = Date.parse(val);
            if (!isNaN(timestamp)) {
              const d = new Date(timestamp);
              newValues[key] = Moment(d).format("MMMM Do, YYYY");
            }
          } else {
            newValues[key] = val;
          }
        });
      }
    }

    return newValues ? (
      <FormattedMessage {...this.props} values={newValues}>
        {this.props.children}
      </FormattedMessage>
    ) : (
      <FormattedMessage {...this.props}>{this.props.children}</FormattedMessage>
    );
  },
});
