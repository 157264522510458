"use strict";

import _ from "lodash";
import { handleResponse } from "utils/api";

export const getTrackerEntryStats = (token, userId) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT + "/tracker-entry-stats?user_id=" + userId,
    {
      method: "GET",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
    },
  ).then(handleResponse);
};
