/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import Actions from "actions";

import Selectors from "selectors";
const { pageSelector } = Selectors;
import { FormattedMessage } from "react-intl";
import { A } from "components/elements/a";

const AppHeader = React.createClass({
  propTypes: {
    params: React.PropTypes.object.isRequired,
  },

  openModal: function (type) {
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: type,
        modalContent: true,
      })
    );
  },

  render() {
    return (
      <>
        {!CUAC_SETTINGS.GROUP.isDisabled && (
          <footer className="site-footer">
            <div className="mx-2">
              <footer className="my-4 justify-content-md-between d-md-flex">
                <ul className="nav">
                  <li className="nav-item">
                    <A
                      className="nav-link px-2 text-muted"
                      href="mailto:help@checkupandchoices.com"
                    >
                      Contact us
                    </A>
                  </li>
                </ul>
                <ul className="nav">
                  <li className="nav-item">
                    <A
                      className="nav-link px-2 text-muted"
                      onClick={() => this.openModal("privacy")}
                    >
                      <FormattedMessage
                        id="footer_nav_privacy"
                        defaultMessage="Privacy"
                      />
                    </A>
                  </li>
                  <li className="nav-item">
                    <A
                      className="nav-link px-2 text-muted"
                      onClick={() => this.openModal("terms")}
                    >
                      <FormattedMessage
                        id="tfooter_nav_terms"
                        defaultMessage="Terms of Service"
                      />
                    </A>
                  </li>
                  <li className="nav-item">
                    <A
                      className="nav-link px-2 text-muted"
                      href="https://www.checkupandchoices.com"
                      target="_blank"
                    >
                      <FormattedMessage
                        id="tfooter_nav_marketing_site"
                        defaultMessage="More Info"
                      />
                    </A>
                  </li>
                </ul>
              </footer>
            </div>
          </footer>
        )}
      </>
    );
  },
});

export default connect((state, props) => ({
  user: state.auth.user,
  routing: state.routing,
  ...pageSelector(state, props),
}))(AppHeader);
