"use strict";

import _ from "lodash";
import React from "react";
import Chart from "components/blocks/chart";
import ToolComparisonChart from "components/blocks/tool-comparison-chart";
import MultiComparisonChart from "components/blocks/multi-comparison";
import { ConditionalFeedback } from "components/blocks/conditional-feedback";
import { FormList } from "components/blocks/form-list";
import { FormListFeedback } from "components/blocks/form-list-feedback";
import { InformationalText } from "components/blocks/informational-text";
import { HighlightedInformationalText } from "components/blocks/highlighted-informational-text";
import { MotivationComparison } from "components/blocks/motivation-comparison";
import { MultipleChoiceList } from "components/blocks/multiple-choice-list";
import { NumberTable } from "components/blocks/number-table";
import Questionnaire from "components/blocks/questionnaire";
import SortList from "../SortList/SortList.component";
import { Switch } from "components/blocks/switch";
import { StringSwitch } from "components/blocks/string-switch";
import TextValueList from "components/blocks/text-value-list";
import { Tracker } from "components/blocks/tracker";
import { CompletionEmail } from "components/blocks/send-completion-email";
import { DrinkUrgeMoodFeedback } from "components/blocks/drink-urge-mood-feedback";
import { InformationalTextForLastStats } from "components/blocks/informational-text-for-last-stats";
import { ContrastFeedback } from "components/blocks/contrast-feedback";
import { ColumnWrapper } from "components/blocks/column-wrapper";
import QuantityFrequency from "./quantity-frequency";
import { AudioPlayer } from "components/blocks/AudioPlayer.component";

const _Block = React.createClass({
  propTypes: {
    addTrackerEntry: React.PropTypes.func,
    component: React.PropTypes.object.isRequired,
    index: React.PropTypes.number.isRequired,
    norms: React.PropTypes.object,
    responses: React.PropTypes.object.isRequired,
    trackerEntries: React.PropTypes.object,
    trackerEntryStats: React.PropTypes.object,
    userProfile: React.PropTypes.object,
    updateResponse: React.PropTypes.func,
  },

  render() {
    const {
      addTrackerEntry,
      component,
      index,
      responses: propResponses,
      trackerEntries,
      trackerEntryStats,
      userProfile,
      updateResponse,
      norms,
      auth,
      tool,
      tools,
      dispatch,
      params,
      allResponses,
      updateProgressManually,
      module,
      section,
      activeResponse,
    } = this.props;
    const componentType = _.get(component, "type");
    let responses;

    switch (componentType) {
      case "chart":
        responses = {
          ...userProfile,
          ...propResponses,
        };
        return (
          <Chart
            key={"chart_" + index}
            params={params}
            {...component}
            responses={responses}
            allResponses={allResponses}
            trackerEntryStats={trackerEntryStats}
          />
        );
      case "tool_comparison_chart":
        responses = {
          ...userProfile,
          ...propResponses,
        };

        return (
          <ToolComparisonChart
            key={"chart_" + index}
            params={params}
            {...component}
            responses={responses}
            allResponses={allResponses}
            tools={tools}
            auth={auth}
            dispatch={dispatch}
          />
        );
      case "audio":
        responses = {
          ...userProfile,
          ...propResponses,
        };
        return (
          <AudioPlayer
            key={"audio_" + index}
            params={params}
            className="mb-4"
            {...component}
            responses={responses}
            allResponses={allResponses}
            tools={tools}
            auth={auth}
            dispatch={dispatch}
          />
        );
      case "multi_comparison":
        responses = {
          ...userProfile,
          ...propResponses,
        };

        return (
          <MultiComparisonChart
            key={"chart_" + index}
            params={params}
            {...component}
            responses={responses}
            allResponses={allResponses}
            tools={tools}
            auth={auth}
          />
        );
      case "conditional_feedback":
        responses = {
          ...userProfile,
          ...activeResponse,
          ..._.pick(
            propResponses,
            _.union(
              Object.keys(component.default_responses),
              component.required_responses,
              [component.data_key]
            )
          ),
        };
        return (
          <ConditionalFeedback
            key={"conditional_feedback_" + index}
            {...component}
            norms={norms}
            responses={responses}
            dispatch={dispatch}
            updateResponse={updateResponse}
            activeResponse={activeResponse}
          />
        );
      case "form_list":
        responses = {
          ...userProfile,
          ...propResponses,
        };
        return (
          <FormList
            key={"form_list_" + index}
            {...component}
            responses={responses}
            updateResponse={updateResponse}
          />
        );
      case "form_list_feedback":
        responses = {
          ...userProfile,
          ...propResponses,
        };
        return (
          <FormListFeedback
            key={"form_list_feedback" + index}
            {...component}
            responses={responses}
          />
        );
      case "informational_text":
        responses = {
          ...userProfile,
          ...propResponses,
        };

        return (
          <InformationalText
            key={"infotext_" + index}
            {...component}
            norms={norms ?? {}}
            responses={responses ?? []}
            trackerEntries={trackerEntries}
            dispatch={dispatch}
            updateProgressManually={updateProgressManually}
            module={module}
            section={section ?? {}}
            tool={tool ?? {}}
          />
        );
      case "informational_text_for_last_stats":
        return (
          <InformationalTextForLastStats
            key={"infotext_" + index}
            {...component}
            trackerEntryStats={trackerEntryStats}
          />
        );
      case "highlighted_informational_text":
        responses = {
          ...userProfile,
          ...propResponses,
        };
        return (
          <HighlightedInformationalText
            key={"infotext_" + index}
            {...component}
            norms={norms}
            responses={responses}
            trackerEntries={trackerEntries}
          />
        );
      case "motivation_comparison":
        responses = {
          ...userProfile,
          ...propResponses,
        };
        return (
          <MotivationComparison
            key={"motivcomp_" + index}
            {...component}
            norms={norms}
            responses={responses}
            allResponses={allResponses}
          />
        );
      case "multiple_choice_list":
        responses = {
          ...userProfile,
          ..._.pick(propResponses, component.response_keys),
        };
        return (
          <MultipleChoiceList
            key={"multiple_c_list" + index}
            {...component}
            responses={responses}
            updateResponse={updateResponse}
          />
        );
      case "number_table":
        return (
          <NumberTable
            key={"number_table_" + index}
            {...component}
            responses={propResponses}
            updateResponse={updateResponse}
          />
        );
      case "questionnaire":
        responses = {
          ...userProfile,
          ...propResponses,
        };
        return (
          <Questionnaire
            key={"questionnaire_" + index}
            {...component}
            responses={responses}
            updateResponse={updateResponse}
            userProfile={userProfile}
          />
        );
      case "sort_list":
        responses = {
          ...userProfile,
          ..._.pick(propResponses, [
            component.data_key,
            component.source_data_key,
          ]),
        };
        return (
          <SortList
            key={"sort_list" + index}
            {...component}
            responses={responses}
            updateResponse={updateResponse}
          />
        );
      case "two_columns":
        return (
          <ColumnWrapper
            key={`colwrapper${index}`}
            {...component}
            responses={responses}
            updateResponse={updateResponse}
          />
        );
      case "drink-urge-mood-feedback":
        return (
          <DrinkUrgeMoodFeedback
            key={"feedback" + index}
            params={params}
            {...component}
            norms={norms}
            responses={allResponses}
            trackerEntryStats={trackerEntryStats}
            tools={tools}
            userProfile={userProfile}
          />
        );
      case "switch":
        // switch delegates userProfile merging downstream
        responses = {
          ...propResponses,
          ...activeResponse,
          ..._.pick(
            propResponses,
            _.union(Object.keys(component.formula.default_responses), [
              component.formula.data_key,
            ])
          ),
        };
        return (
          <Switch
            key={"switch_" + index}
            {...component}
            component={component}
            allResponses={allResponses}
            norms={norms}
            responses={responses}
            userProfile={userProfile}
            updateResponse={updateResponse}
            addTrackerEntry={addTrackerEntry}
            trackerEntries={trackerEntries}
            updateProgressManually={updateProgressManually}
            module={module}
            section={section}
            tool={tool}
            tools={tools}
            auth={auth}
            dispatch={dispatch}
          />
        );
      case "string_switch":
        responses = {
          ...userProfile,
          ...propResponses,
        };
        return (
          <StringSwitch
            key={"string_switch_" + index}
            {...component}
            norms={norms}
            responses={responses}
            userProfile={userProfile}
            updateResponse={updateResponse}
            addTrackerEntry={addTrackerEntry}
            trackerEntries={trackerEntries}
          />
        );
      case "text_value_list":
        responses = {
          ...userProfile,
          ..._.pick(propResponses, [component.data_key]),
          ..._.pick(propResponses, [component.suggestions_data_key]),
        };
        return (
          <TextValueList
            key={"text_value_list_" + component.data_key}
            {...component}
            responses={responses}
            updateResponse={updateResponse}
          />
        );
      case "tracker":
        responses = {
          ...userProfile,
          ...propResponses,
        };
        return (
          <Tracker
            key={"tracker_" + component.data_key}
            {...component}
            addTrackerEntry={addTrackerEntry}
            trackerEntries={trackerEntries}
            params={params}
          />
        );
      case "send-completion-email":
        return <CompletionEmail auth={auth} tool={tool} dispatch={dispatch} />;
      case "contrast_feedback":
        responses = {
          ...userProfile,
          ...propResponses,
        };
        return (
          <ContrastFeedback
            key={"chart_" + index}
            params={params}
            {...component}
            responses={responses}
            allResponses={allResponses}
            tools={tools}
            auth={auth}
          />
        );
      case "mj_quantity_frequency":
        responses = {
          ...userProfile,
          ...propResponses,
        };
        return (
          <QuantityFrequency
            key={"chart_" + index}
            {...component}
            responses={responses}
            dispatch={dispatch}
            updateResponse={updateResponse}
            activeResponse={activeResponse}
          />
        );
      default:
        return null;
    }
  },
});

_Block.displayName = "Block";
export const Block = _Block;
