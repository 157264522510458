"use strict";

import { resetPassword as resetPasswordAPI } from "api/password-resets";
import notify from "actions/notify";

import {
  REQUEST_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_FAILURE,
} from "actions/types";

function requestResetPassword() {
  return {
    type: REQUEST_RESET_PASSWORD,
  };
}

function requestResetPasswordSuccess() {
  return {
    type: REQUEST_RESET_PASSWORD_SUCCESS,
  };
}

function requestResetPasswordFailure(error) {
  return {
    type: REQUEST_RESET_PASSWORD_FAILURE,
    payload: error,
    error: true,
  };
}

export default function resetPassword(resetKey, password) {
  return (dispatch) => {
    dispatch(requestResetPassword());
    return resetPasswordAPI(resetKey, password)
      .then(() => {
        return dispatch(requestResetPasswordSuccess());
      })
      .catch((error) => {
        dispatch(
          notify({
            title: "Network Error",
            message:
              "Your password was NOT reset.  Please try again in a few minutes.",
            level: "error",
            autoDismiss: 0,
          }),
        );
        return dispatch(requestResetPasswordFailure(error));
      });
  };
}
