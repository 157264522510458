"use strict";

import _ from "lodash";
import React from "react";

import { Element } from "components/elements/element";

import { convertToKeyString } from "utils/string";

export const FormListFeedback = React.createClass({
  propTypes: {
    components: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    fields: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    data_key: React.PropTypes.string.isRequired,
    responses: React.PropTypes.object.isRequired,
  },

  render() {
    const { components, data_key, fields, responses } = this.props;
    const response = _.get(responses, data_key, []);
    return (
      <div className="informational-text">
        {response.map((value, valueIndex) => {
          const nestedComponents = [<h3 key={"h3_" + valueIndex}>{value}</h3>];
          nestedComponents.push(
            fields.map((field, fieldIndex) => {
              const replacement = _.get(
                responses,
                field.id + "_" + convertToKeyString(value),
                " ",
              );
              const replacements = {
                question: field.text.defaultMessage,
                value: replacement,
              };
              return components.map((component, componentIndex) => {
                return (
                  <Element
                    key={"element_" + componentIndex}
                    component={component}
                    index={componentIndex}
                    responses={replacements}
                  />
                );
              });
            }),
          );
          return _.flatten(nestedComponents);
        })}
      </div>
    );
  },
});
