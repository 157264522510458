"use strict";

import _ from "lodash";
import moment from "moment";

import { createSelector } from "reselect";
import { checkForRecurring } from "utils/check-for-recurring";
import { canUseTool } from "utils/render";

export const isFetchingToolsSelector = (state) => state.tools.isFetching;

const toolSlugSelector = (state, props) => props.params.toolSlug;
const toolsSelector = (state) => {
  const unfilteredTools = state.tools;
  let tools = {};

  for (const key in unfilteredTools) {
    const _canUseTool = canUseTool(
      {
        ...state.auth.user,
        stripe_customer: state.subscriptions,
      },
      unfilteredTools[key],
      state.responses,
      state.plans
    );

    if (_canUseTool) {
      tools[key] = unfilteredTools[key];
    }
  }

  return tools;
};

const getToolFromSlug = (slug, tools) => {
  for (let tool of _.values(tools)) {
    if (_.get(tool, "slug", "") === slug) {
      return tool;
    }
  }
  return {};
};

export const toolSelector = createSelector(
  toolSlugSelector,
  toolsSelector,
  (toolSlug, tools) => {
    return {
      tool: getToolFromSlug(toolSlug, tools),
    };
  }
);

// const usableToolsSelector = state =>

export const sortedResponsesSelector = ({ auth, responses, tools, plans }) =>
  Object.values(responses)
    .filter((response) => {
      const tool = tools?.[response?.response?.tool_id ?? -1];
      return !!tool && canUseTool(auth?.user, tool, responses, plans);
    })
    .map((item) => item.response)
    .sort((a, b) => {
      if (a.responseId !== responses.activeResponseId) {
        // return moment(b.dt_u).diff(moment(a.dt_u)) // Recent first
        return moment(a.dt_u).diff(moment(b.dt_u)); // Older first
      }
      return -1;
    });

const toolsWithResponsesByIdSelector = createSelector(
  [sortedResponsesSelector, toolsSelector],
  (responses, tools) => {
    delete tools.isFetching;

    return Object.values(tools).reduce((acc, tool) => {
      if (!!tool) {
        const response = responses?.find((r) => r.tool_id === tool._id);

        acc[tool._id] = { ...tool, response };
      }

      return acc;
    }, {});
  }
);

export const toolByStatusSelector = createSelector(
  [
    toolsWithResponsesByIdSelector,
    sortedResponsesSelector,
    (state) => state.auth.user?.tool_ids ?? [],
  ],
  (toolsWithResponsesById, sortedResponses, toolIds) => {
    if (CUAC_SETTINGS.GROUP.screener_tool_id) {
      delete toolsWithResponsesById[CUAC_SETTINGS.GROUP.screener_tool_id];
    }

    return toolIds.reduce(
      (acc, toolId) => {
        const tool = toolsWithResponsesById[toolId];
        const { response } = tool ?? {};
        if (!tool) return acc;

        let pushTo = "toStart";

        if (!!response) {
          if (response.completition_time || response.tool_complete) {
            // Check if tool has recurring and should be in start again
            if (tool.recurring && checkForRecurring(tool, sortedResponses)) {
              // Tool is recurring and should be in toStart again
              pushTo = "toStart";
            } else {
              pushTo = "completed";
            }
          } else {
            pushTo = "inProgress";
          }
        }

        acc[pushTo].push({
          ...tool,
          response: pushTo !== "toStart" ? response : undefined,
        });

        return acc;
      },
      {
        toStart: [],
        inProgress: [],
        completed: [],
      }
    );
  }
);
