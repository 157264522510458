"use strict";

import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistStore, persistReducer } from "redux-persist";
import expireReducer from "redux-persist-expire";

import rootReducer from "reducers";

const expirationTime = 7 * 24 * 60 * 60; // one week
const getExiredConfig = (expiredState = {}) => ({
  expiredState,
  autoExpire: true,
  expireSeconds: expirationTime,
  persistedAtKey: "__persisted_at",
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  // whitelist: ["auth", "tools", "plans"],
  blacklist: [
    "tools.*.metadata",
    "routing",
    "terms",
    "display",
    "form",
    "emails",
  ],
  transform: [
    // Reducers expiration
    expireReducer("auth", getExiredConfig({})),
    expireReducer("tools", getExiredConfig({})),
  ],
};

const initialState = {
  app: {
    toolTOCOpen: true,
  },
  auth: {
    isFetching: false,
    selectedPlan: {},
    user: {},
  },
  norms: {},
  terms: {},
  responses: {
    isFetching: false,
    activeResponseId: "",
  },
  subscriptions: {
    isFetching: false,
  },
  reminders: {
    isFetching: false,
    reminders: [],
  },
  tools: {
    isFetching: false,
  },
  trackerEntries: {
    isFetching: false,
    abcOrder: [],
    drinkOrder: [],
    goalOrder: [],
    happinessOrder: [],
    lapseOrder: [],
    moodOrder: [],
    planOrder: [],
    valueOrder: [],
    solutionOrder: [],
    urgeOrder: [],
    agreementOrder: [],
  },
  trackerEntryStats: {
    isFetching: false,
  },
};

export default function initializeStore() {
  const middleware = applyMiddleware(thunk);

  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
          trace: true,
          traceLimit: 25,
        })
      : compose;

  const composition = composeEnhancers(middleware);

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = composition(createStore)(persistedReducer, initialState);
  const persistor = persistStore(store);

  return [store, persistor];
}
