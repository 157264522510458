/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, self, goToUrl */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React from "react";
import { findDOMNode } from "react-dom";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import ConfirmPopOver from "components/popover/confirm-popover";
import Button from "components/ux/mdl/button";

var ButtonConfirm = React.createClass({
  propTypes: {
    className: React.PropTypes.string,
    message: React.PropTypes.oneOfType([
      React.PropTypes.string,
      React.PropTypes.object,
    ]),
    onClick: React.PropTypes.func.isRequired,
    style: React.PropTypes.object,
    popClass: React.PropTypes.string,
    show: React.PropTypes.bool,
  },

  getInitialState: function () {
    return {};
  },

  getDefaultProps: function () {},

  componentDidMount: function () {},

  UNSAFE_componentWillReceiveProps: function (nextProps) {},

  // ======== LOCAL METHODS ==============

  showConfirm() {
    this.setState({ showConfirm: true });
  },

  cancelConfirm() {
    this.setState({ showConfirm: false });
  },

  onClickYes() {
    this.props.onClick();
    this.cancelConfirm();
  },

  // ======== RENDER ==============

  render: function () {
    const locus = this,
      { message, onClick, popClass, style } = this.props,
      { showConfirm } = this.state,
      confirmMessage =
        typeof message === "string" ? (
          message
        ) : (
          <FormattedMessage {...message} />
        );
    return (
      <div className="confirm-popover-wrap" style={style}>
        <ConfirmPopOver
          show={showConfirm}
          popClass={popClass}
          onClickClose={locus.cancelConfirm}
        >
          <p>{confirmMessage}</p>
          <div style={{ textAlign: "center" }}>
            <Button
              flat={true}
              noColor={true}
              ripple={true}
              onClick={locus.cancelConfirm}
            >
              <FormattedMessage
                id="overview_button_start_fresh_confirm_no"
                description="Confirm -- no"
                defaultMessage="No"
              />
            </Button>
            <Button
              flat={true}
              noColor={true}
              ripple={true}
              onClick={this.onClickYes}
            >
              <FormattedMessage
                id="overview_button_start_fresh_confirm_yes"
                description="Confirm -- yes"
                defaultMessage="Yes"
              />
            </Button>
          </div>
        </ConfirmPopOver>

        <Button
          {...this.props}
          style={null}
          onClick={locus.showConfirm}
          ref="targetButton"
        />
      </div>
    );
  },
});

export default ButtonConfirm;
export { ButtonConfirm };
