"use strict";

import { updateReminder as updateReminderAPI } from "api/reminders";
import notify from "actions/notify";

import {
  REQUEST_UPDATE_REMINDER,
  REQUEST_UPDATE_REMINDER_SUCCESS,
  REQUEST_UPDATE_REMINDER_FAILURE,
} from "actions/types";

function requestUpdateReminder() {
  return {
    type: REQUEST_UPDATE_REMINDER,
  };
}

function requestUpdateReminderSuccess(json) {
  return {
    type: REQUEST_UPDATE_REMINDER_SUCCESS,
    payload: {
      reminders: json,
    },
  };
}

function requestUpdateReminderFailure(error) {
  return {
    type: REQUEST_UPDATE_REMINDER_FAILURE,
    payload: error,
    error: true,
  };
}

export default function updateReminder(userId, token, reminder, reminderId) {
  return (dispatch) => {
    dispatch(requestUpdateReminder());
    return updateReminderAPI(userId, token, reminder, reminderId)
      .then((json) => {
        dispatch(
          notify({
            title: "Reminder Updated",
            message: "Your Reminder was updated successfully.",
            level: "success",
            autoDismiss: 4,
          }),
        );

        return dispatch(requestUpdateReminderSuccess(json));
      })
      .catch((error) => {
        dispatch(
          notify({
            title: "Network Error",
            message:
              "Cannot EDIT Your Reminder. Please try again in a few minutes.",
            level: "error",
            autoDismiss: 4,
          }),
        );

        return dispatch(requestUpdateReminderFailure(error));
      });
  };
}
