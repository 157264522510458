/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import Link from "components/ux/linker";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import Actions from "actions";
import Selectors from "selectors";
const { moduleSelector, userNormsSelector, userProfileSelector } = Selectors;

import Button from "components/ux/mdl/button";
import ProgressBar from "components/elements/progress-bar";
import LinkPages from "components/elements/link-pages";
import ToolTOC from "../components/ToolTOC/ToolTOC.container";
import { Accordion } from "react-bootstrap";

import { Block } from "components/blocks/block";
import {
  faPrint,
  faComment as faCommentingO,
  faChevronCircleRight,
  faBell as faBellO,
  faAngleDoubleLeft,
  faChevronCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import ToolLayout from "../components/blocks/ToolLayout.component.web";
export const ModuleOverviewPage = React.createClass({
  UNSAFE_componentWillMount() {
    const {
      activeResponse: { progress },
      module,
    } = this.props;
    const navOpen = {};
    _.forOwn(progress.modules[module.id], (data, name) => {
      navOpen[name] = false;
    });
    this.setState({
      navOpen: navOpen,
    });
  },

  onToggleNavBlock(navId) {
    this.state.navOpen[navId] = !this.state.navOpen[navId];
    this.setState({ navOpen: this.state.navOpen });
  },

  onKeyDownToggleNavBlock(event, sectionId) {
    if (event.which == 32) {
      this.onToggleNavBlock(sectionId);
    }
  },

  showRemindersModal() {
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "newReminder",
        edit_reminder: {},
        modalContent: true,
      }),
    );
  },

  showHelpFeedbackModal() {
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "helpFeedback",
        modalContent: true,
      }),
    );
  },

  progressBar(sectionProgress) {
    const { question_count, response_count } = sectionProgress || {};
    if (typeof question_count !== "number") return null;
    const progress =
      typeof question_count === "number" && !!question_count
        ? Math.round(((response_count || 0) / question_count) * 100)
        : null;
    if (!progress) return null;
    return (
      <div className="ms-4 text-x-small text-uppercase progress-bar-single">
        <span>{progress}% Completed</span>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${progress}%` }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </div>
    );
  },

  render() {
    const { activeResponse, module, norms, tool, userProfile } = this.props;
    const components = _.get(module, "components", []);
    const progress = _.get(activeResponse, "progress", {});

    return (
      <ToolLayout
        tool={this.props.tool}
        activeResponse={this.props.activeResponse}
      >
        <h3 className="card-title fw-bold">
          <FormattedMessage {...module.long_title} />
        </h3>
        {components.map((component, index) => {
          return (
            <Block
              key={"block_" + index}
              component={component}
              index={index}
              norms={norms}
              responses={_.get(activeResponse, "responses", {})}
              userProfile={userProfile}
            />
          );
        })}
        <div className="card" style={{ border: "none" }}>
          <div className="card-body">
            <Accordion flush>
              {module.order.map((sectionId) => {
                const section = module.sections[sectionId],
                  sectionProgress = _.get(
                    progress,
                    "modules." + module.id + ".sections." + section.id,
                  );
                return (
                  <Accordion.Item key={sectionId} eventKey={sectionId}>
                    <Accordion.Header>
                      <div className="flex-grow-1 d-flex align-items-center">
                        <div className="flex-grow-1">
                          <FormattedMessage {...section.long_title} />
                        </div>
                        <div className="me-2">
                          {this.progressBar(sectionProgress)}
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="list-group list-group-flush text-dark checkup-items">
                        {section.order.map((pageId) => {
                          const page = section.pages[pageId],
                            pageProgress = _.get(
                              progress,
                              "modules." +
                                module.id +
                                ".sections." +
                                section.id +
                                ".pages." +
                                pageId,
                            );
                          return (
                            <li
                              className={`m-0 p-0 list-group-item d-flex align-items-center`}
                              key={pageId}
                            >
                              <Link
                                className={`text-dark flex-grow-1 d-flex align-items-center nav-link section-to-page`}
                                to={
                                  "/" +
                                  tool.slug +
                                  "/" +
                                  module.slug +
                                  "/" +
                                  section.slug +
                                  "/" +
                                  page.slug
                                }
                                href="#"
                              >
                                <div className="flex-grow-1">
                                  <FormattedMessage {...page.long_title} />
                                </div>
                                {this.progressBar(pageProgress)}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
      </ToolLayout>
    );
  },
});

export default connect((state, props) => ({
  auth: state.auth,
  activeResponseId: state.responses.activeResponseId,
  activeResponse: _.get(
    state.responses[state.responses.activeResponseId],
    "response",
  ),
  ...moduleSelector(state, props),
  ...userNormsSelector(state),
  ...userProfileSelector(state),
}))(ModuleOverviewPage);
