import { Col, Row, ToggleButton, Form } from "react-bootstrap";
import { BLUE, LIGHT_GRAY } from "../../stylesheets/colors";
import React from "react";

type Option = {
  value: string;
  label?: string;
  disabled?: boolean;
  alert?: React.ReactNode;
};

type Props = {
  input: any;
  meta: any;
  columns?: number;
  options: Option[];
  multiple?: boolean;
  containerClassName?: string;
  checkbox?: boolean;
  hideError?: boolean;
};

const CheckboxGroup = ({
  input,
  options,
  meta,
  columns,
  multiple,
  ...props
}: Props) => {
  const chunkedOptions = () => {
    let ary = [],
      cutoff = 0;
    const chunkLen = Math.ceil((options || []).length / (columns || 1));
    do {
      cutoff += chunkLen;
      ary = [...ary, (options || []).slice(cutoff - chunkLen, cutoff)];
    } while (cutoff < (options || []).length);
    return ary;
  };

  const checked = (option: Option) =>
    (input.value || []).indexOf(option.value) >= 0;

  const onChange = (e, option: Option) => {
    if (!multiple) {
      input.onChange(option.value);
      return;
    }
    let newValue = [...input.value],
      isChecked = checked(option);
    if (!isChecked) newValue.push(option.value);
    else newValue = newValue.filter((x) => x !== option.value);
    return input.onChange(newValue);
  };

  const btn = (opt: Option) => {
    if (props.checkbox)
      return (
        <Form.Check
          key={opt.value}
          id={`checkbox_${opt.value}`}
          type="checkbox"
          value={opt.value}
          label={opt.label || opt.value}
          checked={checked(opt)}
          onChange={(e) => onChange(e, opt)}
          onClick={(e) => onChange(e, opt)}
          disabled={opt.disabled}
        />
      );
    return (
      <ToggleButton
        key={opt.value}
        className=""
        type="checkbox"
        value={opt.value}
        checked={checked(opt)}
        onChange={(e) => onChange(e, opt)}
        onClick={(e) => onChange(e, opt)}
        disabled={opt.disabled}
        style={{
          border: "none",
          backgroundColor: checked(opt) ? BLUE : LIGHT_GRAY,
          color: checked(opt) ? "white" : "#0E323E",
        }}
      >
        {opt.label || opt.value}
      </ToggleButton>
    );
  };

  if (!columns)
    return (
      <div className="d-flex flex-column">
        <div className={`d-flex flex-wrap  ${props.containerClassName || ""}`}>
          {(options || []).map((opt, idx) => (
            <div className="me-2 my-1" key={idx}>
              {btn(opt)}
            </div>
          ))}
        </div>
        {meta?.error && !props.hideError && (
          <small className="mt-1 text-danger">{meta.error}</small>
        )}
      </div>
    );

  return (
    <Row>
      {chunkedOptions().map((chunk) => (
        <Col className="d-flex flex-column justify-content-between justify-content-md-start align-items-start">
          {chunk.map((opt: Option, idx: number) => (
            <div
              className="my-1 d-flex align-items-center"
              key={idx.toString()}
            >
              {btn(opt)}
              {opt.alert}
            </div>
          ))}
        </Col>
      ))}
    </Row>
  );
};

export default CheckboxGroup;
