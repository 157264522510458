"use strict";

import _ from "lodash";
import Link from "components/ux/linker";
import React from "react";
import { Modal } from "react-bootstrap";

export const A = React.createClass({
  propTypes: {
    href: React.PropTypes.string,
  },

  getInitialState() {
    return {
      videoModalOpen: false,
    };
  },

  _closeVideoModal() {
    this.setState({ videoModalOpen: false });
  },
  _handleVideoClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ videoModalOpen: true });
  },

  onClick() {
    const { progress_pages, updateProgressManually, tool, module, section } =
      this.props;
    if (progress_pages) {
      progress_pages.forEach((page) => {
        let data = {};
        if (_.has(page, "responses")) {
          data = page.responses;
        }
        updateProgressManually(
          data,
          tool,
          module,
          section,
          section.pages[page.title]
        );
      });
    }
  },

  render() {
    const { href = "", title, onClick, children } = this.props;

    const isYoutubeVideo = href.includes("youtube.com");
    const className = ["nav-link", this.props.className]
      .filter((v) => v)
      .join(" ");

    if (href && href.substring(0, 1) === "/") {
      return (
        <Link
          className={className}
          to={href}
          title={this.props.title}
          onClick={this.onClick}
        >
          {this.props.children}
        </Link>
      );
    }

    // // allow links that open modals to keep consistent styling
    // if (onClick) {
    //   return (
    //     <a className={className} title={title} onClick={onClick}>
    //       {children}
    //     </a>
    //   );
    // }

    if (isYoutubeVideo) {
      const videoUrl = href.replace("watch?v=", "embed/").split("?")[0] + "";

      return (
        <>
          <a href="#" onClick={this._handleVideoClick} title={title}>
            {children}
          </a>
          <Modal
            size="xl"
            show={this.state.videoModalOpen}
            onHide={this._closeVideoModal}
            onEscapeKeyDown={this._closeVideoModal}
          >
            <Modal.Header closeButton>
              <Modal.Title as="h5" className="h5">
                {children}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <iframe
                src={videoUrl}
                className="w-100"
                style={{ aspectRatio: "16/9" }}
              />
            </Modal.Body>
          </Modal>
        </>
      );
    }
    return (
      <a {...(onClick ? { className, title, onClick } : this.props)}>
        {children}
      </a>
    );
  },
});
