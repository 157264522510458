/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, componentHandler */
"use strict";

import React from "react";
import ReactDOM from "react-dom";
let findDOMNode = ReactDOM.findDOMNode;

const mdl = window.componentHandler,
  classnames = require("classnames");

const baseClasses = {
  "mdl-data-table": true,
  "mdl-js-data-table": true,
  "mdl-shadow--2dp": true,
};

var Table = React.createClass({
  displayName: "MDL_Table",

  propTypes: {
    className: React.PropTypes.string,
    selectable: React.PropTypes.bool,
    rows: React.PropTypes.array,
    hasHeader: React.PropTypes.bool,
    tableTitle: React.PropTypes.string,
    onSelect: React.PropTypes.func,
  },

  componentDidMount: function () {
    let node = findDOMNode(this.refs.mdlTable);
    if (node) {
      mdl.upgradeElement(node, "MaterialDataTable");
    }
  },

  componentWillUnmount: function () {
    if (findDOMNode(this.refs.mdlTable)) {
      mdl.downgradeElements(findDOMNode(this.refs.mdlTable));
    }
  },

  /* ========= LOCAL METHODS ============ */

  render: function () {
    let { hasHeader, tableTitle, className, selectable } = this.props;

    let rows = _.clone(this.props.rows);

    let tableHead = null,
      headContents,
      tableBodyContents,
      tableBody,
      i = 0,
      cellsNumerical,
      numerical;

    let classes = classnames(
      baseClasses,
      {
        "mdl-data-table--selectable": selectable,
      },
      className,
    );

    tableTitle = !tableTitle ? null : (
      <h4 className="table-title">{tableTitle}</h4>
    );

    function setNumericals(index) {
      // Assume all columns have numerical data
      let i,
        j,
        matrix = [];
      for (i = 0; i < rows[0].length; i++) {
        matrix.push(true);
      }

      // go through all the data, flag any columns with non-numbers
      for (i = index; i < rows.length; i++) {
        for (j = 0; j < rows[i].length; j++) {
          if (isNaN(parseFloat(rows[i][j], 10))) {
            matrix[j] = false;
          }
        }
      }
      return matrix;
    }

    function makeRow(row, isHeader) {
      let i = 0;
      return row.map(function (item) {
        numerical = cellsNumerical[i];
        i++;
        if (isHeader) {
          return numerical ? (
            <th key={"th_" + i}>{item}</th>
          ) : (
            <th key={"th_" + i} className="mdl-data-table__cell--non-numeric">
              {item}
            </th>
          );
        }
        return numerical ? (
          <td key={"td_" + i}>{item}</td>
        ) : (
          <td key={"td_" + i} className="mdl-data-table__cell--non-numeric">
            {item}
          </td>
        );
      });
    }

    if (hasHeader) {
      cellsNumerical = setNumericals(1);
      headContents = <tr>{makeRow(rows[0], true)}</tr>;
      tableHead = <thead>{headContents}</thead>;
      rows.shift();
    } else {
      cellsNumerical = setNumericals(1);
    }

    tableBodyContents = rows.map(function (row) {
      return <tr key={"tr_" + i++}>{makeRow(row)}</tr>;
    });

    tableBody = <tbody>{tableBodyContents}</tbody>;

    return (
      <div className={className}>
        {tableTitle}
        <table ref="mdlTable" className={classes}>
          {tableHead}
          {tableBody}
        </table>
      </div>
    );
  },
});

export default Table;
