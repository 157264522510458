"use strict";

import React, { forwardRef, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import Moment from "moment";
Moment.locale("en");
import InfoBug from "components/popover/infobug";
import DateInputReduxForm from "components/ux/input-date-redux-form";
import TextFieldReduxForm from "components/ux/text-field-redux-form";
import momentTZ from "lib/moment-timezone-with-data";
import TimePickerReduxForm from "../ux/TimePickerReduxForm.component.web";
import CheckboxGroup from "../ux/CheckboxGroup.component.web";
import { FontAwesome } from "@expo/vector-icons";
import NumericInput from "../blocks/NumericInput.component.web";
import BSButton from "../blocks/BSButton.component.web";
import { FOREST_GREEN } from "../../stylesheets/colors";
import { browserHistory } from "react-router";

const messages = defineMessages({
  drink_tracker_dt_occurred_label: {
    id: "drink_tracker_dt_occurred_label",
    description: "Date Consumed",
    defaultMessage: "Date Consumed",
  },
  drink_tracker_std_drink_count_placeholder: {
    id: "drink_tracker_std_drink_count_placeholder",
    description: "Std Drink Count Placeholder",
    defaultMessage: "# of Standard Drinks",
  },
  drink_tracker_with_whom_label: {
    id: "drink_tracker_with_whom_label",
    description: "With Whom",
    defaultMessage: "With Whom",
  },
  drink_tracker_where_label: {
    id: "drink_tracker_where_label",
    description: "Where",
    defaultMessage: "Where",
  },
  drink_tracker_mood_label: {
    id: "drink_tracker_mood_label",
    description: "Your Mood",
    defaultMessage: "Your Mood",
  },
  date_input_year_label: {
    id: "date_input_year_label",
    description: "date choose label -- Year",
    defaultMessage: "Year",
  },
  date_input_month_label: {
    id: "date_input_month_label",
    description: "date choose label -- Month",
    defaultMessage: "Month",
  },
  date_input_day_label: {
    id: "date_imput_day_label",
    description: "date choose label -- Day",
    defaultMessage: "Day",
  },
});

const typeOptions = [
  {
    text: {
      id: "abstinent",
      defaultMessage: "Abstinent",
    },
    value: "abstinent",
  },
  {
    text: {
      id: "beer",
      defaultMessage: "Beer",
    },
    value: "beer",
  },
  {
    text: {
      id: "wine",
      defaultMessage: "Wine",
    },
    value: "wine",
  },
  {
    text: {
      id: "Liquor",
      defaultMessage: "Liquor",
    },
    value: "liquor",
  },
  {
    text: {
      id: "liqueur",
      defaultMessage: "Liqueur",
    },
    value: "liqueur",
  },
];

const validate = (values) => {
  const errors = {};
  const { std_drink_count, drink_type } = values || {};
  if (!drink_type || !drink_type.length) errors["drink_type"] = "Required";
  if (drink_type !== "abstinent" && [0, "0"].includes(std_drink_count))
    errors["std_drink_count"] = "Required";
  return errors;
};

const DrinkTrackerForm = (props) => {
  useEffect(() => {
    if (props.drink_type === "abstinent") props.change("std_drink_count", 0);
  }, [props.drink_type]);

  const isValid = () => !Object.values(props.errors || {}).length;

  const onSave = async (reset = false) => {
    try {
      await props.handleSubmit();
      if (reset) {
        const user_timezone = props.users?.user_timezone;
        const initialDateTime = user_timezone
          ? momentTZ.tz(new Date(), user_timezone)
          : Moment();
        props.dispatch(
          props.initialize({
            dt_occurred: initialDateTime.format("YYYY-MM-DD"),
            tm_occurred: initialDateTime.format("hh:mm A"),
            std_drink_count: 1,
          }),
        );
        browserHistory.push("/drink-tracker");
      } else browserHistory.push("/drinks");
    } catch (e) {}
  };

  const CustomDatepickerInput = forwardRef(({ value, onClick }, ref) => (
    <input
      style={{ textAlign: "right" }}
      value={value}
      type="text"
      className="form-control"
      onClick={onClick}
      ref={ref}
    />
  ));

  return (
    <form
      id="drink_tracker"
      name="drink_tracker"
      className="d-flex flex-column mt-3"
    >
      <DateInputReduxForm
        name="dt_occurred"
        floatingLabel={true}
        yearLabel={props.intl.formatMessage(messages.date_input_year_label)}
        monthLabel={props.intl.formatMessage(messages.date_input_month_label)}
        dayLabel={props.intl.formatMessage(messages.date_input_day_label)}
        labelText={props.intl.formatMessage(
          messages.drink_tracker_dt_occurred_label,
        )}
        wrapperClassName="flex-grow-1"
        labelClassName="form-label"
        customInput={<CustomDatepickerInput />}
      />
      <TimePickerReduxForm
        name="tm_occurred"
        outputFormat="hh:mm A"
        label="Time"
      />
      <div className="d-flex flex-column mb-3 align-items-center align-items-lg-start">
        <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
          <Field
            name="drink_type"
            component={CheckboxGroup}
            options={typeOptions
              .filter(({ value }) => value !== "abstinent")
              .map((x) => ({ value: x.value, label: x.text.defaultMessage }))}
            multiple={false}
            hideError={true}
            containerClassName="flex-column flex-lg-row"
          />
          <h4 className="mb-0 mx-3">OR</h4>
          <BSButton
            onClick={() => props.change("drink_type", "abstinent")}
            className="ms-1"
            variant={
              props.drink_type === "abstinent" ? "green" : "outline-green"
            }
          >
            {
              typeOptions.find(({ value }) => value === "abstinent")?.text
                ?.defaultMessage
            }
          </BSButton>
        </div>
        {props.errors?.drink_type && (
          <small className="text-danger">{props.errors.drink_type}</small>
        )}
      </div>
      <div className="my-4 d-flex flex-column flex-lg-row align-items-center justify-content-between">
        <div className="d-flex align-items-center" style={{ flex: 1 }}>
          <h5 className="fw-bold h-5 mb-0"># Std. Drinks</h5>
          <InfoBug>
            <FormattedMessage
              id="drink_tracker_std_drink_count_infobug"
              defaultMessage="One standard drink is 12 oz. (355ml.) of 5% beer, 5 oz. (125 ml.) of 12% wine, or 1.5 oz (45 ml.) of 40% (80 proof) spirits. If you're having a drink that has 1.5 standard drinks in it, enter 1.5 rather than rounding up to 2 or down to 1."
            />
          </InfoBug>
        </div>
        <div className="d-flex flex-column">
          <div
            className="d-flex justify-content-center p-2 my-3 my-lg-0"
            style={{ borderRadius: 5, flex: 1, border: "1px solid #E0E8EB" }}
          >
            <NumericInput
              name="std_drink_count"
              placeholder="0"
              style={{ width: 70, border: "none", textAlign: "center" }}
              inputGroupClassName="justify-content-center align-items-center mb-0"
              step={0.1}
              min={0}
            />
          </div>
          {props.errors?.std_drink_count && (
            <small className="text-danger">
              {props.errors.std_drink_count}
            </small>
          )}
        </div>
        <div className="d-flex justify-content-end" style={{ flex: 1 }}>
          <BSButton variant="link-green" onClick={props.onDrinkCalculatorClick}>
            <FontAwesome name="calculator" size={20} color={FOREST_GREEN} />
            <div className="ms-2">
              <FormattedMessage
                id="drink_calculator_button"
                defaultMessage="Drink Calculator"
              />
            </div>
          </BSButton>
        </div>
      </div>
      <TextFieldReduxForm
        name="with_whom"
        floatingLabel={true}
        labelText={props.intl.formatMessage(
          messages.drink_tracker_with_whom_label,
        )}
        style={{ textAlign: "right" }}
      />
      <TextFieldReduxForm
        name="where"
        floatingLabel={true}
        labelText={props.intl.formatMessage(messages.drink_tracker_where_label)}
        style={{ textAlign: "right" }}
      />
      <TextFieldReduxForm
        name="mood"
        floatingLabel={true}
        labelText={props.intl.formatMessage(messages.drink_tracker_mood_label)}
        style={{ textAlign: "right" }}
      />
      <div />
      <div className="d-flex align-items-center">
        <BSButton
          variant="green"
          onClick={() => onSave(false)}
          loading={props.isFetching}
          disabled={!isValid() || props.isFetching}
        >
          <FontAwesome name="star-o" color="white" size={20} />
          <span className="ms-2">
            <FormattedMessage
              id="drink_tracker_button_save"
              description="Submit the drink tracker form to save a drink"
              defaultMessage="Save"
            />
          </span>
        </BSButton>
        <BSButton
          className="ms-1"
          variant="link-green"
          onClick={() => onSave(true)}
          loading={props.isFetching}
          disabled={!isValid() || props.isFetching}
        >
          <FormattedMessage
            id="urge_tracker_button_save_add"
            description="Submit the Drink tracker form to save a new Drink"
            defaultMessage="Save & Add New"
          />
        </BSButton>
      </div>
    </form>
  );
};

const formFields = [
  "dt_occurred",
  "tm_occurred",
  "drink_type",
  "std_drink_count",
  "with_whom",
  "where",
  "mood",
];
let form = reduxForm({
  form: "drinkTracker",
  fields: formFields,
  validate,
})(DrinkTrackerForm);

const selector = formValueSelector("drinkTracker"); // <-- same as form name
form = connect((state) => {
  return formFields.reduce(
    (dict, fieldName) =>
      Object.assign({ [fieldName]: selector(state, fieldName) }, dict),
    {
      errors: state.form.drinkTracker?.syncErrors,
      user: state.auth?.user,
    },
  );
})(form);

export default injectIntl(form);
