import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Actions from "actions";
import Button from "components/ux/mdl/button";
import Moment from "moment";
Moment.locale("en");

const buttonStyle = {
  display: "block",
  width: "15em",
};

const DayPartModal = React.createClass({
  closeModal() {
    this.props.dispatch(
      Actions.closeModal("appModal", {
        modalNotifications: "",
        modalContent: false,
      }),
    );
  },

  showTypeAmountModal(time, diff) {
    const { updateResponse, day } = this.props;
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "typeAmount",
        modalContent: true,
        updateResponse: updateResponse,
        day: day,
        time: time,
        diff: diff,
      }),
    );
  },

  render() {
    const { yesterday, lastSaturday, day, isRegularUser } = this.props;

    const times = [
      { id: "morning", defaultMessage: "Morning (6am - Noon)" },
      { id: "afternoon", defaultMessage: "Afternoon (Noon - 6pm)" },
      { id: "evening", defaultMessage: "Evening (6pm - Midnight)" },
      { id: "night", defaultMessage: "Night (Midnight - 6am)" },
    ];

    let diff = 0;
    //logic may seem off here, metatdata is reversed for
    //isRegularUser true/false
    if (isRegularUser === "false") {
      diff = 7 - lastSaturday.diff(day, "days");
    } else {
      diff = 28 - yesterday.diff(day, "days");
    }

    return (
      <div className="buttonBlock">
        <p>
          <strong>Date:</strong> {day.format("dddd, MMMM Do, YYYY")}
        </p>
        {times.map((time, index) => {
          return (
            <Button
              key={index}
              ripple={true}
              raised={true}
              colored={true}
              style={buttonStyle}
              onClick={() => this.showTypeAmountModal(time, diff)}
            >
              <FormattedMessage {...time} />
            </Button>
          );
        })}
      </div>
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
}))(DayPartModal);
