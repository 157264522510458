"use strict";

import { getSubscriptions as getSubscriptionsAPI } from "api/subscriptions";

import {
  REQUEST_GET_SUBSCRIPTIONS,
  REQUEST_GET_SUBSCRIPTIONS_SUCCESS,
  REQUEST_GET_SUBSCRIPTIONS_FAILURE,
} from "actions/types";

function requestGetSubscriptions() {
  return {
    type: REQUEST_GET_SUBSCRIPTIONS,
  };
}

function requestGetSubscriptionsSuccess(json) {
  return {
    type: REQUEST_GET_SUBSCRIPTIONS_SUCCESS,
    payload: {
      subscriptions: json,
    },
  };
}

function requestGetSubscriptionsFailure(error) {
  return {
    type: REQUEST_GET_SUBSCRIPTIONS_FAILURE,
    payload: error,
    error: true,
  };
}

export default function getSubscriptions(token) {
  return (dispatch) => {
    dispatch(requestGetSubscriptions());
    return getSubscriptionsAPI(token)
      .then((json) => {
        return dispatch(requestGetSubscriptionsSuccess(json));
      })
      .catch((error) => {
        return dispatch(requestGetSubscriptionsFailure(error));
      });
  };
}
