"use strict";

import Actions from "actions";

export function reducer(
  state = {
    isFetching: false,
    data: {},
  },
  action = { type: "" },
) {
  switch (action.type) {
    case Actions.REQUEST_GET_GROUP:
      return {
        ...state,
        isFetching: true,
      };
    case Actions.REQUEST_GET_GROUP_SUCCESS:
      return {
        ...state,
        isFetching: true,
        group: action.payload.group,
      };
    case Actions.REQUEST_GET_GROUP_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
