/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import "bootstrap/dist/css/bootstrap.min.css";

import _ from "lodash";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Actions from "actions";
import Notify from "components/modal/notify";
import AppModal from "components/modal/app-modal";
import AppFreeTrial from "components/headers/app-free-trial";
import AppHeader from "containers/app-header";
import FeedbackBanner from "containers/FeedbackBanner.container.web";
import AppFooter from "containers/app-footer";
import { browserHistory } from "react-router";
import { Box } from "@mui/material";

export const App = ({ auth, params, children }) => {
  const { user } = auth ?? {};
  const dispatch = useDispatch();

  useEffect(() => {
    checkForCompatibility();
    checkForMessages();

    // const interval = setInterval(
    //   () => {
    //     // Try to log out the user if they haven't logged in in a while
    //     dispatch(Actions.logOutUser()).then(() =>
    //       browserHistory.push("/logout")
    //     );
    //   },
    //   30 * 60 * 1000
    // );
    //
    // return () => {
    //   clearInterval(interval);
    // };
  }, []);

  useEffect(() => {
    checkForDomain();
    checkForMessages();
  }, [auth]);

  const checkForDomain = () => {
    const { roles } = user ?? {};
    const { admin, system } = roles ?? {};
    const isAdmin =
      !!admin?.length || (!!system?.length && system.includes("admin"));

    if (!!user._id && !isAdmin && _.get(auth, "user.roles.subscriber")) {
      if (user.roles.subscriber.indexOf(CUAC_SETTINGS.GROUP._id) === -1) {
        dispatch(
          Actions.showModal("appModal", {
            modalNotifications: "wrongSubdomain",
            modalContent: true,
          })
        );
      }
    }
  };

  const checkForMessages = () => {
    const messages = auth?.user?.messages ?? [];

    messages?.forEach((message) => {
      if (!message?.viewed) {
        dispatch(
          Actions.showModal("appModal", {
            modalNotifications: "importantMessage",
            modalContent: true,
          })
        );
      }
    });
  };

  const checkForCompatibility = () => {
    //Fake commit
    const isiOS = /(iPhone|iPod|iPad).*AppleWebKit/i.test(navigator.userAgent);
    if (isiOS && false) {
      // check for chrome
      if (navigator.userAgent.indexOf("CriOS") > -1) {
        window.location.href = "https://checkupandchoices.com/requirements/";
      }
    }
  };

  return (
    <div className="app-frame site-wrapper">
      <AppModal />
      <Notify />
      <AppFreeTrial params={user} />
      {!window.location.href.includes("/admin") && (
        <AppHeader params={params} />
      )}
      <FeedbackBanner showGlobally={false} />
      <div className="app-body site-content">
        <div className={!CUAC_SETTINGS.GROUP.isDisabled ? "" : "relative"}>
          {CUAC_SETTINGS.GROUP.isDisabled ? (
            <Box
              style={{
                backgroundColor: "green",
                padding: "1em",
                borderRadius: "1em",
                position: "fixed",
                left: "50%",
                transform: "translate(-50%)",
                marginTop: "5%",
              }}
              className="container mb-4 page-screener screener top-0 left-0 right-0 center-items"
            >
              <Box
                style={{ backgroundColor: "white", padding: "1em" }}
                className="mdl-cell mdl-cell--4-col"
              >
                <h1>Thank You for coming!</h1>
                <br />
                <p>
                  Your organization no longer provides free access to CheckUp &
                  Choices—a confidential, evidence-based, online program for
                  people who want to learn more about their relationship with
                  alcohol and change it if they choose to.
                </p>
                <p>
                  If you want your organization to reactivate this valuable
                  resource, please have an administrator contact us at{" "}
                  <a href="mailto:info@checkupandchoices.com">
                    info@checkupandchoices.com
                  </a>
                  .
                </p>
                <p>
                  If you want to personally sign up for CheckUp & Choices, you
                  can pay with your credit card and get started for as little as
                  $34.{" "}
                  <a href="https://checkupandchoices.com/plans/">Click here</a>{" "}
                  to begin.
                </p>
              </Box>
            </Box>
          ) : (
            children
          )}
        </div>
      </div>
      <AppFooter params={params} />
    </div>
  );
};

export default connect((state, props) => ({
  auth: state.auth,
}))(App);
