/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React, { forwardRef } from "react";
import Actions from "actions";
import { connect } from "react-redux";
import renderJSON from "../../render-json";
import { reduxForm, formValueSelector } from "redux-form";
import Moment from "moment";
Moment.locale("en");
import { eventPreventDefault as prevent } from "utils/tools";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import TextFieldReduxForm from "components/ux/text-field-redux-form";
import DateInputReduxForm from "components/ux/input-date-redux-form";
import InfoBug from "components/popover/infobug";
import { Field } from "redux-form";
import { findDOMNode } from "react-dom";
import RadioGroupReduxForm from "../ux/RadioGroupReduxForm.component.web";
import CheckboxGroup from "../ux/CheckboxGroup.component.web";
import TimePickerReduxForm from "../ux/TimePickerReduxForm.component.web";
import BSButton from "../blocks/BSButton.component.web";
import { FontAwesome } from "@expo/vector-icons";

const reminderOptions = [
  {
    value: "once",
    text: { defaultMessage: "Once", id: "once" },
  },
  {
    value: "recurring",
    text: { defaultMessage: "Recurring", id: "recurring" },
  },
];

const messages = defineMessages({
  new_reminder_dt_occurred_label: {
    id: "new_reminder_dt_occurred_label",
    description: "Enter Date",
    defaultMessage: "Enter Date",
  },
  date_input_year_label: {
    id: "date_input_year_label",
    description: "date choose label -- Year",
    defaultMessage: "Year",
  },
  date_input_month_label: {
    id: "date_input_month_label",
    description: "date choose label -- Month",
    defaultMessage: "Month",
  },
  date_input_day_label: {
    id: "date_input_day_label",
    description: "date choose label -- Day",
    defaultMessage: "Day",
  },
  new_reminder_phone_number: {
    id: "new_reminder_phone_number",
    description: "choose label -- Phone Number",
    defaultMessage: "Text/SMS",
  },
  new_reminder_email_address: {
    id: "new_reminder_email_address",
    description: "choose label -- Email Address",
    defaultMessage: "Email",
  },
  new_reminder_title: {
    id: "new_reminder_title",
    description: "choose label -- Title",
    defaultMessage: "Title",
  },
  new_reminder_message: {
    id: "new_reminder_message",
    description: "choose label -- Message",
    defaultMessage: "Message",
  },
});

const daysOfTheWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const validate = (values) => {
  const errors = {};
  const {
    days_of_the_week,
    message_type,
    reminder_message,
    reminder_title,
    reminder_type,
  } = values || {};
  if (
    message_type === "recurring" &&
    (!days_of_the_week || !days_of_the_week.length)
  ) {
    errors["days_of_the_week"] = "Required field";
  }
  if (!reminder_message || !reminder_message.length)
    errors["reminder_message"] = "Required field";
  if (!reminder_title || !reminder_title.length)
    errors["reminder_title"] = "Required field";
  if (!reminder_type || !reminder_type.length)
    errors["reminder_type"] = "Please select a reminder type";
  return errors;
};

const NewReminderForm = React.createClass({
  displayName: "NewReminderForm",
  contextTypes: { history: React.PropTypes.object },
  propTypes: {
    fields: React.PropTypes.object.isRequired,
    handleSubmit: React.PropTypes.func.isRequired,
  },

  getInitialState: function () {
    var typeValues = [];
    var recurringDays = [];
    var checkboxSet = [];
    var showDays = false;
    var { initialValues } = this.props;
    var initialTime = initialValues.time_to_send;
    var hasPhoneNumber =
      initialValues.user_phoneNo && initialValues.user_phoneNo.length;
    var hasEmail = initialValues.user_email && initialValues.user_email.length;
    var emailLabel = initialValues.user_email || "No Email";
    var phoneLabel = initialValues.user_phoneNo || "No Phone Number";

    const typeOptions = [
      {
        value: "email",
        label: "Email " + "(" + emailLabel + ")",
        alert: hasEmail ? null : this.renderInfoBug("EmailAddress"),
      },
      {
        value: "phoneNo",
        label: "Text/SMS " + "(" + phoneLabel + ")",
        disabled: !hasPhoneNumber,
        alert: hasPhoneNumber ? null : this.renderInfoBug("PhoneNumber"),
      },
    ];

    daysOfTheWeek.forEach(function (value, index) {
      checkboxSet.push({ value: (index + 1).toString(), label: value });
    });

    if (initialValues.days_of_the_week) {
      recurringDays = initialValues.days_of_the_week.map(String);
    }

    if (initialValues.message_type === "recurring") {
      showDays = true;
    }
    //Add default values.
    if (initialValues.user_email) {
      typeValues.push("email");
    }
    if (initialValues.user_phoneNo) {
      typeValues.push("phoneNo");
    }
    //Will work in edit mode.
    if (initialValues.reminder_type) {
      typeValues = initialValues.reminder_type;
      if (!hasPhoneNumber) {
        _.remove(typeValues, function (type) {
          return type === "phoneNo";
        });
      }
    }

    return {
      showDays: showDays,
      showEmail: false,
      initialTime: initialTime,
      checkboxSet: checkboxSet,
      selectedDays: recurringDays,
      typeValues: typeValues,
      hasPhoneNumber: hasPhoneNumber,
      hasEmail: hasEmail,
      typeOptions: typeOptions,
    };
  },

  componentDidUpdate(prevProps) {
    if (this.props.message_type !== prevProps.message_type) {
      if (this.props.message_type === "recurring")
        this.setState({ showDays: true, selectedDays: [] });
      else this.setState({ showDays: false });
    }
  },

  onSave: function (event) {
    prevent(event);
    this.props.handleSubmit();
  },

  renderInfoBug: function (id) {
    var formatMessage = this.props.intl.formatMessage;
    const structure = [
      [
        "InfoBugPhoneNumber",
        null,
        [
          [
            "h6",
            { className: "text-danger" },
            formatMessage(infoBugMessages.text_reminder_infobug_phone_title),
          ],
          [
            "p",
            null,
            formatMessage(infoBugMessages.text_reminder_infobug_phone_body),
          ],
        ],
      ],
      [
        "InfoBugEmailAddress",
        null,
        [
          [
            "h6",
            { className: "text-danger" },
            formatMessage(infoBugMessages.text_reminder_infobug_email_title),
          ],
          [
            "p",
            null,
            formatMessage(infoBugMessages.text_reminder_infobug_email_body),
          ],
        ],
      ],
    ];

    var data = !structure
      ? null
      : _.find(structure, function (item) {
          return item[0] === "InfoBug" + id;
        });

    if (data) {
      return <InfoBug {...data[1]}>{renderJSON(data[2])}</InfoBug>;
    }
    return null;
  },

  goToList: function (event) {
    prevent(event);
    const { dispatch } = this.props;
    dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "listReminders",
        modalContent: true,
      })
    );
  },

  onEnterPress: function (event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      findDOMNode(this.refs.submitButton).focus();
      this.onSave();
    }
  },

  render: function () {
    const {
      fields,
      intl: { formatMessage },
    } = this.props;
    const CustomDatepickerInput = forwardRef(({ value, onClick }, ref) => (
      <input
        style={{ textAlign: "right" }}
        value={value}
        type="text"
        className="form-control"
        onClick={onClick}
        ref={ref}
      />
    ));
    return (
      <form id="add_new_alert" name="add_new_alert">
        <Field
          component={CheckboxGroup}
          multiple={true}
          name="reminder_type"
          options={this.state.typeOptions}
          checkbox={true}
          columns={1}
        />
        {this.props.errors?.reminder_type && (
          <small className="text-danger">
            {this.props.errors.reminder_type}
          </small>
        )}
        <RadioGroupReduxForm
          name="message_type"
          options={reminderOptions}
          fieldsetClassName="d-flex"
          fieldContainerClassName="me-3"
          inputClassName="me-1"
        />
        {this.state.showDays && (
          <div className="my-3">
            <div
              className="p-2"
              style={{ borderRadius: ".25rem", border: "1px solid #E0E8EB" }}
            >
              <Field
                component={CheckboxGroup}
                multiple={true}
                name="days_of_the_week"
                options={this.state.checkboxSet}
                checkbox={true}
              />
            </div>
            {this.props.errors?.days_of_the_week && (
              <small className="text-danger">
                {this.props.errors.days_of_the_week}
              </small>
            )}
          </div>
        )}
        {!this.state.showDays && (
          <DateInputReduxForm
            name="date_to_send"
            yearLabel={formatMessage(messages.date_input_year_label)}
            monthLabel={formatMessage(messages.date_input_month_label)}
            dayLabel={formatMessage(messages.date_input_day_label)}
            labelText={formatMessage(messages.new_reminder_dt_occurred_label)}
            floatingLabel={true}
            customInput={<CustomDatepickerInput />}
            wrapperClassName="flex-grow-1"
            labelClassName="form-label"
          />
        )}
        <TimePickerReduxForm
          name="time_to_send"
          outputFormat="hh:mm A"
          label={
            <FormattedMessage
              id="reminder_form_enter_time"
              defaultMessage="Enter Time"
            />
          }
        />
        <TextFieldReduxForm
          name="reminder_title"
          type="text"
          floatingLabel={true}
          maxLength="10"
          labelText={formatMessage(messages.new_reminder_title)}
          labelClassName="form-label mb-0 me-2"
        />
        <TextFieldReduxForm
          name="reminder_message"
          type="text"
          floatingLabel={true}
          maxLength="30"
          labelText={formatMessage(messages.new_reminder_message)}
          labelClassName="form-label mb-0 me-2"
        />
        <BSButton variant="green" onClick={this.onSave}>
          <FontAwesome name="star" color="white" size={20} />
          <div className="ms-2">
            <FormattedMessage
              id="add_new_alert_button"
              description="Save"
              defaultMessage="Save"
            />
          </div>
        </BSButton>
      </form>
    );
  },
});

const formFields = [
  "date_to_send",
  "reminder_type",
  "message_type",
  "user_email",
  "reminder_message",
  "reminder_title",
  "days_of_the_week",
  "time_to_send",
  "user_phoneNo",
];
let form = reduxForm({
  form: "addNewAlert",
  fields: formFields,
  validate,
})(NewReminderForm);
const selector = formValueSelector("addNewAlert"); // <-- same as form name
form = connect((state) => {
  return formFields.reduce(
    (dict, fieldName) =>
      Object.assign({ [fieldName]: selector(state, fieldName) }, dict),
    {
      errors: state.form.addNewAlert?.syncErrors,
    }
  );
})(form);

export default injectIntl(form);

var infoBugMessages = defineMessages({
  text_reminder_infobug_phone_title: {
    id: "text_reminder_infobug_phone_title",
    description: "infobug -- phone number -- title",
    defaultMessage: "Phone Number Missing",
  },
  text_reminder_infobug_phone_body: {
    id: "text_reminder_infobug_phone_body",
    description: "infobug -- phone number -- description",
    defaultMessage:
      "Please visit your profile and add your phone number there.",
  },
  text_reminder_infobug_email_title: {
    id: "text_reminder_infobug_email_title",
    description: "infobug -- email address -- title",
    defaultMessage: "Email Address Missing",
  },
  text_reminder_infobug_email_body: {
    id: "text_reminder_infobug_email_body",
    description: "infobug -- email address -- description",
    defaultMessage:
      "Please visit your profile and add your email address there.",
  },
});
