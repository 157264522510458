"use strict";

import { getUser as getUserAPI } from "api/users";

import {
  REQUEST_GET_USER,
  REQUEST_GET_USER_SUCCESS,
  REQUEST_GET_USER_FAILURE,
} from "actions/types";

function requestGetUser() {
  return {
    type: REQUEST_GET_USER,
  };
}

function requestGetUserSuccess(json) {
  return {
    type: REQUEST_GET_USER_SUCCESS,
    payload: {
      user: json,
    },
  };
}

function requestGetUserFailure(error) {
  return {
    type: REQUEST_GET_USER_FAILURE,
    payload: error,
    error: true,
  };
}

export default function getUser(token, userId) {
  return (dispatch) => {
    dispatch(requestGetUser());
    return getUserAPI(token, userId)
      .then((json) => {
        return dispatch(requestGetUserSuccess(json));
      })
      .catch((error) => {
        return dispatch(requestGetUserFailure(error));
      });
  };
}
