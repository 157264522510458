"use strict";

import _ from "lodash";
import { addSubscription as addSubscriptionAPI } from "api/subscriptions";
import notify from "actions/notify";
const dataLayer = [];
import Actions from "actions";

import {
  REQUEST_ADD_SUBSCRIPTION,
  REQUEST_ADD_SUBSCRIPTION_SUCCESS,
  REQUEST_ADD_SUBSCRIPTION_FAILURE,
} from "actions/types";

function requestAddSubscription() {
  return {
    type: REQUEST_ADD_SUBSCRIPTION,
  };
}

function requestAddSubscriptionSuccess(json) {
  return {
    type: REQUEST_ADD_SUBSCRIPTION_SUCCESS,
    payload: {
      subscription: json,
    },
  };
}

function requestAddSubscriptionFailure(error) {
  return {
    type: REQUEST_ADD_SUBSCRIPTION_FAILURE,
    payload: error,
    error: true,
  };
}

export default function addSubscription(token, userId, plan, stripeToken) {
  return (dispatch) => {
    dispatch(requestAddSubscription());
    return addSubscriptionAPI(token, plan.stripe_key, stripeToken)
      .then((json) => {
        return Promise.all([
          dispatch(Actions.getUser(token, userId)),
          dispatch(Actions.getTools(token)),
          dispatch(Actions.getTrackerEntries(token, userId)),
        ]).then(() => {
          dispatch(
            Actions.showModal("appModal", {
              modalNotifications: "genericModal",
              modalContent: true,
              modalCustomTitle: {
                id: "thank_you_modal_title",
                defaultMessage: "Thank You",
              },
              modalCustomContent: {
                components: [
                  {
                    type: "p",
                    children: [
                      {
                        type: "formatted_message",
                        text: {
                          id: "thank_you_modal_p0",
                          defaultMessage:
                            'Thank you for starting the CheckUp & Choices program. On the next screen, you\'ll be asked some personal questions - answering these will help us give you better and more accurate feedback. Once you are done, click on "Update" and begin the program. Good luck!',
                        },
                      },
                    ],
                  },
                  {
                    type: "p",
                    children: [
                      {
                        type: "formatted_message",
                        text: {
                          id: "thank_you_modal_p1",
                          defaultMessage:
                            "Be sure to check your inbox for a welcome message from us.  If you do not see it, please check your trash and junk folders.",
                        },
                      },
                    ],
                  },
                ],
              },
              modalNorms: {},
              modalResponses: {},
              closeButton: false,
              scroll: false,
            }),
          );
          const planAmount = _.get(plan, "stripe_data.amount") / 100;
          const product = {
            name: plan.name,
            sku: plan.sku,
            price: planAmount,
            quantity: 1,
          };

          window.dataLayer.push({
            event: "subscriptionAdded",
            userId: userId,
            groupCode: CUAC_SETTINGS.GROUP.code,
            planStripeKey: plan.stripe_key,
            planName: plan.name,
            planAmount: planAmount,
            planCurrency: _.get(plan, "stripe_data.currency"),
            transactionId: userId + "-" + plan.stripe_key + "-" + Date.now(),
            transactionTotal: planAmount,
            transactionAffiliation: CUAC_SETTINGS.GROUP.code,
            transactionProducts: [product],
          });

          return dispatch(requestAddSubscriptionSuccess(json));
        });
      })
      .catch((error) => {
        dispatch(
          notify({
            title: "Subscription Creation Failed",
            message: error.message,
            level: "error",
            autoDismiss: 4,
          }),
        );
        return dispatch(requestAddSubscriptionFailure(error));
      });
  };
}
