"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Actions from "actions";
import { TrackerTypes } from "api/tracker-entries";
import Moment from "moment";
Moment.locale("en");
import momentTZ from "lib/moment-timezone-with-data";
import UrgeTracker from "components/forms/urge-tracker";

const UrgeTrackerModal = React.createClass({
  getInitialState() {
    const { auth } = this.props;
    const user_timezone = _.get(auth, "user.user_timezone");
    const initialDateTime = momentTZ.tz(new Date(), user_timezone);
    const urgeEntry = this.props.urgeEntry || {};
    var isNew = true;

    var initialValues = {
      dt_occurred: initialDateTime.format("YYYY-MM-DD"),
      tm_occurred: initialDateTime.format("hh:mm A"),
      duration: 1,
    };

    if (urgeEntry._id) {
      var isNew = false;

      var tmOccurred = Moment(urgeEntry.data.dt_occurred.split("+")[0]).format(
        "YYYY-MM-DD HH:mm:ss",
      );
      tmOccurred = momentTZ.tz(tmOccurred, "UTC");
      var userTzTmOccurred = tmOccurred.clone().tz(user_timezone);

      initialValues = {
        dt_occurred: userTzTmOccurred.format("YYYY-MM-DD"), //YYYY-MM-DD
        tm_occurred: userTzTmOccurred.format("hh:mm A"),
        with_whom: urgeEntry.data.with_whom,
        intensity: urgeEntry.data.intensity,
        where: urgeEntry.data.where,
        mood: urgeEntry.data.mood,
        strategy: urgeEntry.data.strategy,
        duration: urgeEntry.data.duration,
        urge_type: urgeEntry.data.urge_type,
      };
    }
    return {
      isNew: isNew,
      initialValues: initialValues,
    };
  },

  addUrge(token, userId, data) {
    const { dispatch } = this.props;
    dispatch(
      Actions.addTrackerEntry(token, userId, data, TrackerTypes.URGE),
    ).then(() => {
      this.closeModal();
    });
  },

  editUrge(token, userId, data, trackerEntryId) {
    const { dispatch, urgeEntry } = this.props;
    dispatch(
      Actions.updateTrackerEntry(token, userId, trackerEntryId, data),
    ).then(() => {
      this.closeModal();
    });
  },

  closeModal() {
    this.props.dispatch(
      Actions.closeModal("appModal", {
        modalNotifications: "",
        modalContent: false,
      }),
    );
  },

  onAltClick() {
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "newUrgeTracker",
        modalContent: true,
      }),
    );
  },

  onFormSubmit(data) {
    const { auth, dispatch } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    const user_timezone = _.get(auth, "user.user_timezone");

    var dtOccurred = data.dt_occurred.split("T")[0] + " " + data.tm_occurred;
    dtOccurred = Moment(dtOccurred, "YYYY-MM-DD hh:mm A").format(
      "YYYY-MM-DD HH:mm:ss",
    );
    dtOccurred = momentTZ.tz(dtOccurred, user_timezone); //currently it is in NY.
    //convert it to UTC.
    var utcDtOccurred = dtOccurred.clone().tz("UTC");

    var payload = {
      dt_occurred: utcDtOccurred.format("YYYY-MM-DDTHH:mm:ss"),
      with_whom: data.with_whom,
      intensity: data.intensity,
      where: data.where,
      mood: data.mood,
      strategy: data.strategy,
      duration: data.duration,
      urge_type: data.urge_type,
    };
    if (this.state.isNew) {
      this.addUrge(token, userId, payload, TrackerTypes.URGE);
    } else {
      this.editUrge(token, userId, payload, this.props.urgeEntry._id);
    }
  },

  render() {
    const { trackerEntries } = this.props;
    const isFetching = _.get(trackerEntries, "isFetching");

    return (
      <div className="container">
        <UrgeTracker
          onSubmit={this.onFormSubmit}
          onClick={this.onAltClick}
          isFetching={isFetching}
          initialValues={this.state.initialValues}
        />
      </div>
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
  trackerEntries: state.trackerEntries,
  urgeEntry: state.modal.urgeEntry,
}))(UrgeTrackerModal);
