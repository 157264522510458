"use strict";

import { updateSubscription as updateSubscriptionAPI } from "api/subscriptions";
import notify from "actions/notify";
import Actions from "actions";
const dataLayer = [];

import {
  REQUEST_UPDATE_SUBSCRIPTION,
  REQUEST_UPDATE_SUBSCRIPTION_SUCCESS,
  REQUEST_UPDATE_SUBSCRIPTION_FAILURE,
} from "actions/types";

function requestUpdateSubscription() {
  return {
    type: REQUEST_UPDATE_SUBSCRIPTION,
  };
}

function requestUpdateSubscriptionSuccess(json) {
  return {
    type: REQUEST_UPDATE_SUBSCRIPTION_SUCCESS,
    payload: {
      subscription: json,
    },
  };
}

function requestUpdateSubscriptionFailure(error) {
  return {
    type: REQUEST_UPDATE_SUBSCRIPTION_FAILURE,
    payload: error,
    error: true,
  };
}

export default function updateSubscription(
  token,
  userId,
  subscriptionId,
  updates,
  plan,
) {
  return (dispatch) => {
    dispatch(requestUpdateSubscription());
    return updateSubscriptionAPI(token, subscriptionId, updates)
      .then((json) => {
        return Promise.all([
          dispatch(Actions.getUser(token, userId)),
          dispatch(Actions.getResponses(token, userId)),
          dispatch(Actions.getTools(token)),
        ]).then(() => {
          dispatch(
            notify({
              title: "Subscription Updated",
              message: "Your subscription was updated successfully",
              level: "success",
              autoDismiss: 4,
            }),
          );
          const planAmount = _.get(plan, "stripe_data.amount") / 100;
          window.dataLayer.push({
            event: "subscriptionChanged",
            userId: userId,
            groupCode: CUAC_SETTINGS.GROUP.code,
            planStripeKey: plan.stripe_key,
            planName: plan.name,
            planAmount: planAmount,
            planCurrency: _.get(plan, "stripe_data.currency"),
          });

          return dispatch(requestUpdateSubscriptionSuccess(json));
        });
      })
      .catch((error) => {
        dispatch(
          notify({
            title: "Subscription Update Failed",
            message: error.message,
            level: "error",
            autoDismiss: 0,
          }),
        );
        return dispatch(requestUpdateSubscriptionFailure(error));
      });
  };
}
