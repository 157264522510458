"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "components/ux/mdl/button";
import { Block } from "components/blocks/block";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  LIGHT_YELLOW,
  LIGHTER_FOREST_GREEN,
  YELLOW,
} from "../../stylesheets/colors";
import BSButton from "../blocks/BSButton.component.web";
import CouponCapture from "../blocks/CouponCapture.component.web";

export const PlanList = React.createClass({
  propTypes: {
    onSelectPlan: React.PropTypes.func.isRequired,
    plans: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    showCouponCapture: React.PropTypes.bool.isRequired,
  },
  icons() {
    return [
      CUAC_SETTINGS.S3_ROOT + "/app/img/ux/screener-plan1.png",
      CUAC_SETTINGS.S3_ROOT + "/app/img/ux/screener-plan2.png",
    ];
  },
  render() {
    const { plans, onSelectPlan, onAddFreeSubscription, screener } = this.props;
    // set up our final call to action button to use outside the scope of the price function for plans
    let callToAction = null;
    let callToAction1 = null;
    let callToAction2 = null;
    let twoColumns = false;

    const price = (plan, planData) => {
      const { name, auto_cancel, tagline, stripe_key, order, button_text } =
        plan;
      const { amount, currency, id, interval_count, interval } = planData;
      const realAmount = Number(amount / 100).toFixed(0);
      const realCurrency = currency.toLowerCase() === "usd" ? "$" : currency;

      const checkupMarkup = [
        {
          default_responses: {},
          type: "informational_text",
          components: [
            {
              type: "unescaped",
              className: "paragraph",
              children: [
                {
                  text: {
                    defaultMessage:
                      '<img src="' +
                      CUAC_SETTINGS.S3_ROOT +
                      '/app/img/ux/checkup-icon.png" alt="Icon for the CheckUp displayed as a clipboard with a checkmark on it."> The CheckUp will help you:',
                    id: "plans_checkup_p_0",
                  },
                  type: "formatted_message",
                },
              ],
            },
            {
              type: "ul",
              children: [
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Clarify your relationship with alcohol and its impact on your life, including risks",
                        id: "plans_checkup_ul_0_li_0",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Consider whether or not to change your drinking",
                        id: "plans_checkup_ul_0_li_1",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Assess whether moderating or stopping would be most useful",
                        id: "plans_checkup_ul_0_li_2",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
              ],
            },
            {
              type: "p",
              children: [
                {
                  text: {
                    defaultMessage:
                      "If you decide at the end of taking the Drinker's CheckUp evaluation that you want to take steps to make changes, you can upgrade to our Choices Program to get ongoing help with that.",
                    id: "plans_checkup_p_1",
                  },
                  type: "formatted_message",
                },
              ],
            },
          ],
        },
      ];

      const moderateMarkup = [
        {
          default_responses: {},
          type: "informational_text",
          components: [
            {
              type: "unescaped",
              className: "paragraph",
              children: [
                {
                  text: {
                    defaultMessage:
                      '<img src="' +
                      CUAC_SETTINGS.S3_ROOT +
                      '/app/img/ux/moderate-icon.png" alt="Icon for the Moderate program, shown as a descending arrow on a graph."> The Moderate program will help you:',
                    id: "plans_3month_p_0",
                  },
                  type: "formatted_message",
                },
              ],
            },
            {
              type: "ul",
              children: [
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage: "Set safe and realistic goals",
                        id: "plans_3month_ul_0_li_0",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Learn how to keep track of your drinking",
                        id: "plans_3month_ul_0_li_1",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Learn to manage your triggers for heavy drinking",
                        id: "plans_3month_ul_0_li_2",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage: "Maximize the chances of success.",
                        id: "plans_3month_ul_0_li_3",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ];

      const abstainMarkup = [
        {
          default_responses: {},
          type: "informational_text",
          components: [
            {
              type: "unescaped",
              className: "paragraph",
              children: [
                {
                  text: {
                    defaultMessage:
                      '<img src="' +
                      CUAC_SETTINGS.S3_ROOT +
                      '/app/img/ux/abstain-icon.png" alt="Icon for the Abstain program, shown as a hand giving the \'stop\' signal."> The Abstain program will help you:',
                    id: "plans_3month_p_0",
                  },
                  type: "formatted_message",
                },
              ],
            },
            {
              type: "ul",
              children: [
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Increase and maintain motivation for change",
                        id: "plans_3month_ul_1_li_0",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage: "Cope with urges",
                        id: "plans_3month_ul_1_li_1",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Manage thoughts, feelings, and behaviors",
                        id: "plans_3month_ul_1_li_2",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Live a balanced life and reduce risk of relapse.",
                        id: "plans_3month_ul_1_li_3",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
              ],
            },
            {
              type: "p",
              children: [
                {
                  text: {
                    defaultMessage:
                      "In 2 clinical trials, the Choices Program helped participants to significantly reduce their drinking and alcohol-related problems.",
                    id: "plans_3month_p_1",
                  },
                  type: "formatted_message",
                },
              ],
            },
          ],
        },
      ];

      const marker = { fontSize: "26px", fontWeight: "500" },
        number = { fontSize: "26px", fontWeight: "500", padding: "0 4px" },
        description = { padding: "0 16px 12px 4px" };

      const priceDescription = {
        id: "plan_" + id + "_price_description",
        defaultMessage:
          "{ interval_count, plural, one {per " +
          interval +
          "} other {every # " +
          interval +
          "s} }",
      };
      const priceDescriptionValues = { interval_count };
      const priceDescriptionComponent = auto_cancel ? (
        <span className="price-description" style={description}>
          one-time payment
        </span>
      ) : (
        <span className="price-description" style={description}>
          <FormattedMessage
            {...priceDescription}
            values={priceDescriptionValues}
          />
        </span>
      );
      const checkupButton = auto_cancel ? (
        <div>
          {screener ? (
            <div className="program-choice-item-1-year w-100">
              <div
                className="price-info p-2 my-3"
                style={{
                  backgroundColor: LIGHTER_FOREST_GREEN,
                  borderRadius: ".25rem",
                }}
              >
                <div
                  className="price-amount d-flex align-items-center justify-content-center"
                  style={number}
                >
                  <p className="fs-2 mb-0">
                    {realCurrency}
                    {realAmount}
                  </p>
                  <p className="fs-5 mb-0 ms-1">{priceDescriptionComponent}</p>
                </div>
              </div>
              <p className="text-small" style={{ textAlign: "center" }}>
                {plan.footline}
              </p>
            </div>
          ) : null}

          <div
            className={`d-grid gap-2 w-100 ${screener ? "checkup-button-screener-page" : "plan-button-account-page"}`}
          >
            <BSButton
              variant="blue"
              size="lg"
              className="justify-content-center"
              onClick={() => onSubmit(plan)}
            >
              <FontAwesomeIcon
                size={20}
                icon={faChevronCircleRight}
                style={{ color: "white" }}
              />
              <div className="ms-2">
                <FormattedMessage
                  id="plan_selection_button_select"
                  defaultMessage="{button_text}"
                  values={{ button_text: button_text }}
                  description="Select"
                />
              </div>
            </BSButton>
          </div>
        </div>
      ) : null;
      const freeTrialDesc = {
        width: "100%",
        fontSize: "24px",
        color: "#F6891F",
        fontWeight: "bold",
        marginBottom: "18px",
      };
      const freeTrialText =
        plan.free_trial_days && plan.free_trial_days > 0 ? (
          <div style={freeTrialDesc} className="free-trial-text">
            Try risk free for {plan.free_trial_days} days!
          </div>
        ) : (
          ""
        );

      const choices3MonthButton =
        order == 2 ? (
          <div>
            {screener ? (
              <div className="program-choice-item-1-year w-100">
                <div
                  className="price-info p-2 my-3"
                  style={{
                    backgroundColor: LIGHTER_FOREST_GREEN,
                    borderRadius: ".25rem",
                  }}
                >
                  <div
                    className="price-amount d-flex align-items-center justify-content-center"
                    style={number}
                  >
                    <p className="fs-2 mb-0">
                      {realCurrency}
                      {realAmount}
                    </p>
                    <p className="fs-5 mb-0 ms-1">
                      {priceDescriptionComponent}
                    </p>
                  </div>
                </div>
                <p className="text-small" style={{ textAlign: "center" }}>
                  {plan.footline}
                </p>
              </div>
            ) : null}
            <div className="plan-button-account-page">
              {freeTrialText}
              <BSButton
                variant="blue"
                size="lg"
                className="w-100 justify-content-center"
                onClick={() => onSubmit(plan)}
              >
                <FontAwesomeIcon
                  size={20}
                  icon={faChevronCircleRight}
                  style={{ color: "white" }}
                />
                <div className="ms-1">
                  <FormattedMessage
                    id="plan_selection_button1_select"
                    defaultMessage="{button_text}"
                    values={{ button_text: button_text }}
                    description="Select"
                  />
                </div>
              </BSButton>
            </div>
          </div>
        ) : null;
      // build price-info into call to action button since there is no data pulled
      const choices1YearButton =
        order == 3 ? (
          <div className="program-choice-item-1-year w-100">
            <div
              className="price-info p-2 my-3"
              style={{
                backgroundColor: LIGHTER_FOREST_GREEN,
                borderRadius: ".25rem",
              }}
            >
              <div
                className="price-amount d-flex align-items-center justify-content-center"
                style={number}
              >
                <p className="fs-2 mb-0">
                  {realCurrency}
                  {realAmount}
                </p>
                <p className="fs-5 mb-0 ms-1">{priceDescriptionComponent}</p>
              </div>
            </div>
            <p className="text-small" style={{ textAlign: "center" }}>
              {plan.footline}
            </p>
            <div className="plan-button2-account-page">
              {freeTrialText}
              <BSButton
                variant="blue"
                size="lg"
                className="w-100 justify-content-center"
                onClick={() => onSubmit(plan)}
              >
                <FontAwesomeIcon
                  size={20}
                  icon={faChevronCircleRight}
                  style={{ color: "white" }}
                />
                <div className="ms-1">
                  <FormattedMessage
                    id="plan_selection_button2_select"
                    defaultMessage="{button_text}"
                    values={{ button_text: button_text }}
                    description="Select"
                  />
                </div>
              </BSButton>
            </div>
          </div>
        ) : null;
      // if we have a checkupButton, or choices buttons, we'll assign call to action to it
      if (checkupButton !== null) {
        callToAction = checkupButton;
      }
      if (choices3MonthButton !== null) {
        callToAction1 = choices3MonthButton;
      }
      if (choices1YearButton !== null) {
        callToAction2 = choices1YearButton;
      }

      const onSubmit =
        amount === 0 && this.props.userRegistered
          ? onAddFreeSubscription
          : onSelectPlan;

      // block requires responses, so we create an empty object here for now, we should look at if it needs to be required
      const emptyResponse = {};

      return (
        <div>
          <div
            className="callout-A p-2"
            style={{ backgroundColor: LIGHT_YELLOW, borderRadius: ".25rem" }}
          >
            <p>{plan.description}</p>
            {plan.checkup
              ? checkupMarkup.map((component, index) => {
                  return (
                    <Block
                      key={"block_checkup_" + index}
                      component={component}
                      index={index}
                      responses={emptyResponse}
                    />
                  );
                })
              : null}
            {plan.moderate
              ? moderateMarkup.map((component, index) => {
                  return (
                    <Block
                      key={"block_moderate_" + index}
                      component={component}
                      index={index}
                      responses={emptyResponse}
                    />
                  );
                })
              : null}
            {plan.abstain
              ? abstainMarkup.map((component, index) => {
                  return (
                    <Block
                      key={"block_abstain_" + index}
                      component={component}
                      index={index}
                      responses={emptyResponse}
                    />
                  );
                })
              : null}
            <div className="clearfix" />
          </div>
          {screener ? null : (
            <div
              className="price-info p-2 my-3"
              style={{
                backgroundColor: LIGHTER_FOREST_GREEN,
                borderRadius: ".25rem",
              }}
            >
              <div
                className="price-amount d-flex align-items-center justify-content-center"
                style={number}
              >
                <p className="fs-2 mb-0">
                  {realCurrency}
                  {realAmount}
                </p>
                <p className="fs-5 mb-0 ms-1">{priceDescriptionComponent}</p>
              </div>
            </div>
          )}
        </div>
      );
    };
    var accountPlans = [];

    plans.map(function (plan) {
      // intiate the data so we can access the button for 1 year plan
      if (plan.order == 3) {
        price(plan, _.get(plan, "stripe_data"));
      } else if (plan.order == 2) {
        accountPlans.push(plan);
      } else if (plan.order == 1) {
        twoColumns = true;
        accountPlans.push(plan);
      }
    });
    const freeTrialStyle = {
      width: "100%",
      fontSize: "16px",
      color: "#F6891F",
    };
    const freeTrialOffer = CUAC_SETTINGS.GROUP.free_trial_offer;
    const freeTrialText = freeTrialOffer
      ? "For a limited time, we're offering a FREE 7-day trial for you to try our CheckUp & Choices program"
      : "";

    return (
      <>
        <div className="row">
          <div className="col">
            <h1 className="my-3 text-center">
              <FormattedMessage
                id="plan_list_page_title"
                description="Plan Choice List -- page title"
                defaultMessage="Plans"
              />
            </h1>
            <div className="informational-text text-center">
              <div className="paragraph">
                <span>
                  CheckUp & Choices was designed by clinical psychologists to
                  help you understand and, if you choose to do so, change your
                  drinking and/or drug use. The program demonstrated
                  effectiveness in clinical trials funded by NIH/NIAAA.
                  {_.get(CUAC_SETTINGS, "GROUP.partner_paid")
                    ? null
                    : "Our program comes with a risk-free, money-back guarantee."}
                </span>
                <p style={freeTrialStyle}>{freeTrialText}</p>
              </div>
            </div>
          </div>
        </div>
        {this.props.showCouponCapture && (
          <div className="row my-3">
            <div className="col-12 col-lg-6 offset-lg-3">
              <CouponCapture />
            </div>
          </div>
        )}
        <div className="row d-flex justify-content-center">
          {_.sortBy(accountPlans, (plan) => {
            return _.get(plan, "stripe_data.amount");
          }).map((plan, index) => {
            const planData = _.get(plan, "stripe_data");
            const ICONS = this.icons();
            let iconURL = null;
            if (index < ICONS.length) iconURL = ICONS[index];
            return (
              <div className="col-12 col-lg-4 my-3" key={"program-" + index}>
                <div
                  className="card mx-2 bg-white"
                  style={{ minWidth: 300, border: "none", flex: 1 }}
                >
                  <div className="card-body d-flex flex-column align-items-center">
                    {iconURL && (
                      <img
                        src={iconURL}
                        alt="CheckUp Icon shown as a clipboard."
                      />
                    )}
                    <h4 className="card-title text-center fw-bold my-2">
                      {plan.tagline}
                    </h4>
                    {price(plan, planData)}
                    {screener ? null : (
                      <>
                        <div className="w-100">
                          <p
                            className="text-small"
                            style={{ textAlign: "center" }}
                          >
                            {plan.footline}
                          </p>
                          {plan.order === 1 ? callToAction : callToAction1}
                        </div>
                        {plan.order !== 1
                          ? screener
                            ? null
                            : callToAction2
                          : null}
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {screener && (
          <div className="row">
            <div className="col">
              {callToAction || (
                <>
                  {callToAction1}
                  {callToAction2}
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  },
});
