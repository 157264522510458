"use strict";

import _ from "lodash";

import {
  REQUEST_GET_TRACKER_ENTRY_STATS,
  REQUEST_GET_TRACKER_ENTRY_STATS_SUCCESS,
  REQUEST_GET_TRACKER_ENTRY_STATS_FAILURE,
  REQUEST_LOG_OUT_USER_SUCCESS,
} from "actions/types";

export function reducer(
  state = {
    isFetching: false,
    trackerEntryStats: {},
  },
  action = { type: "" },
) {
  switch (action.type) {
    case REQUEST_GET_TRACKER_ENTRY_STATS:
      return {
        ...state,
        isFetching: true,
      };
    case REQUEST_GET_TRACKER_ENTRY_STATS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        trackerEntryStats: action.payload.trackerEntryStats,
      };
    case REQUEST_GET_TRACKER_ENTRY_STATS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case REQUEST_LOG_OUT_USER_SUCCESS:
      return {
        isFetching: false,
      };
    default:
      return state;
  }
}
