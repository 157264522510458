/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React from "react";
import Actions from "actions";
import { connect } from "react-redux";
import HelpFeedbackForm from "components/forms/help-feedback-form";
import Moment from "moment";
Moment.locale("en");

const HelpFeedback = React.createClass({
  displayName: "HelpFeedback",
  // ======= REACT LIFECYCLE ============================

  closeModal: function () {
    this.props.dispatch(
      Actions.closeModal("appModal", {
        modalNotifications: "",
        modalContent: false,
      }),
    );
  },

  onFormSubmit: function (data) {
    const { dispatch } = this.props;

    dispatch(Actions.updateDisplay("appSpinner", "PLEASE_WAIT"));

    data.url = window.location.href;

    let nVer = "Unknown Navigator App Version";
    let nAgt = "Unknown Navigator User Agent";

    if (navigator.appVersion) {
      nVer = navigator.appVersion;
    }

    if (navigator.userAgent) {
      nAgt = navigator.userAgent;
    }

    let browserName = "Unknown Browser Name";

    if (navigator.appName) {
      browserName = navigator.appName;
    }

    let fullVersion = "" + parseFloat(navigator.appVersion);
    let nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    data.browser = browserName;
    data.browser_version = fullVersion;
    data.user_agent = nAgt;

    let OSName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";

    data.operating_system = OSName;

    const { _id, token, email } = this.props.auth?.user ?? {};
    if (!("email" in data) && !!email) data["email"] = email;

    dispatch(Actions.addHelpFeedback(_id, token, data)).then(() => {
      dispatch(Actions.updateDisplay("appSpinner", "STOP"));
      this.closeModal();
    });
  },

  render: function () {
    return (
      <HelpFeedbackForm
        isFetching={this.props.isFetching}
        onSubmit={this.onFormSubmit}
        loggedIn={this.props.auth.user.status === "unregistered" ? false : true}
      />
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
  isFetching: state.helpFeedback.isFetching,
}))(HelpFeedback);
