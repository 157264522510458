import React from "react";
import { Field } from "redux-form";
import { StyleProp } from "react-native";
import { Form, InputGroup } from "react-bootstrap";

type Props = {
  name?: string;
  placeholder?: string;
  style?: StyleProp<any>;
  label?: string;
  inputGroupClassName?: string;
  input: any;
};

const Input = ({ label, ...props }: Props) => {
  const toggle = () => {
    const { value, onChange } = props.input,
      newVal = typeof value === "boolean" ? !value : true;
    onChange(newVal);
  };

  return (
    <InputGroup
      className={`input-group-fancy mb-3 d-flex align-items-center ${props.inputGroupClassName || ""}`}
    >
      <span className="input-group-text" onClick={toggle}>
        {label}
      </span>
      <Form.Check
        type="switch"
        id="custom-switch"
        value="true"
        checked={!!props.input?.value}
        onChange={props.input?.onChange}
        {...props}
      />
    </InputGroup>
  );
};

const SwitchInput = (props: any) => {
  return <Field component={Input} {...props} />;
};

export default SwitchInput;
