import React, { useState, useRef, useEffect, useCallback } from "react";
import testimonials from "./testimonials.json";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  ViewabilityConfigCallbackPairs,
  LayoutChangeEvent,
} from "react-native";
import Animated, {
  useAnimatedReaction,
  useAnimatedScrollHandler,
  useSharedValue,
} from "react-native-reanimated";
import BSButton from "components/blocks/BSButton.component.web";
import { Ionicons } from "@expo/vector-icons";

const viewabilityConfig = {
  itemVisiblePercentThreshold: 50,
};

const AnimatedList = Animated.createAnimatedComponent(FlatList);

export default function TestimonialsCarousel() {
  const interval = useRef<NodeJS.Timeout>();
  const ref = useRef<FlatList<string>>(null);

  const x = useSharedValue(0);

  const [listSize, setListSize] = useState<number>(0);
  const [data, setData] = useState(testimonials);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  const viewabilityConfigCallbackPairs = useRef<ViewabilityConfigCallbackPairs>(
    [
      {
        viewabilityConfig,
        onViewableItemsChanged: ({ viewableItems }) => {
          if (
            viewableItems[0]?.index !== undefined &&
            viewableItems[0]?.index !== null
          ) {
            setCurrentIndex(viewableItems[0].index);
          }
        },
      },
    ]
  );

  const onScroll = useAnimatedScrollHandler({
    onScroll: (e) => {
      x.value = e.contentOffset.x;
    },
  });

  const _handleScrollList = (index: number, animated = true) => {
    const offset = index * listSize;

    setCurrentIndex(index);
    ref.current?.scrollToOffset({ offset, animated });
  };

  const _handleListSize = useCallback((e: LayoutChangeEvent) => {
    setListSize(e.nativeEvent.layout.width);
  }, []);

  const _handleBeginDrag = () => setIsAutoPlay(false);
  const _handleEndDrag = () => setIsAutoPlay(true);

  const _handleEndReach = () => setData([...data, ...testimonials]);

  useAnimatedReaction(
    () => x.value,
    (xOffset) => {
      // Reaction to keep current index updated
      setCurrentIndex(Math.round(xOffset / listSize));
    }
  );

  useEffect(() => {
    if (!!isAutoPlay) {
      interval.current = setInterval(() => {
        _handleScrollList(currentIndex + 1);
      }, 4000);
    } else if (!!interval.current) {
      clearInterval(interval.current);
    }
    return () => {
      if (!!interval.current) clearInterval(interval.current);
    };
  }, [isAutoPlay, currentIndex]);

  return (
    <View style={styles.root}>
      <Text style={styles.title}>Testimonials</Text>

      <View style={[styles.container]}>
        <BSButton
          variant="transparent"
          style={styles.arrowButton}
          onClick={() => _handleScrollList(Math.max(currentIndex - 1, 0))}
        >
          <Ionicons name="chevron-back-outline" size={24} color="black" />
        </BSButton>

        <AnimatedList
          // @ts-ignore
          ref={ref}
          horizontal
          pagingEnabled
          scrollEnabled={false}
          onLayout={_handleListSize}
          style={{ width: listSize }}
          onScrollBeginDrag={_handleBeginDrag}
          onScrollEndDrag={_handleEndDrag}
          onScroll={onScroll}
          scrollEventThrottle={16}
          bounces={false}
          showsHorizontalScrollIndicator={false}
          viewabilityConfigCallbackPairs={
            viewabilityConfigCallbackPairs.current
          }
          initialScrollIndex={Math.round(testimonials.length / 2)}
          onEndReachedThreshold={0.5}
          onEndReached={_handleEndReach}
          data={data}
          keyExtractor={(_, index) => `list_item${index}`}
          renderItem={({ item: testimonial }) => (
            <View style={[styles.itemContainer, { width: listSize }]}>
              <Text style={styles.itemText}>{testimonial}</Text>
            </View>
          )}
        />
        <BSButton
          variant="transparent"
          style={styles.arrowButton}
          onClick={() => _handleScrollList(currentIndex + 1)}
        >
          <Ionicons name="chevron-forward-outline" size={24} color="black" />
        </BSButton>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "column",
    paddingVertical: 42,
  },
  title: {
    width: "100%",
    fontSize: 24,
    textAlign: "center",
    paddingBottom: 16,
  },
  container: {
    flexDirection: "row",
    justifyContent: "center",
  },
  arrowButton: {
    width: 32,
    maxHeight: 32,
  },
  itemContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  itemText: {
    fontSize: 18,
    lineHeight: 24,
    fontStyle: "italic",
    textAlign: "center",
    maxWidth: "75%",
  },
});
