/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, self, goToUrl */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React, { createRef } from "react";
import { findDOMNode } from "react-dom";
import classnames from "classnames";
import { FontAwesome } from "@expo/vector-icons";
import { BLACK, LIGHT_BLUE } from "../../stylesheets/colors";

var InfoBug = React.createClass({
  displayName: "InfoBug",

  propTypes: {
    className: React.PropTypes.string,
    popClass: React.PropTypes.string,
    display: React.PropTypes.bool,
    style: React.PropTypes.object,
    size: React.PropTypes.number,
    icon: React.PropTypes.string,
  },

  popoverRef: createRef(),

  getInitialState: function () {
    return { showPopOver: false, blockStyle: {} };
  },

  getDefaultProps: function () {},

  componentDidMount: function () {
    document.addEventListener("mousedown", this.handleClick);
    document.addEventListener("touchend", this.handleClick);
  },

  componentWillUnmount: function () {
    document.removeEventListener("mousedown", this.handleClick);
    document.removeEventListener("touchend", this.handleClick);
  },

  UNSAFE_componentWillReceiveProps: function (nextProps) {},

  // ======== LOCAL METHODS ==============

  handleClick: function (e) {
    if (
      !!this.state.showPopOver &&
      this.popoverRef?.current &&
      !this.popoverRef?.current.contains(e.target)
    ) {
      this.onClickToggle(e);
    }
  },

  adjustScreenPosition: function (el) {
    const { extended } = this.props;
    const { blockStyle } = this.state;

    function translate(offsetX = 0, offsetY = 0) {
      const delivery = extended ? _.clone(blockStyle) : {};

      const translation = `translate(${offsetX}px, ${offsetY}px)`;

      delivery["WebkitTransform"] = translation;
      delivery["MozTransform"] = translation;
      delivery["msTransform"] = translation;
      delivery["OTransform"] = translation;
      delivery["transform"] = translation;

      return delivery;
    }

    if (el && this.state.showPopOver) {
      const MARGIN = 20;
      const rect = el.getBoundingClientRect();
      const docBounds = document.body.getBoundingClientRect();

      const verticalOffset = Math.min(
        docBounds.height - (rect.y + rect.height + window.scrollY) - MARGIN,
        0
      );
      const horizontalOffset = Math.min(
        docBounds.width - (rect.x + rect.width) - MARGIN,
        0
      );

      if (verticalOffset < 0 || horizontalOffset < 0) {
        this.setState({
          blockStyle: translate(horizontalOffset, verticalOffset),
        });
      }
    }
  },

  onClickToggle: function (event) {
    const { extended } = this.props;

    event.preventDefault();
    event.stopPropagation();
    this.setState(
      {
        showPopOver: !this.state.showPopOver,
        blockStyle:
          extended && !this.state.showPopOver
            ? { width: window.innerWidth * 0.9 + "px", maxWidth: "600px" }
            : {},
      },
      function () {
        this.adjustScreenPosition(findDOMNode(this.refs.popoverBlock));
      }
    );
  },

  onKeyDownToggle: function (event) {
    if (event.which == 32) {
      this.onClickToggle(event);
    }
  },

  // ======== RENDER ==============

  render: function () {
    let { className, popClass, style, extended, size = 12 } = this.props;

    let outer = classnames(
      "popOver-parent",
      { active: this.state.showPopOver },
      className
    );
    let inner = classnames("popOver-block", { extended: extended }, popClass);

    return (
      <div className={outer} style={style}>
        <span
          className="popOver-trigger"
          onClick={this.onClickToggle}
          role="button"
          tabIndex="0"
          onKeyDown={this.onKeyDownToggle}
          aria-label="Toggle Help Bubble"
        >
          <div className="popOver-trigger-icon-container">
            <FontAwesome
              name={this.props.icon || "info"}
              size={size}
              style={{
                width: size + (size % 8),
                textAlign: "center",
              }}
              color={LIGHT_BLUE}
            />
          </div>
        </span>

        {!!this.state.showPopOver && (
          <div
            className={inner}
            ref="popoverBlock"
            style={this.state.blockStyle}
          >
            <div ref={this.popoverRef}>
              <div className="close-container">
                <div
                  className="close"
                  onClick={this.onClickToggle}
                  role="button"
                  tabIndex="0"
                  onKeyDown={this.onKeyDownToggle}
                  aria-label="Close Help Bubble"
                >
                  <FontAwesome name="times" size={18} color={BLACK} />
                </div>
              </div>

              <div className="popOver-content">{this.props.children}</div>
            </div>
          </div>
        )}
      </div>
    );
  },
});

export default InfoBug;
export { InfoBug };
