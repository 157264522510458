/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, Stripe */
"use strict";

import React from "react";

import { getScoreOptionIndex } from "utils/render";
import { Block } from "components/blocks/block";
import { Element } from "components/elements/element";
import { ScoreChart } from "components/elements/score-chart";
import CompletionNotice from "components/elements/completion-notice";

export const ConditionalFeedback = React.createClass({
  propTypes: {
    data_key: React.PropTypes.string.isRequired,
    default_responses: React.PropTypes.object.isRequired,
    norms: React.PropTypes.object,
    options: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    required_responses: React.PropTypes.arrayOf(React.PropTypes.string)
      .isRequired,
    responses: React.PropTypes.object.isRequired,
    show_highlight: React.PropTypes.bool,
  },

  render() {
    const {
      data_key: dataKey,
      default_responses,
      norms,
      options,
      title,
      responses,
      required_responses,
      mode,
      reverse_spectrum,
      dispatch,
      updateResponse,
      activeResponse,
    } = this.props;
    const mergedResponses = {
      ...default_responses,
      ...responses,
    };
    const score = _.get(responses, dataKey, 0);
    const scoreOptionIndex = getScoreOptionIndex(score, options);

    const isIncomplete = Boolean(
      _.difference(required_responses, Object.keys(responses)).length,
    );
    const scoreChart = mode !== "no_chart" && (
      <ScoreChart
        score={score}
        options={options}
        scoreOptionIndex={scoreOptionIndex}
        isIncomplete={isIncomplete}
        mode={mode}
        reverseSpectrum={reverse_spectrum}
        title={title}
      />
    );

    return (
      <div className="conditional-feedback">
        <CompletionNotice {...this.props} />
        {!isIncomplete && (
          <div>
            {scoreChart}
            <div className="conditional-feedback">
              {options[scoreOptionIndex].components.map((component, index) => (
                <div className="my-4" key={"block_" + index}>
                  {_.get(component, "type") == "conditional_feedback" ||
                  _.get(component, "type") == "send-completion-email" ||
                  _.get(component, "type") == "mj_quantity_frequency" ? (
                    <Block
                      key={"block_" + index}
                      component={component}
                      index={index}
                      responses={responses}
                      dispatch={dispatch}
                      updateResponse={updateResponse}
                      activeResponse={activeResponse}
                    />
                  ) : (
                    <Element
                      key={"element_" + index}
                      title={title}
                      component={component}
                      index={index}
                      norms={norms}
                      responses={mergedResponses}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  },
});
