"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import Actions from "actions";
import { CreditCardDisplay } from "components/account/credit-card-display";
import { ProfileDisplay } from "components/account/profile-display";
import { UnsubscribeFromEmails } from "components/account/unsubscribe-from-emails";
import { CloseAccountDisplay } from "components/account/close-account";
import { WearableLoginDisplay } from "components/account/wearable-login-display";
import PlanSelect from "../components/blocks/PlanSelect.component.web";
import { eventPreventDefault as prevent } from "utils/tools";
import AnonymousUserForm from "../components/blocks/AnonymousUserForm.component";

const AccountOverviewPage = React.createClass({
  onChangePlan() {
    this.props.dispatch(Actions.clearSelectedPlan());
    browserHistory.push("/account/plans");
  },

  onClickNav(event) {
    prevent(event);
    const target = event.currentTarget.getAttribute("data-target");
    if (target) {
      browserHistory.push(target);
    }
  },

  onKeyDownNav(event) {
    if (event.which == 32) {
      prevent(event);
      const target = event.currentTarget.getAttribute("data-target");
      if (target) {
        browserHistory.push(target);
      }
    }
  },

  onTurnOffMarketingEmails() {
    const {
      auth: { user },
    } = this.props;
    const token = _.get(user, "token");
    const userId = _.get(user, "_id");
    const data = {};
    data["email_opt_out"] = !_.get(user, "email_opt_out", false);
    this.props.dispatch(Actions.updateUser(token, userId, data));
  },

  onOpenCancelModal() {
    const { auth } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "cancelSubscriptionSurvey",
        modalContent: true,
      }),
    );
  },

  onOpenCloseAccountModal() {
    const { auth } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "closeAccountSurvey",
        modalContent: true,
      }),
    );
  },

  onOpenWearableLoginModal() {
    const { auth } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "wearableLoginCode",
        modalContent: true,
      }),
    );
  },

  anonymousUser() {
    const { email } = this.props.auth?.user ?? {};
    return !email || email.includes("@example.com");
  },

  render() {
    const { auth, subscriptions } = this.props;
    const amount = _.get(subscriptions, "[0].plan.amount", 0);
    const [sub] = subscriptions || [];
    const planDisplay = (
      <PlanSelect
        auth={auth}
        subscriptions={subscriptions}
        onChangePlan={this.onChangePlan}
        activeSubscription={sub}
      />
    );
    const cardDisplay =
      amount === 0 ? null : (
        <CreditCardDisplay
          auth={auth}
          onOpenCancelModal={this.onOpenCancelModal}
          onClickNav={this.onClickNav}
          onKeyDownNav={this.onKeyDownNav}
          subscriptions={subscriptions}
        />
      );

    if (this.anonymousUser())
      return (
        <div className="container account-overview">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2 d-flex flex-column my-4">
              <AnonymousUserForm />
            </div>
          </div>
        </div>
      );

    return (
      <div className="container account-overview">
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2 d-flex flex-column my-4">
            <div className="card" style={{ border: "none" }}>
              <div className="card-body">
                <h2 className="card-title">Plan</h2>
                {planDisplay}
                {cardDisplay}
              </div>
            </div>
            <div className="card mt-4" style={{ border: "none" }}>
              <div className="card-body section-account-overview">
                <h2 className="card-title">
                  <FormattedMessage
                    id="account_overview_title"
                    description="Account Overview -- title"
                    defaultMessage="Account"
                  />
                </h2>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex">
                    <ProfileDisplay auth={auth} onClickNav={this.onClickNav} />
                  </li>
                  {_.get(CUAC_SETTINGS.GROUP, "show_wearable_login") && (
                    <li className="list-group-item d-flex">
                      <WearableLoginDisplay
                        auth={auth}
                        onOpenWearableLoginModal={this.onOpenWearableLoginModal}
                      />
                    </li>
                  )}
                  <li className="list-group-item d-flex">
                    <UnsubscribeFromEmails
                      auth={auth}
                      onTurnOffMarketingEmails={this.onTurnOffMarketingEmails}
                    />
                  </li>
                  {!_.get(CUAC_SETTINGS.GROUP, "hide_close_account") && (
                    <li className="list-group-item d-flex">
                      <CloseAccountDisplay
                        auth={auth}
                        onOpenCloseAccountModal={this.onOpenCloseAccountModal}
                      />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
  subscriptions: _.get(state, "subscriptions.subscriptions.data"),
}))(AccountOverviewPage);
