"use strict";
import { addResponse as addResponseAPI } from "api/responses";

import {
  REQUEST_ADD_RESPONSE,
  REQUEST_ADD_RESPONSE_SUCCESS,
  REQUEST_ADD_RESPONSE_FAILURE,
} from "actions/types";
import { APPLY_RESPONSE } from "./types";
const dataLayer = [];

export function applyResponse(responseId, response) {
  return {
    type: APPLY_RESPONSE,
    payload: {
      responseId,
      response,
    },
  };
}

function requestAddResponse() {
  return {
    type: REQUEST_ADD_RESPONSE,
  };
}

function requestAddResponseSuccess(json) {
  return {
    type: REQUEST_ADD_RESPONSE_SUCCESS,
    payload: {
      response: json,
    },
  };
}

function requestAddResponseFailure(error) {
  return {
    type: REQUEST_ADD_RESPONSE_FAILURE,
    payload: error,
    error: true,
  };
}

export default function addResponse(token, userId, tool) {
  return (dispatch) => {
    dispatch(requestAddResponse());
    return addResponseAPI(token, userId, tool._id)
      .then((json) => {
        window.dataLayer.push({
          event: "responseAdded",
          userId: userId,
          groupCode: CUAC_SETTINGS.GROUP.code,
          toolId: tool._id,
          toolTitle: tool.long_title.defaultMessage,
        });
        return dispatch(requestAddResponseSuccess(json));
      })
      .catch((error) => {
        return dispatch(requestAddResponseFailure(error));
      });
  };
}
