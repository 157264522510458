/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========

import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import Actions from "actions";
import { Modal } from "react-bootstrap";
import MoodTrackerModal from "components/modal/mood-tracker-modal";
import { FormattedMessage } from "react-intl";

import NewReminder from "components/modal/new-reminder";
import UrgeTrackerModal from "components/modal/urge-tracker-modal";
import Button from "components/ux/mdl/button";
import NotificationSystem from "react-notification-system";
import NewDrinkTracker from "components/modal/drink-tracker-modal";
import GoalTrackerModal from "components/modal/goal-tracker-modal";
import NewSolutionTracker from "components/modal/solution-tracker-modal";
import SolutionTrackerOutcomeModal from "components/modal/solution-tracker-outcome-modal";
import CancelSubscriptionModal from "components/modal/cancel-survey-modal";
import CloseAccountModal from "components/modal/close-account-survey-modal";
import WearableLoginModal from "components/modal/wearable-login-modal";
import DrinkCalculatorModal from "components/modal/drink-calculator-modal";
import ChangePlanModal from "components/modal/change-plan-modal";
import AgreementTrackerModal from "components/modal/agreement-tracker-modal";
import WrongSubdomain from "components/modal/wrong-subdomain";
import ImportantMessage from "components/modal/important-message";
import ToolDescriptions from "components/modal/tool-descriptions";
import { Element } from "components/elements/element";
import DayPartModal from "components/modal/daypart-modal";
import TypeAmountModal from "components/modal/type-amount-modal";
import PrivacyPage from "containers/privacy-page";
import TermsPage from "containers/terms-page";
import HelpFeedback from "components/modal/help-feedback";
import { LIGHTER_FOREST_GREEN } from "../../stylesheets/colors";
import BSButton from "../blocks/BSButton.component.web";
import { FontAwesome } from "@expo/vector-icons";

export const AppModal = React.createClass({
  displayName: "AppModal",
  propTypes: {
    dispatch: React.PropTypes.func,
    modalNotifications: React.PropTypes.object,
  },
  // ======= REACT LIFECYCLE ===========================

  latestNoticeUID: 999999, // NotificationSystem

  /*
     getStateFromFlux: function() {
     let delivery = {},
     appState = this.getFlux().store("AppStateStore").getState();

     delivery.modalContent = null;
     if (appState.networkError) { delivery.modalContent = 'networkError'; }
     if (appState.modalDemo) { delivery.modalContent = 'modalDemo'; }

     _.assign(delivery, appState);

     // ====== NotificationSystem ========
     let notice = delivery.systemNotification;
     if (this.state && notice && notice.uid !== this.latestNoticeUID) {
     this.latestNoticeUID = notice.uid;
     this._addNotification(notice);
     }

     return delivery;
     },
     */

  // ======== NotificationSystem ==============
  _notificationSystem: null,
  componentDidMount: function () {
    this._notificationSystem = this.refs.notificationSystem;
    var notifyMessage = ReactDOM.findDOMNode(this.refs.notificationSystem);
    notifyMessage.setAttribute("role", "alert");
    notifyMessage.setAttribute("aria-live", "polite");
  },

  _addNotification: function (notice) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification(notice);
    }
  },

  closeMe: function (event) {
    if (event) {
      event.preventDefault();
    }
    this.props.dispatch(
      Actions.closeModal("appModal", {
        modalNotifications: "",
        edit_reminder: {},
        moodEntry: {},
        urgeEntry: {},
        drinkTrackerEntry: {},
        goalTrackerEntry: {},
        solutionTrackerEntry: {},
        modalContent: false,
      }),
    );
  },

  _handleEscKey: function (event) {
    if (event.keyCode == 27) {
      this.closeMe(event);
    }
  },

  dismissMessage: function (event) {
    const token = _.get(this.props.auth, "user.token");
    const userId = _.get(this.props.auth, "user._id");
    this.props.dispatch(Actions.dismissUserMessage(token, userId));
    this.closeMe(event);
  },

  UNSAFE_componentWillMount: function () {
    document.addEventListener("keydown", this._handleEscKey, false);
  },

  componentWillUnmount: function () {
    document.removeEventListener("keydown", this._handleEscKey, false);
  },

  componentDidUpdate: function (prevProps, prevState) {
    if (!!this.props.modal.modalContent) {
      if (document.querySelector(".modal-overlay-content input")) {
        document.querySelector(".modal-overlay-content input").focus();
      }
    }
  },

  // ======= RENDER =====================================
  snippets: {
    networkError: {
      title: "Network Error",
      body: <p>Please try again in a few minutes.</p>,
      button: "OK",
    },
    modalConfirmPassword: {
      title: "Confirm Password",
      body: (
        <p>
          To update please confirm your <strong>current</strong> password.
        </p>
      ),
      button: "Submit",
    },
    modalDemo: {
      title: "This is the Modal",
      body: <p>How you like it?</p>,
      button: "Pretty good",
    },
    mailToModal: {
      title: "Send Email",
      body: (
        <div>
          <Button
            onClick={function () {
              window.location =
                "mailto:help@checkupsandchoices.com?subject=Subject&body=Message goes here";
            }}
            ripple={true}
            raised={true}
            colored={true}
          >
            <FontAwesome name="envelope" color="white" size={20} />
            Email Us
          </Button>
        </div>
      ),
      button: "Close",
    },
    newMoodTracker: {
      title: "New Mood Tracker",
      body: <MoodTrackerModal />,
    },
    newAgreementTracker: {
      title: "New Agreement Tracker",
      body: <AgreementTrackerModal />,
    },
    editAgreementTracker: {
      title: "Edit Agreement Tracker",
      body: <AgreementTrackerModal />,
    },
    updateMoodTracker: {
      title: "Edit Mood Tracker",
      body: <MoodTrackerModal />,
    },
    newDrinkTracker: {
      title: "New Drink Tracker",
      body: <NewDrinkTracker />,
    },
    editDrinkTracker: {
      title: "Edit Drink Tracker",
      body: <NewDrinkTracker />,
    },
    newSolutionTracker: {
      title: "New Solution Tracker",
      body: <NewSolutionTracker />,
    },
    editSolutionTracker: {
      title: "Edit Solution Tracker",
      body: <NewSolutionTracker />,
    },
    solutionTrackerAddOutcome: {
      title: "Add Outcome Solution Tracker",
      body: <SolutionTrackerOutcomeModal />,
    },
    newReminder: {
      title: "Add Reminder",
      body: <NewReminder />,
    },
    editReminder: {
      title: "Edit Reminder",
      body: <NewReminder />,
    },
    newUrgeTracker: {
      title: "New Urge Tracker",
      body: <UrgeTrackerModal />,
    },
    updateUrgeTracker: {
      title: "Edit Urge Tracker",
      body: <UrgeTrackerModal />,
    },
    newGoalTracker: {
      title: "Set A Goal",
      body: <GoalTrackerModal />,
    },
    editGoalTracker: {
      title: "Set A Goal",
      body: <GoalTrackerModal />,
    },
    helpFeedback: {
      title: "Help / Feedback",
      body: <HelpFeedback />,
    },
    cancelSubscriptionSurvey: {
      title: "Cancel Subscription",
      body: <CancelSubscriptionModal />,
    },
    closeAccountSurvey: {
      title: "Close Account",
      body: <CloseAccountModal />,
    },
    wearableLoginCode: {
      title: "Wearable App Login Code",
      body: <WearableLoginModal />,
    },
    drinkCalculator: {
      title: "Drink Calculator",
      body: <DrinkCalculatorModal />,
    },
    confirmPlanChange: {
      title: "Change Your Plan",
      body: <ChangePlanModal />,
    },
    wrongSubdomain: {
      title:
        "It looks like you first registered using a different CheckUp & Choices site in our system",
      body: <WrongSubdomain />,
    },
    importantMessage: {
      title: "Important Message",
      body: <ImportantMessage />,
      button: "Close",
      onHide: "dismissMessage",
    },
    toolDescriptions: {
      title: "Tool Descriptions",
      body: <ToolDescriptions />,
    },
    dayPart: {
      title: "Time of Day",
      body: <DayPartModal />,
    },
    typeAmount: {
      title: "Type and Amount",
      body: <TypeAmountModal />,
    },
    privacy: {
      title: "CHECKUP AND CHOICES LLC PRIVACY POLICY",
      body: <PrivacyPage hideHeader={true} />,
    },
    terms: {
      title: "CHECKUP AND CHOICES LLC TERMS OF SERVICE",
      body: <TermsPage hideHeader={true} />,
    },
  },

  bodyHtml: function (subject) {
    //attaching the modal as props to the element with React clone
    return !subject ? (
      <p>Unrecognized appState.</p>
    ) : this.snippets[subject] ? (
      React.cloneElement(this.snippets[subject].body, this.props.modal)
    ) : (
      <p>
        Unrecognized appState <strong>{subject}</strong>
      </p>
    );
  },

  titleText: function (subject) {
    return !subject
      ? ""
      : this.snippets[subject]
        ? this.snippets[subject].title
        : subject;
  },

  buttonText: function (subject) {
    return !subject
      ? "OK"
      : this.snippets[subject]
        ? this.snippets[subject].button
        : "OK";
  },

  renderFooter: function (subject) {
    return !subject
      ? false
      : this.snippets[subject]
        ? this.snippets[subject].button
        : false;
  },

  title() {
    if (this.props.modal.modalNotifications === "genericModal")
      return <FormattedMessage {...this.props.modal.modalCustomTitle} />;
    return this.titleText(this.props.modal.modalNotifications);
  },

  body() {
    if (this.props.modal.modalNotifications === "genericModal") {
      return (
        <>
          {this.props.modal.modalCustomContent ? (
            this.props.modal.modalCustomContent.components.map(
              (component, index) => {
                return (
                  <Element
                    key={"element_" + index}
                    component={component}
                    index={index}
                    norms={this.props.modal.modalNorms}
                    responses={this.props.modal.modalResponses}
                    dispatch={this.props.dispatch}
                  />
                );
              },
            )
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: this.props.modal.modalHTML }}
            ></div>
          )}
          {this.props.modal.modalComponent}
          {this.props.modal.closeButton && (
            <div className="modal-footer">
              <Button
                ripple={true}
                raised={true}
                colored={true}
                onClick={this.closeMe}
              >
                <FormattedMessage id="close-button" defaultMessage="Close" />
              </Button>
            </div>
          )}
        </>
      );
    }
    return this.bodyHtml(this.props.modal.modalNotifications);
  },

  render: function () {
    const content = this.props.modal.modalNotifications;
    return (
      <>
        <Modal
          size="lg"
          show={!!this.props.modal.modalContent}
          onHide={this.closeMe}
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: LIGHTER_FOREST_GREEN }}
          >
            <Modal.Title>{this.title()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.body()}</Modal.Body>
          {this.renderFooter(content) && (
            <Modal.Footer>
              <BSButton
                variant="green"
                onClick={
                  _.get(this.snippets, content + ".onHide")
                    ? this[this.snippets[content].onHide]
                    : this.closeMe
                }
              >
                <FontAwesome name="star-o" color="white" size={20} />
                <div className="ms-2">{this.buttonText(content)}</div>
              </BSButton>
            </Modal.Footer>
          )}
        </Modal>
        <NotificationSystem ref="notificationSystem" />
      </>
    );
  },
});

export default connect((state) => ({ modal: state.modal, auth: state.auth }))(
  AppModal,
);

/* ======== NOTIFICATION SYSTEM -- DOCUMENTATION:  https://github.com/igorprado/react-notification-system ===============

 QUESTION: Why is <NotificationSystem> wrapped inside of the <AppModal> component?
 Why don't we use the separate file in /component/modal/notify.js ...?
 ANSWER: Fluxxor seems to have a deeply screwy fault with too many simultaneous StoreWatchMixins
 declared at/near the top level of the app.  I think.
 So we double up on the top-level store watch of "AppStateStore"

 title      string  null    Title of the notification
 message    string  null    Message of the notification
 level      string  null    Level of the notification. Available: success, error, warning and info
 position   string  tr      Position of the notification. Available: tr (top right), tl (top left), tc (top center), br (bottom right), bl (bottom left), bc (bottom center)
 autoDismiss integer 5      Delay in seconds for the notification go away. Set this to 0 to not auto-dismiss the notification
 dismissible bool   true    Set if notification is dismissible by the user. See more
 action         object  null    Add a button with label and callback function. See more
 onRemove   fnction null    A callback function that will be called when the notification is about to be removed. The first argument is the original notification e.g. function (notification) { console.log(notification.title + 'was removed'); }
 uid        integer/string  null    Overrides the internal uid. Useful if you are managing your notifications id. Notifications with same uid won't be displayed.


 ----- ACTION BUTTON -------

 notification = {
 [...],
 action: {
 label: 'Button name',
 callback: function() {
 console.log('Notification button clicked!');
 }
 }
 }

 =========================== END NOTES -- NOTIFICATION SYSTEM =========================== */
