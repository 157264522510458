"use strict";

import { addFeedback as addFeedbackAPI } from "api/feedback";
import Actions from "actions";
import notify from "actions/notify";

import {
  REQUEST_ADD_FEEDBACK,
  REQUEST_ADD_FEEDBACK_SUCCESS,
  REQUEST_ADD_FEEDBACK_FAILURE,
} from "actions/types";

function requestAddFeedback() {
  return {
    type: REQUEST_ADD_FEEDBACK,
  };
}

function requestAddFeedbackSuccess() {
  return {
    type: REQUEST_ADD_FEEDBACK_SUCCESS,
  };
}

function requestAddFeedbackFailure(error) {
  return {
    type: REQUEST_ADD_FEEDBACK_FAILURE,
    payload: error,
    error: true,
  };
}

export default function addHelpFeedback(userId, token, feedback) {
  return (dispatch) => {
    dispatch(requestAddFeedback());
    return addFeedbackAPI(userId, token, feedback)
      .then(() => {
        dispatch(
          notify({
            title: "Feedback sent",
            message: "Thank you for your feedback.",
            level: "success",
            autoDismiss: 4,
          }),
        );
        return dispatch(requestAddFeedbackSuccess());
      })
      .catch((error) => {
        dispatch(
          notify({
            title: "Network Error",
            message:
              "Unable to send Feedback. Please try again in a few minutes.",
            level: "error",
            autoDismiss: 4,
          }),
        );

        return dispatch(requestAddFeedbackFailure(error));
      });
  };
}
