"use strict";

import _ from "lodash";
import React from "react";
import { getAge } from "utils/date";
import BSButton from "../blocks/BSButton.component.web";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { BLUE } from "../../stylesheets/colors";

export const ProfileDisplay = React.createClass({
  propTypes: {
    auth: React.PropTypes.object.isRequired,
    style: React.PropTypes.object,
  },

  onKeyDown(event) {
    if (event.which == 32) {
      this.props.onClickNav(event);
    }
  },

  render() {
    const locus = this,
      state = locus.state,
      props = locus.props,
      { auth, onClickNav, selfContained, style } = props;
    const name = _.get(auth, "user.name");
    const age = getAge(_.get(auth, "user.dt_dob"));

    const profileDisplay = (
      <div className="flex-grow-1 d-flex align-items-center justify-content-between">
        <strong>Profile</strong>
        <div className="d-flex align-items-center">
          <span>{name + ", Age " + age}</span>
          <BSButton
            variant="flat-blue"
            tooltip="Edit"
            linkTo="/account/profile"
            size="sm"
            className="ms-2"
          >
            <MaterialCommunityIcons
              name="account-edit"
              size={24}
              color={BLUE}
            />
          </BSButton>
        </div>
      </div>
    );

    if (selfContained) {
      return (
        <div className="offset-shadow-box" style={style}>
          {profileDisplay}
        </div>
      );
    }
    return profileDisplay;
  },
});
