"use strict";

import _ from "lodash";
import { handleResponse } from "utils/api";

export const getTermsById = (termsId) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/terms/" + termsId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};
