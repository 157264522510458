"use strict";

import { handleResponse } from "utils/api";

export const getResponses = (token, userId) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/responses?user_id=" + userId, {
    method: "GET",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};

export const addResponse = (token, userId, toolId) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT +
      "/responses?user_id=" +
      userId +
      "&tool_id=" +
      toolId +
      "&group_code=" +
      CUAC_SETTINGS.GROUP.code,
    {
      method: "POST",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        tool_id: toolId,
        progress: {},
        responses: {},
      }),
    },
  ).then(handleResponse);
};

export const updateResponse = (token, responseId, userId, toolId, updates) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT +
      "/responses/" +
      responseId +
      "?user_id=" +
      userId +
      "&tool_id=" +
      toolId,
    {
      method: "PATCH",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updates),
    },
  ).then(handleResponse);
};

export const getResponseById = (token, userId, responseId) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT + "/responses/" + responseId + "?user_id=" + userId,
    {
      method: "GET",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
    },
  ).then(handleResponse);
};
