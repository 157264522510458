import React, { useCallback, useRef, useState, ChangeEvent } from "react";
import { AntDesign } from "@expo/vector-icons";
import { Field } from "redux-form";
import { StyleProp } from "react-native";
import { LIGHTER_FOREST_GREEN } from "../../stylesheets/colors";

type Props = {
  name?: string;
  placeholder?: string;
  style?: StyleProp<any>;
  inputGroupClassName?: string;
  onChange?: (val: number) => void;
  value?: number;
  step?: number | string;
  min?: number | string;
  max?: number | string;
  labelClassName?: string;
  meta?: {
    error?: string;
  };
  input?: {
    value?: string;
    onChange: (val: number) => void;
  };
  label?: string | React.ReactNode;
  currency?: boolean;
};

function _convertToNumber<DefaultValue = undefined>(
  value: number | string | undefined,
  defaultValue: DefaultValue
) {
  if (!value) return defaultValue;
  return typeof value === "string" ? parseFloat(value) : value;
}

export const NumberInput = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [wasVisited, setWasVisited] = useState(false);

  const value = _convertToNumber(props.value ?? props.input?.value, 0);

  const onChange = (val: number = 0) => {
    const parsedMin = _convertToNumber(props?.min, 0);
    const parsedMax = _convertToNumber(props?.max, undefined);

    if (val < parsedMin || (!!parsedMax && val > parsedMax)) return;
    if (props.onChange) props.onChange(val);
    else if (props.input) props.input.onChange(val);
  };

  const change = (delta: number) => {
    const parsedVal = _convertToNumber(value, 0);
    const newVal = Math.round((parsedVal + delta) * 100) / 100;
    setWasVisited(true);

    onChange(newVal);
  };

  const step = () => {
    if (typeof props.step === "number" || typeof props.step === "string")
      return parseFloat(props.step?.toString());
    return 1;
  };

  const _handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(_convertToNumber(e.target.value, 0));
    },
    [onChange]
  );

  return (
    <div
      className={`input-group ${props.inputGroupClassName || ""} ${wasVisited && props.meta?.error ? " has-validation " : ""}`}
    >
      {props.label && (
        <div
          className={`d-flex flex-column align-items-start justify-content-center ${props.labelClassName || "input-group-text"}`}
          onClick={() => inputRef?.current?.focus()}
        >
          {props.label}
          {wasVisited && props.meta?.error && (
            <small className="text-danger">{props.meta.error}</small>
          )}
        </div>
      )}
      <div className="d-flex align-items-center">
        <button
          className="btn"
          type="button"
          id="button-addon1"
          onClick={() => change(step() * -1)}
        >
          <AntDesign name="minus" size={24} color="#0E323E" />
        </button>
        {props.currency && (
          <span
            style={{ backgroundColor: LIGHTER_FOREST_GREEN, border: "none" }}
            className="input-group-text"
            id="dollar-sign-addon"
          >
            $
          </span>
        )}

        <input
          ref={inputRef}
          type="number"
          className="form-control flex-grow-0"
          placeholder={props.placeholder}
          value={value}
          onChange={_handleChange}
          style={props.style || {}}
        />

        <button
          className="btn"
          type="button"
          id="button-addon2"
          onClick={() => change(step())}
        >
          <AntDesign name="plus" size={24} color="#0E323E" />
        </button>
      </div>
    </div>
  );
};

const NumericInput = (props: Props) => {
  return <Field component={NumberInput} {...props} />;
};

export default NumericInput;
