import React from "react";
import { Form } from "react-bootstrap";
import { StyleProp } from "react-native";

type Props = {
  label: string;
  placeholder?: string;
  meta?: {
    error?: any;
    touched: boolean;
    dirty: boolean;
  };
  type?: string;
  input?: {
    value?: string;
    onChange: (val: string) => void;
  };
  value?: string;
  initialValue?: string;
  onChange: (data: any) => void;
  as?: string;
  style?: StyleProp<any>;
  error?: string;
};

const BSInput = (props: Props) => {
  const invalid = () => {
    if ("meta" in props) {
      const { error, dirty, touched } = props.meta || {};
      return error && error.length && (dirty || touched);
    }
    return props.error && props.error.length;
  };

  const error = () => {
    const error = props.error || props.meta?.error;
    if ("meta" in props) {
      const { dirty, touched } = props.meta || {};
      if (!dirty && !touched) return null;
    }
    if (!error || !error.length) return null;
    return (
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    );
  };

  const val = () => {
    const { value, initialValue, input } = props;
    return value || initialValue || input?.value;
  };

  return (
    <Form.Group>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <Form.Control
        {...(props.input || {})}
        as={props.as}
        type={props.type || "text"}
        placeholder={props.placeholder}
        value={val()}
        onChange={"onChange" in props ? props.onChange : props.input?.onChange}
        isInvalid={invalid()}
        style={props.style || {}}
      />
      {error()}
    </Form.Group>
  );
};

export default BSInput;
