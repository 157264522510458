import React, { useEffect, useState } from "react";
import Card from "./Card.component";
import update from "immutability-helper";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

function getHash(input: string) {
  let hash = 0,
    len = input.length;
  for (let i = 0; i < len; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i);
    hash |= 0; // to 32bit integer
  }
  return hash;
}

export interface Item {
  id: number;
  text: string;
}
export interface ContainerState {
  cards: Item[];
}

type Props = {
  title?: any;
  data_key: string;
  responses: { [key: string]: any };
  updateResponse: (data: { [key: string]: string[] }) => void;
};

type Card = {
  id: number;
  text: string;
};

const SortList = (props: Props) => {
  const [cards, setCards] = useState<Card[]>([]);

  useEffect(() => {
    setCards(
      _.get(props.responses, props.data_key, []).map((response: string) => ({
        id: getHash(response),
        text: response,
      })),
    );
  }, [props.responses]);

  useEffect(() => {
    if (!cards || !cards.length) return;
    const currResponses = _.get(props.responses, props.data_key, []),
      newResponses = (cards || []).map(({ text }) => text);
    if (_.isEqual(currResponses, newResponses)) return;
    props.updateResponse({ [props.data_key]: newResponses });
  }, [cards]);

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const dragCard = cards[dragIndex];
    setCards(
      update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      }),
    );
  };

  return (
    <div className="card" style={{ border: "none" }}>
      <div className="card-body">
        <h3 className="card-title">
          <FormattedMessage {...props.title} />
        </h3>
        {cards.map((card, i) => (
          <Card
            key={card.id}
            index={i}
            id={card.id}
            text={card.text}
            moveCard={moveCard}
          />
        ))}
      </div>
    </div>
  );
};

export default SortList;
