"use strict";

import React, { findDOMNode } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";

import TimePicker from "react-time-picker";
import Moment from "moment";
Moment.locale("en");

import { eventPreventDefault as prevent } from "utils/tools";
import InfoBug from "components/popover/infobug";
import ButtonSpinner from "components/ux/button-spinner";
import TextFieldReduxForm from "components/ux/text-field-redux-form";
import { faStar as faStarO } from "@fortawesome/free-solid-svg-icons";
import { validator } from "utils/tools";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

const messages = defineMessages({
  solution_tracker_title_label: {
    id: "solution_tracker_title_label",
    description: "Title",
    defaultMessage: "Title",
  },
  solution_tracker_problem_label: {
    id: "solution_tracker_problem_label",
    description: "What is the problem?",
    defaultMessage: "What is the problem?",
  },
  solution_tracker_solutions_label: {
    id: "solution_tracker_solutions_label",
    description: "What are the solutions you can think of?",
    defaultMessage: "What are the solutions you can think of?",
  },
  solution_tracker_undesirable_solutions_label: {
    id: "solution_tracker_undesirable_solutions_label",
    description: "Which solutions are undesirable?",
    defaultMessage: "Which solutions are undesirable?",
  },
  solution_tracker_solution_to_try_label: {
    id: "solution_tracker_solution_to_try_label",
    description: "Which solution will you try?",
    defaultMessage: "Which solution will you try?",
  },
  solution_tracker_obstacles_label: {
    id: "solution_tracker_obstacles_label",
    description: "What could get in your way?",
    defaultMessage: "What could get in your way?",
  },
  solution_tracker_obstacle_solutions_label: {
    id: "solution_tracker_obstacle_solutions_label",
    description: "How will you address each of these obstacles?",
    defaultMessage: "How will you address each of these obstacles?",
  },
  solution_tracker_when_label: {
    id: "solution_tracker_when_label",
    description: "When will you try out the solution?",
    defaultMessage: "When will you try out the solution?",
  },
  solution_tracker_results_label: {
    id: "solution_tracker_results_label",
    description: "What happened when you tried the solution?",
    defaultMessage: "What happened when you tried the solution?",
  },
  solution_tracker_next_steps_label: {
    id: "solution_tracker_next_steps_label",
    description:
      "Do you need to refine the solution or try another. If so, what changes will you make or which solution will you try?",
    defaultMessage:
      "Do you need to refine the solution or try another. If so, what changes will you make or which solution will you try?",
  },
});

const SolutionTracker = React.createClass({
  propTypes: {
    fields: React.PropTypes.object.isRequired,
    handleSubmit: React.PropTypes.func.isRequired,
  },

  getInitialState() {
    return {};
  },

  onChange(fieldName, event) {
    this.props.change(fieldName, event.target.value);
  },

  onSave(event) {
    prevent(event);
    setTimeout(this.props.handleSubmit, 50);
  },

  onSave2(event) {
    prevent(event);
    const that = this;
    setTimeout(this.props.handleSubmit, 50);
    setTimeout(function () {
      if (document.getElementsByClassName("is-invalid").length == 0) {
        that.props.onClick();
      }
    }, 1000);
  },

  render() {
    const {
      handleSubmit,
      isNew,
      intl: { formatMessage },
    } = this.props;
    return (
      <div className="container">
        <form
          id="solution_tracker"
          name="solution_tracker"
          className="stack-input"
          style={{ margin: "0 auto 40px auto" /*, maxWidth: '290px'*/ }}
        >
          <div>
            <TextFieldReduxForm
              name="title"
              floatingLabel={true}
              labelText={formatMessage(messages.solution_tracker_title_label)}
            />
            <div className="row">&nbsp;</div>
            <TextFieldReduxForm
              name="problem"
              textArea={true}
              floatingLabel={true}
              labelText={formatMessage(messages.solution_tracker_problem_label)}
            />
            <InfoBug>
              <FormattedMessage
                id="solution_tracker_problem_infobug"
                defaultMessage="Example: I want to find a job that has flexible work hours."
              />
            </InfoBug>
            <div className="row">&nbsp;</div>
            <TextFieldReduxForm
              name="solutions"
              textArea={true}
              floatingLabel={true}
              labelText={formatMessage(
                messages.solution_tracker_solutions_label,
              )}
            />
            <InfoBug>
              <FormattedMessage
                id="solution_tracker_solutions_infobug"
                defaultMessage="Examples 1. Ask my current boss about this. 2. Ask others who work in my field about openings that have flexible work hours. 3. Ask friends and family about possible work positions. 4. Check online sites like Craigslist or Monster.com."
              />
            </InfoBug>
            <div className="row">&nbsp;</div>
            <TextFieldReduxForm
              name="undesirable_solutions"
              textArea={true}
              floatingLabel={true}
              labelText={formatMessage(
                messages.solution_tracker_undesirable_solutions_label,
              )}
            />
            <InfoBug>
              <FormattedMessage
                id="solution_tracker_undesirable_solutions_infobug"
                defaultMessage="Example: 1. If my boss can't accommodate me he may think I'll be looking for work elsewhere and let me go."
              />
            </InfoBug>
            <div className="row">&nbsp;</div>
            <TextFieldReduxForm
              name="solution_to_try"
              textArea={true}
              floatingLabel={true}
              labelText={formatMessage(
                messages.solution_tracker_solution_to_try_label,
              )}
            />
            <InfoBug>
              <FormattedMessage
                id="solution_tracker_solution_to_try_infobug"
                defaultMessage="Example: #2."
              />
            </InfoBug>
            <div className="row">&nbsp;</div>
            <TextFieldReduxForm
              name="obstacles"
              textArea={true}
              floatingLabel={true}
              labelText={formatMessage(
                messages.solution_tracker_obstacles_label,
              )}
            />
            <InfoBug>
              <FormattedMessage
                id="solution_tracker_obstacles_infobug"
                defaultMessage="Example: My fear of changing."
              />
            </InfoBug>
            <div className="row">&nbsp;</div>
            <TextFieldReduxForm
              name="obstacle_solutions"
              textArea={true}
              floatingLabel={true}
              labelText={formatMessage(
                messages.solution_tracker_obstacle_solutions_label,
              )}
            />
            <InfoBug>
              <FormattedMessage
                id="solution_tracker_obstacle_solutions_infobug"
                defaultMessage="Example: I'll do a reality check with friends and family to see if my fears are valid or over-blown."
              />
            </InfoBug>
            <div className="row">&nbsp;</div>
            <TextFieldReduxForm
              name="when"
              textArea={true}
              floatingLabel={true}
              labelText={formatMessage(messages.solution_tracker_when_label)}
            />
            <InfoBug>
              <FormattedMessage
                id="solution_tracker_when_infobug"
                defaultMessage="Example: After I get a current work project done in two weeks."
              />
            </InfoBug>
            {!isNew ? (
              <div>
                <div className="row">&nbsp;</div>
                <TextFieldReduxForm
                  name="results"
                  textArea={true}
                  floatingLabel={true}
                  labelText={formatMessage(
                    messages.solution_tracker_results_label,
                  )}
                />
                <div className="row">&nbsp;</div>
                <TextFieldReduxForm
                  name="next_steps"
                  textArea={true}
                  floatingLabel={true}
                  labelText={formatMessage(
                    messages.solution_tracker_next_steps_label,
                  )}
                />
              </div>
            ) : null}
            <div className="row">&nbsp;</div>
            <div className="row">&nbsp;</div>
            <ButtonSpinner
              spinner={this.props.isFetching}
              onClick={this.onSave}
              ripple={true}
              raised={true}
              colored={true}
            >
              <FontAwesomeIcon icon={faStarO} />
              <FormattedMessage
                id="solution_tracker_button_save_exit"
                description="Submit the Solution tracker form to save a Solution"
                defaultMessage="Save and Exit"
              />
            </ButtonSpinner>
            <ButtonSpinner
              spinner={this.props.isFetching}
              onClick={this.onSave2}
              ripple={true}
              raised={true}
              colored={true}
            >
              <FontAwesomeIcon icon={faStarO} />
              <FormattedMessage
                id="solution_tracker_button_save_add"
                description="Submit the Solution tracker form to save a Solution"
                defaultMessage="Save and add New"
              />
            </ButtonSpinner>
          </div>
          <div className="clearfix" />
        </form>
      </div>
    );
  },
});

const formFields = [
  "title",
  "problem",
  "solutions",
  "undesirable_solutions",
  "solution_to_try",
  "obstacles",
  "obstacle_solutions",
  "when",
  "results",
  "next_steps",
];
let form = reduxForm({
  form: "solutionTracker",
  fields: formFields,
  validate: validator({
    title: { required: "Required" },
    problem: { required: "Required" },
    solutions: { required: "Required" },
    undesirable_solutions: { required: "Required" },
    solution_to_try: { required: "Required" },
    obstacles: { required: "Required" },
    obstacle_solutions: { required: "Required" },
    when: { required: "Required" },
  }),
})(SolutionTracker);
const selector = formValueSelector("solutionTracker"); // <-- same as form name
form = connect((state) => {
  return formFields.reduce(
    (dict, fieldName) =>
      Object.assign({ [fieldName]: selector(state, fieldName) }, dict),
    {},
  );
})(form);

export default injectIntl(form);
