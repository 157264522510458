"use strict";

import React from "react";

export const CalorieFeedback = React.createClass({
  componentDidMount() {
    const { responses, data_key } = this.props;
    const numberOfLattes = _.get(responses, data_key, 0);
    const latteContainer = document.getElementById("latte-container");
    while (latteContainer.hasChildNodes()) {
      latteContainer.removeChild(latteContainer.lastChild);
    }
    for (var i = 1; i <= numberOfLattes; i++) {
      var div = document.createElement("div");
      div.className = "latte-glass";
      latteContainer.appendChild(div);
    }
    let remainder = numberOfLattes % 1;
    var div = document.createElement("div");
    div.className = "latte-glass";
    div.style.height = 106 * remainder + "px";
    div.style.marginTop = 106 - 106 * remainder + 2 + "px";
    latteContainer.appendChild(div);
  },

  render() {
    return <div id="latte-container"></div>;
  },
});
