"use strict";

import _ from "lodash";
import React from "react";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";

import { EmptyForm } from "components/forms/empty-form";
import { FormListForm } from "components/forms/form-list-form";

import { convertToKeyString } from "utils/string";

export const FormList = React.createClass({
  form: EmptyForm,

  propTypes: {
    data_key: React.PropTypes.string.isRequired,
    form_name: React.PropTypes.string.isRequired,
    fields: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    formulas: React.PropTypes.arrayOf(React.PropTypes.object),
    responses: React.PropTypes.object.isRequired,
    updateResponse: React.PropTypes.func.isRequired,
  },

  UNSAFE_componentWillMount() {
    const { form_name: formName, fields } = this.props;
    const mergedFields = this.buildFields(fields);
    this.form = injectIntl(
      reduxForm({
        form: formName,
        fields: _.pluck(mergedFields, "id"),
      })(FormListForm),
    );
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { form_name: formName } = this.props;
    const nextFormName = _.get(nextProps, "form_name");
    if (formName !== nextFormName) {
      const mergedFields = this.buildFields(nextProps.fields);
      this.form = injectIntl(
        reduxForm({
          form: nextFormName,
          fields: _.pluck(mergedFields, "id"),
        })(FormListForm),
      );
    }
  },

  buildFields(fields) {
    const { data_key, responses } = this.props;
    const values = _.get(responses, data_key, []);
    return _.flatten(
      values.map((value, index) => {
        const rowFields = [];
        for (const field of fields) {
          rowFields.push({
            ...field,
            id: field.id + "_" + convertToKeyString(value),
          });
        }
        return rowFields;
      }),
    );
  },

  render() {
    const { data_key, fields, responses } = this.props;
    const listResponse = _.get(responses, data_key, []);
    const Form = this.form;
    return (
      <Form
        fieldsMetadata={fields}
        listResponse={listResponse}
        updateResponse={this.updateResponse}
        initialValues={responses}
      />
    );
  },

  updateResponse(data) {
    const { formulas, updateResponse } = this.props;
    updateResponse(data, formulas);
  },
});
