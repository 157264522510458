import React from "react";
import { View, StyleSheet } from "react-native";
import { Block } from "./block";

// inspired by https://stackoverflow.com/questions/50835303/2-column-layout-with-flexbox-on-react-native
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start", // if you want to fill rows left to right
  },
});
export const ColumnWrapper = (props) => {
  if (!props.components || props.components.length === 0) return <View />;
  const colWidth = StyleSheet.create({
    col: {
      width: `${Math.floor(100 / props.components.length)}%`,
    },
  });
  return (
    <View style={styles.container}>
      {props.components.map((component, index) => (
        <View style={colWidth.col} key={index}>
          <Block
            key={`view${index}`}
            component={component}
            index={props.index}
            norms={props.norms}
            activeResponse={_.omit(props.activeResponse, [
              "progress",
              "responses",
            ])}
            responses={_.get(props.activeResponse, "responses", {})}
            userProfile={props.userProfile}
            updateResponse={props.updateResponse}
            updateProgressManually={props.updateProgressManually}
            addTrackerEntry={props.addTrackerEntry}
            trackerEntries={props.trackerEntries}
            trackerEntryStats={props.trackerEntryStats}
            tool={props.tool}
            module={props.module}
            section={props.section}
            auth={props.auth}
            dispatch={props.dispatch}
            params={props.params}
            allResponses={props.responses}
            tools={props.tools}
          />
        </View>
      ))}
    </View>
  );
};
