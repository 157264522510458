"use strict";

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Actions from "actions";
import Moment from "moment";
Moment.locale("en");
import CancelSurvey from "components/forms/cancel-survey";

const CancelSubscriptionModal = React.createClass({
  propTypes: {
    auth: React.PropTypes.object.isRequired,
    dispatch: React.PropTypes.func.isRequired,
  },

  getInitialState: function () {
    const { auth } = this.props;
    const user_timezone = _.get(auth, "user.user_timezone");

    return {};
  },

  onCancelSubscriptionFromModal(values, event) {
    event.preventDefault();
    const { auth } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    let jsonValues = {};
    if (typeof values !== "object") {
      jsonValues = {
        cancellation_check: "1",
        cancellation_reason_text: "",
        cancellation_contact_permission: "",
        cancellation_reason: [],
      };
    } else {
      let cancellation_reason = [];
      if (_.get(values, "cancellation_reason")) {
        cancellation_reason = values.cancellation_reason;
      }
      let cancellation_contact_permission = "";
      if (_.get(values, "cancellation_contact_permission")) {
        cancellation_contact_permission =
          values.cancellation_contact_permission;
      }
      let cancellation_reason_text = "";
      if (_.get(values, "cancellation_reason_text")) {
        cancellation_reason_text = values.cancellation_reason_text;
      }
      jsonValues = {
        cancellation_check: "1",
        cancellation_reason_text: cancellation_reason_text,
        cancellation_contact_permission: cancellation_contact_permission,
        cancellation_reason: cancellation_reason,
      };
    }
    this.props
      .dispatch(
        Actions.deleteSubscription(
          token,
          userId,
          this.props.subscriptions.subscriptions.data[0].id,
          jsonValues,
        ),
      )
      .then(() => {
        this.closeModal();
      });
  },

  closeModal() {
    this.props.dispatch(
      Actions.closeModal("appModal", {
        modalNotifications: "",
        modalContent: false,
      }),
    );
  },

  render() {
    return (
      <div className="container">
        <CancelSurvey submitCancelModal={this.onCancelSubscriptionFromModal} />
      </div>
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
  subscriptions: state.subscriptions,
}))(CancelSubscriptionModal);
