import React from "react";
import { browserHistory } from "react-router";
import {
  BLUE,
  DARK_GRAY,
  FOREST_GREEN,
  LIGHT_BLUE,
  LIGHT_FOREST_GREEN,
  LIGHT_GRAY,
  LIGHTER_FOREST_GREEN,
  LIGHTEST_BLUE,
} from "../../stylesheets/colors";
import { Button, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { StyleProp } from "react-native";

type Props = {
  label?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  linkTo?: string;
  variant?: string;
  size?: "sm" | "lg";
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  as?: string;
  tooltip?: string;
  tooltipPlacement?: string;
  style?: StyleProp<any>;
  id?: string;
  type?: string;
};

const BSButton = (props: Props) => {
  const goToLink = (e: MouseEvent) => {
    e.preventDefault();
    if (props.linkTo) browserHistory.push(props.linkTo);
  };

  const attrs = {
    ...(props.onClick ? { onClick: props.onClick } : {}),
    ...(props.linkTo ? { onClick: goToLink } : {}),
    ...(props.size ? { size: props.size } : {}),
    ...("disabled" in props || props.loading
      ? { disabled: props.disabled || props.loading }
      : {}),
    ...(props.as ? { as: props.as } : {}),
  };

  const btn = () => (
    <Button
      type={props.type}
      id={props.id}
      style={{ borderRadius: 12, ...(props.style || {}) }}
      variant={props.variant}
      className={`d-flex align-items-center ${props.className || ""}`}
      {...attrs}
    >
      {props.loading ? (
        <div className="d-flex align-items-center">
          <Spinner animation="border" role="status" size="sm">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className="ps-2 mb-0">Loading...</p>
        </div>
      ) : (
        props.label || props.children
      )}
    </Button>
  );

  const renderTooltip = (p) => <Tooltip {...p}>{props.tooltip}</Tooltip>;

  return (
    <>
      <style>
        {`
                    .btn-blue {
                        background-color: ${BLUE};
                        color: white;
                    }
                    .btn-blue:hover {
                        color: white;
                        background-color: ${LIGHT_BLUE};
                    }
                    .btn-green {
                        background-color: ${FOREST_GREEN};
                        color: white;
                    }
                    .btn-green:hover {
                        color: white;
                        background-color: ${LIGHT_FOREST_GREEN};
                    }
                    .btn-link-green {
                        color: ${FOREST_GREEN};
                    }
                    .btn-link-green:hover {
                        color: ${FOREST_GREEN};
                    }
                    .btn-flat-blue {
                        color: ${BLUE};
                        background-color: white;
                        border-color: white;
                    }
                    .btn-flat-gray {
                        color: ${BLUE};
                        background-color: ${LIGHT_GRAY};
                        border-color: ${LIGHT_GRAY};
                    }
                    .btn-flat-gray:hover {
                        color: ${BLUE};
                        background-color: ${LIGHTEST_BLUE};
                    }
                    .btn-link-green {
                        color: ${FOREST_GREEN};
                        background-color: transparent;
                        borderColor: transparent;
                    }
                    .btn-outline-green {
                        color: ${FOREST_GREEN};
                        border: 1px solid ${FOREST_GREEN};
                        background-color: transparent;
                    }
                    .btn-outline-green:hover {
                        color: white;
                        background-color: ${LIGHT_FOREST_GREEN};
                    }
                    .btn-outline-blue {
                        color: ${BLUE};
                        border: 1px solid ${BLUE};
                        background-color: transparent;
                    }
                    .btn-outline-blue:hover {
                        color: ${BLUE};
                        background-color: ${LIGHTEST_BLUE};
                    }
                    .btn-link-gray {
                        color: ${DARK_GRAY};
                        background-color: transparent;
                    }
                    `}
      </style>
      {props.tooltip ? (
        <OverlayTrigger
          placement={props.tooltipPlacement || "top"}
          overlay={renderTooltip}
        >
          {btn()}
        </OverlayTrigger>
      ) : (
        btn()
      )}
    </>
  );
};

export default BSButton;
