"use strict";

import Cookies from "cookies-js";
import { getSelf } from "api/users";
const dataLayer = [];
import {
  REQUEST_LOG_IN_USER,
  REQUEST_LOG_IN_USER_SUCCESS,
  REQUEST_LOG_IN_USER_FAILURE,
} from "actions/types";

function requestLogInUser() {
  return {
    type: REQUEST_LOG_IN_USER,
  };
}

function requestLogInUserSuccess(json) {
  return {
    type: REQUEST_LOG_IN_USER_SUCCESS,
    payload: {
      user: json,
    },
  };
}

function requestLogInUserFailure(error) {
  return {
    type: REQUEST_LOG_IN_USER_FAILURE,
    payload: error,
    error: true,
  };
}

export default function logInUser(email, password) {
  return (dispatch) => {
    dispatch(requestLogInUser());
    return getSelf(email, password)
      .then((json) => {
        if (json.two_factor_auth === true)
          return dispatch(requestLogInUserSuccess(json));
        Cookies.set("userData", json._id + ":" + json.token);
        window.dataLayer.push({
          event: "login",
          userId: json._id,
          groupCode: CUAC_SETTINGS.GROUP.code,
        });
        return dispatch(requestLogInUserSuccess(json));
      })
      .catch((error) => {
        return dispatch(requestLogInUserFailure(error));
      });
  };
}
