"use strict";

import _ from "lodash";
import { handleResponse } from "utils/api";

export const TrackerTypes = {
  ABC: "abc",
  DRINK: "drink",
  GOAL: "goal",
  HAPPINESS: "happiness",
  LAPSE: "lapse",
  MOOD: "mood",
  PLAN: "plan",
  SOLUTION: "solution",
  URGE: "urge",
  VALUE: "value",
  AGREEMENT: "agreement",
};

export const getTrackerEntries = (token, userId, trackerTypeName) => {
  const trackerTypeQueryString =
    typeof trackerTypeName !== "undefined" ? "&type=" + trackerTypeName : "";
  return fetch(
    CUAC_SETTINGS.API_ROOT +
      "/tracker-entries?user_id=" +
      userId +
      trackerTypeQueryString,
    {
      method: "GET",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
    },
  ).then(handleResponse);
};

export const addTrackerEntry = (token, userId, data, trackerTypeName, tag) => {
  const tagObject = typeof tag !== "undefined" ? { tag } : {};
  const payload = {
    user_id: userId,
    tracker_type_name: trackerTypeName,
    ...tagObject,
    data: {
      ...data,
    },
  };
  return fetch(CUAC_SETTINGS.API_ROOT + "/tracker-entries?user_id=" + userId, {
    method: "POST",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then(handleResponse);
};

export const updateTrackerEntry = (token, userId, trackerEntryId, updates) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT +
      "/tracker-entries/" +
      trackerEntryId +
      "?user_id=" +
      userId,
    {
      method: "PATCH",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: updates,
      }),
    },
  ).then(handleResponse);
};

export const deleteTrackerEntry = (token, trackerEntryId, userId) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT +
      "/tracker-entries/" +
      trackerEntryId +
      "?user_id=" +
      userId,
    {
      method: "DELETE",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
    },
  ).then(handleResponse);
};
