/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import { getUserEmailEntry as getUserEmailEntryAPI } from "api/user-email-entry";

import {
  REQUEST_GET_USER_EMAIL_ENTRY,
  GET_USER_EMAIL_ENTRY_SUCCESS,
  GET_USER_EMAIL_ENTRY_FAILURE,
} from "actions/types";

function requestGetUserEmailEntry() {
  return {
    type: REQUEST_GET_USER_EMAIL_ENTRY,
  };
}

function requestGetUserEmailEntrySuccess(json) {
  return {
    type: GET_USER_EMAIL_ENTRY_SUCCESS,
    payload: {
      response: json,
    },
  };
}

function requestGetUserEmailEntryFailure(error) {
  return {
    type: GET_USER_EMAIL_ENTRY_FAILURE,
    payload: error,
    error: true,
  };
}

export default function getUserEmailEntry(token, userId, toolId) {
  return (dispatch) => {
    dispatch(requestGetUserEmailEntry());
    return getUserEmailEntryAPI(token, userId, toolId)
      .then((json) => {
        return dispatch(requestGetUserEmailEntrySuccess(json));
      })
      .catch((error) => {
        return dispatch(requestGetUserEmailEntryFailure(error));
      });
  };
}
