"use strict";

import _ from "lodash";
import { FormattedMessage } from "react-intl";

import React from "react";

export const FormattedScoreMessage = React.createClass({
  propTypes: {
    defaultMessage: React.PropTypes.string,
  },

  render() {
    const blacklistedKeys = ["gender"];
    let newValues = {};
    let defaultMessage = this.props.defaultMessage;

    if (this.props.values) {
      let that = this;
      // get the value
      let messageKey = false;
      if (
        this.props.defaultMessage.indexOf("{") > -1 &&
        this.props.defaultMessage.indexOf("}") > -1
      ) {
        messageKey = this.props.defaultMessage.split("{")[1].split("}")[0];
        if (messageKey.indexOf(", number") > -1) {
          messageKey = messageKey.split(", number")[0];
        }
      }
      // check if it is in the values and if not, set it
      if (messageKey) {
        if (!this.props.values.hasOwnProperty(messageKey)) {
          newValues[messageKey] = <span className="score">incomplete</span>;
        }
      }

      _.forOwn(this.props.values, (val, key) => {
        if (
          blacklistedKeys.indexOf(key) < 0 &&
          that.props.defaultMessage.indexOf("number}") == -1 &&
          that.props.defaultMessage.indexOf("plural") == -1
        ) {
          if (this.props.defaultMessage.indexOf("${") > -1) {
            defaultMessage = defaultMessage.replace("$", "");
            newValues[key] = <span className="score">${val}</span>;
          } else {
            newValues[key] = <span className="score">{val}</span>;
          }
        } else {
          newValues[key] = val;
        }
      });
    }

    const newProps = {
      ...this.props,
      defaultMessage,
    };

    return (
      <FormattedMessage {...newProps} values={newValues}>
        {this.props.children}
      </FormattedMessage>
    );
  },
});
