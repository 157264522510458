"use strict";

import Cookies from "cookies-js";

import { handleResponse } from "utils/api";

export const getGroup = (token, groupId) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/groups/" + groupId, {
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};

export const adminGetGroups = (token) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/groups", {
    method: "GET",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};

export const adminGetGroupList = (token, groupId = undefined) => {
  const p = new URLSearchParams({ ...(groupId ? { group_id: groupId } : {}) });
  return fetch(CUAC_SETTINGS.API_ROOT + "/admin/group_list?" + p.toString(), {
    method: "GET",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};
