"use strict";

import _ from "lodash";

import { deleteTrackerEntry as deleteTrackerEntryAPI } from "api/tracker-entries";
import notify from "actions/notify";
import getTrackerEntryStats from "actions/get-tracker-entry-stats";

import {
  REQUEST_DELETE_TRACKER_ENTRY,
  REQUEST_DELETE_TRACKER_ENTRY_SUCCESS,
  REQUEST_DELETE_TRACKER_ENTRY_FAILURE,
} from "actions/types";

function requestDeleteTrackerEntry(trackerEntryId) {
  return {
    type: REQUEST_DELETE_TRACKER_ENTRY,
    payload: {
      trackerEntryId,
    },
  };
}

function requestDeleteTrackerEntrySuccess(trackerEntryId, trackerEntryName) {
  return {
    type: REQUEST_DELETE_TRACKER_ENTRY_SUCCESS,
    payload: {
      trackerEntryName,
      trackerEntryId,
    },
  };
}

function requestDeleteTrackerEntryFailure(error) {
  return {
    type: REQUEST_DELETE_TRACKER_ENTRY_FAILURE,
    payload: error,
    error: true,
  };
}

export default function deleteTrackerEntry(
  token,
  userId,
  trackerEntryId,
  trackerType,
) {
  return (dispatch) => {
    dispatch(requestDeleteTrackerEntry(trackerEntryId));
    return deleteTrackerEntryAPI(token, trackerEntryId, userId, trackerType)
      .then((json) => {
        dispatch(
          notify({
            title: "Entry Deleted",
            message: "Tracker Entry was removed.",
            level: "success",
            autoDismiss: 4,
          }),
        );
        dispatch(getTrackerEntryStats(token, userId, trackerType, 1));
        return dispatch(
          requestDeleteTrackerEntrySuccess(trackerEntryId, trackerType),
        );
      })
      .catch((error) => {
        return dispatch(requestDeleteTrackerEntryFailure(error));
      });
  };
}
