"use strict";

import _ from "lodash";
import React from "react";

import { FormattedMessage } from "react-intl";

import { Element } from "components/elements/element";

export const MotivationComparison = React.createClass({
  propTypes: {
    responses: React.PropTypes.object.isRequired,
    allResponses: React.PropTypes.object.isRequired,
  },

  render() {
    const { responses, allResponses } = this.props;

    let screenerMotivationScore = "0";

    for (var key in allResponses) {
      if (
        _.get(allResponses[key].response, "tool_id") ==
        CUAC_SETTINGS.GROUP.screener_tool_id
      ) {
        screenerMotivationScore = _.get(
          allResponses[key].response.responses,
          "motivation_questions_score_screener",
          "0",
        );
      }
    }

    const currentMotivationScore = _.get(
      responses,
      "motivation_questions_score",
      "0",
    );

    const text = {
      id: "motivation-comparison-text",
      defaultMessage:
        "Before completing the CheckUp, you ranked your motivation as {screenerMotivationScore}. After completing the CheckUp, you ranked your motivation as {currentMotivationScore}.",
      values: {
        screenerMotivationScore: screenerMotivationScore,
        currentMotivationScore: currentMotivationScore,
      },
    };

    return (
      <div className="informational-text motivation-comparison">
        <div className="paragraph">
          <FormattedMessage {...text} />
        </div>
      </div>
    );
  },
});
