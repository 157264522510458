/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { TrackerTypes } from "api/tracker-entries";
import Paginator from "lib/Paginator";
import momentTZ from "lib/moment-timezone-with-data";
import { itemsPerPage } from "utils/pagination";
import Actions from "actions";
import TrackerNav from "../blocks/TrackerNav.component.web";
import TrackerEntryTable from "../blocks/TrackerEntryTable.component.web";
import {
  Entypo,
  FontAwesome,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import BSButton from "../blocks/BSButton.component.web";
import { FOREST_GREEN, LIGHTER_FOREST_GREEN } from "../../stylesheets/colors";
import { Modal, Dropdown } from "react-bootstrap";
import MoodTracker from "components/forms/mood-tracker";
import Moment from "moment";
import { combineDateAndTime, extractDateAndTime } from "../../utils/timezone";
import { sumValues } from "utils/formulas";
import ConfirmModal from "../blocks/ConfirmModal.component.web";

const COLUMNS = [
  { label: "Date/Time", key: "date" },
  { label: "Positive Score", key: "score_pos" },
  { label: "Negative Score", key: "score_neg" },
  { label: "", key: "actions" },
];

const MoodList = React.createClass({
  getInitialState() {
    return {
      showModal: false,
      showConfirmModal: false,
      entryToEdit: undefined,
      onConfirmDelete: undefined,
      displayedEntries: {
        start: 0,
        end: 5,
      },
    };
  },

  propTypes: {
    tag: React.PropTypes.string,
    trackerEntries: React.PropTypes.object.isRequired,
    showTrackerNav: React.PropTypes.bool,
    showFeedbackLink: React.PropTypes.bool,
  },

  deleteMoodTrackerEntry(trackerEntryId, currentPage, totalEntries) {
    this.props.dispatch(Actions.updateDisplay("appSpinner", "PLEASE_WAIT"));
    const { auth, dispatch } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    dispatch(
      Actions.deleteTrackerEntry(
        token,
        userId,
        trackerEntryId,
        TrackerTypes.MOOD,
      ),
    ).then(() => {
      this.updateCurrentPage(currentPage, totalEntries);
      dispatch(Actions.updateDisplay("appSpinner", "STOP"));
      this.setState({ showConfirmModal: false, onConfirmDelete: undefined });
    });
  },

  updateCurrentPage(currentPage, totalEntries) {
    if (currentPage.start >= itemsPerPage && totalEntries - 1 === 0) {
      this.setState({
        displayedEntries: {
          start: this.state.displayedEntries.start - itemsPerPage,
          end: this.state.displayedEntries.end - itemsPerPage,
        },
      });
    }
  },

  onChangePage(page) {
    var end = page * itemsPerPage;
    var start = end - itemsPerPage;
    this.setState({
      displayedEntries: {
        start: start,
        end: end,
      },
      openEntries: [],
    });
  },

  pagination() {
    const { tag, trackerEntries } = this.props;
    const fullOrder = _.get(trackerEntries, TrackerTypes.MOOD + "Order", []);
    const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
      return _.get(trackerEntries, trackerEntryId, {});
    });
    const filteredEntries =
      typeof tag !== "undefined"
        ? _.filter(fullOrderEntries, (trackerEntry) => {
            return _.get(trackerEntry, "tag") === tag;
          })
        : fullOrderEntries;
    if (!filteredEntries.length) return null;
    return (
      <Paginator
        className={"pagination"}
        page={this.state.displayedEntries.end / itemsPerPage}
        maxVisible={5}
        max={Math.ceil(fullOrder.length / itemsPerPage)}
        onChange={this.onChangePage}
      />
    );
  },

  rowData() {
    const { tag, trackerEntries } = this.props;
    const user_timezone = _.get(this.props.auth, "user.user_timezone");

    const fullOrder = _.get(trackerEntries, TrackerTypes.MOOD + "Order", []);
    const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
      return _.get(trackerEntries, trackerEntryId, {});
    });
    const filteredEntries =
      typeof tag !== "undefined"
        ? _.filter(fullOrderEntries, (trackerEntry) => {
            return _.get(trackerEntry, "tag") === tag;
          })
        : fullOrderEntries;

    const sortedEntries = _.sortByOrder(
      filteredEntries,
      ["data.dt_occurred"],
      ["desc"],
    );
    const page = {
      start: this.state.displayedEntries.start,
      end: this.state.displayedEntries.end,
    };
    const orderEntries = sortedEntries.slice(page.start, page.end);
    return orderEntries.map((fullTrackerEntry) => {
      const trackerEntry = _.get(fullTrackerEntry, "data", {});
      const { dt_occurred, tm_occurred } = extractDateAndTime(
        trackerEntry.dt_occurred,
        user_timezone,
      );
      return {
        date: `${dt_occurred} ${tm_occurred}`,
        score_pos: trackerEntry.positive_score,
        score_neg: trackerEntry.negative_score,
        actions: (
          <Dropdown>
            <Dropdown.Toggle variant="light" size="sm">
              <MaterialCommunityIcons
                name="dots-vertical"
                size={24}
                color="black"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ entryToEdit: fullTrackerEntry }, () => {
                    this.setState({ showModal: true });
                  });
                }}
                className="d-flex align-items-center"
              >
                <FontAwesome name="edit" size={24} color="black" />
                <span className="ms-2">Edit</span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({
                    showConfirmModal: true,
                    onConfirmDelete: () =>
                      this.deleteMoodTrackerEntry(
                        fullTrackerEntry._id,
                        page,
                        orderEntries.length,
                      ),
                  });
                }}
                className="d-flex align-items-center"
              >
                <FontAwesome name="trash" size={24} color="black" />
                <span className="ms-2">Delete</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      };
    });
  },

  addMood(token, userId, data, trackerType) {
    return this.props.dispatch(
      Actions.addTrackerEntry(token, userId, data, trackerType),
    );
  },

  updateMood(token, userId, data, trackerId) {
    return this.props.dispatch(
      Actions.updateTrackerEntry(token, userId, trackerId, data),
    );
  },

  positiveScore(data) {
    const positiveValues = _.pick(data, (value, key) => {
      return (
        [
          "interested",
          "excited",
          "strong",
          "enthusiastic",
          "proud",
          "alert",
          "inspired",
          "determined",
          "attentive",
          "active",
        ].indexOf(key) !== -1
      );
    });
    return sumValues(positiveValues).toString();
  },

  negativeScore(data) {
    const negativeValues = _.pick(data, (value, key) => {
      return (
        [
          "distressed",
          "upset",
          "guilty",
          "scared",
          "hostile",
          "irritable",
          "ashamed",
          "nervous",
          "jittery",
          "afraid",
        ].indexOf(key) !== -1
      );
    });
    return sumValues(negativeValues).toString();
  },

  serialize(data) {
    const user_timezone = _.get(this.props.auth, "user.user_timezone");
    const date = combineDateAndTime(
      data.dt_occurred,
      data.tm_occurred,
      user_timezone,
    );
    return {
      active: data.active,
      afraid: data.afraid,
      alert: data.alert,
      ashamed: data.ashamed,
      attentive: data.attentive,
      determined: data.determined,
      distressed: data.distressed,
      dt_occurred: date.format("YYYY-MM-DDTHH:mm:ss"),
      enthusiastic: data.enthusiastic,
      excited: data.excited,
      guilty: data.guilty,
      hostile: data.hostile,
      inspired: data.inspired,
      interested: data.interested,
      irritable: data.irritable,
      jittery: data.jittery,
      negative_score: parseInt(this.negativeScore(data)),
      nervous: data.nervous,
      positive_score: parseInt(this.positiveScore(data)),
      proud: data.proud,
      scared: data.scared,
      strong: data.strong,
      upset: data.upset,
    };
  },

  onSubmit(data) {
    const token = _.get(this.props.auth, "user.token");
    const userId = _.get(this.props.auth, "user._id");
    const payload = this.serialize(data);
    if (this.state.entryToEdit)
      return this.updateMood(
        token,
        userId,
        payload,
        this.state.entryToEdit._id,
      );
    else return this.addMood(token, userId, payload, TrackerTypes.MOOD);
  },

  initValues() {
    const user_timezone = _.get(this.props.auth, "user.user_timezone");
    if (this.state.entryToEdit) {
      let tmOccurred = Moment(
        this.state.entryToEdit.data.dt_occurred.split("+")[0],
      ).format("YYYY-MM-DD HH:mm:ss");
      tmOccurred = momentTZ.tz(tmOccurred, "UTC");
      const userTzTmOccurred = user_timezone
        ? tmOccurred.clone().tz(user_timezone)
        : tmOccurred.clone();
      return {
        dt_occurred: userTzTmOccurred.format("YYYY-MM-DD"),
        tm_occurred: userTzTmOccurred.format("hh:mm A"),
        active: this.state.entryToEdit.data.active,
        afraid: this.state.entryToEdit.data.afraid,
        alert: this.state.entryToEdit.data.alert,
        ashamed: this.state.entryToEdit.data.ashamed,
        attentive: this.state.entryToEdit.data.attentive,
        determined: this.state.entryToEdit.data.determined,
        distressed: this.state.entryToEdit.data.distressed,
        enthusiastic: this.state.entryToEdit.data.enthusiastic,
        excited: this.state.entryToEdit.data.excited,
        guilty: this.state.entryToEdit.data.guilty,
        hostile: this.state.entryToEdit.data.hostile,
        inspired: this.state.entryToEdit.data.inspired,
        interested: this.state.entryToEdit.data.interested,
        irritable: this.state.entryToEdit.data.irritable,
        jittery: this.state.entryToEdit.data.jittery,
        nervous: this.state.entryToEdit.data.nervous,
        proud: this.state.entryToEdit.data.proud,
        scared: this.state.entryToEdit.data.scared,
        strong: this.state.entryToEdit.data.strong,
        upset: this.state.entryToEdit.data.upset,
      };
    }
    const initialDateTime = momentTZ.tz(new Date(), user_timezone);
    return {
      dt_occurred: initialDateTime.format("YYYY-MM-DD"),
      tm_occurred: initialDateTime.format("hh:mm A"),
    };
  },

  render() {
    const confirmMessage = {
      id: "delete_mood_tracker_confirm_instructions",
      description: "Mood Tracker -- Delete entry, confirmation message",
      defaultMessage: "Are you sure you want to delete this tracker entry?",
    };

    const { tools } = this.props;

    let hasModerate = null;

    for (var tool in tools) {
      var singleTool = tools[tool];
      for (var key in singleTool) {
        if (key == "slug") {
          if (singleTool[key] == "moderate") {
            hasModerate = true;
          }
        }
      }
    }

    return (
      <>
        <div className="card my-4" style={{ border: "none" }}>
          <div className="card-body">
            {this.props.showTrackerNav && <TrackerNav />}
            <h3 className="card-title my-2">
              <FormattedMessage
                id="mood_list_title"
                defaultMessage="Your Recent Moods"
              />
            </h3>
            <p className="text-muted">
              <FormattedMessage
                id="mood_tracker_instructions"
                defaultMessage="The mood tracker measures positive and negative feelings. Because positive feelings vary over the course of the day we recommend that when you re-take it to see how you're doing that you do so around the same time of day each time. Read each item and then indicate to what extent you have felt this way during the past several days."
              />
            </p>
            <p className="text-muted">
              <FormattedMessage
                id="mood_tracker_instructions_infobug"
                defaultMessage="This is the Positive and Negative Affect Scale (Clark & Tellegen, 1988). Copyright 1988 by the American Psychological Association. Reproduced with permission. No further reproduction or distribution is permitted without the written permission of the American Psychological Association."
              />
            </p>
            <TrackerEntryTable columns={COLUMNS} rows={this.rowData()}>
              <div className="w-100 d-flex flex-column-reverse flex-lg-row align-items-lg-center">
                <div className="d-flex align-items-center flex-grow-1">
                  <BSButton
                    variant="green"
                    onClick={() =>
                      this.setState({ entryToEdit: null, showModal: true })
                    }
                  >
                    <FontAwesome name="plus" color="white" size={20} />
                    <span className="ms-2">
                      <FormattedMessage
                        id="button_add"
                        description="Add Drink Tracker Entry"
                        defaultMessage="Add New"
                      />
                    </span>
                  </BSButton>
                  {hasModerate && this.props.showFeedbackLink && (
                    <BSButton
                      variant="link-green"
                      className="ms-3"
                      linkTo={
                        "/moderate/lifestyle/mood-monitoring/tracker?override=true"
                      }
                    >
                      <Entypo name="bar-graph" size={23} color={FOREST_GREEN} />
                      <span className="ms-2">Mood Feedback</span>
                    </BSButton>
                  )}
                </div>
                <div className="my-2 my-lg-0">{this.pagination()}</div>
              </div>
            </TrackerEntryTable>
          </div>
        </div>
        <Modal
          size="md"
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: LIGHTER_FOREST_GREEN }}
          >
            <Modal.Title>
              {this.state.entryToEdit ? "Edit" : "New"} Mood Tracker
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MoodTracker
              onSubmit={this.onSubmit}
              isFetching={this.props.isFetching}
              initialValues={this.initValues()}
              hideModal={() => this.setState({ showModal: false })}
            />
          </Modal.Body>
        </Modal>
        <ConfirmModal
          onCancel={() => {
            this.setState({
              showConfirmModal: false,
              onConfirmDelete: undefined,
            });
          }}
          show={this.state.showConfirmModal}
          onConfirm={() => {
            this.state.onConfirmDelete();
          }}
          message={<FormattedMessage {...confirmMessage} />}
        />
      </>
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
  tools: state.tools,
  isFetching: state.trackerEntries.isFetching,
}))(MoodList);
