"use strict";

import React from "react";
import { FormattedMessage } from "react-intl";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import BSButton from "../blocks/BSButton.component.web";

export const CloseAccountDisplay = React.createClass({
  propTypes: {
    auth: React.PropTypes.object.isRequired,
    style: React.PropTypes.object,
  },

  render() {
    const locus = this,
      state = locus.state,
      props = locus.props,
      { auth, onClickNav, selfContained, style, onOpenCloseAccountModal } =
        props;

    const closeAccountDisplay = (
      <div className="flex-grow-1 d-flex align-items-center justify-content-between">
        <strong>
          <FormattedMessage
            id="account_overview_close_account_label"
            description="Account Overview -- cancel account"
            defaultMessage="Close Account"
          />
        </strong>
        <div>
          <BSButton
            variant="flat-blue"
            size="sm"
            onClick={onOpenCloseAccountModal}
            tooltip="Close account permanently"
          >
            <MaterialCommunityIcons
              name="account-cancel"
              size={24}
              color="red"
            />
          </BSButton>
        </div>
      </div>
    );

    if (selfContained) {
      return (
        <div className="offset-shadow-box" style={style}>
          {closeAccountDisplay}
        </div>
      );
    }
    return closeAccountDisplay;
  },
});
