"use strict";
import React from "react";
import { Field } from "redux-form";
import CheckboxGroup from "../ux/CheckboxGroup.component.web";

const MedicalHistory = (props) => {
  return <Field component={CheckboxGroup} multiple={true} {...props} />;
};

export default MedicalHistory;
