"use strict";

import _ from "lodash";

import React from "react";
import { FormattedMessage } from "react-intl";

export const NormPrintableTable = React.createClass({
  render() {
    const { responses, norms } = this.props;
    const rows = this.props.rows.map((row) => {
      const { path, data_key, norm_key, text } = row;
      const norm = _.get(norms, norm_key);
      const specificNorm = path ? _.get(norm, path, []) : norm;
      const response = _.get(responses, data_key);
      const goodResponse = typeof response !== "undefined";

      let normValue;
      if (goodResponse) {
        const intResponse = parseInt(response, 10);
        normValue =
          intResponse > specificNorm.length - 1
            ? specificNorm[specificNorm.length - 1]
            : specificNorm[intResponse];
      } else {
        normValue = (
          <FormattedMessage
            id="norm_lookup_no_response"
            defaultMessage="incomplete"
          />
        );
      }
      return (
        <tr key={data_key}>
          <td>
            <FormattedMessage {...text} values={responses} />
          </td>
          <td>{normValue}</td>
        </tr>
      );
    });

    return (
      <div className="norm-table print-only mb-3">
        <table className="table table-borderless">
          <tbody>
            <tr>
              <th>Area</th>
              <th>Percentile</th>
            </tr>
            {rows}
          </tbody>
        </table>
        <hr />
      </div>
    );
  },
});
