"use strict";

import _ from "lodash";
import React from "react";
import { reduxForm } from "redux-form";
import { injectIntl } from "react-intl";

import { EmptyForm } from "components/forms/empty-form";
import { MultipleChoiceListForm } from "components/forms/multiple-choice-list-form";

export const MultipleChoiceList = React.createClass({
  form: EmptyForm,

  propTypes: {
    data_key_prefix: React.PropTypes.string.isRequired,
    field_options: React.PropTypes.object.isRequired,
    form_name: React.PropTypes.string.isRequired,
    responses: React.PropTypes.object.isRequired,
    response_keys: React.PropTypes.arrayOf(React.PropTypes.string).isRequired,
    row_headers: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    updateResponse: React.PropTypes.func.isRequired,
  },

  UNSAFE_componentWillMount() {
    const { form_name: formName, response_keys } = this.props;
    this.form = injectIntl(
      reduxForm({
        form: formName,
        fields: response_keys,
      })(MultipleChoiceListForm),
    );
  },

  render() {
    const { data_key_prefix, field_options, responses, row_headers } =
      this.props;
    const Form = this.form;
    return (
      <Form
        fieldOptions={field_options}
        rowHeaders={row_headers}
        dataKeyPrefix={data_key_prefix}
        updateResponse={this.updateResponse}
        initialValues={responses}
      />
    );
  },

  updateResponse(data) {
    const { formulas, updateResponse } = this.props;
    updateResponse(data, formulas);
  },
});
