/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, Stripe */
"use strict";
import _ from "lodash";
import { connect } from "react-redux";
import React from "react";
import BSButton from "../blocks/BSButton.component.web";
import { FormattedMessage } from "react-intl";
import { Ionicons } from "@expo/vector-icons";
import { FOREST_GREEN } from "../../stylesheets/colors";

const CompletionNotice = React.createClass({
  linkURL(moduleSlug, sectionSlug, pageSlug, fieldID) {
    let url = `/${this.props.tool?.slug}/${moduleSlug}/${sectionSlug}/${pageSlug}`;
    const params = {
      returnUrl: location.pathname,
      ...(fieldID && { field: fieldID }),
    };
    return Object.keys(params).reduce((acc, key, idx) => {
      if (idx) return acc + `&${key}=${params[key]}`;
      return acc + `?${key}=${params[key]}`;
    }, url);
  },

  pageLink() {
    const { metadata } = this.props.tool || {},
      links = [];
    (this.props.required_responses || [])
      .filter((reqResponse) => {
        const { [reqResponse]: val } = this.props.responses || {};
        if (Array.isArray(val) || typeof val === "string") return !val.length;
        if (typeof val === "number") return false;
        return !val;
      })
      .forEach((reqResponse) => {
        Object.values(metadata?.modules ?? {}).forEach((module) => {
          Object.values(module?.sections ?? {}).forEach((section) => {
            Object.values(section?.pages ?? {}).forEach((page) => {
              page?.components?.forEach((component) => {
                const matchingField = component.fields?.find(
                    ({ id }) => id === reqResponse,
                  ),
                  matchingFormula = component.formulas?.find(
                    ({ data_key }) => data_key === reqResponse,
                  );
                if (matchingField || matchingFormula) {
                  links.push({
                    url: this.linkURL(
                      module.slug,
                      section.slug,
                      page.slug,
                      matchingField?.id,
                    ),
                    title: matchingField?.text ?? page.title,
                  });
                }
              });
            });
          });
        });
      });
    return links;
  },

  render() {
    const { responses, required_responses } = this.props;
    const relevantResponses = _.pick(
      responses,
      (value, key) => required_responses.indexOf(key) !== -1,
    );
    const relevantResponseKeys = Object.keys(relevantResponses);
    const responseCount = relevantResponseKeys.length;
    const isComplete = _.isEmpty(
      _.difference(required_responses, relevantResponseKeys),
    );
    if (isComplete) return null;

    return (
      <div className="card" style={{ border: "none" }}>
        <div className="card-body">
          <h3 className="card-title">
            <FormattedMessage
              id="completion_notice_incomplete"
              defaultMessage="Incomplete"
            />
          </h3>
          {responseCount ? (
            <p>
              You haven't answered all of the questions. Please return to the
              questions page and answer each question.
            </p>
          ) : (
            <p>
              You have not answered any of the questions. Please return to the
              questions page and answer each question.
            </p>
          )}
          <div className="my-3">
            {this.pageLink().map(({ url, title }, idx) => (
              <BSButton variant="link-green" linkTo={url} key={`link_${idx}`}>
                <div className="me-2">
                  <FormattedMessage {...title} />
                </div>
                <Ionicons name="arrow-forward" size={20} color={FOREST_GREEN} />
              </BSButton>
            ))}
          </div>
          <p>
            This feedback <strong>is not accurate or meaningful</strong> until
            all questions have been answered.
          </p>
        </div>
      </div>
    );
  },
});

export default connect((state) => {
  const activeResponseId = state.responses.activeResponseId,
    { [activeResponseId]: activeResponse } = state.responses || {},
    { tool_id } = activeResponse?.response ?? {},
    { [tool_id]: tool } = state.tools || {};
  return {
    tool,
  };
})(CompletionNotice);
