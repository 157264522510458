/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import Link from "components/ux/linker";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import Actions from "actions";
import Selectors from "selectors";
const { toolSelector, userProfileSelector } = Selectors;

import Button from "components/ux/mdl/button";

import { PrintProgress } from "components/blocks/print-progress";
import renderJSON from "../render-json";
import {
  faPrint,
  faAdjust,
  faBolt,
  faWineGlassAlt as faGlass,
  faThList,
  faComment as faCommentingO,
  faBell as faBellO,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

export const ProgressPage = React.createClass({
  render() {
    const { responses, norms, tool, trackerEntryStats, userProfile } =
      this.props;

    return (
      <div className="body-column page-screener">
        <div className="grid-layout grid-pad">
          <div className="section overview-tool">
            <Button
              className="overview-back"
              linkTo="/"
              flat={true}
              noColor={true}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
              <FormattedMessage
                id="tool_overview_back_to_dashboard"
                description="tool overview -- link back to dashboard"
                defaultMessage="Dashboard"
              />
            </Button>
            <h1 className="section-title">
              <FormattedMessage {...tool.long_title} />
            </h1>
            <PrintProgress
              responses={responses}
              userProfile={userProfile}
              tool={tool}
            />
          </div>{" "}
          {/* END section overview-tool */}
        </div>{" "}
        {/* END grid-layout grid-pad */}
      </div>
    );
  },
});

export default connect((state, props) => ({
  auth: state.auth,
  responses: state.responses,
  ...toolSelector(state, props),
  ...userProfileSelector(state),
}))(ProgressPage);
