/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import React from "react";
import { FormattedMessage } from "react-intl";

import TextFieldReduxForm from "components/ux/text-field-redux-form";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

export const NumberTableNumberQuestion = React.createClass({
  propTypes: {
    field: React.PropTypes.object.isRequired,
    fieldName: React.PropTypes.string.isRequired,
    formatMessage: React.PropTypes.func.isRequired,
    max: React.PropTypes.string,
    min: React.PropTypes.string,
    placeholder_text: React.PropTypes.object,
    style: React.PropTypes.object,
    text: React.PropTypes.object,
    updateResponse: React.PropTypes.func.isRequired,
  },

  updateLabelText: function (placeholder_text, fieldName) {
    if (_.get(placeholder_text, "defaultMessage") == "#") {
      const message = "Number of " + fieldName.replace(/_/g, " ");
      return {
        id: fieldName + "-label",
        defaultMessage: message,
      };
    } else {
      return placeholder_text;
    }
  },

  render() {
    const {
      field,
      fieldName,
      max,
      min,
      style,
      text,
      placeholder_text,
      formatMessage,
    } = this.props;
    const labelText = formatMessage(
      this.updateLabelText(placeholder_text, fieldName),
    );
    const label = !text ? null : (
      <label htmlFor={fieldName}>
        <FontAwesomeIcon icon={faAngleDoubleRight} />
        <FormattedMessage {...text} />
      </label>
    );

    return (
      <div className="table-block number" style={style}>
        <div className="question">
          {label}
          <div style={{ textAlign: "center" }}>
            <TextFieldReduxForm
              type="number"
              id={fieldName}
              {...field}
              ariaLabel={labelText}
              save={(value) =>
                this.props.updateResponse({ [fieldName]: value })
              }
              min={min}
              max={max}
              selectOnFocus={true}
            />
          </div>
        </div>
      </div>
    );
  },
});
