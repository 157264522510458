/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import { NOTIFY } from "actions/types";

export function reducer(state = {}, action = { type: "" }) {
  switch (action.type) {
    case NOTIFY:
      // if the action is undefined, set it to null so we override other potential actions from previous notifcations
      if (typeof action.payload.action == "undefined") {
        action.payload.action = null;
      }
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
