"use strict";

import { getTool as getToolsAPI } from "api/tools";

import {
  REQUEST_GET_TOOL,
  REQUEST_GET_TOOL_SUCCESS,
  REQUEST_GET_TOOL_FAILURE,
} from "actions/types";

function requestGetTool() {
  return {
    type: REQUEST_GET_TOOL,
  };
}

function requestGetToolSuccess(json) {
  return {
    type: REQUEST_GET_TOOL_SUCCESS,
    payload: {
      tool: json,
    },
  };
}

function requestGetToolFailure(error) {
  return {
    type: REQUEST_GET_TOOL_FAILURE,
    payload: error,
    error: true,
  };
}

export default function getTool(token, toolId, cb = () => {}) {
  return (dispatch) => {
    dispatch(requestGetTool());
    return getToolsAPI(token, toolId)
      .then((json) => {
        return dispatch(requestGetToolSuccess(json));
      })
      .catch((error) => {
        return dispatch(requestGetToolFailure(error));
      })
      .finally(() => {
        cb?.();
      });
  };
}
