/* globals _, CX_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React from "react";
import { FormattedMessage } from "react-intl";
import { browserHistory, withRouter, Link } from "react-router";
import { validProfile } from "../../utils/auth";
import { Nav } from "react-bootstrap";
import BSButton from "../blocks/BSButton.component.web";

// ======= APP COMPONENTS ===============

const CrumbTrail = React.createClass({
  // ======= REACT LIFECYCLE ==================================
  propTypes: {
    navigation: React.PropTypes.array,
    user: React.PropTypes.object,
  },

  getInitialState() {
    return { index: 1, navDepth: 4 };
  },

  UNSAFE_componentWillMount() {},

  componentDidMount() {
    this.adjustCursor();
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.adjustCursor(nextProps);
  },

  componentDidUpdate() {},

  adjustCursor(nextProps) {
    const props = nextProps || this.props,
      navigation = props.navigation,
      index =
        navigation.length > 1 ? navigation.length - 2 : navigation.length - 1;

    this.setState({
      navDepth: navigation.length,
      index: index,
    });
  },

  // ====================== LOCAL METHODS =====================================

  onMenuButtonClick(e, crumbIndex, item) {
    this.props.onMenuButtonClick(item);
    const currentIndex = this.state.index,
      navDepth = this.state.navDepth;
    let newIndex;
    e.preventDefault();
    // Feedback link opens feedback modal in app-header.js
    if (item.path === "/feedback") return;

    return new Promise((resolve, reject) => {
      if (crumbIndex === currentIndex) {
        resolve();
      } else {
        if (crumbIndex === navDepth - 1) {
          newIndex = navDepth - 2;
        } else {
          newIndex = crumbIndex;
        }

        if (newIndex === currentIndex) {
          resolve();
        } else {
          resolve();
          browserHistory.push(item.path);
          this.setState({
            index: newIndex,
          });
        }
      }
    });
  },

  positionCrumbs(data) {
    // clean out any previous settings
    function clean(datum) {
      delete datum.position;
      delete datum.opacity;
      delete datum.className;
      delete datum.crumbIndex;
      delete datum.currentIndex;
      delete datum.flatten;
    }
    function getLength(navItem) {
      function occurrences(candidate, chars, allowOverlapping) {
        const string = String(candidate);
        const subString = String(chars);
        if (subString.length <= 0) {
          return string.length + 1;
        }
        var n = 0,
          pos = 0,
          step = allowOverlapping ? 1 : subString.length;
        while (true) {
          //eslint-disable-line
          pos = string.indexOf(subString, pos);
          if (pos >= 0) {
            ++n;
            pos += step;
          } else break;
        }
        return n;
      }

      let measured = "";
      if (navItem.currentPath) {
        measured = _.result(
          _.find(navItem.items, (item) => {
            return item.path === navItem.currentPath;
          }),
          "title.defaultMessage",
        );
      }
      if (!measured) {
        measured = navItem.lastSegment;
      }

      // Estimating length works better when we accommodate narrow characters
      const narrowChars = [" ", "/", '"', "'", "i", "I"];
      let reduction = 0;
      narrowChars.forEach((narrow) => {
        reduction += occurrences(measured, narrow);
      });
      reduction = 0.4 * reduction;

      return measured.length - reduction;
    }

    const index = Number(this.state.index);
    let crumb, i, offset;

    for (i = 0; i < data.length; i++) {
      crumb = data[i];
      clean(crumb);

      crumb.crumbIndex = i;
      crumb.currentIndex = index;

      // expected width:  10px + (stringlength * 9px) + 36px
      if (i < index) {
        if (i === index - 1) {
          offset = getLength(crumb) ? getLength(crumb) * 8 : 0;
          crumb.position = "-" + offset + "px";
          crumb.opacity = 1;
          crumb.className = "hide-button-text";
        } else {
          crumb.position = "-160px";
          crumb.className = "hide-button-text";
          crumb.opacity = 0;
          crumb.flatten = true;
        }
      } else if (i === index) {
        crumb.position = index === 0 ? "0px" : "24px";
        crumb.opacity = 1;
      } else if (i > index) {
        offset = getLength(data[i - 1]) ? getLength(data[i - 1]) * 9 + 46 : 46;
        crumb.position =
          index === 0 ? offset + "px" : Number(offset + 24) + "px";
        crumb.opacity = 1;
      }
      if (i > index + 1) {
        crumb.flatten = true;
        crumb.opacity = 0;
      }
    }
    return data;
  },

  render: function () {
    const { navigation, user, routing } = this.props,
      { roles } = user || {},
      { admin, system } = roles || {},
      isAdmin =
        !!admin?.length || (!!system?.length && system.includes("admin"));
    const userStatus = _.get(user, "status");
    const isLoggedIn = userStatus === "normal" || userStatus === "unsubscribed";
    const positionedNavigation = this.positionCrumbs(navigation);
    const homeMenu = {
      currentPath: routing.path,
      items: isLoggedIn
        ? [
            ...(isAdmin
              ? [
                  {
                    path: "/admin",
                    title: {
                      id: "admin",
                      defaultMessage: "Admin",
                    },
                  },
                ]
              : []),
            {
              path: "/dashboard",
              title: {
                id: "dashboard",
                defaultMessage: "Dashboard",
              },
              hidden: !validProfile(this.props.user),
            },
            {
              path: "/account",
              title: {
                id: "my_account",
                defaultMessage: "My Account",
              },
            },
            {
              path: "/drinks",
              title: {
                id: "drink_tracker",
                defaultMessage: "Trackers",
              },
            },
            {
              path: "/reminders",
              title: {
                id: "reminders",
                defaultMessage: "Reminders",
              },
            },
            {
              path: "/feedback",
              title: {
                id: "feedback_mailto",
                defaultMessage: "Feedback",
              },
            },
            {
              path: "/logout",
              title: {
                id: "log_out",
                defaultMessage: "Log Out",
              },
            },
          ]
        : // ------- NOT LOGGED IN ------------------
          Boolean(_.get(CUAC_SETTINGS, "GROUP.partner_paid"))
          ? [
              {
                path: "/screener",
                title: {
                  id: "screener",
                  defaultMessage: "Get Started",
                },
              },
              {
                path: "/login",
                title: {
                  id: "login",
                  defaultMessage: "Log In",
                },
              },
              {
                path: "/feedback",
                title: {
                  id: "feedback_mailto",
                  defaultMessage: "Feedback",
                },
              },
            ]
          : [
              {
                path: "/screener",
                title: {
                  id: "screener",
                  defaultMessage: "Get Started",
                },
              },
              {
                path: "/feedback",
                title: {
                  id: "feedback_mailto",
                  defaultMessage: "Feedback",
                },
              },
              {
                path: "/register",
                title: {
                  id: "register",
                  defaultMessage: "Register",
                },
              },
              {
                path: "/login",
                title: {
                  id: "login",
                  defaultMessage: "Log In",
                },
              },
            ],
    };

    return homeMenu.items
      .filter(({ hidden }) => !hidden && !CUAC_SETTINGS.GROUP.isDisabled)
      .map((item, index) => {
        if (item.path === "/login")
          return (
            <BSButton
              variant={
                this.props.router?.location?.pathname === "/login"
                  ? "green"
                  : "outline-green"
              }
              key={`nav_link_${index}`}
              onClick={(e) => this.onMenuButtonClick(e, index, item)}
            >
              Login
            </BSButton>
          );
        return (
          <>
            {item.path === "/feedback" && (
              <Nav.Link
                eventKey={item.path}
                key={`nav_link_${index}`}
                href="#"
                onClick={(e) => this.onMenuButtonClick(e, index, item)}
              >
                <FormattedMessage {...item.title} />
              </Nav.Link>
            )}
            {item.path !== "/feedback" && (
              <Link
                className="nav-link"
                role="button"
                to={item.path}
                key={`nav_link_${index}`}
              >
                <FormattedMessage {...item.title} />
              </Link>
            )}
          </>
        );
      });
  },
});

export default withRouter(CrumbTrail);
