/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import InfoBug from "components/popover/infobug";
import ToggleSwitch from "components/ux/switch";
import BSButton from "../../components/blocks/BSButton.component.web";
import { Ionicons } from "@expo/vector-icons";
import { BLUE } from "../../stylesheets/colors";

const getButtonText = (toolCompleted, progress) => {
  if (!!progress && !toolCompleted) {
    return {
      title: "Continue",
      description: "continue with tool",
    };
  }

  if (toolCompleted) {
    return {
      title: "Review",
      description: "Review completed response",
    };
  }

  return {
    title: "Begin",
    description: "Let's get started",
  };
};

export const ToolItem = (props) => {
  const { tool } = props;
  const {response = {}} = tool ?? {}
  const { tool_complete } = response ?? {};
  const { question_count = 0, response_count = 0 } = response?.progress ?? {};

  const progress =
    question_count > 0
      ? Math.round((response_count / question_count) * 100)
      : undefined;

  const buttonTexts = getButtonText(tool_complete, progress);


  const onClick = () => {
    if (response) {
      props.editResponse(response?._id, tool);
    } else props.addResponse(props.tool);
  };

  


  return (
    <li key={props.tool._id} className="list-group-item">
      <div className="d-flex flex-column flex-lg-row w-100 justify-content-between mb-2">
        <h5 className="mb-0">
          <FormattedMessage {...props.tool.long_title} />
        </h5>

        {!props.hideProgress && progress >= 0 && (
          <div className="ms-lg-2 mb-2">
            <p className="text-small mb-0">{progress}% Completed</p>
            <div className="progress w-100">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%`, backgroundColor: BLUE }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          </div>
        )}
      </div>
      <div className="d-flex flex-column flex-lg-row justify-content-between mb-2">
        <p className="mb-2 me-2"> </p>
        <BSButton
          variant="green"
          onClick={onClick}
          disabled={props.disableButton}
        >
          <div className="me-2">
            <FormattedMessage
              id="overview_button_begin"
              description={buttonTexts.description}
              defaultMessage={buttonTexts.title}
            />
          </div>
          <Ionicons name="arrow-forward" size={20} color="white" />
        </BSButton>
      </div>
      {props.showEmailToggle && _.get(props.tool, "guided_email_id") && (
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-2">
            <p className="mb-0">Receiving Guided Program Emails</p>
            <InfoBug>
              <FormattedMessage
                id="guided_email_infobug"
                defaultMessage="We recommend using the guided email feature to move through the program. If you check the box, we'll send you an email weekly for 12 weeks. The email will guide you through the program step by step which improves your chances of learning the tools."
              />
            </InfoBug>
          </div>
          <ToggleSwitch
            switchId={props.tool._id}
            onSwitch={(_, checked) =>
              props.onEmailSwitch(props.tool._id, checked)
            }
            checked={props.checkAllowEmail(props.tool._id)}
            labelTrue="On"
            labelFalse="Off"
          />
        </div>
      )}
    </li>
  );
};
