"use strict";

import React from "react";

export const P = React.createClass({
  render() {
    return (
      <div
        className={
          this.props.className
            ? "paragraph " + this.props.className
            : "paragraph"
        }
      >
        {this.props.children}
      </div>
    );
  },
});
