"use strict";

import { SHOW_MODAL } from "actions/types";

export default function showModal(name, data) {
  return {
    type: SHOW_MODAL,
    payload: data,
  };
}
