"use strict";

import { handleResponse } from "utils/api";

export const addFeedback = (userId, token, feedback) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/feedback?user_id=" + userId, {
    method: "POST",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title: feedback.title,
      message: feedback.message,
      email: feedback.email,
      browser: feedback.browser,
      operating_system: feedback.operating_system,
      browser_version: feedback.browser_version,
      user_agent: feedback.user_agent,
      page_url: feedback.url,
      user_id: userId,
    }),
  }).then(handleResponse);
};
