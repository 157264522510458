"use strict";

import addHelpFeedback from "actions/add-help-feedback";
import addReminder from "actions/add-new-reminder";
import addResponse from "actions/add-response";
import addSubscription from "actions/add-subscription";
import addTrackerEntry from "actions/add-tracker-entry";
import addUnregisteredUser from "actions/add-unregistered-user";
import changePassword from "actions/change-password";
import clearSelectedPlan from "actions/clear-selected-plan";
import closeModal from "actions/close-modal";
import deleteReminder from "actions/delete-reminder";
import deleteSubscription from "actions/delete-subscription";
import deleteTrackerEntry from "actions/delete-tracker-entry";
import developHackUser from "actions/develop-hack";
import dismissMessage from "actions/dismiss-message";
import getPasswordReset from "actions/get-password-reset";
import getPlans from "actions/get-plans";
import getResponses from "actions/get-responses";
import getSubscriptions from "actions/get-subscriptions";
import getTool from "actions/get-tool";
import getTerms from "actions/get-terms";
import getTools, { getSimplifiedTools } from "actions/get-tools";
import getTrackerEntries from "actions/get-tracker-entries";
import getTrackerEntryStats from "actions/get-tracker-entry-stats";
import getUser from "actions/get-user";
import getAllReminders from "actions/get-reminders";
import logInUser from "actions/log-in-user";
import ssoLogInUser from "actions/sso-log-in-user";
import logOutUser from "actions/log-out-user";
import notify from "actions/notify";
import { registerUser, registerAnonymousUser } from "actions/register-user";
import sendCompletionEmail from "actions/send-completion-email";
import requestPasswordReset from "actions/request-password-reset";
import showModal from "actions/show-modal";
import resetPassword from "actions/reset-password";
import selectPlan from "actions/select-plan";
import setCoupon from "actions/set-coupon";
import setResponse from "actions/set-response";
import twoFactorLogIn from "actions/two-factor-login";
import updateDisplay from "actions/update-display";
import updateResponse from "actions/update-response";
import updateSubscription from "actions/update-subscription";
import updateReminder from "actions/update-reminder";
import updateTrackerEntry from "actions/update-tracker-entry";
import updateUser from "actions/update-user";
import getUserEmailEntry from "actions/get-user-email-entry";
import updateUserEmailEntry from "actions/update-user-email-entry";
import deactivateUser from "actions/deactivate-user";
import addGroupToUser from "actions/add-group-to-user";
import getWearableLoginCode from "actions/get-wearable-login-code";
import getGroup from "actions/get-group";
import dismissUserMessage from "actions/dismiss-user-message";
import { setToolTOCOpen } from "./app";
import { setUser } from "./auth";
import getNorms from "./get-norms";

const actions = {
  setToolTOCOpen,
  addHelpFeedback,
  addReminder,
  addResponse,
  addSubscription,
  addTrackerEntry,
  addUnregisteredUser,
  changePassword,
  clearSelectedPlan,
  closeModal,
  deleteReminder,
  deleteSubscription,
  deleteTrackerEntry,
  dismissMessage,
  getPasswordReset,
  getPlans,
  getResponses,
  getSubscriptions,
  getTerms,
  getTool,
  getTools,
  getSimplifiedTools,
  getTrackerEntries,
  getTrackerEntryStats,
  getUser,
  setUser,
  getAllReminders,
  logInUser,
  ssoLogInUser,
  logOutUser,
  notify,
  registerUser,
  registerAnonymousUser,
  requestPasswordReset,
  resetPassword,
  selectPlan,
  setCoupon,
  setResponse,
  showModal,
  twoFactorLogIn,
  updateDisplay,
  updateResponse,
  updateSubscription,
  updateReminder,
  updateTrackerEntry,
  updateUser,
  sendCompletionEmail,
  getUserEmailEntry,
  updateUserEmailEntry,
  deactivateUser,
  addGroupToUser,
  getWearableLoginCode,
  getGroup,
  dismissUserMessage,
  getNorms,
};

export default actions;
