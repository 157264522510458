"use strict";

import { handleResponse } from "utils/api";

export const getNorms = (token) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/norms", {
    method: "GET",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};
