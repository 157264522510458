import React, { useCallback, useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { Group, User } from "../../types";
import { adminUpdateUser } from "../../api/users";
import { connect } from "react-redux";
import Actions from "../../actions";
import BSButton from "../blocks/BSButton.component.web";
interface Props {
  open: boolean;
  close: () => void;
  user?: User;
  targetUser?: User;
  group?: Group;
  notify: (payload: {
    title: string;
    message: string;
    level: "error" | "success";
  }) => void;
}

interface UserForm
  extends Pick<User, "email" | "name" | "two_factor_auth" | "phone_number"> {}

function UserFormModal({
  notify,
  group,
  user,
  open,
  close,
  targetUser,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<UserForm>({} as UserForm);

  useEffect(() => {
    if (targetUser)
      setForm({
        email: targetUser.email,
        name: targetUser.name,
        two_factor_auth: !!targetUser.two_factor_auth,
        phone_number: targetUser.phone_number,
      });
  }, [targetUser]);

  const updateForm = useCallback(
    (key: keyof UserForm) => (e: any) => {
      setForm((prev) => ({
        ...prev,
        [key]: e.target.value,
      }));
    },
    [setForm],
  );

  const save = useCallback(() => {
    (async () => {
      if (
        !user?.token?.length ||
        !group?._id?.length ||
        !targetUser?._id?.length
      )
        return;
      try {
        setLoading(true);
        const res = await adminUpdateUser(
          user.token,
          targetUser._id,
          group._id,
          form,
        );
        notify({
          level: "success",
          title: "Success!",
          message: "User updated!",
        });
        setLoading(false);
        close();
      } catch (e) {
        setLoading(false);
        notify({
          level: "error",
          title: "Error!",
          message: "There was an error updating the user.",
        });
      }
    })();
  }, [form, close, group, user]);

  return (
    <Modal
      show={open}
      onHide={close}
      backdrop="static"
      keyboard={true}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{user?._id?.length ? "Edit" : "Create"} User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              disabled={true}
              value={form.email ?? ""}
              type="email"
              placeholder="Enter email"
            />
          </Form.Group>
          <Form.Group
            onChange={updateForm("name")}
            className="mb-3"
            controlId="name"
          >
            <Form.Label>Name</Form.Label>
            <Form.Control
              value={form.name ?? ""}
              type="text"
              placeholder="Enter name"
            />
          </Form.Group>
          <hr />
          <h5 className="fw-bold fs-5 mb-1">Two-Factor Authentication</h5>
          {form.two_factor_auth ? (
            <>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  disabled={true}
                  value={form.phone_number ?? ""}
                  type="text"
                  placeholder="Enter phone"
                />
              </Form.Group>
              <Form.Check
                checked={form.two_factor_auth}
                disabled={!form.two_factor_auth}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    two_factor_auth: e.target.checked,
                  }))
                }
                type="checkbox"
                id="two_factor_auth"
                label="Two-Factor Authentication"
              />
            </>
          ) : (
            <p>Not enabled for this user</p>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <BSButton
          variant="link-green"
          disabled={loading}
          onClick={close}
          label="Cancel"
        />
        <BSButton
          disabled={loading}
          loading={loading}
          label="Save"
          variant="green"
          onClick={save}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  {
    notify: Actions.notify,
  },
)(UserFormModal);
