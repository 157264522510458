"use strict";

export function reducer(state = false, action) {
  switch (action.type) {
    case "WELCOME_BANNER_DISMISS":
      return true;
    default:
      return state;
  }
}
