"use strict";

import _ from "lodash";
import React from "react";
import { getLatestTrackerEntry } from "utils/render";

import { Element } from "components/elements/element";

export const InformationalTextForLastStats = React.createClass({
  propTypes: {
    components: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    trackerEntryStats: React.PropTypes.object.isRequired,
  },

  render() {
    const { components, trackerEntryStats, default_responses } = this.props;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayToEdit = new Date();
    todayToEdit.setHours(0, 0, 0, 0);
    const todaysDay = todayToEdit.getDay();
    const diff = todayToEdit.getDate() - todaysDay;
    const sunday = new Date(todayToEdit.setDate(diff));

    let statResponses = {};

    // check to see if it is a week or day view
    const listOfStats = _.get(trackerEntryStats, "trackerEntryStats", {});
    for (const key in listOfStats) {
      const stats = listOfStats[key];
      if (key.indexOf("week") > -1) {
        for (const subKey in stats) {
          const date = new Date(_.get(stats[subKey], "_id", ""));
          date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
          date.setHours(0, 0, 0, 0);
          if (date.getTime() === sunday.getTime()) {
            const statObject = stats[subKey];
            for (const subSubKey in statObject) {
              statResponses[key + "_" + subSubKey] = statObject[subSubKey];
            }
          }
        }
      } else if (key.indexOf("day") > -1) {
        for (const subKey in stats) {
          const date = new Date(_.get(stats[subKey], "_id", ""));
          date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
          date.setHours(0, 0, 0, 0);
          if (date.getTime() === today.getTime()) {
            const statObject = stats[subKey];
            for (const subSubKey in statObject) {
              statResponses[key + "_" + subSubKey] = statObject[subSubKey];
            }
          }
        }
      }
    }

    const mergedResponses = {
      ...default_responses,
      ...statResponses,
    };

    return (
      <div className="informational-text">
        {components.map((component, index) => {
          return (
            <Element
              key={"element_" + index}
              component={component}
              index={index}
              responses={mergedResponses}
            />
          );
        })}
      </div>
    );
  },
});
