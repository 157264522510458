/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage, FormattedDate, FormattedTime } from "react-intl";
import InfoBug from "components/popover/infobug";

import { TrackerTypes } from "api/tracker-entries";
import Accordion from "components/ux/accordion";
import Button from "components/ux/mdl/button";
import {
  faChevronCircleDown,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

export const ValueList = React.createClass({
  propTypes: {
    tag: React.PropTypes.string,
    trackerEntries: React.PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      openEntries: [],
    };
  },

  toggleEntry(index) {
    let openEntries = this.state.openEntries;
    if (openEntries.indexOf(index) == -1) {
      openEntries.push(index);
    } else {
      openEntries = _.without(openEntries, index);
    }

    this.setState({
      openEntries: openEntries,
    });
  },

  render() {
    const { tag, trackerEntries } = this.props;
    const locus = this,
      openEntries = this.state.openEntries,
      toggleEntry = this.toggleEntry;
    const fullOrder = _.get(trackerEntries, TrackerTypes.VALUE + "Order", []);
    const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
      return _.get(trackerEntries, trackerEntryId, {});
    });
    const filteredEntries =
      typeof tag !== "undefined"
        ? _.filter(fullOrderEntries, (trackerEntry) => {
            return _.get(trackerEntry, "tag") === tag;
          })
        : fullOrderEntries;
    const orderEntries = filteredEntries.slice(0, 20);

    return (
      <div className="section section-tracker-list col-1-1">
        <div
          className="contain-box-wrap"
          style={{ maxWidth: "690px", marginTop: "20px" }}
        >
          <div className="contain-box-main">
            <h3 className="section-title contain-box-title">
              <FormattedMessage
                id="value_list_title"
                defaultMessage="Your Previous Value Explorations"
              />
            </h3>
            <div className="tracker-list-data">
              <div className="tracker-episode header">
                <div className="tracker-row">
                  <div className="tracker-entry tracker-entry-type">
                    <FormattedMessage
                      id="value_data_header_title"
                      defaultMessage="Title"
                    />
                  </div>
                  <div className="tracker-entry tracker-entry-date-time">
                    <FormattedMessage
                      id="drink_data_header_date"
                      defaultMessage="Last Updated"
                    />
                  </div>

                  <div className="clearfix" />
                </div>
              </div>
              {orderEntries.map((fullTrackerEntry, index) => {
                const trackerEntry = _.get(fullTrackerEntry, "data", {});
                const dateUpdated = new Date(fullTrackerEntry.dt_u),
                  isOpen = openEntries.indexOf(index) !== -1,
                  accordionButton =
                    "accordion-toggle" + (isOpen ? " open" : ""),
                  accordionButtonIcon = isOpen
                    ? faChevronCircleDown
                    : faChevronCircleRight;

                return !_.isEmpty(trackerEntry) ? (
                  <div className="tracker-episode" key={"plan_" + index}>
                    <div className="tracker-row">
                      <div className="tracker-entry tracker-entry-title">
                        {trackerEntry.title}
                      </div>
                      <div className="tracker-entry tracker-entry-date-time">
                        <div className="tracker-date">
                          <FormattedDate
                            value={dateUpdated}
                            day="numeric"
                            month="short"
                            year="numeric"
                          />
                        </div>
                        <div className="tracker-time">
                          <FormattedTime
                            value={dateUpdated}
                            hour="numeric"
                            minute="numeric"
                          />
                        </div>
                      </div>
                      <Button
                        className={accordionButton}
                        flat={true}
                        onClick={toggleEntry.bind(locus, index)}
                      >
                        <FontAwesomeIcon icon={accordionButtonIcon} />
                      </Button>
                      <Accordion
                        collapsed={!isOpen}
                        className="tracker-entry-block"
                      >
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="value_data_popover_title_label"
                              defaultMessage="Title"
                            />
                          </span>
                          {trackerEntry.title}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="value_data_popover_first_taught_label"
                              defaultMessage="Who first taught you about this value?"
                            />
                          </span>
                          {trackerEntry.first_taught}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="value_data_popover_admire_label"
                              defaultMessage="Who do you most admire in relation to this value?"
                            />
                          </span>
                          {trackerEntry.admire}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="value_data_popover_example_label"
                              defaultMessage="What is the greatest example of this value that you know?"
                            />
                          </span>
                          {trackerEntry.example}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="value_data_popover_best_done_label"
                              defaultMessage="What's the best you've done in relation to this value?"
                            />
                          </span>
                          {trackerEntry.best_done}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="value_data_popover_best_could_label"
                              defaultMessage="What is the best you could ever do in relation to this value?"
                            />
                          </span>
                          {trackerEntry.best_could}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="value_data_popover_relationship_label"
                              defaultMessage="What got in the way of your relationship with this value?"
                            />
                          </span>
                          {trackerEntry.relationship}
                        </p>
                      </Accordion>

                      <div className="clearfix" />
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  },
});
