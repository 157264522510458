"use strict";

import _ from "lodash";

import { createSelector } from "reselect";

import { pageSlugSelector } from "selectors/page";

const screenerToolSelector = (state) => {
  return state.tools[CUAC_SETTINGS.GROUP.screener_tool_id];
};
const getScreenerPageFromSlug = (slug, tool) => {
  if (!tool) {
    return {};
  }
  for (const page of _.values(_.get(tool.metadata, "pages", {}))) {
    if (page.slug === slug) {
      return page;
    }
  }
  const pageId = _.first(tool.metadata.order);
  return pageId ? _.get(tool.metadata.pages, pageId, {}) : {};
};

export const screenerPageSelector = createSelector(
  pageSlugSelector,
  screenerToolSelector,
  (pageSlug, screenerTool) => {
    return {
      tool: screenerTool,
      page: getScreenerPageFromSlug(pageSlug, screenerTool),
    };
  }
);
