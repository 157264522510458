/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import _ from "lodash";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import NumberInputReduxForm from "components/ux/number-input-redux-form";

const baseClasses = {
  "question-block": true,
  number: true,
};
export const NumberQuestion = React.createClass({
  propTypes: {
    field: React.PropTypes.object.isRequired,
    fieldName: React.PropTypes.string.isRequired,
    formatMessage: React.PropTypes.func.isRequired,
    max: React.PropTypes.string,
    min: React.PropTypes.string,
    placeholder_text: React.PropTypes.object,
    style: React.PropTypes.object,
    text: React.PropTypes.object,
    updateResponse: React.PropTypes.func.isRequired,
  },

  onBlur(event) {
    if (!isNaN(event.target.value)) {
      this.props.updateResponse({
        [event.target.name]: String(Number(event.target.value)),
      });
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    const nextValue = _.get(nextProps, "field.value"),
      currentValue = _.get(this.props, "field.value");

    return currentValue !== nextValue;
  },

  render() {
    const locus = this;
    const {
      field,
      fieldName,
      max,
      min,
      step,
      noButtons,
      style,
      text,
      placeholder_text,
      formatMessage,
      className,
      currency,
    } = this.props;
    const value = this.props.field ? this.props.field.value : 99;

    const label = !text ? null : (
      <label htmlFor={fieldName}>
        <FontAwesomeIcon icon={faAngleDoubleRight} />
        <FormattedMessage {...text} />
      </label>
    );

    const placeHolder = placeholder_text
      ? formatMessage(placeholder_text)
      : "Number";

    const numberClass = classnames(baseClasses, className);

    return (
      <div className={numberClass} style={style}>
        <div className="question">
          {label}
          <div style={{ textAlign: "center" }}>
            <NumberInputReduxForm
              id={fieldName}
              {...field}
              value={value}
              name={fieldName}
              fieldName={fieldName}
              floatingLabel={true}
              labelText={placeHolder}
              noButtons={noButtons}
              save={(value) => {
                locus.props.updateResponse({ [fieldName]: value });
              }}
              min={min}
              max={max}
              step={step}
              onBlur={this.onBlur}
              noblur={true}
              currency={currency}
              ariaText={
                _.get(text, "defaultMessage") ? text.defaultMessage : null
              }
            />
          </div>
        </div>
      </div>
    );
  },
});
