/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import { updateUserEmailEntry as updateUserEmailEntryAPI } from "api/user-email-entry";

import {
  REQUEST_UPDATE_USER_EMAIL_ENTRY,
  UPDATE_USER_EMAIL_ENTRY_SUCCESS,
  UPDATE_USER_EMAIL_ENTRY_FAILURE,
} from "actions/types";

function requestUpdateUserEmailEntry() {
  return {
    type: REQUEST_UPDATE_USER_EMAIL_ENTRY,
  };
}

function requestUpdateUserEmailEntrySuccess(json) {
  return {
    type: UPDATE_USER_EMAIL_ENTRY_SUCCESS,
    payload: {
      response: json,
    },
  };
}

function requestUpdateUserEmailEntryFailure(error) {
  return {
    type: UPDATE_USER_EMAIL_ENTRY_FAILURE,
    payload: error,
    error: true,
  };
}

export default function updateUserEmailEntry(
  token,
  userId,
  toolId,
  allowEmail,
) {
  return (dispatch) => {
    dispatch(requestUpdateUserEmailEntry());
    return updateUserEmailEntryAPI(token, userId, toolId, allowEmail)
      .then((json) => {
        return dispatch(requestUpdateUserEmailEntrySuccess(json));
      })
      .catch((error) => {
        return dispatch(requestUpdateUserEmailEntryFailure(error));
      });
  };
}
