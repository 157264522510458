"use strict";

import React from "react";
import { Alert } from "react-bootstrap";

const FeedbackBanner = React.createClass({
  propTypes: {
    showGlobally: React.PropTypes.bool,
  },

  showFeedbackModal(event) {
    event.preventDefault();
    this.props.showModal("appModal", {
      modalNotifications: "helpFeedback",
      modalContent: true,
    });
  },

  render() {
    if (!this.props.showGlobally || this.props.isDismissed) return null;
    return (
      <Alert
        variant="success"
        onClose={this.props.dismiss}
        className="mb-0 alert-dismissible d-flex flex-column flex-lg-row align-items-center justify-content-center"
      >
        <Alert.Heading className="mb-0">Welcome!</Alert.Heading>
        <p className="mb-0 ms-2">
          You might have noticed that we've got a new look - please take a look
          around and let us know what you think
          <Alert.Link onClick={this.showFeedbackModal} href="#">
            {" "}
            here!
          </Alert.Link>
        </p>
        <button
          onClick={this.props.dismiss}
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        />
      </Alert>
    );
  },
});

export default FeedbackBanner;
