"use strict";

import _ from "lodash";
import React from "react";
import { DateQuestion } from "components/fields/date-question";
import { LongTextQuestion } from "components/fields/long-text-question";
import { MultipleChoiceNumberQuestion } from "components/fields/multiple-choice-number-question";
import { MultipleChoiceTableQuestion } from "components/fields/multiple-choice-table-question";
import { MultipleChoiceQuestion } from "components/fields/multiple-choice-question";
import NumberNeverQuestion from "components/fields/NumberNeverQuestion.component";
import { NumberTableNumberQuestion } from "components/fields/number-table-number-question";
import { YesNoQuestion } from "components/fields/yes-no-question";
import { Checkbox } from "components/fields/checkbox";

export const Field = React.createClass({
  propTypes: {
    fieldMetadata: React.PropTypes.object.isRequired,
    field: React.PropTypes.object.isRequired,
    formatMessage: React.PropTypes.func.isRequired,
    responses: React.PropTypes.object,
    updateResponse: React.PropTypes.func.isRequired,
  },

  prepopulateAnswers(value) {
    const { fieldMetadata, updateResponse } = this.props;
    if (_.get(fieldMetadata, "prepopulate_answer")) {
      if (value == fieldMetadata.prepopulate_answer) {
        // build our response object
        var response = {};
        for (var question in fieldMetadata.prepopulate_answers) {
          response[question] = fieldMetadata.prepopulate_answers[question];
        }
        // update responses
        updateResponse(response);
      }
    }
  },

  render() {
    const {
        fieldMetadata,
        field,
        responses,
        updateResponse,
        formatMessage,
        userProfile,
      } = this.props,
      hasInterpolation =
        !!this.props.text?.defaultMessage?.match(/\{([^]+)\}/g);
    let showField = true;
    if (
      _.get(fieldMetadata, "conditional_key") &&
      _.get(fieldMetadata, "conditional_value")
    ) {
      if (_.get(fieldMetadata, "conditional_comparison")) {
        if (fieldMetadata.conditional_comparison == "<") {
          if (typeof responses[fieldMetadata.conditional_key] !== "undefined") {
            if (
              _.get(responses, fieldMetadata.conditional_key) >=
              fieldMetadata.conditional_value
            ) {
              showField = false;
            }
          } else {
            showField = false;
          }
        } else if (fieldMetadata.conditional_comparison == ">") {
          if (typeof responses[fieldMetadata.conditional_key] !== "undefined") {
            if (
              _.get(responses, fieldMetadata.conditional_key) <=
              fieldMetadata.conditional_value
            ) {
              showField = false;
            }
          } else {
            showField = false;
          }
        }
      } else {
        if (
          _.get(responses, fieldMetadata.conditional_key) !=
          fieldMetadata.conditional_value
        ) {
          showField = false;
        }
      }
    }
    if (showField) {
      if (responses && field && fieldMetadata && fieldMetadata.id)
        field.value = responses[fieldMetadata.id];
      const commonProps = {
        key: fieldMetadata.id,
        field,
        fieldName: fieldMetadata.id,
        hasInterpolation,
        updateResponse,
        text: fieldMetadata.text,
      };
      switch (fieldMetadata.field_type) {
        case "date":
          return (
            <DateQuestion
              formatMessage={formatMessage}
              placeholder_text={fieldMetadata.placeholder_text}
              {...commonProps}
            />
          );
        case "long_text":
          return (
            <LongTextQuestion
              formatMessage={formatMessage}
              responses={responses}
              bootstrap={true}
              {...commonProps}
            />
          );
        case "multiple_choice":
          return (
            <MultipleChoiceQuestion
              options={fieldMetadata.options}
              answer_options={fieldMetadata.answer_options}
              answer_option_key={fieldMetadata.answer_option_key}
              options_data_key={fieldMetadata.options_data_key}
              infobug={fieldMetadata.infobug}
              responses={responses}
              prepopulateAnswers={this.prepopulateAnswers}
              userProfile={userProfile}
              {...commonProps}
            />
          );
        case "multiple_choice_table":
          return (
            <MultipleChoiceTableQuestion
              options={fieldMetadata.options}
              {...commonProps}
            />
          );
        case "multiple_choice_number":
          return (
            <MultipleChoiceNumberQuestion
              options={fieldMetadata.options}
              extreme_first={fieldMetadata.extreme_first}
              extreme_last={fieldMetadata.extreme_last}
              responses={responses}
              {...commonProps}
            />
          );
        case "number_table_number":
          return (
            <NumberTableNumberQuestion
              formatMessage={formatMessage}
              min={fieldMetadata.min}
              max={fieldMetadata.max}
              placeholder_text={fieldMetadata.placeholder_text}
              responses={responses}
              {...commonProps}
            />
          );
        case "number":
          return (
            <NumberNeverQuestion
              min={fieldMetadata.min}
              max={fieldMetadata.max}
              noButtons={fieldMetadata.no_buttons}
              step={fieldMetadata.step}
              placeholder_text={fieldMetadata.placeholder_text}
              currency={fieldMetadata.currency}
              showNever={false}
              placeholder="0"
              style={{
                border: "none",
                width: 90,
                textAlign: "center",
                backgroundColor: "white",
              }}
              responses={responses}
              {...commonProps}
            />
          );
        case "number_never":
          return (
            <NumberNeverQuestion
              min={fieldMetadata.min}
              max={fieldMetadata.max}
              noButtons={fieldMetadata.no_buttons}
              step={fieldMetadata.step}
              placeholder_text={fieldMetadata.placeholder_text}
              currency={fieldMetadata.currency}
              showNever={true}
              placeholder="0"
              style={{
                border: "none",
                width: 70,
                textAlign: "center",
                backgroundColor: "white",
              }}
              responses={responses}
              {...commonProps}
            />
          );
        case "yes_no":
          return (
            <YesNoQuestion
              yes_no_values={fieldMetadata.yes_no_values}
              {...commonProps}
            />
          );
        case "checkbox":
          return <Checkbox responses={responses} {...commonProps} />;
        default:
          return null;
      }
    } else {
      return null;
    }
  },
});
