"use strict";

import {
  REQUEST_GET_TERMS,
  REQUEST_GET_TERMS_SUCCESS,
  REQUEST_GET_TERMS_FAILURE,
} from "actions/types";

export function reducer(
  state = {
    isFetching: false,
  },
  action = { type: "" }
) {
  switch (action.type) {
    case REQUEST_GET_TERMS:
      return {
        ...state,
        isFetching: true,
      };
    case REQUEST_GET_TERMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload.terms,
      };
    case REQUEST_GET_TERMS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
