"use strict";

import Cookies from "cookies-js";

import { handleResponse } from "utils/api";

export const addUnregisteredUser = () => {
  return fetch(
    CUAC_SETTINGS.API_ROOT + "/users?group_id=" + CUAC_SETTINGS.GROUP._id,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  ).then(handleResponse);
};

export const changePassword = (token, userId, oldPassword, newPassword) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/password-changes/" + userId, {
    method: "POST",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      old_password: oldPassword,
      password: newPassword,
    }),
  }).then(handleResponse);
};

export const adminAddUnregisteredUser = (token, updates, groupId) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/admin/users?group_id=" + groupId, {
    method: "POST",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updates),
  }).then(handleResponse);
};

export const adminGetUsers = (token, groupId, params = {}) => {
  const p = new URLSearchParams(params);
  return fetch(`${CUAC_SETTINGS.API_ROOT}/admin/users/` + groupId + "?" + p, {
    method: "GET",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};

export const adminUpdateUser = (token, userId, groupId, updates) => {
  return fetch(
    `${CUAC_SETTINGS.API_ROOT}/admin/users/` + userId + "?group_id=" + groupId,
    {
      method: "PATCH",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updates),
    }
  ).then(handleResponse);
};

export const adminDeactivateUser = (token, userId, groupId) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT +
      "/admin/users/deactivate/" +
      userId +
      "?group_id=" +
      groupId,
    {
      method: "PATCH",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
    }
  ).then(handleResponse);
};

export const getUser = (token, id) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/users/" + id, {
    method: "GET",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};

export const getSelf = (email, password) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/users/me", {
    method: "GET",
    headers: {
      Authorization: "Basic " + btoa(email + ":" + password),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};

export const getSelf2FA = (id, token) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT + "/users/two-factor-auth-login?user_id=" + id,
    {
      method: "POST",
      headers: {
        Authorization: "CUAC " + token,
      },
    }
  ).then(handleResponse);
};

export const getSelfSSO = (sessionKey) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/users/sso", {
    method: "GET",
    headers: {
      Authorization: "CUAC-SSO " + btoa(sessionKey),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};

export const registerUser = (
  registrationKey,
  name,
  email,
  password,
  heard_from,
  terms_id,
  auto_start_tool_id
) => {
  const body = {
    name: name,
    email: email,
    password: password,
    heard_from: heard_from,
    auto_start_tool_id: auto_start_tool_id,
  };
  if (terms_id) {
    body["terms_id"] = terms_id;
  }

  return fetch(
    CUAC_SETTINGS.API_ROOT +
      "/user-registrations/" +
      registrationKey +
      "?group_id=" +
      CUAC_SETTINGS.GROUP._id,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(handleResponse)
    .catch((error) => {
      // taking out the status code for the error message
      throw error.message;
    });
};

export const updateUser = (token, userId, updates) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT +
      "/users/" +
      userId +
      "?group_id=" +
      CUAC_SETTINGS.GROUP._id,
    {
      method: "PATCH",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updates),
    }
  ).then(handleResponse);
};

export const deactivateUser = (token, userId, data) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/user-deactivate/" + userId, {
    method: "PATCH",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(handleResponse);
};

export const addGroupToUser = (token, userId) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT +
      "/users/" +
      userId +
      "/add-group?group_id=" +
      CUAC_SETTINGS.GROUP._id,
    {
      method: "POST",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  ).then(handleResponse);
};

export const getWearableLoginCode = (token, id) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/users/one-time-login/" + id, {
    method: "GET",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};

export const dismissUserMessage = (token, id) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/users/dismiss-message/" + id, {
    method: "PATCH",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};
