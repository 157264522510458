"use strict";

import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";

import { reducer as app } from "reducers/app";
import { reducer as auth } from "reducers/auth";
import { reducer as reminders } from "reducers/reminders";
import { reducer as helpFeedback } from "reducers/help-feedback";
import { reducer as display } from "reducers/display";
import { reducer as form } from "reducers/form";
import { reducer as group } from "reducers/group";
import { reducer as messages } from "reducers/messages";
import { reducer as modal } from "reducers/modal";
import { reducer as norms } from "reducers/norms";
import { reducer as plans } from "reducers/plans";
import { reducer as notifications } from "reducers/notifications";
import { reducer as responses } from "reducers/responses";
import { reducer as subscriptions } from "reducers/subscriptions";
import { reducer as tools } from "reducers/tools";
import { reducer as terms } from "reducers/terms";
import { reducer as trackerEntries } from "reducers/tracker-entries";
import { reducer as trackerEntryStats } from "reducers/tracker-entry-stats";
import { reducer as userEmailEntry } from "reducers/user-email-entry";
import { reducer as emails } from "reducers/emails";
import { reducer as welcomeBanner } from "reducers/welcome-banner";

const rootReducer = combineReducers({
  app,
  auth,
  display,
  form,
  group,
  helpFeedback,
  messages,
  modal,
  norms,
  notifications,
  plans,
  responses,
  subscriptions,
  routing,
  terms,
  reminders,
  tools,
  trackerEntries,
  trackerEntryStats,
  userEmailEntry,
  emails,
  welcomeBanner,
});

export default rootReducer;
