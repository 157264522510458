/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";
// ========= LIBRARY COMPONENTS =========
import React from "react";
import PropTypes from "prop-types";
import createClass from "create-react-class";
Object.assign(React, {
  PropTypes,
  createClass,
});
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import Actions from "actions";

import NotificationSystem from "react-notification-system";

var Notify = React.createClass({
  // ======= REACT LIFECYCLE ===========================

  latestUID: 999999,
  _notificationSystem: null,

  componentDidMount: function () {
    this._notificationSystem = this.refs.notificationSystem;
    var notifyMessage = ReactDOM.findDOMNode(this.refs.notificationSystem);
    notifyMessage.setAttribute("role", "alert");
    notifyMessage.setAttribute("aria-live", "polite");
  },

  UNSAFE_componentWillReceiveProps: function (nextProps) {
    const notice = Object.assign(
      {
        title: "Update",
        message: "",
        level: "success",
        position: "tr",
      },
      nextProps.notifications,
    );
    this._addNotification(notice);
  },

  // ======== LOCAL METHODS ==============
  _addNotification: function (notice) {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification(notice);
    }
  },

  styles: {
    NotificationItem: {
      DefaultStyle: {
        padding: "12px 10px 32px 24px",
        borderTopWidth: "6px",
        borderBottomWidth: "2px",
        borderRadius: "0",
      },
      success: {
        WebkitBoxShadow: "1px 1px 14px rgba(0,0,0,0.2)",
        MozBoxShadow: "1px 1px 14px rgba(0,0,0,0.2)",
        boxShadow: "1px 1px 14px rgba(0,0,0,0.2)",
      },

      error: {
        WebkitBoxShadow: "1px 1px 14px rgba(0,0,0,0.2)",
        MozBoxShadow: "1px 1px 14px rgba(0,0,0,0.2)",
        boxShadow: "1px 1px 14px rgba(0,0,0,0.2)",
      },

      warning: {
        WebkitBoxShadow: "1px 1px 14px rgba(0,0,0,0.2)",
        MozBoxShadow: "1px 1px 14px rgba(0,0,0,0.2)",
        boxShadow: "1px 1px 14px rgba(0,0,0,0.2)",
      },

      info: {
        WebkitBoxShadow: "1px 1px 14px rgba(0,0,0,0.2)",
        MozBoxShadow: "1px 1px 14px rgba(0,0,0,0.2)",
        boxShadow: "1px 1px 14px rgba(0,0,0,0.2)",
      },
    },
    Dismiss: {
      DefaultStyle: {
        fontFamily: `"Montserrat", "Roboto", Helvetica, Verdana, sans-serif`,
        fontSize: "30px",
        top: "0",
        right: "0",
        backgroundColor: "rgba(0,0,0,0)",
        color: "#dededf",
        borderRadius: "0",
        width: "30px",
        height: "30px",
        fontWeight: "bold",
        textAlign: "center",
        padding: "7px 0 0 0",
        opacity: "0.6",
      },
      success: {
        color: "#b0ca92",
        backgroundColor: "rgba(0,0,0,0)",
      },

      error: {
        color: "#e4bebe",
        backgroundColor: "rgba(0,0,0,0)",
      },

      warning: {
        color: "#e1cfac",
        backgroundColor: "rgba(0,0,0,0)",
      },

      info: {
        color: "#a4becb",
        backgroundColor: "rgba(0,0,0,0)",
      },
    },
    Title: {
      DefaultStyle: {
        margin: "0 0 6px 0",
      },
    },

    MessageWrapper: {
      DefaultStyle: {
        padding: "0 0 0 2px",
      },
    },
  },

  // ======== RENDER ==============
  render: function () {
    return <NotificationSystem ref="notificationSystem" style={this.styles} />;
  },
});

export default connect((state) => ({
  notifications: state.notifications,
}))(Notify);

/* ======== NOTIFICATION SYSTEM -- MORE DOCUMENTATION:  https://github.com/igorprado/react-notification-system ===============



title 	    string 	null 	Title of the notification
message 	string 	null 	Message of the notification
level 	    string 	null 	Level of the notification. Available: success, error, warning and info
position 	string 	tr 	    Position of the notification. Available: tr (top right), tl (top left), tc (top center), br (bottom right), bl (bottom left), bc (bottom center)
autoDismiss integer 5    	Delay in seconds for the notification go away. Set this to 0 to not auto-dismiss the notification
dismissible bool 	true 	Set if notification is dismissible by the user. See more
action 	    object 	null 	Add a button with label and callback function. See more
onRemove 	fnction null 	A callback function that will be called when the notification is about to be removed. The first argument is the original notification e.g. function (notification) { console.log(notification.title + 'was removed'); }
uid 	    integer/string 	null    Overrides the internal uid. Useful if you are managing your notifications id. Notifications with same uid won't be displayed.

 =========================== END NOTES -- NOTIFICATION SYSTEM =========================== */
