/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";
import React, { useEffect, useState } from "react";
import { NumberInput } from "../blocks/NumericInput.component.web";
import { Form } from "react-bootstrap";
import FieldLabel from "../blocks/FieldLabel.component";
import { StyleProp } from "react-native";
import { injectIntl } from "react-intl";
import { BLUE } from "../../stylesheets/colors";
import { FormattedMessage } from "react-intl";

type Props = {
  showNever?: boolean;
  fieldName: string;
  placeholder?: string;
  field?: {
    value?: string | number;
  };
  updateResponse: (data: { [key: string]: number | string }) => void;
  responses: {
    [key: string]: string | number;
  };
  style?: StyleProp<any>;
  text?: string | React.ReactNode;
  placeholder_text?: string;
  step?: number | string;
  min?: number | string;
  max?: number | string;
  intl: any;
  currency?: boolean;
};

const NumberNeverQuestion = (props: Props) => {
  const [checked, setChecked] = useState(undefined);

  useEffect(() => {
    if (!props.showNever) return;
    const { value } = props.field || {};
    if (checked === undefined && typeof value === "number")
      setChecked(!value ? 0 : -1);
  }, [props.field?.value]);

  useEffect(() => {
    if (!props.showNever) return;
    if (checked === 0) props.updateResponse({ [props.fieldName]: 0 });
  }, [checked]);

  const onChange = (val) => {
    props.updateResponse({ [props.fieldName]: val });
    if (props.showNever && typeof val === "number" && !!val) setChecked(-1);
  };

  return (
    <div>
      {props.text && <FieldLabel text={props.text} id={props.fieldName} />}
      <div className="d-flex align-items-center justify-content-start">
        <div className="">
          <NumberInput
            name={props.fieldName}
            onChange={onChange}
            value={(props.responses || {})[props.fieldName]}
            style={
              props.style || { border: "none", width: 70, textAlign: "center" }
            }
            label={
              typeof props.placeholder_text === "object"
                ? props.intl.formatMessage(props.placeholder_text)
                : props.placeholder_text
            }
            labelClassName="text-muted me-2"
            placeholder={props.placeholder}
            step={props.step}
            min={props.min}
            max={props.max}
            currency={props.currency}
          />
        </div>
        {props.showNever && (
          <div className="flex-grow-1 ms-3">
            <Form.Check type="checkbox" id={`cb_never_${props.fieldName}`}>
              <Form.Check.Input
                type="checkbox"
                value={0}
                checked={checked === 0}
                onChange={() => setChecked(!checked ? -1 : 0)}
                style={{
                  backgroundColor: checked === 0 ? BLUE : "white",
                  borderColor: checked === 0 ? BLUE : "#959595",
                }}
              />
              <Form.Check.Label className="ms-2">
                <FormattedMessage
                  id="number_never_checkbox_label"
                  defaultMessage="Never"
                  description="Checkbox label for number never question"
                />
              </Form.Check.Label>
            </Form.Check>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(NumberNeverQuestion);
