"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";

export const DynamicUnorderedList = React.createClass({
  render() {
    const { data_key, responses, title, className } = this.props;
    const response = _.get(responses, data_key, []);
    if (_.isEmpty(response)) return null;
    return (
      <div className={`${className || ""}`}>
        {title && (
          <h3>
            <FormattedMessage {...title} />
          </h3>
        )}
        <ul>
          {response.map((response, index) => {
            return <li key={data_key + "_" + index}>{response}</li>;
          })}
        </ul>
      </div>
    );
  },
});
