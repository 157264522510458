/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage, FormattedDate, FormattedTime } from "react-intl";
import InfoBug from "components/popover/infobug";
import { connect } from "react-redux";
import { TrackerTypes } from "api/tracker-entries";
import Actions from "actions";
import Accordion from "components/ux/accordion";
import Button from "components/ux/mdl/button";
import ButtonConfirm from "components/ux/button-confirm";
import Paginator from "lib/Paginator";
import Moment from "moment";
Moment.locale("en");
import momentTZ from "lib/moment-timezone-with-data";
import { itemsPerPage } from "utils/pagination";
import { PrintButton } from "components/elements/print-button";
import {
  faChevronCircleDown,
  faChevronCircleRight,
  faTimes,
  faEdit as faPencilSquareO,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
export const AgreementList = React.createClass({
  propTypes: {
    tag: React.PropTypes.string,
    trackerEntries: React.PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      openEntries: [],
      displayedEntries: {
        start: 0,
        end: 5,
      },
    };
  },

  toggleEntry(index) {
    let openEntries = this.state.openEntries;
    if (openEntries.indexOf(index) == -1) {
      openEntries.push(index);
    } else {
      openEntries = _.without(openEntries, index);
    }

    this.setState({
      openEntries: openEntries,
    });
  },

  addAgreementTrackerEntry() {
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "newAgreementTracker",
        modalContent: true,
      }),
    );
  },

  editAgreementTrackerEntry(trackerEntryId, trackerEntry) {
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "editAgreementTracker",
        agreementEntry: {
          _id: trackerEntryId,
          entry: trackerEntry,
        },
        modalContent: true,
      }),
    );
  },

  deleteAgreementTrackerEntry(trackerEntryId, currentPage, totalEntries) {
    this.props.dispatch(Actions.updateDisplay("appSpinner", "PLEASE_WAIT"));
    const { auth, dispatch } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    dispatch(
      Actions.deleteTrackerEntry(
        token,
        userId,
        trackerEntryId,
        TrackerTypes.AGREEMENT,
      ),
    ).then(() => {
      this.updateCurrentPage(currentPage, totalEntries);
      dispatch(Actions.updateDisplay("appSpinner", "STOP"));
    });
  },

  updateCurrentPage(currentPage, totalEntries) {
    if (currentPage.start >= itemsPerPage && totalEntries - 1 === 0) {
      this.setState({
        displayedEntries: {
          start: this.state.displayedEntries.start - itemsPerPage,
          end: this.state.displayedEntries.end - itemsPerPage,
        },
      });
    }
  },

  onChangePage(page) {
    var end = page * itemsPerPage;
    var start = end - itemsPerPage;
    this.setState({
      displayedEntries: {
        start: start,
        end: end,
      },
    });
  },

  render() {
    const { tag, trackerEntries } = this.props;
    const locus = this,
      openEntries = this.state.openEntries,
      toggleEntry = this.toggleEntry;
    const fullOrder = _.get(
      trackerEntries,
      TrackerTypes.AGREEMENT + "Order",
      [],
    );
    const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
      return _.get(trackerEntries, trackerEntryId, {});
    });
    const filteredEntries =
      typeof tag !== "undefined"
        ? _.filter(fullOrderEntries, (trackerEntry) => {
            return _.get(trackerEntry, "tag") === tag;
          })
        : fullOrderEntries;
    const page = {
      start: this.state.displayedEntries.start,
      end: this.state.displayedEntries.end,
    };
    const sortedEntries = _.sortByOrder(filteredEntries, ["dt_u"], ["desc"]);
    const orderEntries = sortedEntries.slice(page.start, page.end);

    const { auth } = this.props;
    const user_timezone = _.get(auth, "user.user_timezone");

    const confirmMessage = {
      id: "delete_agreement_tracker_confirm_instructions",
      description: "Agreement Tracker -- Delete entry, confirmation message",
      defaultMessage: "Are you sure you want to delete this tracker entry?",
    };

    const printButtonText = {
      id: "print-button",
      defaultMessage: "Click here to print",
    };

    return (
      <div
        className="section section-tracker-list col-1-1"
        style={{ marginTop: "52px" }}
      >
        <div
          className="contain-box-wrap"
          style={{ maxWidth: "690px", marginTop: "20px" }}
        >
          <div className="contain-box-main">
            <h1 className="page-title contain-box-title">
              <FormattedMessage
                id="agreement_list_title"
                defaultMessage="Your Agreements"
              />
            </h1>
            <div className="tracker-list-data">
              <div className="tracker-episode header">
                <div className="tracker-row">
                  <div className="tracker-entry tracker-entry-date-time">
                    <FormattedMessage
                      id="agreement_data_header_title"
                      defaultMessage="Title"
                    />
                  </div>
                  <div className="tracker-entry tracker-entry-date-time">
                    <FormattedMessage
                      id="agreement_data_header_date"
                      defaultMessage="Start Date"
                    />
                  </div>
                  <div className="tracker-entry tracker-entry-type">
                    <FormattedMessage
                      id="agreement_data_header_area"
                      defaultMessage="# of Days"
                    />
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
              {orderEntries.map((fullTrackerEntry, index) => {
                const trackerEntry = _.get(fullTrackerEntry, "data", {});

                var dtStarted = moment(
                  trackerEntry.dt_start_date.split("+")[0],
                ).format("YYYY-MM-DD HH:mm:ss");
                dtStarted = momentTZ.tz(dtStarted, "UTC");
                var dateStarted = dtStarted.clone().tz(user_timezone);
                dateStarted = dateStarted.format("MMM DD, YYYY");

                const isOpen = openEntries.indexOf(index) !== -1,
                  accordionButton =
                    "accordion-toggle" +
                    (isOpen ? " open" : "") +
                    " tracker-entry-btn",
                  accordionButtonIcon = isOpen
                    ? faChevronCircleDown
                    : faChevronCircleRight;

                return !_.isEmpty(trackerEntry) ? (
                  <div className="tracker-episode" key={"agreement_" + index}>
                    <div className="tracker-row">
                      <div className="tracker-entry tracker-entry-title list-content plan-list">
                        {trackerEntry.title}
                      </div>
                      <div className="tracker-entry tracker-entry-title list-content plan-list">
                        {dateStarted}
                      </div>
                      <div className="tracker-entry tracker-entry-title list-content plan-list">
                        {trackerEntry.how_long}
                      </div>
                      <Button
                        className={accordionButton}
                        flat={true}
                        onClick={toggleEntry.bind(locus, index)}
                      >
                        <FontAwesomeIcon icon={accordionButtonIcon} />
                      </Button>
                      <div className="tracker-delete-btn">
                        <ButtonConfirm
                          flat={false}
                          noColor={false}
                          ripple={true}
                          message={confirmMessage}
                          className="tracker-entry-btn tracker-action-btn"
                          onClick={(event) =>
                            this.deleteAgreementTrackerEntry(
                              fullTrackerEntry._id,
                              page,
                              orderEntries.length,
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} />
                          <FormattedMessage
                            id="delete_agreement_tracker_button"
                            description="Delete Agreement Tracker Entry"
                            defaultMessage="Delete"
                          />
                        </ButtonConfirm>
                      </div>
                      <Button
                        className="tracker-entry-btn tracker-action-btn arrange-tracker-btn"
                        key={"edit" + fullTrackerEntry._id}
                        id={fullTrackerEntry._id}
                        onClick={() =>
                          this.editAgreementTrackerEntry(
                            fullTrackerEntry._id,
                            trackerEntry,
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faPencilSquareO} />
                        <span className="hidden-sm">Edit</span>
                      </Button>
                      <div className="clearfix" />
                      <Accordion
                        collapsed={!isOpen}
                        className="tracker-entry-block"
                      >
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="agreement_data_popover_title_label"
                              defaultMessage="Title"
                            />
                          </span>
                          {trackerEntry.title}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="agreement_data_popover_start_date_label"
                              defaultMessage="Start Date"
                            />
                          </span>
                          {dateStarted}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="agreement_data_popover_how_long_label"
                              defaultMessage="How Long"
                            />
                          </span>
                          {trackerEntry.how_long} days
                        </p>
                        <PrintButton text={printButtonText} />
                      </Accordion>
                      <div className="clearfix" />
                    </div>
                  </div>
                ) : null;
              })}
              <div className="mdl-layout__tab-bar-right-button addNewTrackerBtn">
                <Button
                  key="button"
                  ref="menuButton"
                  onClick={this.addAgreementTrackerEntry}
                >
                  <FontAwesomeIcon icon={faPlusSquare} />
                  Add New
                </Button>
              </div>
            </div>
            {orderEntries.length > 0 ? (
              <Paginator
                className={"pagination"}
                page={this.state.displayedEntries.end / itemsPerPage}
                max={Math.ceil(fullOrder.length / itemsPerPage)}
                onChange={this.onChangePage}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  },
});
export default connect((state) => ({
  auth: state.auth,
}))(AgreementList);
