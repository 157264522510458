import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Actions from "actions";
import Button from "components/ux/mdl/button";
import Moment from "moment";
Moment.locale("en");
import Questionnaire from "../../components/blocks/questionnaire";
import _ from "lodash";

const TypeAmountModal = React.createClass({
  closeModal() {
    this.props.dispatch(
      Actions.closeModal("appModal", {
        modalNotifications: "",
        modalContent: false,
      }),
    );
  },

  render() {
    const { diff, time, updateResponse, day, responses } = this.props;

    const typeOptions = [
      {
        text: {
          id: "day_" + diff + "_form_joint",
          defaultMessage: "Joint or Blunt",
        },
        value: "joint",
      },
      {
        text: {
          id: "day_" + diff + "_form_pipe",
          defaultMessage: "Pipe or Bong",
        },
        value: "pipe",
      },
      {
        text: {
          id: "day_" + diff + "_form_vapor",
          defaultMessage: "Vaporized",
        },
        value: "vapor",
      },
      {
        text: {
          id: "day_" + diff + "_form_oral",
          defaultMessage: "Consumed Orally (Edible)",
        },
        value: "oral",
      },
      {
        text: { id: "day_" + diff + "_form_other", defaultMessage: "Other" },
        value: "other",
      },
    ];

    const quantityOptions = [
      {
        text: {
          id: "day_" + diff + "_quantity_a_bit",
          defaultMessage: "Just a Bit",
        },
        value: "a_bit",
      },
      {
        text: {
          id: "day_" + diff + "_quantity_moderate",
          defaultMessage: "A Moderate Amount",
        },
        value: "moderate",
      },
      {
        text: {
          id: "day_" + diff + "_quantity_heavy",
          defaultMessage: "Heavy Dose",
        },
        value: "heavy",
      },
      {
        text: {
          id: "day_" + diff + "_quantity_too_much",
          defaultMessage: "Too Much",
        },
        value: "too_much",
      },
    ];

    const fields = [
      {
        id: "day_" + diff + "_" + time.id + "_form",
        text: { id: "form_consumed", defaultMessage: "Form Consumed:" },
        field_type: "multiple_choice",
        options: typeOptions,
      },
      {
        id: "day_" + diff + "_" + time.id + "_quantity",
        text: { id: "quantity_consumed", defaultMessage: "Quantity Consumed:" },
        field_type: "multiple_choice",
        options: quantityOptions,
      },
      {
        id: "day_" + diff + "_" + time.id + "_hours_affected",
        text: {
          id: "hours_affected",
          defaultMessage: "How Many Hours You Were Affected:",
        },
        field_type: "number",
        min: "0",
        max: "24",
        placeholder_text: {
          defaultMessage: "# of hours",
          id: "number_of_hours",
        },
      },
    ];

    return (
      <div>
        <p>
          <strong>Date:</strong> {day.format("dddd, MMMM Do, YYYY")}
        </p>
        <p>{time.defaultMessage}</p>
        <Questionnaire
          fields={fields}
          updateResponse={updateResponse}
          responses={_.get(
            responses[responses.activeResponseId],
            "response.responses",
          )}
          form_name={"TestForm"}
        />
        <Button
          ripple={true}
          raised={true}
          colored={true}
          onClick={this.closeModal}
        >
          <FormattedMessage id={"close"} defaultMessage={"Close"} />
        </Button>
      </div>
    );
  },
});

export default connect((state) => ({
  responses: state.responses,
}))(TypeAmountModal);
