"use strict";

import _ from "lodash";

import {
  REQUEST_GET_REMINDERS,
  REQUEST_GET_REMINDERS_SUCCESS,
  REQUEST_GET_REMINDERS_FAILURE,
  REQUEST_ADD_REMINDER,
  REQUEST_ADD_REMINDER_SUCCESS,
  REQUEST_ADD_REMINDER_FAILURE,
  REQUEST_UPDATE_REMINDER,
  REQUEST_UPDATE_REMINDER_SUCCESS,
  REQUEST_UPDATE_REMINDER_FAILURE,
  REQUEST_DELETE_REMINDER,
  REQUEST_DELETE_REMINDER_SUCCESS,
  REQUEST_DELETE_REMINDER_FAILURE,
  REQUEST_LOG_OUT_USER_SUCCESS,
} from "actions/types";

export function reducer(
  state = {
    isFetching: false,
    activeResponseId: "",
  },
  action = { type: "" },
) {
  switch (action.type) {
    case REQUEST_GET_REMINDERS:
      return {
        ...state,
        isFetching: true,
      };
    case REQUEST_GET_REMINDERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        reminders: action.payload.reminders,
      };
    case REQUEST_GET_REMINDERS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case REQUEST_ADD_REMINDER:
      return {
        ...state,
        isFetching: true,
      };
    case REQUEST_ADD_REMINDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case REQUEST_ADD_REMINDER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case REQUEST_UPDATE_REMINDER:
      return {
        ...state,
        isFetching: true,
      };
    case REQUEST_UPDATE_REMINDER_SUCCESS:
      var updatedReminders = _.clone(state.reminders);
      _.remove(updatedReminders, function (x) {
        return x._id === action.payload.reminders._id;
      });
      updatedReminders.push(action.payload.reminders);
      return {
        ...state,
        isFetching: false,
        reminders: updatedReminders,
      };
    case REQUEST_UPDATE_REMINDER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case REQUEST_DELETE_REMINDER:
      return {
        ...state,
        isFetching: true,
      };
    case REQUEST_DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case REQUEST_DELETE_REMINDER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case REQUEST_LOG_OUT_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        reminders: [],
      };
    default:
      return state;
  }
}
