"use strict";

import _ from "lodash";
import { updateResponse as updateResponseAPI } from "api/responses";
import Actions from "actions";

import {
  REQUEST_UPDATE_RESPONSE,
  REQUEST_UPDATE_RESPONSE_SUCCESS,
  REQUEST_UPDATE_RESPONSE_FAILURE,
  REQUEST_SUBMIT_UPDATES,
  REQUEST_SUBMIT_UPDATES_SUCCESS,
  REQUEST_SUBMIT_UPDATES_FAILURE,
  CACHE_UPDATE,
} from "actions/types";

// Cache an update in the responses 'updates' object. This is a temp container for a the page's
// responses, which can be bulk submitted on page change
export const cacheUpdate = (responseId, response) => ({
  type: CACHE_UPDATE,
  payload: { responseId, response },
});

const buildResponsePayload = (
  state,
  responseId,
  updates,
  tool,
  module,
  section,
  page,
) => {
  let lastURL = null,
    progress = {};
  if (module?.id && section?.id && page?.id) {
    lastURL =
      "/" +
      tool.slug +
      "/" +
      module.slug +
      "/" +
      section.slug +
      "/" +
      page.slug;
    progress = progressPayload(
      state,
      responseId,
      module.id,
      section.id,
      page.id,
      _.mapValues(updates, () => true),
      lastURL,
    );
  }
  return { responses: updates, progress };
};

// Bulk submit all the user's response updates from the current page
export const submitUpdates =
  (token, userId, responseId, module, section, page) =>
  async (dispatch, getState) => {
    dispatch({ type: REQUEST_SUBMIT_UPDATES });
    try {
      const state = getState(),
        { [responseId]: response } = state.responses || {},
        { updates, tool_id } = response?.response ?? {},
        { [tool_id]: tool } = state.tools || {},
        payload = buildResponsePayload(
          getState(),
          responseId,
          updates,
          tool,
          module,
          section,
          page,
        );
      const json = await updateResponseAPI(
        token,
        responseId,
        userId,
        tool._id,
        payload,
      );
      return dispatch({
        type: REQUEST_SUBMIT_UPDATES_SUCCESS,
        payload: { responseId, response: json },
      });
    } catch (e) {
      dispatch(
        Actions.notify({
          title: "Oops...",
          message: "An error occurred while attempting to save your responses.",
          level: "error",
          autoDismiss: 4,
        }),
      );
      return dispatch({ type: REQUEST_SUBMIT_UPDATES_FAILURE });
    }
  };

function requestUpdateResponse(responseId, responses, progress) {
  return {
    type: REQUEST_UPDATE_RESPONSE,
    payload: {
      responseId,
      responses,
      progress,
    },
  };
}

function requestUpdateResponseSuccess(responseId, response) {
  return {
    type: REQUEST_UPDATE_RESPONSE_SUCCESS,
    payload: {
      responseId,
      response,
    },
  };
}

function requestUpdateResponseFailure(error) {
  return {
    type: REQUEST_UPDATE_RESPONSE_FAILURE,
    payload: error,
    error: true,
  };
}

const progressPayload = (
  state,
  responseId,
  moduleId,
  sectionId,
  pageId,
  updates,
  url,
) => {
  const { [responseId]: responseObj } = state.responses,
    { response } = responseObj,
    { progress, tool_id } = response || {},
    { [tool_id]: tool } = state.tools,
    { default_progress } = tool || {},
    now = new Date().toISOString();

  const progressModules = Object.keys(default_progress.modules).reduce(
    (acc, moduleKey) => {
      const { [moduleKey]: defaultModule } = default_progress.modules,
        { [moduleKey]: progressModule } = progress.modules || {},
        { sections: defaultSections } = defaultModule,
        { sections: progressSections } = progressModule,
        moduleWasVisited = moduleKey === moduleId;
      const sectionsRet = Object.keys(defaultSections || {}).reduce(
        (sectionAcc, sectionKey) => {
          const { [sectionKey]: defaultSection } = defaultSections,
            { pages: defaultPages } = defaultSection || {},
            { [sectionKey]: progressSection } = progressSections || {},
            { pages: progressPages } = progressSection || {},
            sectionWasVisited = moduleWasVisited && sectionKey === sectionId;
          const pagesRet = Object.keys(defaultPages || {}).reduce(
            (pagesAcc, pageKey) => {
              const { [pageKey]: defaultPage } = defaultPages || {},
                { [pageKey]: progressPage } = progressPages || {};
              const pageWasVisited = sectionWasVisited && pageKey === pageId;
              return {
                ...pagesAcc,
                [pageKey]: {
                  visited:
                    pageWasVisited ||
                    (progressPage?.visited ?? defaultPage.visited),
                  dt_updated: pageWasVisited
                    ? now
                    : progressPage?.dt_updated || defaultPage.dt_updated,
                  responses: Object.keys(defaultPage?.responses || {}).reduce(
                    (resAcc, responseKey) => {
                      const { [responseKey]: progressResponse } =
                        progressPage?.responses ?? {};
                      return {
                        ...resAcc,
                        [responseKey]:
                          progressResponse ||
                          defaultPage.responses[responseKey],
                        ...(pageWasVisited ? updates : {}),
                      };
                    },
                    {},
                  ),
                },
              };
            },
            {},
          );
          return {
            ...sectionAcc,
            [sectionKey]: {
              visited:
                sectionWasVisited ||
                (progressSection?.visited ?? defaultSection.visited),
              dt_updated: sectionWasVisited
                ? now
                : (progressSection?.dt_updated ?? defaultSection.dt_updated),
              pages: pagesRet,
            },
          };
        },
        {},
      );
      return {
        ...acc,
        [moduleKey]: {
          visited:
            moduleWasVisited ||
            (progressModule?.visited ?? defaultModule.visited),
          dt_updated: moduleWasVisited
            ? now
            : (progressModule?.dt_updated ?? defaultModule.dt_updated),
          sections: sectionsRet,
        },
      };
    },
    {},
  );

  return {
    last_updated_url: url,
    visited: true,
    dt_updated: now,
    modules: progressModules,
  };
};

// Submit updates from an individual response change
export default function updateResponse(
  token,
  userId,
  responseId,
  updates,
  tool,
  module,
  section,
  page,
) {
  return (dispatch, getState) => {
    const payload = buildResponsePayload(
      getState(),
      responseId,
      updates,
      tool,
      module,
      section,
      page,
    );
    dispatch(requestUpdateResponse(responseId, updates, payload.progress));
    return updateResponseAPI(token, responseId, userId, tool._id, payload)
      .then((json) => {
        return dispatch(requestUpdateResponseSuccess(responseId, json));
      })
      .catch((error) => {
        dispatch(
          Actions.notify({
            title: "Oops...",
            message:
              "An error occurred while attempting to save your response.",
            level: "error",
            autoDismiss: 4,
          }),
        );
        return dispatch(requestUpdateResponseFailure(error));
      });
  };
}
