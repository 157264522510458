import React from "react";
import IconParty from "css/assets/icon-party.png";
import { Image } from "react-native";
import BSButton from "./BSButton.component.web";

type Props = {
  auth: any;
  subscriptions: any[];
  onChangePlan: () => void;
  activeSubscription?: any;
  containerClassName?: string;
};

const PlanSelect = (props: Props) => {
  const plan = () =>
    props.activeSubscription?.plan ?? props.auth?.selectedPlan?.stripe_data;

  const planName = () => {
    const { name } = plan() || {};
    return name;
  };

  const planAmount = () => {
    if (!plan()) return "";
    const { amount } = plan();
    return `$${(amount / 100).toFixed(2)}`;
  };

  return (
    <div
      className={`card text-white bg-theme-blue ${
        props.containerClassName || ""
      }`}
    >
      <div className="card-body p4">
        <div className="card-text d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            {/* <Image style={{ width: 48, height: 48 }} source={IconParty} /> */}

            {plan() ? (
              <div className="div plan-list pr-3 d-flex flex-column">
                <p className="text-uppercase mb-0">Current Plan</p>
                <p className="text-small mb-0">
                  {planName()} - <strong>{planAmount()}</strong>
                </p>
              </div>
            ) : (
              <div className="div plan-list pr-3 d-flex flex-column">
                <p className="text-uppercase mb-0">No plan selected</p>
              </div>
            )}
          </div>
          <BSButton variant="flat-blue" onClick={props.onChangePlan}>
            Change
          </BSButton>
        </div>
      </div>
    </div>
  );
};

export default PlanSelect;
