"use strict";

import _ from "lodash";

import { getTrackerEntryStats as getTrackerEntryStatsAPI } from "api/tracker-entry-stats";

import {
  REQUEST_GET_TRACKER_ENTRY_STATS,
  REQUEST_GET_TRACKER_ENTRY_STATS_SUCCESS,
  REQUEST_GET_TRACKER_ENTRY_STATS_FAILURE,
} from "actions/types";

function requestGetTrackerEntryStats() {
  return {
    type: REQUEST_GET_TRACKER_ENTRY_STATS,
  };
}

function requestGetTrackerEntryStatsSuccess(json) {
  return {
    type: REQUEST_GET_TRACKER_ENTRY_STATS_SUCCESS,
    payload: {
      trackerEntryStats: _.get(json, "[0]", {}),
    },
  };
}

function requestGetTrackerEntryStatsFailure(error) {
  return {
    type: REQUEST_GET_TRACKER_ENTRY_STATS_FAILURE,
    payload: error,
    error: true,
  };
}

export default function getTrackerEntryStats(
  token,
  userId,
  trackerTypeName,
  timeout = 0,
) {
  return (dispatch) => {
    dispatch(requestGetTrackerEntryStats());
    setTimeout(() => {
      return getTrackerEntryStatsAPI(token, userId, trackerTypeName)
        .then((json) => {
          return dispatch(requestGetTrackerEntryStatsSuccess(json));
        })
        .catch((error) => {
          return dispatch(requestGetTrackerEntryStatsFailure(error));
        });
    }, 1000 * timeout);
  };
}
