"use strict";

import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { injectIntl } from "react-intl";
import { Field as OurField } from "../fields/field";

const Questionnaire = React.createClass({
  propTypes: {
    form_name: React.PropTypes.string.isRequired,
    fields: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    formulas: React.PropTypes.arrayOf(React.PropTypes.object),
    responses: React.PropTypes.object.isRequired,
    updateResponse: React.PropTypes.func.isRequired,
  },

  fieldsListToFieldsObject(fields) {
    return fields.reduce((obj, o) => {
      obj[o.id] = o;
      return obj;
    }, {});
  },

  render() {
    const { fields } = this.props;
    const { activeResponseId } = this.props.responses,
      { [activeResponseId]: activeResponse } = this.props.responses;
    const responses = activeResponse?.response?.responses ?? {};
    const newFields = fields.map((f) => {
      const { [f.id]: val } = responses || {};
      return {
        ...f,
        value: val,
      };
    });
    return newFields.map((f) => (
      <div className="my-4" key={f.id}>
        <Field
          updateResponse={this.updateResponse}
          formatMessage={this.props.intl.formatMessage}
          responses={responses}
          fieldMetadata={f}
          component={OurField}
          field={f}
          name={f.id}
          {...f}
        />
      </div>
    ));
  },

  updateResponse(data) {
    const { formulas, updateResponse } = this.props;
    updateResponse(data, formulas);
  },
});

const form = reduxForm({
  form: "questionnaire",
})(injectIntl(Questionnaire));
export default connect((state) => ({
  responses: state.responses,
  activeResponse: state.user?.activeResponse,
}))(form);
