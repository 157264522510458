import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import BSButton from "../blocks/BSButton.component.web";
import { connect } from "react-redux";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import Actions from "actions";
import { MaterialCommunityIcons } from "@expo/vector-icons";

type Props = {
  onSubmit: (cardTokenID: string) => void;
  notify: (data: any) => void;
  loading?: boolean;
};

const CardForm = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const elements = useElements();
  const stripe = useStripe();

  const onSubmit = async () => {
    setLoading(true);
    const card = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(card);
    if (error)
      props.notify({
        title: "Oops...",
        message: error.message || "An error occurred!",
        level: "error",
        autoDismiss: 5,
      });
    else props.onSubmit(token.id);
    setLoading(false);
  };

  return (
    <div className="d-flex flex-column">
      <div className="form-group">
        <label>Credit or debit card</label>
        <div
          className="my-3 form-control form-control-lg"
          style={{ backgroundColor: "#e9ecef", border: "none" }}
        >
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "1.25rem",
                  paddingVertical: ".5rem",
                },
              },
            }}
          />
        </div>
      </div>
      <div>
        <BSButton
          variant="green"
          onClick={onSubmit}
          loading={loading || props.loading}
          disabled={props.loading}
        >
          <MaterialCommunityIcons
            name="credit-card-plus"
            size={20}
            color="white"
          />
          <span className="ms-2">Save Card</span>
        </BSButton>
      </div>
    </div>
  );
};

export default connect((state) => ({}), {
  notify: Actions.notify,
})(injectIntl(CardForm));
