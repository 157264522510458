/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect, useSelector } from "react-redux";
import { browserHistory } from "react-router";
import Moment from "moment";
Moment.locale("en");
import Actions from "actions";
import Selectors from "selectors";
const { userNormsSelector, userProfileSelector } = Selectors;
import { Accordion } from "react-bootstrap";
import { canUseTool } from "utils/render";
import Cookies from "cookies-js";
import notify from "actions/notify";
import TrackerNav from "../../components/blocks/TrackerNav.component.web";
import BSButton from "../../components/blocks/BSButton.component.web";
import { BLUE, LIGHT_FOREST_GREEN } from "../../stylesheets/colors";
import Loading from "../../components/blocks/Loading.component.web";
import BrandedImage from "../../components/blocks/branded-image";
import UniversalCookies from "universal-cookie";
import { ToolItem } from "./ToolItem";
import { isFetchingToolsSelector, toolByStatusSelector } from "selectors/tool";

const cookies = new UniversalCookies();

const OverviewPage = (props) => {
  const [responses, setResponses] = useState(undefined);
  const [processingButton, setProcessingButton] = useState(false);

  const isFetchingTools = useSelector(isFetchingToolsSelector);
  const toolsByStatus = useSelector(toolByStatusSelector);

  useEffect(() => {
    const toolId = props.activeResponse?.tool_id;

    if (props.auth.user && toolId) {
      const { _id: userId, token } = props.auth.user;

      (async () => {
        const emailResponses = [...(responses || [])];
        // The initialization function for the guided emails switch value.
        const json = await props.dispatch(
          Actions.getUserEmailEntry(token, userId, toolId)
        );
        const payload = json.payload.response;
        if (payload)
          emailResponses.push({
            id: toolId,
            allow_email: payload.allow_email,
            showToggle: true,
          });
        else
          emailResponses.push({
            id: toolId,
            allow_email: false,
            showToggle: true,
          });
        setResponses(responses);
      })();
    }
  }, [props.auth.user, props.activeResponse?.tool_id]);

  useEffect(() => {
    props.dispatch(Actions.updateDisplay("appSpinner", "STOP"));
    if (!isOnOwnSubdomain()) {
      props.dispatch(
        Actions.showModal("appModal", {
          modalNotifications: "wrongSubdomain",
          modalContent: true,
        })
      );
    } else {
      if (
        CUAC_SETTINGS.GROUP.code &&
        props.location.pathname === "/" &&
        props.auth.user.tool_ids.length < 4
      ) {
        if (!Cookies.get("shown_coupon")) showUpgradeModal();
        else if (
          _.get(props.auth, "user.roles.subscriber") &&
          props.auth.user.roles.subscriber.indexOf(CUAC_SETTINGS.GROUP._id) !==
            -1
        ) {
          props.dispatch(
            Actions.notify({
              title: "Ready to make changes in your life?",
              message:
                "Upgrade to our Choices Program to get help in the “how to” of cutting back or quitting.",
              level: "success",
              autoDismiss: 20,
              action: {
                label: "Upgrade Now",
                callback: function () {
                  browserHistory.push("/account/plans");
                },
              },
            })
          );
        }
      }
    }
  }, []);

  const renderPrintProgress = () => {
    const {
      auth: { user },
      tools,
      responses,
      plans,
    } = props;
    let toolArray = [];
    for (let key in tools) {
      if (
        typeof tools[key] === "object" &&
        _.get(tools, key + ".slug") !== "screener"
      ) {
        if (canUseTool(user, tools[key], responses, plans)) {
          toolArray.push(
            <BSButton
              variant="link-green"
              linkTo={`/progress/` + tools[key].slug}
              key={"progress-" + tools[key]._id}
            >
              <FormattedMessage {...tools[key].long_title} />
            </BSButton>
          );
        }
      }
    }

    if (
      !toolArray.length ||
      !CUAC_SETTINGS.GROUP.print_progress ||
      !CUAC_SETTINGS.GROUP.print_progress_message
    )
      return null;
    return (
      <div className="card mt-4" style={{ border: "none" }}>
        <div className="card-body">
          <h3 className="card-title">
            <FormattedMessage
              id="overview_print_progress_title"
              description="Overview -- print progress section -- title"
              defaultMessage="Print Your Progress"
            />
          </h3>
          {CUAC_SETTINGS.GROUP.print_progress_message && (
            <p className="text-muted">
              <FormattedMessage
                {...CUAC_SETTINGS.GROUP.print_progress_message}
              />
            </p>
          )}
          {toolArray}
        </div>
      </div>
    );
  };

  const showUpgradeModal = () => {
    Cookies.set("shown_coupon", true);
    props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "genericModal",
        modalContent: true,
        modalCustomTitle: {
          defaultMessage: "Ready to make changes in your life?",
          id: "modal_more_tools",
        },
        modalCustomContent: {
          components: [
            {
              children: [
                {
                  text: {
                    defaultMessage:
                      "Upgrade to our Choices Program to get help in the “how to” of cutting back or quitting.",
                    id: "modal_more_tools_p_0",
                  },
                  type: "formatted_message",
                },
              ],
              type: "p",
              className: "mb-2 text-center",
            },
            {
              children: [
                {
                  text: {
                    defaultMessage: "Save $34 with coupon code TIME4CHANGE",
                    id: "modal_more_tools_p_1",
                  },
                  type: "formatted_message",
                },
              ],
              className:
                "text-center bg-theme-light-yellow text-dark p-2 rounded",
              type: "h4",
            },
          ],
        },
        modalComponent: (
          <p className="mt-3 d-flex justify-content-center">
            <BSButton
              variant="green"
              onClick={() => {
                browserHistory.push("/account/plans");
                props.dispatch(
                  Actions.closeModal("appModal", {
                    modalNotifications: "",
                    edit_reminder: {},
                    moodEntry: {},
                    urgeEntry: {},
                    drinkTrackerEntry: {},
                    goalTrackerEntry: {},
                    solutionTrackerEntry: {},
                    modalContent: false,
                  })
                );
              }}
            >
              <FormattedMessage
                id="go_to_account_page"
                description="Go to accout page with coupon"
                defaultMessage="Learn More"
              />
            </BSButton>
          </p>
        ),
        modalNorms: {},
        modalResponses: {},
        scroll: false,
      })
    );
  };

  const showEmailToggle = (id) => {
    return _.find(responses || [], function (response) {
      if (id === response.id) return response.showToggle;
    });
  };

  const onEmailSwitch = async (toolId, checked) => {
    const token = _.get(props.auth, "user.token");
    const userId = _.get(props.auth, "user._id");
    const allowEmail = !!checked;
    const emailResponses = [];
    const json = await props.dispatch(
      Actions.updateUserEmailEntry(token, userId, toolId, allowEmail)
    );
    _.map(responses, function (response, index) {
      if (response.id === toolId) {
        response.allow_email = json.payload.response
          ? json.payload.response.allow_email
          : false;
      }
      var showToggle = response.allow_email !== undefined;
      emailResponses.push({
        id: response.id,
        allow_email: response.allow_email,
        showToggle: showToggle,
      });
      if (index === email_responses.length - 1) setResponses(emailResponses);
    });
  };

  const addResponse = async (tool) => {
    await handleResponse(tool);
  };

  const editResponse = async (responseId, tool) => {
    await handleResponse(tool, responseId);
  };

  const handleResponse = async (tool, responseId = null) => {
    setProcessingButton(true);
    const token = _.get(props, "auth.user.token");
    const userId = _.get(props, "auth.user._id");
    const cookie = cookies.get(`user-token-${_.get(tool, "_id")}`);

    if (responseId)
      props.dispatch(Actions.setResponse(token, userId, responseId));
    else await props.dispatch(Actions.addResponse(token, userId, tool));

    setProcessingButton(false);

    const navFunction = (url) =>
      CUAC_SETTINGS.GROUP.should_reload_on_tool_navigation
        ? window.location.replace(props.router.createPath(url))
        : browserHistory.push(url);

    if (cookie) {
      navFunction(cookie.page);
    } else {
      navFunction(props.router.createPath(`/${tool.slug}`));
    }
  };

  const isOnOwnSubdomain = () =>
    _.get(props.auth, "user.roles.subscriber") &&
    props.auth.user.roles.subscriber.indexOf(CUAC_SETTINGS.GROUP._id) !== -1;

  const renderTrackerNavButtons = () => {
    const {
      auth: { user },
      tools,
    } = props;
    const toolIds = _.get(user, "tool_ids", []);
    let userTools = _.map(toolIds, (toolId) => {
      return _.get(tools, toolId);
    });
    _.remove(userTools, (tool) => {
      if (_.get(tool, "slug")) {
        return (
          tool.slug.indexOf("checkup") > -1 ||
          tool.slug.indexOf("precheck") > -1
        );
      }
    });
    if (!userTools.length) return null;
    // show a pop up for reminders
    if (!Cookies.get("shown-tracker-reminder")) {
      Cookies.set("shown-tracker-reminder", true);
      props.dispatch(
        notify({
          title: "Trackers",
          message:
            "Welcome back. Please enter your urge and drink/no drink data first.",
          level: "success",
          autoDismiss: 4,
        })
      );
    }
    return <TrackerNav />;
  };

  const checkAllowEmail = (id) => {
    const response = (responses || []).find((response) => response.id === id);
    return response?.allow_email;
  };

  const trakerNavBtns = renderTrackerNavButtons();

  return (
    <div className="container mb-4">
      {trakerNavBtns && (
        <div className="row mt-4">
          <div className="col">
            <div className="card" style={{ border: "none" }}>
              <div className="card-body">{trakerNavBtns}</div>
            </div>
          </div>
        </div>
      )}
      <div className="row mt-4">
        <div className="col-12 col-lg-6">
          <div className="card" style={{ border: "none" }}>
            <div className="card-body">
              {isFetchingTools ? (
                <Loading loading>Loading...</Loading>
              ) : (
                <Accordion
                  flush
                  defaultActiveKey={
                    toolsByStatus.inProgress?.length
                      ? "in_progress"
                      : "get_started"
                  }
                >
                  {/**
                   * In Progress
                   */}
                  {!!toolsByStatus?.inProgress?.length && (
                    <Accordion.Item eventKey="in_progress">
                      <Accordion.Header
                        style={{ backgroundColor: LIGHT_FOREST_GREEN }}
                      >
                        <h3>
                          <FormattedMessage
                            id="overview_in_progress_section_title"
                            defaultMessage="In Progress"
                            description="overview page, title of the in-progress section"
                          />
                        </h3>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="list-group list-group-flush">
                          {toolsByStatus?.inProgress.map((tool) => (
                            <ToolItem
                              key={tool?.response?._id ?? tool._id}
                              addResponse={addResponse}
                              editResponse={editResponse}
                              tool={tool}
                              response={tool.response}
                              onEmailSwitch={onEmailSwitch}
                              checkAllowEmail={checkAllowEmail}
                              showEmailToggle={showEmailToggle(tool._id)}
                              disableButton={processingButton}
                            />
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}

                  {/**
                   * To Start
                   */}
                  {!!toolsByStatus?.toStart.length && (
                    <Accordion.Item eventKey="get_started">
                      <Accordion.Header
                        style={{ backgroundColor: LIGHT_FOREST_GREEN }}
                      >
                        <h3>
                          {CUAC_SETTINGS.GROUP.get_started_text ? (
                            <FormattedMessage
                              {...CUAC_SETTINGS.GROUP.get_started_text}
                            />
                          ) : (
                            <FormattedMessage
                              id="overview_add_tools_title"
                              description="Overview -- get started section -- title"
                              defaultMessage="Get Started"
                            />
                          )}
                        </h3>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="list-group list-group-flush">
                          {toolsByStatus?.toStart.map((tool) => (
                            <ToolItem
                              tool={tool}
                              key={tool?.response?._id ?? tool._id}
                              addResponse={addResponse}
                              editResponse={editResponse}
                              onEmailSwitch={onEmailSwitch}
                              checkAllowEmail={checkAllowEmail}
                              showEmailToggle={showEmailToggle(tool._id)}
                              disableButton={processingButton}
                            />
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}

                  {/**
                   * Completed
                   */}
                  {!!toolsByStatus?.completed.length && (
                    <Accordion.Item eventKey="completed">
                      <Accordion.Header
                        style={{ backgroundColor: LIGHT_FOREST_GREEN }}
                      >
                        <h3>
                          {CUAC_SETTINGS.GROUP.completed_responses_text ? (
                            <FormattedMessage
                              {...CUAC_SETTINGS.GROUP.completed_responses_text}
                            />
                          ) : (
                            <FormattedMessage
                              id="overview_completed_title"
                              description="Overview -- completed -- title"
                              defaultMessage="Completed"
                            />
                          )}
                        </h3>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="list-group list-group-flush">
                          {toolsByStatus?.completed.map((tool) => (
                            <ToolItem
                              key={tool?.response?._id ?? tool._id}
                              addResponse={addResponse}
                              editResponse={editResponse}
                              tool={tool}
                              response={tool.response}
                              hideProgress={true}
                              onEmailSwitch={onEmailSwitch}
                              checkAllowEmail={checkAllowEmail}
                              showEmailToggle={showEmailToggle(tool._id)}
                              disableButton={processingButton}
                            />
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <BrandedImage
            image_type="dashboard_image"
            default_image={require("assets/screener-1.png")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">{renderPrintProgress()}</div>
      </div>
    </div>
  );
};

export default connect(function (state) {
  const { responses, tools } = state;
  const user = state.auth.user;
  const plans = state.plans.plans;

  return {
    plans,
    auth: state.auth,
    activeResponseId: state.responses.activeResponseId,
    activeResponse: _.get(
      state.responses[state.responses.activeResponseId],
      "response"
    ),
    responses: state.responses,
    trackerEntryStats: state.trackerEntryStats,
    tools: state.tools,
    ...userNormsSelector(state),
    ...userProfileSelector(state),
  };
})(OverviewPage);
