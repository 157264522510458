"use strict";

import { handleResponse } from "utils/api";

export const getSubscriptions = (token) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/subscriptions", {
    method: "GET",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};

export const addSubscription = (token, plan, stripeToken) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/subscriptions", {
    method: "POST",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      plan,
      token: stripeToken,
    }),
  }).then(handleResponse);
};

export const updateSubscription = (token, subscriptionId, updates) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/subscriptions/" + subscriptionId, {
    method: "PATCH",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updates),
  }).then(handleResponse);
};

export const deleteSubscription = (token, subscriptionId, values) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/subscriptions/" + subscriptionId, {
    method: "DELETE",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  }).then(handleResponse);
};
