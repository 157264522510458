/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, global, require */
"use strict";

import "./main.css";
import "./legacy2.css";
import "./conventional.css";
import "utils/cssloader";

import _ from "lodash";
import "react-mdl/extra/material.js";
import Cookies from "cookies-js";
import { useScroll } from "react-router-scroll";
import config from "../config";
import React, { useState, useEffect } from "react";
// https://stackoverflow.com/questions/46671373/upgrading-a-project-to-react-16-with-old-dependencies
import PropTypes from "prop-types";
import createClass from "create-react-class";
Object.assign(React, {
  PropTypes,
  createClass,
});
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { Router, browserHistory, applyRouterMiddleware } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import initializeStore from "store";
import getRoutes from "./routes";
import { DndProvider } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import Loading from "./components/blocks/Loading.component.web"; // or any other pipeline
import TagManager from "react-gtm-module";
import { PersistGate } from "redux-persist/integration/react";
import AppSpinner from "components/modal/app-spinner";
import { AuthProvider } from "./auth-provider";

const tagManagerArgs = {
  gtmId: config.GOOGLE_TAG,
};

TagManager.initialize(tagManagerArgs);

const waitForSettings = async (calls = 0) => {
  const sleep = (seconds) =>
    new Promise((done) => setTimeout(done, seconds * 1000));
  if ("CUAC_SETTINGS" in window) return true;
  const script = document.getElementById("settingsjsscript");
  const isVercel = !!Boolean(process.env.IS_VERCEL_PREVIEW ?? false);

  if (!script || isVercel) {
    var c = document.location.hostname.split(".")[0],
      s = document.createElement("script"),
      p = document.location.search
        .substr(1)
        .split("&")
        .map((s) => s.split("="))
        .concat([["code", "stage"]]);

    if (isVercel) {
      c = "stage";
    } else if (c === "localhost") {
      c = p.filter(([k, v]) => k == "code")[0][1];
    }

    s.type = "text/javascript";
    s.id = "settingsjsscript";
    s.src =
      config.API_URL +
      "js/settings.js?code=" +
      c +
      "&ts=" +
      Date.now().toString();
    document.head.appendChild(s);
  }
  await sleep(1);
  if (calls < 5) return await waitForSettings(calls + 1);
  else return false;
};

// Scroll to top of page on route change unless we're scrolling to a particular field
const shouldScroll = (prevRouterProps, { location }) => {
  const field = _.get(location, "query.field");
  return !field || !field.length;
};

export default function () {
  const [{ store, persistor }, setRedux] = useState({
    store: null,
    persistor: null,
  });

  useEffect(() => {
    (async () => {
      await waitForSettings();

      const [store, persistor] = initializeStore();

      setRedux({ store, persistor });
    })();
  }, []);

  if (!store || !persistor || !("CUAC_SETTINGS" in window)) {
    return (
      <Loading
        loading={true}
        text={<h4 className="mb-0 ms-2 fw-bold">Loading...</h4>}
      />
    );
  }

  const history = syncHistoryWithStore(browserHistory, store);

  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <Provider store={store}>
        <IntlProvider locale="en">
          <PersistGate
            persistor={persistor}
            loading={
              <Loading
                loading={true}
                text={<h4 className="mb-0 ms-2 fw-bold">Loading...</h4>}
              />
            }
          >
            <AuthProvider>
              <div style={{ position: "relative" }}>
                <Router
                  routes={getRoutes(store)}
                  history={history}
                  render={applyRouterMiddleware(useScroll(shouldScroll))}
                />
                <AppSpinner />
              </div>
            </AuthProvider>
          </PersistGate>
        </IntlProvider>
      </Provider>
    </DndProvider>
  );
}
