"use strict";

import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import Moment from "moment";
Moment.locale("en");
import TextFieldReduxForm from "components/ux/text-field-redux-form";
import CheckboxGroup from "../ux/CheckboxGroup.component.web";
import RadioGroupReduxForm from "../ux/RadioGroupReduxForm.component.web";
import BSButton from "../blocks/BSButton.component.web";

const messages = defineMessages({
  close_account_reason_text_label: {
    id: "close_account_reason_text_label",
    description: "Close Account Reason Text",
    defaultMessage: "Other/Please Elaborate:",
  },
});

const closeAccountOptions = [
  {
    label:
      "The tools were helpful, and I feel like I can manage this on my own, without Checkup & Choices.",
    value:
      "The tools were helpful, and I feel like I can manage this on my own, without Checkup & Choices.",
  },
  {
    label:
      "I found another tool or method for managing my drinking that works better for me.",
    value:
      "I found another tool or method for managing my drinking that works better for me.",
  },
  {
    label: "The tools were not helpful.",
    value: "The tools were not helpful.",
  },
  {
    label: "The tools were confusing to use.",
    value: "The tools were confusing to use.",
  },
  {
    label: "I was not using the tools as much as I thought I would.",
    value: "I was not using the tools as much as I thought I would.",
  },
  {
    label: "It is too expensive.",
    value: "It is too expensive.",
  },
];

const closeAccountPermissionOptions = [
  {
    text: {
      id: "yes",
      defaultMessage: "Yes",
    },
    value: "yes",
  },
  {
    text: {
      id: "no",
      defaultMessage: "No",
    },
    value: "no",
  },
];

const CloseAccountSurvey = React.createClass({
  propTypes: {
    fields: React.PropTypes.object.isRequired,
    handleSubmit: React.PropTypes.func.isRequired,
  },

  onChange(fieldName, event) {
    this.props.change(fieldName, event.target.value);
  },

  onCheckboxSelect: function (setID, values) {
    this.props.fields.close_account_reason.onChange(values);
  },

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <form id="close_account" name="close_account">
        <label className="baseline-form" htmlFor="close_account_reason">
          <FormattedMessage
            id="close_account_reason_label"
            defaultMessage="I am closing my account because...(select all relevant options)"
          />
        </label>
        <Field
          name="close_account_reason"
          component={CheckboxGroup}
          options={closeAccountOptions}
          columns={1}
          checkbox={true}
          multiple={true}
        />
        <div className="my-2">
          <TextFieldReduxForm
            name="close_account_reason_text"
            textArea={true}
            floatingLabel={true}
            labelText={formatMessage(messages.close_account_reason_text_label)}
          />
        </div>
        <p className="h5 my-3">
          <FormattedMessage
            id="close_account_contact_thanks_label"
            defaultMessage="Thank you! We appreciate your feedback."
          />
        </p>
        <RadioGroupReduxForm
          title={
            <FormattedMessage
              id="close_account_contact_permission_label"
              defaultMessage="Can we contact you for more feedback?"
            />
          }
          name="close_account_contact_permission"
          options={closeAccountPermissionOptions}
          fieldsetClassName="d-flex"
          inputClassName="me-1"
          fieldContainerClassName="me-3"
        />
        <BSButton
          loading={this.props.isFetching}
          onClick={(event) =>
            this.props.submitCloseAccountModal(this.props.values, event)
          }
          variant="green"
        >
          <FormattedMessage
            id="close_account_button_save"
            description="Submit Close Account Survey"
            defaultMessage="Submit and Immediately Close Account"
          />
        </BSButton>
      </form>
    );
  },
});

const formFields = [
  "close_account_reason",
  "close_account_reason_text",
  "close_account_contact_permission",
];
let form = reduxForm({
  form: "accountClose",
  fields: formFields,
})(CloseAccountSurvey);

const selector = formValueSelector("accountClose"); // <-- same as form name
form = connect((state) => {
  return formFields.reduce(
    (dict, fieldName) =>
      Object.assign({ [fieldName]: selector(state, fieldName) }, dict),
    {},
  );
})(form);

export default injectIntl(form);
