"use strict";

import {
  REQUEST_NORMS,
  REQUEST_NORMS_SUCCESS,
  REQUEST_NORMS_FAILURE,
} from "actions/types";

export function reducer(state = {}, action = { type: "" }) {
  switch (action.type) {
    case REQUEST_NORMS:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_NORMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...action.payload.reduce((acc, norm) => {
          acc[norm.type] = norm;
          return acc;
        }, {}),
      };
    }
    case REQUEST_NORMS_FAILURE: {
      return { loading: false };
    }
    default:
      return state;
  }
}
