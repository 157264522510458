"use strict";
import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import { Field, reduxForm } from "redux-form";
import { FOREST_GREEN } from "../../stylesheets/colors";
import { FontAwesome } from "@expo/vector-icons";

const Input = ({ input, ...props }) => {
  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        aria-describedby="button-addon2"
        style={{ border: "none" }}
        {...input}
      />
      <button
        className="btn"
        type="button"
        id="button-addon2"
        onClick={props.onSubmit}
        style={{ backgroundColor: FOREST_GREEN }}
        disabled={!input.value || !input.value.length}
      >
        <FontAwesome name="plus" color="white" size={24} />
      </button>
    </div>
  );
};

const messages = defineMessages({
  text_value_list_label_new_text_value: {
    id: "text_value_list_label_new_text_value",
    description: "Label for the new text value input",
    defaultMessage: "Add new",
  },
  text_value_list_label_new_text_add: {
    id: "text_value_list_label_new_text_add",
    description: "Add button title for the new text value input",
    defaultMessage: "Add",
  },
});

const TextValueListForm = ({ formName, intl: { formatMessage }, ...props }) => {
  const onSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit();
    props.dispatch(props.reset("textValueList"));
  };

  return (
    <div className="my-3" onSubmit={onSubmit}>
      <form id="textValueList" name="textValueList">
        <h4 style={{ color: "#0E323E" }} className="fw-bold">
          {formatMessage(messages.text_value_list_label_new_text_value)}
        </h4>
        <div className="d-flex align-items-center">
          <Field
            name="newTextValue"
            component={Input}
            {...props}
            onSubmit={onSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "textValueList",
  fields: ["newTextValue"],
})(injectIntl(TextValueListForm));
