"use strict";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RegistrationContainer from "../components/Registration/Registration.container";
import { injectIntl } from "react-intl";
import { loadStripe } from "@stripe/stripe-js";
import config from "../../config";
import { Elements } from "@stripe/react-stripe-js";
import { Helmet } from "react-helmet";

const RegistrationPage = () => {
  const [stripe, setStripe] = useState();

  useEffect(() => {
    (async () => {
      const res = await loadStripe(config.STRIPE_KEY);
      setStripe(res);
    })();
  }, []);

  if (!stripe) return null;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign up to Checkup & Choices</title>
        <meta
          name="description"
          content="Our affordable and flexible plans are guaranteed to help--or your money back."
        />
      </Helmet>
      <Elements stripe={stripe}>
        <RegistrationContainer />
      </Elements>
    </>
  );
};

export default connect((state) => ({}))(injectIntl(RegistrationPage));
