/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import _ from "lodash";
import React from "react";
import Actions from "actions";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import ButtonSpinner from "components/ux/button-spinner";
import Link from "components/ux/linker";
import { canUseTool } from "utils/render";

const ImportantMessage = React.createClass({
  closeMe: function () {
    this.props.dispatch(
      Actions.closeModal("appModal", {
        modalNotifications: "",
        edit_reminder: {},
        moodEntry: {},
        urgeEntry: {},
        drinkTrackerEntry: {},
        goalTrackerEntry: {},
        solutionTrackerEntry: {},
        modalContent: false,
      }),
    );
  },

  dismissMessage: function () {
    const token = _.get(this.props.auth, "user.token");
    const userId = _.get(this.props.auth, "user._id");
    this.props.dispatch(Actions.dismissUserMessage(token, userId));
    this.closeMe();
  },

  render: function () {
    let shownMessage = false;
    const messages = _.get(this.props.auth, "user.messages");
    if (messages) {
      for (let i = 0; i < messages.length; i++) {
        if (!_.get(messages, i + ".viewed")) {
          shownMessage = messages[i];
          break;
        }
      }
    }
    let link = "review your CheckUp results";
    let checkup = false;
    let checkup25 = false;
    const toolIds = _.get(this.props.auth, "user.tool_ids", []);
    const userTools = _.map(toolIds, (toolId) => {
      return _.get(this.props.tools, toolId);
    });
    _.remove(userTools, (tool) => {
      if (
        !canUseTool(
          this.props.auth.user,
          tool,
          this.props.responses,
          this.props.plans,
        )
      ) {
        return true;
      }
    });
    _.map(userTools, (tool) => {
      if (tool.slug === "checkup") {
        checkup = true;
      } else if (tool.slug === "checkup25") {
        checkup25 = true;
      }
    });
    if (checkup) {
      link = (
        <Link
          className="nav-link"
          to={"/checkup/consider/game-plan/feedback?override=true"}
          onClick={this.dismissMessage}
          title={"See Feedback"}
        >
          click here to review your CheckUp results
        </Link>
      );
    }
    if (checkup25) {
      link = (
        <Link
          className="nav-link"
          to={"/checkup25/consider/game-plan/feedback?override=true"}
          onClick={this.dismissMessage}
          title={"See Feedback"}
        >
          click here to review your CheckUp results
        </Link>
      );
    }
    let message = "";
    if (shownMessage) {
      switch (shownMessage.type) {
        case "mast-error-extend-low":
          message = (
            <div>
              <p>
                We discovered an error in a formula that generated one of the
                scores in your CheckUp. The affected score is for your
                probability of success with moderation as a goal of change. This
                score has now been corrected in your CheckUp. Please {link} and
                the corrected score for probability of success with moderation
                and its associated feedback.
              </p>
              <p>
                We apologize for this error. We have performed a complete audit
                of the other scores that contribute to your CheckUp feedback and
                we are confident that all other information is accurate as
                originally reported to you when you completed your CheckUp. We
                also want to stress that no single score can or should drive
                your decision as to whether and how to change your drinking.
                Please consider the entire feedback summary.
              </p>
              <p>
                With this in mind, and to support you, we are extending your
                access to CheckUp & Choices—free of charge—for three more months
                beyond your currently scheduled expiration date. We hope that
                you find the updated feedback helpful and wish you the best on
                your journey.
              </p>
            </div>
          );
          break;
        case "mast-error-extend-high":
          message = (
            <div>
              <p>
                We discovered an error in a formula that generated one of the
                scores in your CheckUp. The affected score is for your
                probability of success with moderation as a goal of change. This
                score has now been corrected in your CheckUp. Please {link} and
                the corrected score for probability of success with moderation
                and its associated feedback.
              </p>
              <p>
                We apologize for this error. We have performed a complete audit
                of the other scores that contribute to your CheckUp feedback and
                we are confident that all other information is accurate as
                originally reported to you when you completed your CheckUp. We
                also want to stress that no single score can or should drive
                your decision as to whether and how to change your drinking.
                Please consider the entire feedback summary.
              </p>
              <p>
                With this in mind, and to support you, we are extending your
                access to CheckUp & Choices—free of charge—for one year beyond
                your currently scheduled expiration date. We hope that you find
                the updated feedback helpful and wish you the best on your
                journey.
              </p>
            </div>
          );
          break;
        case "mast-error-reactivate-low":
          message = (
            <div>
              <p>
                We discovered an error in a formula that generated one of the
                scores in your CheckUp. The affected score is for your
                probability of success with moderation as a goal of change. This
                score has now been corrected in your CheckUp. Please {link} and
                the corrected score for probability of success with moderation
                and its associated feedback.
              </p>
              <p>
                We apologize for this error. We have performed a complete audit
                of the other scores that contribute to your CheckUp feedback and
                we are confident that all other information is accurate as
                originally reported to you when you completed your CheckUp. We
                also want to stress that no single score can or should drive
                your decision as to whether and how to change your drinking.
                Please consider the entire feedback summary.
              </p>
              <p>
                With this in mind, and to support you, we have reactivated your
                CheckUp & Choices account and provided you three months of
                complete access—free of charge. We hope that you find the
                updated feedback helpful and wish you the best on your journey.
              </p>
            </div>
          );
          break;
        case "mast-error-reactivate-high":
          message = (
            <div>
              <p>
                We discovered an error in a formula that generated one of the
                scores in your CheckUp. The affected score is for your
                probability of success with moderation as a goal of change. This
                score has now been corrected in your CheckUp. Please {link} and
                the corrected score for probability of success with moderation
                and its associated feedback.
              </p>
              <p>
                We apologize for this error. We have performed a complete audit
                of the other scores that contribute to your CheckUp feedback and
                we are confident that all other information is accurate as
                originally reported to you when you completed your CheckUp. We
                also want to stress that no single score can or should drive
                your decision as to whether and how to change your drinking.
                Please consider the entire feedback summary.
              </p>
              <p>
                With this in mind, and to support you, we have reactivated your
                CheckUp & Choices account and provided you one year of complete
                access—free of charge. We hope that you find the updated
                feedback helpful and wish you the best on your journey.
              </p>
            </div>
          );
          break;
        case "mast-error-enterprise-low":
          message = (
            <div>
              <p>
                We discovered an error in a formula that generated one of the
                scores in your CheckUp. The affected score is for your
                probability of success with moderation as a goal of change. This
                score has now been corrected in your CheckUp. Please {link} and
                the corrected score for probability of success with moderation
                and its associated feedback.
              </p>
              <p>
                We apologize for this error. We have performed a complete audit
                of the other scores that contribute to your CheckUp feedback and
                we are confident that all other information is accurate as
                originally reported to you when you completed your CheckUp. We
                also want to stress that no single score can or should drive
                your decision as to whether and how to change your drinking.
                Please consider the entire feedback summary.
              </p>
              <p>
                We hope that you find the updated feedback helpful and wish you
                the best on your journey.
              </p>
            </div>
          );
          break;
        case "mast-error-enterprise-high":
          message = (
            <div>
              <p>
                We discovered an error in a formula that generated one of the
                scores in your CheckUp. The affected score is for your
                probability of success with moderation as a goal of change. This
                score has now been corrected in your CheckUp. Please {link} and
                the corrected score for probability of success with moderation
                and its associated feedback.
              </p>
              <p>
                We apologize for this error. We have performed a complete audit
                of the other scores that contribute to your CheckUp feedback and
                we are confident that all other information is accurate as
                originally reported to you when you completed your CheckUp. We
                also want to stress that no single score can or should drive
                your decision as to whether and how to change your drinking.
                Please consider the entire feedback summary.
              </p>
              <p>
                We hope that you find the updated feedback helpful and wish you
                the best on your journey.
              </p>
            </div>
          );
          break;
        default:
          break;
      }
    }
    return <div className="container">{message}</div>;
  },
});

export default connect((state) => ({
  auth: state.auth,
  isFetching: state.auth.isFetching,
  tools: state.tools,
  responses: state.responses,
  plans: state.plans.plans,
}))(ImportantMessage);
