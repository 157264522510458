"use strict";

import _ from "lodash";

export const getPageStatus = (page) => {
  const { question_count, response_count, visited } = page;
  const completed =
    visited && (!question_count || response_count === question_count);
  return {
    completed,
    completedCount: response_count,
    totalCount: question_count,
    text: "questions",
  };
};

export const getSectionStatus = (pages) => {
  let completed = true;
  let completedCount = 0;
  let totalCount = 0;
  for (const page of pages) {
    totalCount += 1;
    const status = getPageStatus(page);
    const pageCompleted = _.get(status, "completed", false);
    if (!pageCompleted) {
      completed = false;
    } else {
      completedCount += 1;
    }
  }
  return {
    completed,
    completedCount,
    totalCount,
    text: "pages",
  };
};

export const getModuleStatus = (sections) => {
  let completed = true;
  let completedCount = 0;
  let totalCount = 0;
  for (const section of sections) {
    totalCount += 1;
    const status = getSectionStatus(_.values(_.get(section, "pages", [])));
    const sectionCompleted = _.get(status, "completed", false);
    if (!sectionCompleted) {
      completed = false;
    } else {
      completedCount += 1;
    }
  }
  return {
    completed,
    completedCount,
    totalCount,
    text: "sections",
  };
};

export const getToolStatus = (modules) => {
  let completed = true;
  let completedCount = 0;
  let totalCount = 0;
  for (const module of modules) {
    totalCount += 1;

    const status = getModuleStatus(_.values(_.get(module, "sections", [])));
    const moduleCompleted = _.get(status, "completed", false);
    if (!moduleCompleted) {
      completed = false;
    } else {
      completedCount += 1;
    }
  }
  return {
    completed,
    completedCount,
    totalCount,
    text: "modules",
  };
};
