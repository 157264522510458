"use strict";

import {
  REQUEST_GET_USER_EMAIL_ENTRY,
  GET_USER_EMAIL_ENTRY_SUCCESS,
  GET_USER_EMAIL_ENTRY_FAILURE,
  REQUEST_UPDATE_USER_EMAIL_ENTRY,
  UPDATE_USER_EMAIL_ENTRY_SUCCESS,
  UPDATE_USER_EMAIL_ENTRY_FAILURE,
} from "actions/types";

export function reducer(state = {}, action = { type: "" }) {
  switch (action.type) {
    case REQUEST_GET_USER_EMAIL_ENTRY:
      return {
        ...state,
      };
    case GET_USER_EMAIL_ENTRY_SUCCESS:
      return {
        ...state,
        ...action.payload.response,
      };
    case GET_USER_EMAIL_ENTRY_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case REQUEST_UPDATE_USER_EMAIL_ENTRY:
      return {
        ...state,
      };
    case UPDATE_USER_EMAIL_ENTRY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_USER_EMAIL_ENTRY_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
