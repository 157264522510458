/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import { isToolInPlan } from "utils/plans";

export const canUseTool = (user, tool, responses, plans) => {
  if (typeof tool === "undefined") {
    return false;
  }
  // if we have group tool ids, check to see if the tool is in there
  if (_.get(CUAC_SETTINGS.GROUP, "tool_ids")) {
    // if it is not, return false
    if (CUAC_SETTINGS.GROUP.tool_ids.indexOf(tool["_id"]) === -1) {
      return false;
    }
  } else if (!_.get(user, "stripe_customer.subscriptions.data[0].plan.id")) {
    // if this is a paid subdomain and we don't have a plan, return false
    // unless it is a checkup since those people should always have access to
    if (_.get(tool, "slug", "").indexOf("checkup") < 0) {
      return false;
    }
  }
  // check for plans tool ids, unless we are on a subdomain with a default program id (unpaid)
  if (
    _.get(user, "stripe_customer.subscriptions.data[0].plan.id") &&
    !_.get(CUAC_SETTINGS.GROUP, "default_program_id")
  ) {
    const toolInPlan = isToolInPlan(user, plans, tool["_id"]);
    if (!toolInPlan) {
      return false;
    }
  }
  // check for restrictions
  const restrictions = _.get(tool, "restrictions");
  if (typeof restrictions !== "undefined") {
    for (let restriction of restrictions) {
      const restrictionType = _.get(restriction, "type");
      switch (restrictionType) {
        case "relative_date_range":
          var { min, max } = restriction;
          const now = new Date();
          const absoluteMin =
            typeof min !== "undefined"
              ? new Date(now).setUTCFullYear(now.getUTCFullYear() + min)
              : new Date(-8640000000000000).getTime();
          const absoluteMax =
            typeof max !== "undefined"
              ? new Date(now).setUTCFullYear(now.getUTCFullYear() + max)
              : new Date(8640000000000000).getTime();
          let userField = _.get(user, restriction.field);
          if (!userField) {
            return false;
          }
          userField = new Date(userField).getTime();
          if (userField <= absoluteMin || userField > absoluteMax) {
            return false;
          }
          break;
        case "screener_value":
          var { min, max } = restriction;
          // go through all the responses
          for (var responseKey in responses) {
            // see if there is a screener question score
            if (
              _.get(
                responses[responseKey],
                "response.responses.screener_questions_score",
              ) ||
              _.get(
                responses[responseKey],
                "response.responses.screener_questions_score",
              ) === 0
            ) {
              //check to see if it is between the min and max
              if (
                _.get(
                  responses[responseKey],
                  "response.responses.screener_questions_score",
                ) < min ||
                _.get(
                  responses[responseKey],
                  "response.responses.screener_questions_score",
                ) > max
              ) {
                return false;
              }
            }
          }
          break;
        case "tool_key_value":
          var { key, value } = restriction;
          // go through all the responses
          for (var responseKey in responses) {
            // check if there is a response key that matches the key we are looking for
            if (
              _.get(responses[responseKey], "response.responses." + key) ||
              _.get(responses[responseKey], "response.responses." + key) === 0
            ) {
              let userValue = _.get(
                responses[responseKey],
                "response.responses." + key,
              );
              // check if the response value and the value we are looking for are NOT the same, then return false
              if (userValue != value) {
                return false;
              }
            }
          }
          break;
        case "tool_key_value_range":
          var { key, value } = restriction;
          // go through all the responses
          for (var responseKey in responses) {
            // check if there is a response key that matches the key we are looking for
            if (
              _.get(responses[responseKey], "response.responses." + key) ||
              _.get(responses[responseKey], "response.responses." + key) === 0
            ) {
              let userValue = _.get(
                responses[responseKey],
                "response.responses." + key,
              );
              // check if the response value is less than the value we are looking for
              if (userValue < value) {
                return false;
              }
            }
          }
          break;
      }
    }
  }
  return true;
};

export const getScoreOptionIndex = (score, options) => {
  let scoreOptionIndex = 0;
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (
      (typeof option.min === "undefined" || score >= option.min) &&
      (typeof option.max === "undefined" || score <= option.max)
    ) {
      scoreOptionIndex = i;
      break;
    }
  }
  return scoreOptionIndex;
};

/*
 * get the latest tracker entry
 * @params Object of all tracker entries
 * @returns Object of data from the lastest tracker entries of a certain type
 */
export const getLatestTrackerEntry = (trackerEntries, type) => {
  // set up a date for the biggining of time
  let date = new Date(0).getTime();
  // set up a trackerEntry variable to merge with our resonses
  let trackerEntry = null;
  for (var key in trackerEntries) {
    if (trackerEntries[key].tracker_type_name == type) {
      if (new Date(trackerEntries[key].dt_u).getTime() > date) {
        date = new Date(trackerEntries[key].dt_u).getTime();
        trackerEntry = trackerEntries[key].data;
      }
    }
  }
  return trackerEntry;
};
