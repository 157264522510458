"use strict";

import _ from "lodash";

import { createSelector } from "reselect";

import { moduleSelector } from "selectors/module";

const sectionSlugSelector = (state, props) => props.params.sectionSlug;
const getSectionFromSlug = (slug, module) => {
  for (let section of _.values(_.get(module, "sections", {}))) {
    if (section.slug === slug) {
      return section;
    }
  }
  return {};
};

export const sectionSelector = createSelector(
  sectionSlugSelector,
  moduleSelector,
  (sectionSlug, module) => {
    return {
      ...module,
      section: getSectionFromSlug(sectionSlug, module.module),
    };
  },
);
