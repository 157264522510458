/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import React from "react";
import { FontAwesome } from "@expo/vector-icons";

var Paginator = React.createClass({
  propTypes: {
    max: React.PropTypes.number.isRequired,
    maxVisible: React.PropTypes.number,
    onChange: React.PropTypes.func.isRequired,
  },
  componentDidUpdate: function (prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.props.onChange(this.state.currentPage);
    }
  },
  getDefaultProps: function (props) {
    return {
      maxVisible: 5,
    };
  },
  getInitialState: function () {
    return {
      currentPage: this.props.page || 1,
      items: [],
    };
  },
  goTo: function (page) {
    this.setState({ currentPage: page });
  },

  onClickNext: function () {
    var page = this.state.currentPage;

    if (page < this.props.max) {
      this.goTo(page + 1);
    }
  },
  onClickPrev: function () {
    if (this.state.currentPage > 1) {
      this.goTo(this.state.currentPage - 1);
    }
  },
  render: function () {
    var className = this.props.className || "",
      p = this.props,
      s = this.state,
      skip = 0;

    if (s.currentPage > p.max - 1 && s.currentPage < p.max) {
      skip = s.currentPage - p.max + 1;
    } else if (s.currentPage === p.max) {
      skip = s.currentPage - p.max;
    }

    var iterator = Array.apply(null, Array(p.max)).map(function (v, i) {
      return skip + i + 1;
    });

    return (
      <nav>
        <ul className="pagination">
          <li className="page-item" key="prev">
            <a className="page-link" href="#" onClick={this.onClickPrev}>
              <FontAwesome name="chevron-left" size={14} color="black" />
            </a>
          </li>
          <li
            key="one"
            className={`page-item ${s.currentPage == 1 || s.currentPage < p.maxVisible ? "d-none" : ""}`}
            onClick={this.goTo.bind(this, 1)}
          >
            <a
              style={{ color: "#0E323E" }}
              href="#"
              className={`page-link ${s.currentPage === 1 ? "active" : ""}`}
            >
              {1}
            </a>
          </li>
          <li
            key="left-dots"
            className={`page-item ${s.currentPage == 1 || s.currentPage <= p.maxVisible ? "d-none" : ""}`}
          >
            <a style={{ color: "#0E323E" }} className="page-link dots">
              ...
            </a>
          </li>
          {iterator.map(function (page) {
            // Verify if the current page is past the visibility point.
            if (s.currentPage >= p.maxVisible) {
              // Create a range allowed to be visible.
              if (page <= s.currentPage - (p.maxVisible - 1)) return;
              else if (page >= s.currentPage + 2) return;
              // Do not show pages past the maximum visibility.
            } else if (page > p.maxVisible) return;
            return (
              <li
                className="page-item"
                key={page}
                onClick={this.goTo.bind(this, page)}
              >
                <a
                  href="#"
                  style={{ color: "#0E323E" }}
                  className={`page-link ${s.currentPage === page ? "active" : ""}`}
                >
                  {page}
                </a>
              </li>
            );
          }, this)}
          <li
            key="right-dots"
            className={`page-item ${s.currentPage == p.max || s.currentPage >= p.max - 2 || p.max == p.maxVisible ? "d-none" : ""}`}
          >
            <a style={{ color: "#0E323E" }} className="page-link dots">
              ...
            </a>
          </li>
          <li
            key={p.max}
            className={`page-item ${s.currentPage == p.max || s.currentPage >= p.max - 1 || p.max == p.maxVisible ? "d-none" : ""}`}
            onClick={this.goTo.bind(this, p.max)}
          >
            <a
              style={{ color: "#0E323E" }}
              href="#"
              className={`page-link ${s.currentPage === p.max ? "active" : ""}`}
            >
              {p.max}
            </a>
          </li>
          <li key="next" className="page-item">
            <a className="page-link" href="#" onClick={this.onClickNext}>
              <FontAwesome name="chevron-right" size={14} color="black" />
            </a>
          </li>
        </ul>
      </nav>
    );
  },
});

export default Paginator;
export { Paginator };
