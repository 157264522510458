"use strict";

import { handleResponse } from "utils/api";

export const sendCompletionEmail = (token, user_id, tool_id) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT +
      "/emails/send-completion-email?user_id=" +
      user_id +
      "&tool_id=" +
      tool_id,
    {
      method: "post",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tool_id: tool_id,
      }),
    },
  ).then(handleResponse);
};
