"use strict";
import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import { faStar as faStarO } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import BSButton from "../blocks/BSButton.component.web";
import { Form } from "react-bootstrap";
import { validator } from "utils/tools";

const Input = ({ input, type, placeholder, ...props }) => {
  return (
    <Form.Control
      type={type || "text"}
      placeholder={placeholder}
      value={input?.value}
      onChange={input?.onChange}
      {...props}
    />
  );
};

const messages = defineMessages({
  coupon_label_coupon_code: {
    id: "coupon_label_coupon_code",
    description: "Label for the coupon code input",
    defaultMessage: "Coupon Code",
  },
});

const Coupon = React.createClass({
  propTypes: {
    fields: React.PropTypes.object.isRequired,
    handleSubmit: React.PropTypes.func.isRequired,
  },

  render() {
    const {
      handleSubmit,
      intl: { formatMessage },
      coupon_code,
    } = this.props;
    return (
      <form id="coupon" name="coupon" className="d-flex align-items-center">
        <Field
          component={Input}
          name="coupon_code"
          label={formatMessage(messages.coupon_label_coupon_code)}
          placeholder={formatMessage(messages.coupon_label_coupon_code)}
        />
        <BSButton
          disabled={!coupon_code || !coupon_code.length}
          variant="green"
          className="justify-content-center ms-3"
          onClick={handleSubmit}
        >
          <FontAwesomeIcon icon={faStarO} color="white" />
          <div className="ms-1">
            <FormattedMessage
              id="coupon_button_apply"
              description="Apply the coupon"
              defaultMessage="Apply"
            />
          </div>
        </BSButton>
      </form>
    );
  },
});

const formFields = ["coupon_code"];
let form = reduxForm({
  form: "coupon",
  fields: formFields,
  validate: validator({
    coupon_code: { required: "Required" },
  }),
})(Coupon);

const selector = formValueSelector("coupon"); // <-- same as form name
form = connect((state) => {
  return formFields.reduce(
    (dict, fieldName) =>
      Object.assign({ [fieldName]: selector(state, fieldName) }, dict),
    {},
  );
})(form);

export default injectIntl(form);
