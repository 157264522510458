"use strict";

import _ from "lodash";
import React, { findDOMNode } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";

import InfoBug from "components/popover/infobug";
import ButtonSpinner from "components/ux/button-spinner";
import TextFieldReduxForm from "components/ux/text-field-redux-form";
import { faStar as faStarO } from "@fortawesome/free-solid-svg-icons";
import { validator } from "utils/tools";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

const messages = defineMessages({
  lapse_tracker_title_label: {
    id: "lapse_tracker_title_label",
    description: "Title",
    defaultMessage: "Title",
  },
  lapse_tracker_triggers_label: {
    id: "lapse_tracker_triggers_label",
    description: "What were the triggers?",
    defaultMessage: "What were the triggers?",
  },
  lapse_tracker_mistakes_label: {
    id: "lapse_tracker_mistakes_label",
    description: "What mistakes did you make in dealing with these triggers?",
    defaultMessage:
      "What mistakes did you make in dealing with these triggers?",
  },
  lapse_tracker_changes_to_make_label: {
    id: "lapse_tracker_changes_to_make_label",
    description: "What changes are you going to make?",
    defaultMessage: "What changes are you going to make?",
  },
  lapse_tracker_reasons_to_change_label: {
    id: "lapse_tracker_reasons_to_change_label",
    description: "What are your most important reasons for changing?",
    defaultMessage: "What are your most important reasons for changing?",
  },
  lapse_tracker_steps_to_changing_label: {
    id: "lapse_tracker_steps_to_changing_label",
    description:
      "How do you plan to achieve your goals. What are the specific steps involved? When, where, and how will you take them?",
    defaultMessage:
      "How do you plan to achieve your goals. What are the specific steps involved? When, where, and how will you take them?",
  },
  lapse_tracker_how_others_can_help_label: {
    id: "lapse_tracker_how_others_can_help_label",
    description: "How can others help? How can you get them to help you?",
    defaultMessage: "How can others help? How can you get them to help you?",
  },
  lapse_tracker_possible_setbacks_label: {
    id: "lapse_tracker_possible_setbacks_label",
    description:
      "What could go wrong or undermine your plans? How can you stick with your plan despite these setbacks or problems?",
    defaultMessage:
      "What could go wrong or undermine your plans? How can you stick with your plan despite these setbacks or problems?",
  },
  lapse_tracker_good_things_from_changing_label: {
    id: "lapse_tracker_good_things_from_changing_label",
    description: "What good things will happen as a result of changing?",
    defaultMessage: "What good things will happen as a result of changing?",
  },
});

const LapseTracker = React.createClass({
  propTypes: {
    fields: React.PropTypes.object.isRequired,
    handleSubmit: React.PropTypes.func.isRequired,
    clearTrackerEntry: React.PropTypes.func.isRequired,
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const fields = this.props.fields;
    if (nextProps.trackerEntry) {
      _.forOwn(nextProps.trackerEntry, (val, key) => {
        fields[key].onChange(val);
      });
      this.props.clearTrackerEntry();
    }
  },

  onChange(fieldName, event) {
    this.props.change(fieldName, event.target.value);
  },

  render() {
    const {
      handleSubmit,
      intl: { formatMessage },
    } = this.props;
    return (
      <div className="section section-lapse-tracker col-1-1 no-print">
        <div
          className="contain-box-wrap"
          style={{ maxWidth: "690px", marginTop: "52px" }}
        >
          <div className="contain-box-main">
            <h1 className="page-title contain-box-title">
              <FormattedMessage
                id="lapse_tracker_title"
                description="Title for the Change Plan Tracker form"
                defaultMessage="Your Lapse Change Plan"
              />
            </h1>

            <form
              id="lapse_tracker"
              name="lapse_tracker"
              className="stack-input"
              style={{ margin: "0 auto 40px auto" /*, maxWidth: '290px'*/ }}
            >
              <div>
                <TextFieldReduxForm
                  name="title"
                  floatingLabel={true}
                  labelText={formatMessage(messages.lapse_tracker_title_label)}
                />
                <TextFieldReduxForm
                  name="triggers"
                  textArea={true}
                  floatingLabel={true}
                  labelText={formatMessage(
                    messages.lapse_tracker_triggers_label,
                  )}
                />
                <TextFieldReduxForm
                  name="mistakes"
                  textArea={true}
                  floatingLabel={true}
                  labelText={formatMessage(
                    messages.lapse_tracker_mistakes_label,
                  )}
                />
                <TextFieldReduxForm
                  name="changes_to_make"
                  textArea={true}
                  floatingLabel={true}
                  labelText={formatMessage(
                    messages.lapse_tracker_changes_to_make_label,
                  )}
                />
                <InfoBug>
                  <FormattedMessage
                    id="lapse_tracker_changes_to_make_infobug"
                    defaultMessage="What happened? What did someone else do or say?"
                  />
                </InfoBug>
                <TextFieldReduxForm
                  name="reasons_to_change"
                  textArea={true}
                  floatingLabel={true}
                  labelText={formatMessage(
                    messages.lapse_tracker_reasons_to_change_label,
                  )}
                />
                <InfoBug>
                  <FormattedMessage
                    id="lapse_tracker_reasons_to_change_infobug"
                    defaultMessage="What am I saying to myself about this event? Which of them are facts? Which are reasons_to_change or opinions? Which are helpful? Are there any which may be self-defeating? Are there some you'd rather not have?"
                  />
                </InfoBug>
                <TextFieldReduxForm
                  name="steps_to_changing"
                  textArea={true}
                  floatingLabel={true}
                  labelText={formatMessage(
                    messages.lapse_tracker_steps_to_changing_label,
                  )}
                />
                <InfoBug>
                  <FormattedMessage
                    id="lapse_tracker_steps_to_changing_infobug"
                    defaultMessage="What are the immediate, short-term steps_to_changing of how I responded? How did I feel and behave? What are the long-term steps_to_changing? How did I feel or behave? Were these feelings and/or behaviors helpful or not?"
                  />
                </InfoBug>
                <TextFieldReduxForm
                  name="how_others_can_help"
                  textArea={true}
                  floatingLabel={true}
                  labelText={formatMessage(
                    messages.lapse_tracker_how_others_can_help_label,
                  )}
                />
                <InfoBug>
                  <FormattedMessage
                    id="lapse_tracker_how_others_can_help_infobug"
                    defaultMessage="Challenge and how_others_can_help the thoughts or reasons_to_change that are self-defeating. What is the objective evidence that my thoughts or reasons_to_change are true (and not just my belief or opinion)? Is there another way to look at it? How are my thoughts and reasons_to_change likely to be helpful or not in the long run?"
                  />
                </InfoBug>
                <TextFieldReduxForm
                  name="possible_setbacks"
                  textArea={true}
                  floatingLabel={true}
                  labelText={formatMessage(
                    messages.lapse_tracker_possible_setbacks_label,
                  )}
                />
                <InfoBug>
                  <FormattedMessage
                    id="lapse_tracker_possible_setbacks_infobug"
                    defaultMessage="What more helpful reasons_to_change or thoughts can I use to replace the unhelpful thoughts? How might they lead to better steps_to_changing?"
                  />
                </InfoBug>
                <TextFieldReduxForm
                  name="good_things_from_changing"
                  textArea={true}
                  floatingLabel={true}
                  labelText={formatMessage(
                    messages.lapse_tracker_good_things_from_changing_label,
                  )}
                />
                <InfoBug>
                  <FormattedMessage
                    id="lapse_tracker_good_things_from_changing_infobug"
                    defaultMessage="If I take this different approach to thinking about this event, what are my new feelings or reactions?"
                  />
                </InfoBug>
                <ButtonSpinner
                  spinner={this.props.isFetching}
                  onClick={handleSubmit}
                  ripple={true}
                  raised={true}
                  colored={true}
                >
                  <FontAwesomeIcon icon={faStarO} />
                  <FormattedMessage
                    id="lapse_tracker_button_save"
                    description="Submit the Change Plan tracker form to save an Change Plan"
                    defaultMessage="Save"
                  />
                </ButtonSpinner>
              </div>
              <div className="clearfix" />
            </form>
          </div>
        </div>
      </div>
    );
  },
});

const formFields = [
  "title",
  "triggers",
  "mistakes",
  "changes_to_make",
  "reasons_to_change",
  "steps_to_changing",
  "how_others_can_help",
  "possible_setbacks",
  "good_things_from_changing",
];
let form = reduxForm({
  form: "lapseTracker",
  fields: formFields,
  validate: validator({
    title: { required: "Required" },
    triggers: { required: "Required" },
    mistakes: { required: "Required" },
    changes_to_make: { required: "Required" },
    reasons_to_change: { required: "Required" },
    steps_to_changing: { required: "Required" },
    how_others_can_help: { required: "Required" },
    possible_setbacks: { required: "Required" },
    good_things_from_changing: { required: "Required" },
  }),
})(LapseTracker);

const selector = formValueSelector("lapseTracker"); // <-- same as form name
form = connect((state) => {
  return formFields.reduce(
    (dict, fieldName) =>
      Object.assign({ [fieldName]: selector(state, fieldName) }, dict),
    {},
  );
})(form);

export default injectIntl(form);
