/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React from "react";
import { Field } from "redux-form";
import NumberField from "components/ux/mdl/number-input";

var NumberInputReduxForm = React.createClass({
  // ======= REACT LIFECYCLE ============================

  getInitialState: function () {
    return { focus: false };
  },

  // ======= LOCAL METHODS =============================
  handleChange(value) {
    if (this.props.save) {
      this.props.save(value);
    }
  },

  handleBlur(event) {
    this.setState({ focus: false }, this.upstreamBlur(event));
  },

  upstreamBlur(event) {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  },

  // ======= RENDER =====================================

  render: function () {
    return (
      <Field
        component={(field) => {
          if (this.props.labelText) {
            return (
              <div class="input-group mb-3 input-group-fancy">
                <span className="input-group-text">{this.props.labelText}</span>
                <NumberField
                  {...field.input}
                  value={this.props.value}
                  input="form-control"
                  isInvalid={field.meta.error && !field.meta.valid}
                  onChange={this.handleChange}
                  errorText={field.meta.error}
                />
              </div>
            );
          } else {
            return (
              <NumberField
                {...field.input}
                {...this.props}
                isInvalid={field.meta.error && !field.meta.valid}
                onChange={this.handleChange}
                errorText={field.meta.error}
              />
            );
          }
        }}
        name={this.props.name}
        onBlur={this.handleBlur}
      />
    );
  },
});

export default NumberInputReduxForm;
