"use strict";

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import Actions from "actions";
import {
  REQUEST_UPDATE_SUBSCRIPTION_SUCCESS,
  REQUEST_ADD_SUBSCRIPTION_SUCCESS,
} from "actions/types";
import { PlanList } from "components/account/plan-list";
import { SubstancePlanList } from "components/account/substance-plan-list";
import { param } from "lib/query-params";
import Loading from "../components/blocks/Loading.component.web";

const ScreenerPlanSelectionPage = React.createClass({
  propTypes: {
    plans: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    auth: React.PropTypes.object,
    subscriptions: React.PropTypes.arrayOf(React.PropTypes.object),
  },

  componentDidMount() {
    // redirect user to external plan selection page
    if (CUAC_SETTINGS.GROUP.plan_select_url && !param("plan")) {
      window.location = CUAC_SETTINGS.GROUP.plan_select_url;
    }
  },

  componentDidUpdate(prevProps) {
    const { plans, subscriptions } = this.props;
    if (plans.length > (prevProps?.plans?.length || 0)) {
      const hasSubscription = !_.isEmpty(subscriptions);

      const plan_selection = param("plan");
      const onSelectPlan = hasSubscription ? this.changePlan : this.selectPlan;
      if (
        CUAC_SETTINGS.GROUP.plan_select_url &&
        plan_selection &&
        plans.filter((plan) => plan._id === plan_selection).length > 0
      ) {
        onSelectPlan(plans.filter((plan) => plan._id == plan_selection)[0]);
      }
    }
  },

  selectPlan(plan) {
    this.props.dispatch(Actions.selectPlan(plan)).then((result) => {
      if (this.props.auth.user.status == "unregistered") {
        browserHistory.push("/register");
      } else {
        browserHistory.push("/account/payment");
      }
    });
  },

  selectPlanFromMarketing(plan) {
    let tool_id;
    if (this.props.location.query.tool_id) {
      tool_id = this.props.location.query.tool_id;
    }
    this.props.dispatch(Actions.selectPlan(plan));
    if (this.props.auth.user.status == "unregistered") {
      browserHistory.push(
        "/register?override=true&auto_start_tool_id=" + tool_id,
      );
    } else {
      browserHistory.push("/account/payment");
    }
  },

  addFreeSubscription(plan) {
    const { auth } = this.props;
    const amount = _.get(plan, "stripe_data.amount");
    if (amount === 0) {
      const token = _.get(auth, "user.token");
      const userId = _.get(auth, "user._id");
      this.spinnerStart("ADDING_SUBSCRIPTION");
      this.props
        .dispatch(Actions.addSubscription(token, userId, plan))
        .then((result) => {
          this.spinnerStop();
          if (result.type === REQUEST_ADD_SUBSCRIPTION_SUCCESS) {
            browserHistory.push("/account");
          }
        });
    }
  },

  changePlan(plan) {
    this.spinnerStart("PLAN_CHANGE_PROCESSING");
    const { auth, subscriptions } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    const subscriptionId = _.get(subscriptions, "[0].id");
    this.props
      .dispatch(
        Actions.updateSubscription(
          token,
          userId,
          subscriptionId,
          { plan: plan.stripe_key },
          plan,
        ),
      )
      .then((result) => {
        this.spinnerStop();
        if (result.type === REQUEST_UPDATE_SUBSCRIPTION_SUCCESS) {
          browserHistory.push("/account");
        }
      });
  },
  spinnerStart(messageKey) {
    this.props.dispatch(Actions.updateDisplay("appSpinner", messageKey));
  },

  spinnerStop() {
    this.props.dispatch(Actions.updateDisplay("appSpinner", "STOP"));
  },
  render() {
    const { hasCoupons, plans, subscriptions } = this.props;
    const hasSubscription = !_.isEmpty(subscriptions);
    const onSelectPlan = hasSubscription ? this.changePlan : this.selectPlan;

    let planListToUse = (
      <PlanList
        plans={plans}
        onSelectPlan={onSelectPlan}
        onAddFreeSubscription={this.addFreeSubscription}
        screener={false}
        userRegistered={
          this.props.auth.user.status == "unregistered" ? false : true
        }
        showCouponCapture={hasCoupons && _.isEmpty(subscriptions)}
      />
    );
    if (this.props.location.pathname == "/plan-select") {
      planListToUse = (
        <SubstancePlanList
          plans={plans}
          onSelectPlan={this.selectPlanFromMarketing}
          onAddFreeSubscription={this.addFreeSubscription}
          screener={false}
          userRegistered={
            this.props.auth.user.status == "unregistered" ? false : true
          }
        />
      );
    }

    // user needs to be redirected to external plan select page, show loading
    // spinner while they're being redirected
    if (CUAC_SETTINGS.GROUP.plan_select_url && !param("plan"))
      return <Loading loading={true} />;

    return (
      <div className="container screener-plans d-flex">
        <div className="row">
          <div className="col">{planListToUse}</div>
        </div>
      </div>
    );
  },
});

export default connect((state) => {
  const couponCode = state.plans?.couponCode;
  return {
    auth: state.auth,
    hasCoupons: state.plans.hasCoupons,
    plans: (state.plans?.plans ?? []).filter((plan) => {
      if (couponCode && couponCode.length)
        return (
          plan.coupon_code &&
          plan.coupon_code.toLowerCase().trim() ===
            couponCode.toLowerCase().trim()
        );
      return !plan.coupon_code || !plan.coupon_code.length;
    }),
    subscriptions: _.get(state, "subscriptions.subscriptions.data"),
  };
})(ScreenerPlanSelectionPage);
