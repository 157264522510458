"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";

import Actions from "actions";

import Button from "components/ux/mdl/button";
import ButtonConfirm from "components/ux/button-confirm";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import BSButton from "../blocks/BSButton.component.web";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { BLUE, FOREST_GREEN } from "../../stylesheets/colors";
import { browserHistory } from "react-router";

export const CreditCardDisplay = React.createClass({
  propTypes: {
    auth: React.PropTypes.object.isRequired,
    subscriptions: React.PropTypes.arrayOf(React.PropTypes.object),
    selfContained: React.PropTypes.bool,
    style: React.PropTypes.object,
  },

  render() {
    const {
      auth,
      onOpenCancelModal,
      onClickNav,
      onKeyDownNav,
      subscriptions,
      selfContained,
      style,
    } = this.props;
    const currentSubscription = _.get(subscriptions, "[0]");
    const renewalDate = new Date(
      _.get(currentSubscription, "current_period_end") * 1000
    );
    const card = _.get(auth, "user.stripe_customer.sources.data[0]", {});

    let checkup_sub;

    if (currentSubscription.plan.name == "The CheckUp") {
      checkup_sub = true;
    }

    return (
      <ul className="list-group list-group-flush">
        {checkup_sub ? null : (
          <li className="px-0 list-group-item d-flex align-items-center justify-content-between bg-transparent">
            <strong>
              {currentSubscription.cancel_at_period_end ? (
                <FormattedMessage
                  id="account_overview_cancels_on_date_label"
                  description="Account Overview - Cancels On Date label"
                  defaultMessage="Cancels On"
                />
              ) : (
                <FormattedMessage
                  id="account_overview_renewal_date_label"
                  description="Account Overview - Renewal Date label"
                  defaultMessage="Renewal Date"
                />
              )}
            </strong>
            <div className="d-flex align-items-center">
              <FormattedMessage
                id="account_overview_renewal_date"
                defaultMessage="{ renewalDate, date, medium }"
                values={{ renewalDate }}
              />
              <BSButton
                className="ms-2"
                variant="flat-blue"
                disabled={!!currentSubscription?.cancel_at_period_end}
                onClick={onOpenCancelModal}
                size="sm"
                tooltip="Cancel"
              >
                <MaterialCommunityIcons name="cancel" size={24} color="red" />
              </BSButton>
            </div>
          </li>
        )}
        <li className="px-0 list-group-item d-flex align-items-center justify-content-between bg-transparent">
          <strong>
            <FormattedMessage
              id="account_overview_payment_information_label"
              description="Account Overview - Payment Information label"
              defaultMessage="Payment Method"
            />
          </strong>
          <div className="d-flex align-items-center">
            {card ? card?.brand + " - " + card?.last4 : ""}
            <BSButton
              variant="flat-blue"
              size="sm"
              className="ms-2"
              linkTo="/account/payment"
              tooltip="Change"
            >
              <MaterialCommunityIcons
                name="credit-card-settings"
                size={24}
                color={BLUE}
              />
            </BSButton>
          </div>
        </li>
      </ul>
    );
  },
});
