"use strict";

import {
  REQUEST_GET_PASSWORD_RESET_SUCCESS,
  REQUEST_GET_PASSWORD_RESET_FAILURE,
  REQUEST_LOG_IN_USER_FAILURE,
  REQUEST_LOG_OUT_USER_SUCCESS,
  REQUEST_RESET_PASSWORD_SUCCESS,
  DISMISS_MESSAGE,
  REQUEST_REQUEST_PASSWORD_RESET_SUCCESS,
} from "actions/types";

export function reducer(
  state = {
    login: "",
  },
  action = { type: "" },
) {
  switch (action.type) {
    case REQUEST_LOG_IN_USER_FAILURE:
      return {
        ...state,
        login: action.payload.message,
      };
    case REQUEST_REQUEST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        requestReset: "SUBMITTED",
      };
    case REQUEST_LOG_OUT_USER_SUCCESS:
      return {
        ...state,
        login: "LOGGED_OUT",
      };
    case REQUEST_GET_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        resetPassword: "GOOD_TOKEN",
      };
    case REQUEST_GET_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        resetPassword: "BAD_TOKEN",
      };
    case REQUEST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: "SUCCESS",
      };
    case DISMISS_MESSAGE:
      return {
        ...state,
        [action.payload.key]: "",
      };
    default:
      return state;
  }
}
