/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import React from "react";
import { FormattedMessage } from "react-intl";

import RadioSet from "components/ux/radio-set-dynamic";

export const YesNoQuestion = React.createClass({
  //    mixins: [],
  //    statics: {},

  propTypes: {
    field: React.PropTypes.object.isRequired,
    fieldName: React.PropTypes.string.isRequired,
    style: React.PropTypes.object,
    text: React.PropTypes.object.isRequired,
    updateResponse: React.PropTypes.func.isRequired,
  },

  //    getInitialState() {},
  //    getDefaultProps() {},
  //    componentWillMount() {},
  //    componentDidMount() {},
  //    componentWillReceiveProps(nextProps) {},
  //    shouldComponentUpdate(nextProps, nextState) {},
  //    componentWillUpdate(nextProps, nextState) {},
  //    componentDidUpdate(prevProps, prevState) {},
  //    componentWillUnmount() {},

  shouldComponentUpdate: function (nextProps, nextState) {
    const next = _.get(nextProps, "field.value"),
      current = _.get(this.props, "field.value");
    return next !== current;
  },

  onChange(event) {
    this.props.updateResponse({ [event.target.name]: event.target.value });
  },

  render() {
    const { fieldName, style, text, yes_no_values } = this.props;
    let field = this.props.field;
    field.value = parseInt(field.value);
    const yesNoValues = yes_no_values || ["1", "0"];
    const options = [
      {
        text: {
          id: "yes_no_question_yes",
          defaultMessage: "Yes",
        },
        value: yesNoValues[0],
      },
      {
        text: {
          id: "yes_no_question_no",
          defaultMessage: "No",
        },
        value: yesNoValues[1],
      },
    ];
    return (
      <div className="question-block multiple-choice" style={style}>
        <RadioSet
          options={options}
          questionText={text}
          number={true}
          fieldName={fieldName}
          {...field}
          onChange={this.onChange}
        />
      </div>
    );
  },
});
