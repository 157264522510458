"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  icon: {
    color: "#62913e",
    fontSize: "28px",
    marginBottom: "5px",
    display: "inline",
  },
});

export const H3 = React.createClass({
  render() {
    return (
      <h3
        className={
          this.props.className
            ? "h3-icon-paragraph " + this.props.className
            : "h3-icon-paragraph"
        }
      >
        <FontAwesomeIcon
          className="h3-paragraph-icon"
          icon={faAngleRight}
          style={styles.icon}
        />
        {this.props.children}
      </h3>
    );
  },
});
