/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require */
"use strict";

// ========= LIBRARY COMPONENTS =========
import React from "react";
import { reduxForm, Field } from "redux-form";
const { TextInput } = require("components/ux/mdl/text-field");

var TextFieldReduxForm = React.createClass({
  // ======= REACT LIFECYCLE ============================

  getInitialState: function () {
    return { focus: false };
  },

  // ======= LOCAL METHODS =============================
  handleBlur(event) {
    this.setState({ focus: false }, this.upstreamBlur(event));
  },

  handleChange(event) {
    if (this.props.save) {
      this.props.save(event.target.value);
    }
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  },

  upstreamBlur(event) {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  },

  handleFocus(event) {
    this.setState({ focus: true });
    if (this.props.selectOnFocus) {
      document.getElementById(this.props.id).select();
    }
  },

  // ======= RENDER =====================================
  renderTraditional: function (field) {
    if (field) {
      return (
        <TextInput
          {...field.input}
          isInvalid={
            !this.state.focus && field.meta.error && field.meta.touched
          }
          errorText={field.meta.error}
          autoComplete={this.props.autoComplete}
          {...this.props}
          {...field.input}
        >
          {this.props.children}
        </TextInput>
      );
    } else {
      return (
        <TextInput
          isInvalid={
            !this.state.focus && this.props.error && this.props.touched
          }
          errorText={this.props.error}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          autoComplete={this.props.autoComplete}
          {...this.props}
        >
          {this.props.children}
        </TextInput>
      );
    }
  },
  render: function () {
    if (this.props.name) {
      return (
        <Field
          name={this.props.name}
          component={this.renderTraditional}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
      );
    } else {
      return this.renderTraditional();
    }
  },
});

export default TextFieldReduxForm;
