"use strict";

import { moduleSelector } from "selectors/module";
import { pageSelector } from "selectors/page";
import { responsesByToolSelector } from "selectors/responses-by-tool";
import { screenerPageSelector } from "selectors/screener-page";
import { sectionSelector } from "selectors/section";
import { toolSelector } from "selectors/tool";
import { userNormsSelector } from "selectors/user-norms";
import { userProfileSelector } from "selectors/user-profile";

const selectors = {
  moduleSelector,
  pageSelector,
  responsesByToolSelector,
  screenerPageSelector,
  sectionSelector,
  toolSelector,
  userNormsSelector,
  userProfileSelector,
};

export default selectors;
