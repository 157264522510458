/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage, FormattedDate, FormattedTime } from "react-intl";
import InfoBug from "components/popover/infobug";

import { TrackerTypes } from "api/tracker-entries";
import Actions from "actions";
import { connect } from "react-redux";
import Accordion from "components/ux/accordion";
import ButtonConfirm from "components/ux/button-confirm";
import Button from "components/ux/mdl/button";
import Paginator from "lib/Paginator";
import { itemsPerPage } from "utils/pagination";
import Moment from "moment";
Moment.locale("en");
import momentTZ from "lib/moment-timezone-with-data";
import {
  faChevronCircleDown,
  faChevronCircleRight,
  faClipboard,
  faEdit as faPencilSquareO,
  faTimes,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
const SolutionList = React.createClass({
  propTypes: {
    tag: React.PropTypes.string,
    dispatch: React.PropTypes.func.isRequired,
    trackerEntries: React.PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      openEntries: [],
      displayedEntries: {
        start: 0,
        end: 5,
      },
    };
  },

  toggleEntry(index) {
    let openEntries = this.state.openEntries;
    if (openEntries.indexOf(index) == -1) {
      openEntries.push(index);
    } else {
      openEntries = _.without(openEntries, index);
    }

    this.setState({
      openEntries: openEntries,
    });
  },

  addSolutionTrackerEntry() {
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "newSolutionTracker",
        modalContent: true,
      }),
    );
  },

  editSolutionTrackerEntry(trackerEntryId, trackerEntry) {
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "editSolutionTracker",
        solutionTrackerEntry: {
          _id: trackerEntryId,
          entry: trackerEntry,
        },
        modalContent: true,
      }),
    );
  },

  solutionTrackerAddOutcome(trackerEntryId, trackerEntry) {
    this.props.dispatch(
      Actions.showModal("appModal", {
        modalNotifications: "solutionTrackerAddOutcome",
        solutionTrackerEntry: {
          _id: trackerEntryId,
          entry: trackerEntry,
        },
        modalContent: true,
      }),
    );
  },

  deleteSolutionTrackerEntry(trackerEntryId, currentPage, totalEntries) {
    this.props.dispatch(Actions.updateDisplay("appSpinner", "PLEASE_WAIT"));
    const { auth, dispatch } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    dispatch(
      Actions.deleteTrackerEntry(
        token,
        userId,
        trackerEntryId,
        TrackerTypes.SOLUTION,
      ),
    ).then(() => {
      this.updateCurrentPage(currentPage, totalEntries);
      dispatch(Actions.updateDisplay("appSpinner", "STOP"));
    });
  },

  updateCurrentPage(currentPage, totalEntries) {
    if (currentPage.start >= itemsPerPage && totalEntries - 1 === 0) {
      this.setState({
        displayedEntries: {
          start: this.state.displayedEntries.start - itemsPerPage,
          end: this.state.displayedEntries.end - itemsPerPage,
        },
      });
    }
  },

  onChangePage(page) {
    var end = page * itemsPerPage;
    var start = end - itemsPerPage;
    this.setState({
      displayedEntries: {
        start: start,
        end: end,
      },
    });
  },

  render() {
    const { tag, trackerEntries } = this.props;
    const locus = this,
      openEntries = this.state.openEntries,
      toggleEntry = this.toggleEntry;
    const fullOrder = _.get(
      trackerEntries,
      TrackerTypes.SOLUTION + "Order",
      [],
    );
    const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
      return _.get(trackerEntries, trackerEntryId, {});
    });
    const filteredEntries =
      typeof tag !== "undefined"
        ? _.filter(fullOrderEntries, (trackerEntry) => {
            return _.get(trackerEntry, "tag") === tag;
          })
        : fullOrderEntries;
    const sortedEntries = _.sortByOrder(
      filteredEntries,
      ["data.dt_occurred"],
      ["desc"],
    );
    const page = {
      start: this.state.displayedEntries.start,
      end: this.state.displayedEntries.end,
    };
    const orderEntries = sortedEntries.slice(page.start, page.end);
    const popOverLabelStyle = {
      fontWeight: "700",
      padding: "0 8px 0 0",
      textTransform: "uppercase",
      color: "rgb(139, 195, 74)",
      display: "inline-block",
      width: "50%",
      textAlign: "right",
    };
    const popOverTime = {
      fontSize: "13px",
      padding: "0 0 4px 0",
      margin: "-12px 0 8px 0",
      lineHeight: "16px",
    };
    const popOverPStyle = {
      fontSize: "13px",
      padding: "0 0 4px 0",
      margin: "0",
      lineHeight: "16px",
    };
    const confirmMessage = {
      id: "delete_solution_tracker_confirm_instructions",
      description: "Solution Tracker -- Delete entry, confirmation message",
      defaultMessage: "Are you sure you want to delete this tracker entry?",
    };

    const { auth } = this.props;
    const user_timezone = _.get(auth, "user.user_timezone");

    return (
      <div className="section section-tracker-list col-1-1">
        <div
          className="contain-box-wrap"
          style={{ maxWidth: "690px", marginTop: "20px" }}
        >
          <div className="row">&nbsp;</div>
          <br />
          <div className="contain-box-main">
            <h3 className="section-title contain-box-title">
              <FormattedMessage
                id="solution_list_title"
                defaultMessage="Your Recent Solutions"
              />
            </h3>
            <div className="tracker-list-data">
              <div className="tracker-episode header">
                <div className="tracker-row">
                  <div className="tracker-entry tracker-entry-title solution-tracker-entry">
                    <FormattedMessage
                      id="solution_data_header_title"
                      defaultMessage="Title"
                    />
                  </div>
                  <div className="tracker-entry tracker-entry-date-time solution-tracker-entry">
                    <FormattedMessage
                      id="drink_data_header_date"
                      defaultMessage="Last Updated"
                    />
                  </div>

                  <div className="clearfix" />
                </div>
              </div>
              {orderEntries.map((fullTrackerEntry, index) => {
                const trackerEntry = _.get(fullTrackerEntry, "data", {});
                var dtOccurred = Moment(
                  fullTrackerEntry.dt_u.split("+")[0],
                ).format("YYYY-MM-DD HH:mm:ss");
                dtOccurred = momentTZ.tz(dtOccurred, "UTC");
                var dateOccurred = dtOccurred.clone().tz(user_timezone);
                var timeOccurred = dateOccurred.format("hh:mm A");
                dateOccurred = dateOccurred.format("MMM DD, YYYY");
                const dateUpdated = new Date(fullTrackerEntry.dt_u),
                  isOpen = openEntries.indexOf(index) !== -1,
                  accordionButton =
                    "accordion-toggle" + (isOpen ? " open" : ""),
                  accordionButtonIcon = isOpen
                    ? faChevronCircleDown
                    : faChevronCircleRight;

                return !_.isEmpty(trackerEntry) ? (
                  <div className="tracker-episode" key={"solution_" + index}>
                    <div className="tracker-row">
                      <div className="tracker-entry tracker-entry-title list-content">
                        {trackerEntry.title}
                      </div>
                      <div className="tracker-entry tracker-entry-date-time list-content">
                        <div className="tracker-date">{dateOccurred}</div>
                        <div className="tracker-time">{timeOccurred}</div>
                      </div>
                      <div className="tracker-delete-btn">
                        <Button
                          className={accordionButton}
                          flat={true}
                          onClick={toggleEntry.bind(locus, index)}
                        >
                          <FontAwesomeIcon icon={accordionButtonIcon} />
                        </Button>
                        {!_.get(trackerEntry, "results") ||
                        !_.get(trackerEntry, "next_steps") ? (
                          <Button
                            className="tracker-entry-btn tracker-action-btn solution-tracker-action-btn"
                            key={"outcome" + fullTrackerEntry._id}
                            id={fullTrackerEntry._id}
                            onClick={() =>
                              this.solutionTrackerAddOutcome(
                                fullTrackerEntry._id,
                                trackerEntry,
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faClipboard} />
                            <span className="hidden-sm">Outcome</span>
                          </Button>
                        ) : null}
                        <Button
                          className="tracker-entry-btn tracker-action-btn solution-tracker-action-btn"
                          key={"edit" + fullTrackerEntry._id}
                          id={fullTrackerEntry._id}
                          onClick={() =>
                            this.editSolutionTrackerEntry(
                              fullTrackerEntry._id,
                              trackerEntry,
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faPencilSquareO} />
                          <span className="hidden-sm">Edit</span>
                        </Button>
                        <ButtonConfirm
                          flat={false}
                          noColor={false}
                          ripple={true}
                          message={confirmMessage}
                          className="tracker-entry-btn tracker-action-btn solution-tracker-action-btn"
                          onClick={(event) =>
                            this.deleteSolutionTrackerEntry(
                              fullTrackerEntry._id,
                              page,
                              orderEntries.length,
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} />
                          <FormattedMessage
                            id="delete_solution_tracker_button"
                            description="Delete Solution Tracker Entry"
                            defaultMessage="Delete"
                          />
                        </ButtonConfirm>
                      </div>
                      <Accordion
                        collapsed={!isOpen}
                        className="tracker-entry-block"
                      >
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="solution_data_popover_title_label"
                              defaultMessage="Title"
                            />
                          </span>
                          {trackerEntry.title}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="solution_data_popover_problem_label"
                              defaultMessage="What is the problem?"
                            />
                          </span>
                          {trackerEntry.problem}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="solution_data_popover_solutions_label"
                              defaultMessage="What are the solutions you can think of?"
                            />
                          </span>
                          {trackerEntry.solutions}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="solution_data_popover_undesirable_solutions_label"
                              defaultMessage="Which solutions are undesirable?"
                            />
                          </span>
                          {trackerEntry.undesirable_solutions}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="solution_data_popover_solution_to_try_label"
                              defaultMessage="Which solution will you try?"
                            />
                          </span>
                          {trackerEntry.solution_to_try}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="solution_data_popover_obstacles_label"
                              defaultMessage="What could get in your way?"
                            />
                          </span>
                          {trackerEntry.obstacles}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="solution_data_popover_obstacle_solutions_label"
                              defaultMessage="How will you address each of these obstacles?"
                            />
                          </span>
                          {trackerEntry.obstacle_solutions}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="solution_data_popover_when_label"
                              defaultMessage="When will you try out the solution?"
                            />
                          </span>
                          {trackerEntry.when}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="solution_data_popover_results_label"
                              defaultMessage="What happened when you tried the solution?"
                            />
                          </span>
                          {trackerEntry.results}
                        </p>
                        <p className="tracker-entry-p">
                          <span className="tracker-entry-label">
                            <FormattedMessage
                              id="solution_data_popover_next_steps_label"
                              defaultMessage="Do you need to refine the solution or try another. If so, what changes will you make or which solution will you try?"
                            />
                          </span>
                          {trackerEntry.next_steps}
                        </p>
                      </Accordion>

                      <div className="clearfix" />
                    </div>
                  </div>
                ) : null;
              })}
              <div className="mdl-layout__tab-bar-right-button addNewTrackerBtn">
                <Button
                  key="button"
                  ref="menuButton"
                  onClick={this.addSolutionTrackerEntry}
                >
                  <FontAwesomeIcon icon={faPlusSquare} />
                  Add New
                </Button>
              </div>
            </div>
            {orderEntries.length > 0 ? (
              <Paginator
                className={"pagination"}
                page={this.state.displayedEntries.end / itemsPerPage}
                max={Math.ceil(fullOrder.length / itemsPerPage)}
                onChange={this.onChangePage}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  },
});
export default connect((state) => ({
  auth: state.auth,
}))(SolutionList);
