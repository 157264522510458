"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "components/ux/mdl/button";
import { Block } from "components/blocks/block";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
export const SubstancePlanList = React.createClass({
  propTypes: {
    onSelectPlan: React.PropTypes.func.isRequired,
    plans: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
  },

  render() {
    const { onSelectPlan, onAddFreeSubscription, screener } = this.props;
    // set up our final call to action button to use outside the scope of the price function for plans
    let callToAction = null;
    let callToAction1 = null;
    let callToAction2 = null;
    let twoColumns = false;
    let plans = this.props.plans;

    const price = (plan, planData) => {
      const { name, auto_cancel, tagline, stripe_key, order, button_text } =
        plan;
      const { amount, currency, id, interval_count, interval } = planData;
      const realAmount = Number(amount / 100).toFixed(0);
      const realCurrency = currency.toLowerCase() === "usd" ? "$" : currency;

      const abstainMarkup = [
        {
          default_responses: {},
          type: "informational_text",
          components: [
            {
              type: "unescaped",
              className: "paragraph",
              children: [
                {
                  text: {
                    defaultMessage:
                      '<img src="' +
                      CUAC_SETTINGS.S3_ROOT +
                      '/app/img/ux/abstain-icon.png" alt="Icon for the Abstain program, shown as a hand giving the \'stop\' signal."> The Abstain program will help you:',
                    id: "plans_3month_p_0",
                  },
                  type: "formatted_message",
                },
              ],
            },
            {
              type: "ul",
              children: [
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Increase and maintain motivation for change",
                        id: "plans_3month_ul_1_li_0",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage: "Cope with urges",
                        id: "plans_3month_ul_1_li_1",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Manage thoughts, feelings, and behaviors",
                        id: "plans_3month_ul_1_li_2",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Live a balanced life and reduce risk of relapse.",
                        id: "plans_3month_ul_1_li_3",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
              ],
            },
            {
              type: "p",
              children: [
                {
                  text: {
                    defaultMessage:
                      "In 2 clinical trials, the Choices Program helped participants to significantly reduce their drinking and alcohol-related problems.",
                    id: "plans_3month_p_1",
                  },
                  type: "formatted_message",
                },
              ],
            },
          ],
        },
      ];

      const moderateMarkup = [
        {
          default_responses: {},
          type: "informational_text",
          components: [
            {
              type: "unescaped",
              className: "paragraph",
              children: [
                {
                  text: {
                    defaultMessage:
                      '<img src="' +
                      CUAC_SETTINGS.S3_ROOT +
                      '/app/img/ux/moderate-icon.png" alt="Icon for the Moderate program, shown as a descending arrow on a graph."> The Moderate program will help you:',
                    id: "plans_3month_p_0",
                  },
                  type: "formatted_message",
                },
              ],
            },
            {
              type: "ul",
              children: [
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage: "Set safe and realistic goals",
                        id: "plans_3month_ul_0_li_0",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Learn how to keep track of your drinking",
                        id: "plans_3month_ul_0_li_1",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage:
                          "Learn to manage your triggers for heavy drinking",
                        id: "plans_3month_ul_0_li_2",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
                {
                  type: "li",
                  children: [
                    {
                      text: {
                        defaultMessage: "Maximize the chances of success.",
                        id: "plans_3month_ul_0_li_3",
                      },
                      type: "formatted_message",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ];

      const marker = { fontSize: "26px", fontWeight: "500" },
        number = { fontSize: "26px", fontWeight: "500", padding: "0 4px" },
        description = { padding: "0 16px 12px 4px" };

      const priceDescription = {
        id: "plan_" + id + "_price_description",
        defaultMessage:
          "{ interval_count, plural, one {per " +
          interval +
          "} other {every # " +
          interval +
          "s} }",
      };
      const priceDescriptionValues = { interval_count };
      const priceDescriptionComponent = (
        <span className="price-description" style={description}>
          <FormattedMessage
            {...priceDescription}
            values={priceDescriptionValues}
          />
        </span>
      );
      const choices3MonthButton =
        order == 2 ? (
          <div>
            <div className="plan-button-account-page">
              <p style={{ textAlign: "center" }}>{plan.footline}</p>
              <Button ref="chooseTarget" onClick={() => onSubmit(plan)}>
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  style={{ color: "rgb(250,250,250)", opacity: "0.7" }}
                />
                <FormattedMessage
                  id="plan_selection_button1_select"
                  defaultMessage="{button_text}"
                  values={{ button_text: button_text }}
                  description="Select"
                />
              </Button>
            </div>
          </div>
        ) : null;
      // build price-info into call to action button since there is no data pulled
      const choices1YearButton =
        order == 3 ? (
          <div className="program-choice-item-1-year">
            <div className="price-info">
              <span className="price-marker" style={marker}>
                {realCurrency}
              </span>
              <span className="price-amount" style={number}>
                {realAmount}
                {priceDescriptionComponent}
              </span>
            </div>
            <p style={{ textAlign: "center" }}>{plan.footline}</p>
            <div className="plan-button2-account-page">
              <Button ref="chooseTarget" onClick={() => onSubmit(plan)}>
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  style={{ color: "rgb(250,250,250)", opacity: "0.7" }}
                />
                <FormattedMessage
                  id="plan_selection_button2_select"
                  defaultMessage="{button_text}"
                  values={{ button_text: button_text }}
                  description="Select"
                />
              </Button>
            </div>
          </div>
        ) : null;
      // if we have a checkupButton, or choices buttons, we'll assign call to action to it
      if (choices3MonthButton !== null) {
        callToAction1 = choices3MonthButton;
      }
      if (choices1YearButton !== null) {
        callToAction2 = choices1YearButton;
      }

      const onSubmit =
        amount === 0 && this.props.userRegistered
          ? onAddFreeSubscription
          : onSelectPlan;

      // block requires responses, so we create an empty object here for now, we should look at if it needs to be required
      const emptyResponse = {};

      return (
        <div>
          <div className="callout-A">
            <p>{plan.description}</p>
            {plan.moderate
              ? moderateMarkup.map((component, index) => {
                  return (
                    <Block
                      key={"block_moderate_" + index}
                      component={component}
                      index={index}
                      responses={emptyResponse}
                    />
                  );
                })
              : null}
            {plan.abstain
              ? abstainMarkup.map((component, index) => {
                  return (
                    <Block
                      key={"block_abstain_" + index}
                      component={component}
                      index={index}
                      responses={emptyResponse}
                    />
                  );
                })
              : null}
            <div className="clearfix" />
          </div>
          {
            <div className="price-info">
              <span className="price-marker" style={marker}>
                {realCurrency}
              </span>
              <span className="price-amount" style={number}>
                {realAmount}
                {priceDescriptionComponent}
              </span>
            </div>
          }
        </div>
      );
    };
    var accountPlans = [];

    plans.map(function (plan) {
      // intiate the data so we can access the button for 1 year plan
      if (plan.order == 3) {
        price(plan, _.get(plan, "stripe_data"));
      } else if (plan.order == 2) {
        accountPlans.push(plan);
      }
    });
    return (
      <div className="section section-account-overview col-1-1">
        <h1 className="section-title">
          <FormattedMessage
            id="plan_list_page_title"
            description="Plan Choice List -- page title"
            defaultMessage="Plans"
          />
        </h1>
        <div className="informational-text">
          <div className="paragraph">
            <span>
              CheckUp & Choices was designed by clinical psychologists to help
              you understand and, if you choose to do so, change your drinking
              and/or drug use. The program demonstrated effectiveness in
              clinical trials funded by NIH/NIAAA. Our program comes with a
              risk-free, money-back guarantee.
            </span>
          </div>
        </div>
        <div className="screener-icon-center">
          <img
            src={CUAC_SETTINGS.S3_ROOT + "/app/img/ux/screener-plan2.png"}
            alt="Moderate/Abstain icon shown as bottle with descending arrow on graph."
          />
        </div>
        {_.sortBy(accountPlans, (plan) => {
          return _.get(plan, "stripe_data.amount");
        }).map((plan, index) => {
          const planData = _.get(plan, "stripe_data");
          return (
            <div
              className={"program-choice-item center"}
              key={"program-" + index}
            >
              <h4>{plan.tagline}</h4>
              {price(plan, planData)}
              <div>{callToAction1}</div>
              {callToAction2}
            </div>
          );
        })}
      </div>
    );
  },
});
