"use strict";

import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import Moment from "moment";
Moment.locale("en");
import TextFieldReduxForm from "components/ux/text-field-redux-form";
import CheckboxGroup from "../ux/CheckboxGroup.component.web";
import RadioGroupReduxForm from "../ux/RadioGroupReduxForm.component.web";
import BSButton from "../blocks/BSButton.component.web";

const messages = defineMessages({
  cancellation_reason_text_label: {
    id: "cancellation_reason_text_label",
    description: "Cancellation Reason Text",
    defaultMessage: "Other/Please Elaborate:",
  },
});

const cancelOptions = [
  {
    label:
      "The tools were helpful, and I feel like I can manage this on my own, without Checkup & Choices.",
    value:
      "The tools were helpful, and I feel like I can manage this on my own, without Checkup & Choices.",
  },
  {
    label:
      "I found another tool or method for managing my drinking that works better for me.",
    value:
      "I found another tool or method for managing my drinking that works better for me.",
  },
  {
    label: "The tools were not helpful.",
    value: "The tools were not helpful.",
  },
  {
    label: "The tools were confusing to use.",
    value: "The tools were confusing to use.",
  },
  {
    label: "I was not using the tools as much as I thought I would.",
    value: "I was not using the tools as much as I thought I would.",
  },
  {
    label: "It is too expensive.",
    value: "It is too expensive.",
  },
];

const cancelPermissionOptions = [
  {
    text: {
      id: "yes",
      defaultMessage: "Yes",
    },
    value: "yes",
  },
  {
    text: {
      id: "no",
      defaultMessage: "No",
    },
    value: "no",
  },
];

const CancelSurvey = React.createClass({
  propTypes: {
    fields: React.PropTypes.object.isRequired,
    handleSubmit: React.PropTypes.func.isRequired,
  },

  onChange(fieldName, event) {
    this.props.change(fieldName, event.target.value);
  },

  onCheckboxSelect: function (setID, values) {
    this.props.fields.cancellation_reason.onChange(values);
  },

  render() {
    const {
      handleSubmit,
      intl: { formatMessage },
    } = this.props;

    return (
      <form id="account_cancel" name="account_cancel">
        <label className="baseline-form" htmlFor="close_account_reason">
          <FormattedMessage
            id="cancellation_reason_label"
            defaultMessage="I am canceling my subscription because...(select all relevant options)"
          />
        </label>
        <Field
          name="cancellation_reason"
          component={CheckboxGroup}
          options={cancelOptions}
          columns={1}
          checkbox={true}
          multiple={true}
        />
        <div className="my-2">
          <TextFieldReduxForm
            name="cancellation_reason_text"
            textArea={true}
            floatingLabel={true}
            labelText={formatMessage(messages.cancellation_reason_text_label)}
          />
        </div>
        <p className="h5 my-3">
          <FormattedMessage
            id="cancellation_contact_thanks_label"
            defaultMessage="Thank you! We appreciate your feedback."
          />
        </p>
        <RadioGroupReduxForm
          title={
            <FormattedMessage
              id="cancellation_contact_permission_label"
              defaultMessage="Can we contact you for more feedback?"
            />
          }
          name="cancellation_contact_permission"
          options={cancelPermissionOptions}
          fieldsetClassName="d-flex"
          inputClassName="me-1"
          fieldContainerClassName="me-3"
        />
        <BSButton
          variant="green"
          onClick={(event) =>
            this.props.submitCancelModal(this.props.values, event)
          }
        >
          <FormattedMessage
            id="cancel_button_save"
            description="Submit Cancel Survey"
            defaultMessage="Submit and Cancel Subscription at the End of Your Billing Period"
          />
        </BSButton>
      </form>
    );
  },
});
const formFields = [
  "cancellation_reason",
  "cancellation_reason_text",
  "cancellation_contact_permission",
];
let form = reduxForm({
  form: "accountCancel",
  fields: formFields,
})(CancelSurvey);

const selector = formValueSelector("agreementTracker"); // <-- same as form name
form = connect((state) => {
  return formFields.reduce(
    (dict, fieldName) =>
      Object.assign({ [fieldName]: selector(state, fieldName) }, dict),
    {},
  );
})(form);

export default injectIntl(form);
