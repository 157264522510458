import { getNorms as getNormsAPI } from "api/norms";
import {
  REQUEST_NORMS,
  REQUEST_NORMS_SUCCESS,
  REQUEST_NORMS_FAILURE,
} from "actions/types";

function requestGetNorms() {
  return { type: REQUEST_NORMS };
}

function requestGetNormsSuccess(payload) {
  return {
    payload,
    type: REQUEST_NORMS_SUCCESS,
  };
}

function requestGetNormsFailure() {
  return { type: REQUEST_NORMS_FAILURE };
}

export default function getNorms(token) {
  return (dispatch) => {
    dispatch(requestGetNorms());
    return getNormsAPI(token)
      .then((payload) => dispatch(requestGetNormsSuccess(payload)))
      .catch(() => dispatch(requestGetNormsFailure()));
  };
}
