/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, module, require, componentHandler */
"use strict";

import React from "react";
import { findDOMNode } from "react-dom";
import classnames from "classnames";
import _ from "lodash";
import {
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

const mdl = window.componentHandler;

const baseClasses = {
  div: {
    "mdl-textfield": true,
    "mdl-js-textfield": true,
    "number-input": true,
  },
  input: {
    "mdl-textfield__input": true,
  },
  label: {
    "mdl-textfield__label": true,
  },
  error: {
    "mdl-textfield__error": true,
  },
};

const NumberInput = React.createClass({
  propTypes: {
    className: React.PropTypes.string,
    type: React.PropTypes.string,
    name: React.PropTypes.string,
    pattern: React.PropTypes.string,
    floatingLabel: React.PropTypes.bool,
    expanding: React.PropTypes.bool,
    expandingIcon: React.PropTypes.element,
    disabled: React.PropTypes.bool,
    noBlur: React.PropTypes.bool, // noBlur={true} sanitizes NumberInput for use in "regular" forms, not tied to DynamicPage
  },

  mdlComponent: null,

  componentDidMount: function () {
    const node = findDOMNode(this.refs.target);
    if (node) {
      this.mdlComponent = mdl.upgradeElement(node, "MaterialTextfield");
    }
  },

  UNSAFE_componentWillReceiveProps: function () {},

  componentDidUpdate: function (prevProps, prevState) {
    // TODO: Disabling not good enough.
    // const node = findDOMNode(this.refs.target);
    // if (node) { node.MaterialTextfield.change(); }
  },

  componentWillUnmount: function () {
    if (findDOMNode(this.refs.target)) {
      mdl.downgradeElements(findDOMNode(this.refs.target));
    }
  },

  render: function () {
    const {
      value,
      className,
      pattern,
      type,
      isInvalid,
      disabled,
      readOnly,
      id,
      onChange,
      onBlur,
      onFocus,
      onEnter,
      min,
      max,
      name,
      style,
      textArea,
      step,
      noButtons,
      labelText,
      errorText,
      floatingLabel,
      expanding,
      expandingIcon,
      noBlur,
      currency,
      ariaText,
      onChangeOverride,
    } = this.props;

    const propStep = step ? Number(step) : 1;

    let classes = {
      div: classnames(
        baseClasses.div,
        {
          "mdl-textfield--floating-label": floatingLabel,
          "mdl-textfield--expandable": expanding,
          "mdl-programmatic-invalid": isInvalid,
          "mdl-textfield--text-area": textArea,
        },
        className,
      ),
      input: classnames(baseClasses.input, {}),
      label: classnames(baseClasses.label, {}),
      error: classnames(baseClasses.error, {}),
    };

    return (
      <div ref="target" style={style} className={classes.div}>
        <InputNumber
          id={id ? id : name}
          disabled={disabled}
          key="input"
          className={classes.input}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          noBlur={noBlur}
          onFocus={onFocus}
          onEnter={onEnter}
          min={min}
          max={max}
          step={propStep}
          noButtons={noButtons}
          readOnly={readOnly}
          pattern={pattern}
          value={value}
          currency={currency}
          ariaText={ariaText}
          onChangeOverride={onChangeOverride}
          ariaLabelledBy={ariaText ? null : id ? id : name + "-label"}
        />
        {labelText && (
          <label
            key="label"
            className={classes.label}
            id={id ? id : name + "-label"}
          >
            {labelText}
          </label>
        )}
        <span
          style={isInvalid ? { visibility: "visible" } : {}}
          key="error"
          className={classes.error}
        >
          {errorText}
        </span>
      </div>
    );
  },
});

export default NumberInput;

/* ======== INPUT-NUMBER, ADAPTED FROM HERE:  https://github.com/wangzuo/react-input-number =============== */

var KEY_UP = 38;
var KEY_DOWN = 40;
var KEY_ENTER = 13;

const InputNumber = React.createClass({
  propTypes: {
    value: React.PropTypes.oneOfType([
      React.PropTypes.string,
      React.PropTypes.number,
    ]),
    step: React.PropTypes.number,
    min: React.PropTypes.string,
    max: React.PropTypes.string,
    name: React.PropTypes.string,
    onKeyUp: React.PropTypes.func,
    onKeyDown: React.PropTypes.func,
    onChange: React.PropTypes.func,
  },

  getDefaultProps() {
    return {
      step: 1,
    };
  },

  getInitialState() {
    return {
      value: this.parse(this.props.value),
    };
  },

  componentDidMount() {
    this.debouncedChange = _.debounce(this.change, 250);
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (nextProps.currency == "USD" || nextProps.currency == "EUR") &&
      typeof nextProps.value !== "undefined"
    ) {
      this.setState({
        value: parseFloat(nextProps.value).toFixed(2),
      });
    } else {
      this.setState({
        value: parseFloat(nextProps.value),
      });
    }
  },

  // ========== LOCAL METHODS ===========

  parse(val) {
    return parseNumber(
      val,
      this.props.step,
      this.props.max,
      this.props.min,
      this.props.currency,
    );
  },

  change(value, override = false) {
    value = this.parse(value);
    // show the user his/her input
    this.setState({ value: value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    if (this.props.onBlur) {
      if (!this.props.noBlur || override) {
        this.props.onBlur({ target: { value: value, name: this.props.name } });
      }
    }
  },

  up() {
    let value = this.state.value || 0;
    let newValue = parseFloat(value) + parseFloat(this.props.step);
    // round to maximum of two decimals
    newValue = round(newValue, 2);
    if (this.props.currency == "USD" || this.props.currency == "EUR") {
      newValue = newValue.toFixed(2);
    }
    this.setState({ value: newValue });
    this.debouncedChange(newValue, true);
  },

  down() {
    let value = this.state.value || 0;
    let newValue = parseFloat(value) - parseFloat(this.props.step);
    // round to maximum of two decimals
    newValue = round(newValue, 2);
    if (this.props.currency == "USD" || this.props.currency == "EUR") {
      newValue = newValue.toFixed(2);
    }
    this.setState({ value: newValue });
    this.debouncedChange(newValue, true);
  },

  keyDownUp(event) {
    if (event.which == 13 || event.which == 32) {
      this.up();
    }
  },

  keyDownDown(event) {
    if (event.which == 13 || event.which == 32) {
      this.down();
    }
  },

  handleKeyDown(e) {
    switch (e.keyCode) {
      case KEY_UP:
        e.preventDefault();
        this.up();
        break;
      case KEY_DOWN:
        e.preventDefault();
        this.down();
        break;
      case KEY_ENTER:
        e.preventDefault();
        break;
    }
  },

  handleKeyUp(e) {
    if (e.keyCode === KEY_ENTER) {
      e.preventDefault();
      this.change(this.state.value, true);
    }
  },

  handleChange(e) {
    /*
        this.setState({
            value: e.target.value
        });
        */
    // show the user his/her input
    if (!isNaN(parseFloat(e.target.value))) {
      // check for .0 numbers
      if (e.target.value.slice(-2) == ".0") {
        this.setState({ value: parseFloat(e.target.value).toFixed(1) });
      }
      // check for .00 numbers
      else if (e.target.value.slice(-3) == ".00") {
        this.setState({ value: parseFloat(e.target.value).toFixed(2) });
      }
      // check for .x0 numbers
      else if (
        e.target.value.charAt(e.target.value.length - 1) == "0" &&
        e.target.value.indexOf(".") > -1
      ) {
        this.setState({ value: parseFloat(e.target.value).toFixed(2) });
      }
      // anything else
      else {
        this.setState({ value: e.target.value });
      }
    }
    // otherwise set to 0
    else {
      this.setState({ value: 0 });
    }
    if (this.props.onChangeOverride) {
      this.change(e.target.value, true);
    }
  },

  handleBlur(e) {
    this.change(e.target.value, true);
  },

  // ========== RENDERING ====================

  render() {
    var props = blacklist(
      this.props,
      "step",
      "min",
      "max",
      "noButtons",
      "onKeyUp",
      "onKeyDown",
      "onChange",
    );

    const inputAdd = "Add to " + this.props.name;
    const inputSub = "Subtract from " + this.props.name;

    let buttons = [];
    if (!this.props.noButtons) {
      buttons = [
        <FontAwesomeIcon
          key="upButton"
          aria-controls={this.props.id ? this.props.id : this.props.name}
          className="number-button up"
          icon={faChevronCircleUp}
          onClick={this.up}
          role="button"
          tabIndex="0"
          onKeyDown={this.keyDownUp}
          aria-label={inputAdd}
        />,
        <FontAwesomeIcon
          key="downButton"
          aria-controls={this.props.id ? this.props.id : this.props.name}
          className="number-button down"
          icon={faChevronCircleDown}
          onClick={this.down}
          role="button"
          tabIndex="0"
          onKeyDown={this.keyDownDown}
          aria-label={inputSub}
        />,
      ];
    }

    return (
      <div className="number-input-inner">
        {buttons}
        <input
          {...props}
          type="number"
          step="0.01"
          value={this.state.value}
          onKeyUp={this.handleKeyUp}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          role="textbox"
          aria-live="polite"
          aria-label={this.props.ariaText}
          aria-labelledby={this.props.ariaLabelledBy}
          id={this.props.id ? this.props.id : this.props.name}
        />
      </div>
    );
  },
});

/* ============================== Helper Functions ====================================== */

/*  --- BLACKLIST ---- https://github.com/dcousens/blacklist ---- */

function blacklist(src) {
  var copy = {};
  var filter = arguments[1];

  if (typeof filter === "string") {
    filter = {};
    for (var i = 1; i < arguments.length; i++) {
      filter[arguments[i]] = true;
    }
  }

  for (var key in src) {
    // blacklist?
    if (filter[key]) {
      continue;
    }

    copy[key] = src[key];
  }

  return copy;
}

function parseNumber(propValue, step, propMax, propMin, currency) {
  const min = Number(propMin),
    max = Number(propMax);

  let value = propValue;

  if (value === "") {
    return "";
  }

  if (value) {
    value = parseFloat(value);
    if (isNaN(value)) {
      return "";
    }
  }

  if (typeof max === "number" && value > max) return max;
  if (typeof min === "number" && value < min) return min;

  if (step && value) {
    var p = (step.toString().split(".")[1] || []).length;
    if (p) {
      return parseFloat(value.toFixed(p));
    }
  }

  if (
    (currency == "USD" || currency == "EUR") &&
    typeof value !== "undefined"
  ) {
    value = value.toFixed(2);
  }

  return value;
}

function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}
