/* globals _, CUAC_SETTINGS, DEBUG, PROD, console, Stripe */
"use strict";

import React from "react";
import { FormattedMessage } from "react-intl";
import { eventPreventDefault as prevent } from "utils/tools";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";
import enterAnimation from "components/animations/animations";
import leaveAnimation from "components/animations/animations";
import { Button, Spinner } from "react-bootstrap";
import { FontAwesome } from "@expo/vector-icons";

const TextValuesChosen = React.createClass({
  propTypes: {
    className: React.PropTypes.string,
    responses_title: React.PropTypes.object,
    responses: React.PropTypes.arrayOf(React.PropTypes.string),
    removeTextValue: React.PropTypes.func.isRequired,
    updating: React.PropTypes.bool,
  },
  // ================= LOCAL METHODS =====================

  onRemoveTextValue(event, value) {
    prevent(event);
    if (this.props.updating) return;
    this.props.removeTextValue(value);
  },

  hashCode(s) {
    // source:  http://jsperf.com/hashcodelordvlad/47
    var hash = 0,
      i,
      chr,
      len;
    if (!s || s.length === 0) {
      return hash;
    }
    for (i = 0, len = s.length; i < len; i++) {
      chr = s.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  },

  // ================= RENDER ============================
  render() {
    const locus = this,
      { responses, responses_title, updating } = this.props,
      onRemoveTextValue = this.onRemoveTextValue,
      hashCode = this.hashCode;

    var rows = responses.map(function (data, i, arr) {
      const item = typeof data === "string" ? data : data.value;
      return (
        <Button
          disabled={updating}
          className="phrase btn-light-yellow m-2 d-flex align-items-center"
          key={hashCode(item)}
          role="button"
          tabIndex="0"
          data-message={item}
          style={{ color: "#0E323E", border: "none" }}
        >
          {item}
          <div className="ms-2" data-message={item}>
            <FontAwesome
              name="times-circle"
              size={20}
              onClick={(e) => onRemoveTextValue(e, item)}
              aria-label="Remove item:"
            />
          </div>
        </Button>
      );
    });
    var groupStyle = { margin: "10px 0" };

    const phrasesClass = this.props.className
      ? "chosen-phrases " + this.props.className
      : "chosen-phrases";

    return (
      <div className={phrasesClass}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h4 className="title fw-bold" style={{ color: "#0E323E" }}>
            <FormattedMessage {...responses_title} />
          </h4>
          {this.props.updating && (
            <Spinner animation="border" variant="primary" />
          )}
        </div>
        <div className="interior">
          <VelocityTransitionGroup
            enter={enterAnimation}
            leave={leaveAnimation}
            component="div"
            className="chosen-phrase"
            style={groupStyle}
          >
            {rows}
          </VelocityTransitionGroup>
        </div>
        {this.props.children}
      </div>
    );
  },
});

export default TextValuesChosen;
