import React, { useState } from "react";
import { LIGHT_YELLOW } from "../../stylesheets/colors";
import { Ionicons } from "@expo/vector-icons";
import BSButton from "./BSButton.component.web";

export type Column = {
  label: string;
  key: string;
  collapse?: boolean;
};

type RowProps = {
  idx: number;
  columns: Column[];
  row: object;
};

const Row = (props: RowProps) => {
  const [collapsed, setCollapsed] = useState(true);

  const onClick = () => setCollapsed(!collapsed);

  return (
    <>
      <tr key={props.idx}>
        {hasCollapse(props.columns) && (
          <td>
            <BSButton onClick={onClick} variant="link-blue">
              <Ionicons
                name={collapsed ? "chevron-down" : "chevron-up"}
                size={24}
                color="black"
              />
            </BSButton>
          </td>
        )}
        {nonCollapseColumns(props.columns).map((col) => (
          <td key={col.key}>{props.row[col.key]}</td>
        ))}
      </tr>
      {!collapsed && (
        <tr>
          <td colSpan={nonCollapseColumns(props.columns).length + 1}>
            <ul className="list-group list-group-flush">
              {collapseColumns(props.columns).map((col) => (
                <li
                  className="list-group-item d-flex align-items-center"
                  key={col.key}
                >
                  <div className="w-25">
                    <strong>{col.label}</strong>
                  </div>
                  <div className=" ms-2 flex-grow-1">
                    <span>{props.row[col.key]}</span>
                  </div>
                </li>
              ))}
            </ul>
          </td>
        </tr>
      )}
    </>
  );
};

type Props = {
  columns: Column[];
  rows: {
    [col: string]: string | number;
  }[];
  children?: React.ReactNode;
  className?: string;
};

const TrackerEntryTable = (props: Props) => {
  return (
    <>
      <div className={props.className || "table-responsive"}>
        <table className="table table-borderless">
          <thead>
            <tr style={{ backgroundColor: LIGHT_YELLOW }}>
              {hasCollapse(props.columns) && <th />}
              {nonCollapseColumns(props.columns).map((col) => (
                <th key={col.key}>{col.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(props.rows || []).map((row, idx) => (
              <Row
                key={idx.toString()}
                columns={props.columns}
                idx={idx}
                row={row}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center">{props.children}</div>
    </>
  );
};

export default TrackerEntryTable;

const nonCollapseColumns = (columns: Column[]) =>
  (columns || []).filter(({ collapse }) => !collapse);

const collapseColumns = (columns: Column[]) =>
  (columns || []).filter(({ collapse }) => collapse);

const hasCollapse = (columns: Column[]) => !!collapseColumns(columns).length;
