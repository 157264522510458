"use strict";

import React, { findDOMNode } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import { faStar as faStarO } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import Moment from "moment";
Moment.locale("en");

import InfoBug from "components/popover/infobug";
import ButtonSpinner from "components/ux/button-spinner";
import TextFieldReduxForm from "components/ux/text-field-redux-form";

import { validator } from "utils/tools";

const messages = defineMessages({
  solution_tracker_results_label: {
    id: "solution_tracker_results_label",
    description: "What happened when you tried the solution?",
    defaultMessage: "What happened when you tried the solution?",
  },
  solution_tracker_next_steps_label: {
    id: "solution_tracker_next_steps_label",
    description:
      "Do you need to refine the solution or try another. If so, what changes will you make or which solution will you try?",
    defaultMessage:
      "Do you need to refine the solution or try another. If so, what changes will you make or which solution will you try?",
  },
});

const SolutionTrackerAddOutcomeForm = React.createClass({
  propTypes: {
    fields: React.PropTypes.object.isRequired,
    handleSubmit: React.PropTypes.func.isRequired,
  },

  onChange(fieldName, event) {
    this.props.change(fieldName, event.target.value);
  },

  render() {
    const {
      handleSubmit,
      intl: { formatMessage },
    } = this.props;
    const results = { name: "results" },
      next_steps = { name: "next_steps" };
    return (
      <div className="container">
        <form
          id="solution_tracker_add_outcome"
          name="solution_tracker_add_outcome"
          className="stack-input"
          style={{ margin: "0 auto 40px auto" /*, maxWidth: '290px'*/ }}
        >
          <div>
            <TextFieldReduxForm
              name="results"
              textArea={true}
              floatingLabel={true}
              labelText={formatMessage(messages.solution_tracker_results_label)}
            />
            <div className="row">&nbsp;</div>
            <TextFieldReduxForm
              name="next_steps"
              textArea={true}
              floatingLabel={true}
              labelText={formatMessage(
                messages.solution_tracker_next_steps_label,
              )}
            />
            <div className="row">&nbsp;</div>
            <div className="row">&nbsp;</div>
            <ButtonSpinner
              spinner={this.props.isFetching}
              onClick={handleSubmit}
              ripple={true}
              raised={true}
              colored={true}
            >
              <FontAwesomeIcon icon={faStarO} />
              <FormattedMessage
                id="solution_tracker_button_save"
                description="Submit the Solution tracker form to save a Solution"
                defaultMessage="Save"
              />
            </ButtonSpinner>
          </div>
          <div className="clearfix" />
        </form>
      </div>
    );
  },
});

const formFields = ["results", "next_steps"];
let form = reduxForm({
  form: "solutionTrackerAddOutcome",
  fields: formFields,
  validate: validator({
    results: { required: "Required" },
    next_steps: { required: "Required" },
  }),
})(SolutionTrackerAddOutcomeForm);
const selector = formValueSelector("solutionTrackerAddOutcome"); // <-- same as form name
form = connect((state) => {
  return formFields.reduce(
    (dict, fieldName) =>
      Object.assign({ [fieldName]: selector(state, fieldName) }, dict),
    {},
  );
})(form);

export default injectIntl(form);
