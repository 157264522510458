"use strict";

import _ from "lodash";
import React from "react";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import Actions from "actions";
import Button from "components/ux/mdl/button";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

export const CompletionEmail = React.createClass({
  sendCompletionEmail() {
    this.props
      .dispatch(
        Actions.sendCompletionEmail(
          this.props.auth.user.token,
          this.props.auth.user._id,
          this.props.tool._id,
        ),
      )
      .then(() => {
        this.props.dispatch(
          Actions.notify({
            title: "Email Sent",
            message: "Your email has been sent successfully.",
            level: "success",
            autoDismiss: 5,
          }),
        );
      })
      .catch((error) => {
        this.props.dispatch(
          Actions.notify({
            title: "Network Error",
            message: "Unable to send email, please try again in a few minutes.",
            level: "error",
            autoDismiss: 3,
          }),
        );
      });
  },

  render() {
    return (
      <div className="mdl-typography--text-center">
        <Button flat={true} noColor={true} onClick={this.sendCompletionEmail}>
          <FontAwesomeIcon icon={faChevronCircleRight} /> Send Completion Email
        </Button>
      </div>
    );
  },
});
