"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import Actions from "actions";
import Button from "components/ux/mdl/button";
import Moment from "moment";
Moment.locale("en");

import {
  REQUEST_UPDATE_SUBSCRIPTION_SUCCESS,
  REQUEST_ADD_SUBSCRIPTION_SUCCESS,
} from "actions/types";

const ChangePlanModal = React.createClass({
  propTypes: {
    auth: React.PropTypes.object.isRequired,
    dispatch: React.PropTypes.func.isRequired,
  },
  state: {
    loading: false,
  },

  getInitialState() {
    return { selected: null };
  },

  onChangePlanFromModal(plan) {
    this.spinnerStart("PLAN_CHANGE_PROCESSING");
    this.setState({ loading: true });
    const { auth, subscriptions } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    const subscriptionId = _.get(subscriptions, "subscriptions.data.[0].id");
    this.props
      .dispatch(
        Actions.updateSubscription(
          token,
          userId,
          subscriptionId,
          { plan: plan.stripe_key },
          plan,
        ),
      )
      .then((result) => {
        this.spinnerStop();
        this.setState({ loading: false });
        if (result.type === REQUEST_UPDATE_SUBSCRIPTION_SUCCESS) {
          this.setState({
            selected: true,
          });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  },

  spinnerStart(messageKey) {
    this.props.dispatch(Actions.updateDisplay("appSpinner", messageKey));
  },

  spinnerStop() {
    this.props.dispatch(Actions.updateDisplay("appSpinner", "STOP"));
  },

  closeModal() {
    this.props.dispatch(
      Actions.closeModal("appModal", {
        modalNotifications: "",
        modalContent: false,
      }),
    );
  },

  renderModalMessage() {
    const { modalProps } = this.props;
    const plan = this.props.modalProps.plan;
    const planText = plan.name;

    if (this.state.selected == null) {
      return (
        <div>
          <div>
            <p>
              Are you sure you want to change your plan to {planText}? This will
              charge your card.
            </p>
          </div>
          <div>
            <Button
              loading={this.state.loading}
              disabled={this.state.loading}
              onClick={(event) => this.onChangePlanFromModal(plan)}
              ripple={true}
              raised={true}
              colored={true}
            >
              <FormattedMessage
                id="change_plan_button_save"
                description="Submit Change Plan"
                defaultMessage="Submit and Immediately Change Plan"
              />
            </Button>
          </div>
        </div>
      );
    } else if (this.state.selected == true) {
      return (
        <div>
          <p>Thank you for your purchase!</p>
        </div>
      );
    }
  },

  render() {
    return (
      <div className="container">
        {this.renderModalMessage()}
        <div className="clearfix" />
      </div>
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
  subscriptions: state.subscriptions,
}))(ChangePlanModal);
