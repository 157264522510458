"use strict";

import { handleResponse } from "utils/api";

export const addNewReminder = (userId, token, alert) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/alerts?user_id=" + userId, {
    method: "POST",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      alert_type: alert.alert_type,
      message_alert: alert.message_alert,
    }),
  }).then(handleResponse);
};

export const updateReminder = (userId, token, alert, alertId) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT + "/alerts/" + alertId + "?user_id=" + userId,
    {
      method: "PATCH",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        alert_type: alert.alert_type,
        message_alert: alert.message_alert,
      }),
    },
  ).then(handleResponse);
};

export const deleteReminder = (userId, token, alertId) => {
  return fetch(
    CUAC_SETTINGS.API_ROOT + "/alerts/" + alertId + "?user_id=" + userId,
    {
      method: "DELETE",
      headers: {
        Authorization: "CUAC " + btoa(token),
        "Content-Type": "application/json",
      },
    },
  ).then(handleResponse);
};

export const getAllReminders = (userId, token) => {
  return fetch(CUAC_SETTINGS.API_ROOT + "/alerts?user_id=" + userId, {
    method: "GET",
    headers: {
      Authorization: "CUAC " + btoa(token),
      "Content-Type": "application/json",
    },
  }).then(handleResponse);
};
