/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import Button from "components/ux/mdl/button";
import { faThList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import Loading from "components/blocks/Loading.component.web";
import Actions from "actions";

Moment.locale("en");

export const TermsPage = ({ hideHeader = false }) => {
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.terms.isFetching);
  const termsText = useSelector((state) => state.terms.terms_text);

  useEffect(() => {
    if (!termsText) {
      dispatch(Actions.getTerms(CUAC_SETTINGS.GROUP.terms_id));
    }
  }, [termsText]);

  if (isFetching) return <Loading loading text="Loading terms..." />;
  return (
    <div className="body-column privacy">
      <div className="grid-layout grid-pad">
        {hideHeader ? null : (
          <div className="section" style={{ marginBottom: "-40px" }}>
            <div className="sub-header">
              <Button
                flat={true}
                noColor={true}
                linkTo="/privacy"
                style={{ float: "right", fontSize: "12px" }}
              >
                <FontAwesomeIcon icon={faThList} />
                <FormattedMessage
                  id="terms_link_to_privacy"
                  description=""
                  defaultMessage="Privacy"
                />
              </Button>
            </div>
          </div>
        )}
        <div className="section baseline-text">
          {hideHeader ? null : (
            <h1 className="section-title">
              <FormattedMessage
                id="terms_section_title"
                defaultMessage="Checkup and Choices LLC Terms of Service"
              />
            </h1>
          )}
          <div dangerouslySetInnerHTML={{ __html: termsText }}></div>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
