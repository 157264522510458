"use strict";

import _ from "lodash";

import React from "react";
import { FormattedMessage } from "react-intl";
import { Chart } from "react-google-charts";

export const NormLookup = React.createClass({
  propTypes: {
    data_key: React.PropTypes.string,
    norm: React.PropTypes.oneOfType([
      React.PropTypes.object,
      React.PropTypes.array,
    ]),
    path: React.PropTypes.string,
    responses: React.PropTypes.object,
    text: React.PropTypes.object,
  },

  render() {
    const { data_key, norm, path, responses, text, inline } = this.props;
    const specificNorm = path ? _.get(norm, path, []) : norm;
    const response = _.get(responses, data_key);
    const goodResponse = typeof response !== "undefined";
    let normComponent,
      normValue = {};
    if (goodResponse) {
      const intResponse = parseInt(response, 10);

      if (specificNorm) {
        normValue =
          intResponse > specificNorm.length - 1
            ? specificNorm[specificNorm.length - 1]
            : specificNorm[intResponse];
      }

      const values = { [data_key]: normValue };
      normComponent = <FormattedMessage {...text} values={values} />;
    } else {
      normComponent = (
        <FormattedMessage
          id="norm_lookup_no_response"
          defaultMessage="incomplete"
        />
      );
    }

    return inline ? (
      <span style={{ padding: "0 4px" }} className="score">
        {normComponent}
      </span>
    ) : (
      <div className="norm-display">
        {!goodResponse ? null : (
          <Chart
            getChartWrapper={(chartWrapper) => {
              window.addEventListener("print", function () {
                chartWrapper.draw();
              });
            }}
            chartType="PieChart"
            data={[
              [
                "More Problems\nThan You",
                "Fewer or Same Number of\nProblems as You",
              ],
              ["More Problems\nThan You", 100 - normValue],
              ["Fewer or Same Number of\nProblems as You", normValue],
            ]}
            options={{
              pieSliceTextStyle: {
                color: "#282828",
                fontName: "Roboto",
                fontSize: 14,
              },
              tooltip: {
                text: "percentage",
              },
              title: "How you compare with the rest of the population:",
              titleTextStyle: {
                color: "#282828",
                fontName: "Roboto",
                fontSize: 16,
                bold: false,
              },
              legend: {
                textStyle: {
                  color: "#282828",
                  fontName: "Roboto",
                  fontSize: 14,
                  bold: false,
                },
                position: "right",
                alignment: "center",
              },
              backgroundColor: "transparent",
              slices: [
                {
                  color: "#62913E",
                  textStyle: {
                    color: "white",
                  },
                },
                {
                  color: "rgb(233,61,61)",
                  textStyle: {
                    color: "white",
                  },
                },
              ],
            }}
            graph_id={data_key}
            width="85%"
            height="300px"
            legend_toggle
          />
        )}
      </div>
    );
  },
});
