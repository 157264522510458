import React, { useState } from "react";
import BSInput from "./BSInput.component.web";
import BSButton from "./BSButton.component.web";
import { connect } from "react-redux";
import Actions from "../../actions";
import _ from "lodash";

interface Props {
  updateUser: (token: string, id: string, payload: any) => Promise<any>;
  changePassword: (
    token: string,
    id: string,
    password: string,
    newPassword: string,
  ) => Promise<any>;
  user?: any;
}

type AnonymousForm = {
  email?: string;
  name?: string;
  password?: string;
  passwordConfirmation?: string;
};

const AnonymousUserForm = (props: Props) => {
  const [form, setForm] = useState<AnonymousForm>({});
  const [errors, setErrors] = useState<AnonymousForm>({});

  const onChange = (key: keyof AnonymousForm, val: string) => {
    setForm((prev) => ({
      ...(prev || {}),
      [key]: val || "",
    }));
  };

  const _submit = async () => {
    try {
      await updateUser();
    } catch (e) {}
  };

  const validateForm = () => {
    const { name, email, password, passwordConfirmation } = form;
    const updates = {
      name: !!(name && name.length) ? undefined : "Required field",
      email: !!(email && email.length) ? undefined : "Required field",
      password: !!(
        password &&
        password.length &&
        passwordConfirmation === password
      )
        ? undefined
        : "Invalid password",
      passwordConfirmation:
        !!passwordConfirmation && passwordConfirmation === password
          ? undefined
          : "Passwords do not match",
    };
    setErrors((prev) => ({
      ...(prev || {}),
      ...updates,
    }));
    if (Object.values(updates).filter((x) => !!x).length) throw Error;
  };

  const updateUser = async () => {
    try {
      validateForm();
    } catch (e) {
      return;
    }
    const data = {
      email: form.email,
      name: form.name,
      password: form.password,
    };
    const token = _.get(props.user || {}, "token");
    const userId = _.get(props.user || {}, "_id");
    try {
      const res = await props.updateUser(token, userId, data);
    } catch (e) {}
  };

  return (
    <div className="card" style={{ border: "none" }}>
      <div className="card-body">
        <h2 className="card-title">Create Account</h2>
        <div className="d-flex">
          <div className="flex-1 me-1">
            <BSInput
              label="Email"
              onChange={({ target }) => onChange("email", target.value)}
              type="email"
              error={errors["email"]}
            />
          </div>
          <div className="flex-1 ms-1">
            <BSInput
              label="Name"
              onChange={({ target }) => onChange("name", target.value)}
              error={errors["name"]}
            />
          </div>
        </div>
        <div className="d-flex mt-2">
          <div className="flex-1 me-1">
            <BSInput
              label="Password"
              onChange={({ target }) => onChange("password", target.value)}
              type="password"
              error={errors["password"]}
            />
          </div>
          <div className="flex-1 ms-1">
            <BSInput
              label="Confirm Password"
              onChange={({ target }) =>
                onChange("passwordConfirmation", target.value)
              }
              type="password"
              error={errors["passwordConfirmation"]}
            />
          </div>
        </div>
        <div className="my-2">
          <BSButton label="Submit" onClick={_submit} />
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    user: state.auth?.user,
  }),
  {
    updateUser: Actions.updateUser,
    changePassword: Actions.changePassword,
  },
)(AnonymousUserForm);
