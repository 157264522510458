import React from "react";
import AudioPlayerLibrary from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

type Props = {
  autoPlay?: boolean;
  src: string;
  className?: string;
};

export function AudioPlayer(props: Props) {
  return <AudioPlayerLibrary {...props} />;
}
