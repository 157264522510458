"use strict";
import React from "react";
import { browserHistory } from "react-router";

export const ProgressBoxes = (props) => {
  const active = (index) => {
    const { id: pageID } = props.page || {},
      { order } = props,
      idx = (order || []).indexOf(pageID);
    return index <= idx;
  };

  const goTo = (idx) => (e) => {
    e.preventDefault();
    if (active(idx)) browserHistory.push(e.currentTarget.href);
  };

  return (
    <div className="progress-bar-wrapper d-flex align-items-center justify-content-between my-4">
      {(props.order || []).map((key, idx) => {
        const { [key]: page } = props.pages || {},
          { slug } = page || {},
          href = slug ? props.linkPrefix + "/" + slug : "#";
        return (
          <a
            key={key}
            href={href}
            style={{ height: 7 }}
            className="mw-100 flex-grow-1 d-flex"
            onClick={goTo(idx)}
          >
            <div
              style={{ height: "100%", borderRadius: 5 }}
              className={`mw-100 flex-grow-1 progress-bar-indicator ${active(idx) ? "progress-bar-indicator__active" : ""}`}
            />
          </a>
        );
      })}
    </div>
  );
};
