"use strict";

import React, { findDOMNode } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import { faStar as faStarO } from "@fortawesome/free-solid-svg-icons";
import InfoBug from "components/popover/infobug";
import ButtonSpinner from "components/ux/button-spinner";
import TextFieldReduxForm from "components/ux/text-field-redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

import { validator } from "utils/tools";

const messages = defineMessages({
  abc_tracker_title_label: {
    id: "abc_tracker_title_label",
    description: "Title",
    defaultMessage: "Title",
  },
  abc_tracker_activating_event_label: {
    id: "abc_tracker_activating_event_label",
    description: "A: Activating Event",
    defaultMessage: "A: Activating Event",
  },
  abc_tracker_beliefs_label: {
    id: "abc_tracker_beliefs_label",
    description: "B: Beliefs, Expectancies, Or Thoughts",
    defaultMessage: "B: Beliefs, Expectancies, Or Thoughts",
  },
  abc_tracker_consequences_label: {
    id: "abc_tracker_consequences_label",
    description: "C: Consequences",
    defaultMessage: "C: Consequences",
  },
  abc_tracker_dispute_label: {
    id: "abc_tracker_dispute_label",
    description: "D: Dispute",
    defaultMessage: "D: Dispute",
  },
  abc_tracker_effective_new_belief_label: {
    id: "abc_tracker_effective_new_belief_label",
    description: "E: Effective New Belief",
    defaultMessage: "E: Effective New Belief",
  },
  abc_tracker_emotional_consequence_label: {
    id: "abc_tracker_emotional_consequence_label",
    description: "F: Emotional Consequence",
    defaultMessage: "F: Emotional Consequence",
  },
});

const ABCTracker = React.createClass({
  propTypes: {
    fields: React.PropTypes.object.isRequired,
    handleSubmit: React.PropTypes.func.isRequired,
  },

  onChange(fieldName, event) {
    this.props.change(fieldName, event.target.value);
  },

  render() {
    const {
      fields: {
        title,
        activating_event,
        beliefs,
        consequences,
        dispute,
        effective_new_belief,
        emotional_consequence,
      },
      handleSubmit,
      intl: { formatMessage },
    } = this.props;
    return (
      <div className="section section-account-overview col-1-1">
        <div
          className="contain-box-wrap"
          style={{ maxWidth: "690px", marginTop: "52px" }}
        >
          <div className="contain-box-main">
            <h1 className="page-title contain-box-title">
              <FormattedMessage
                id="abc_tracker_title"
                description="Title for the ABC Tracker form"
                defaultMessage="ABC"
              />
            </h1>

            <form
              id="abc_tracker"
              name="abc_tracker"
              className="stack-input"
              style={{ margin: "0 auto 40px auto" /*, maxWidth: '290px'*/ }}
            >
              <div>
                <TextFieldReduxForm
                  name="title"
                  floatingLabel={true}
                  labelText={formatMessage(messages.abc_tracker_title_label)}
                />
                <div className="row">&nbsp;</div>
                <Row>
                  <Col>
                    <TextFieldReduxForm
                      name="activating_event"
                      textArea={true}
                      floatingLabel={true}
                      labelText={formatMessage(
                        messages.abc_tracker_activating_event_label,
                      )}
                    />
                  </Col>
                  <Col xs={1}>
                    <InfoBug>
                      <FormattedMessage
                        id="abc_tracker_activating_event_infobug"
                        defaultMessage="What happened? What did someone else do or say?"
                      />
                    </InfoBug>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextFieldReduxForm
                      name="beliefs"
                      textArea={true}
                      floatingLabel={true}
                      labelText={formatMessage(
                        messages.abc_tracker_beliefs_label,
                      )}
                    />
                  </Col>
                  <Col xs={1}>
                    <InfoBug>
                      <FormattedMessage
                        id="abc_tracker_beliefs_infobug"
                        defaultMessage="What am I saying to myself about this event? Which of them are facts? Which are beliefs or opinions? Which are helpful? Are there any which may be self-defeating? Are there some you'd rather not have?"
                      />
                    </InfoBug>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextFieldReduxForm
                      name="consequences"
                      textArea={true}
                      floatingLabel={true}
                      labelText={formatMessage(
                        messages.abc_tracker_consequences_label,
                      )}
                    />
                  </Col>
                  <Col xs={1}>
                    <InfoBug>
                      <FormattedMessage
                        id="abc_tracker_consequences_infobug"
                        defaultMessage="What are the immediate, short-term consequences of how I responded? How did I feel and behave? What are the long-term consequences? How did I feel or behave? Were these feelings and/or behaviors helpful or not?"
                      />
                    </InfoBug>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextFieldReduxForm
                      name="dispute"
                      textArea={true}
                      floatingLabel={true}
                      labelText={formatMessage(
                        messages.abc_tracker_dispute_label,
                      )}
                    />
                  </Col>
                  <Col xs={1}>
                    <InfoBug>
                      <FormattedMessage
                        id="abc_tracker_dispute_infobug"
                        defaultMessage="Challenge and dispute the thoughts or beliefs that are self-defeating. What is the objective evidence that my thoughts or beliefs are true (and not just my belief or opinion)? Is there another way to look at it? How are my thoughts and beliefs likely to be helpful or not in the long run?"
                      />
                    </InfoBug>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextFieldReduxForm
                      name="effective_new_belief"
                      textArea={true}
                      floatingLabel={true}
                      labelText={formatMessage(
                        messages.abc_tracker_effective_new_belief_label,
                      )}
                    />
                  </Col>
                  <Col xs={1}>
                    <InfoBug>
                      <FormattedMessage
                        id="abc_tracker_effective_new_belief_infobug"
                        defaultMessage="What more helpful beliefs or thoughts can I use to replace the unhelpful thoughts? How might they lead to better consequences?"
                      />
                    </InfoBug>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextFieldReduxForm
                      name="emotional_consequence"
                      textArea={true}
                      floatingLabel={true}
                      labelText={formatMessage(
                        messages.abc_tracker_emotional_consequence_label,
                      )}
                    />
                  </Col>
                  <Col xs={1}>
                    <InfoBug>
                      <FormattedMessage
                        id="abc_tracker_emotional_consequence_infobug"
                        defaultMessage="If I take this different approach to thinking about this event, what are my new feelings or reactions?"
                      />
                    </InfoBug>
                  </Col>
                </Row>
                <ButtonSpinner
                  spinner={this.props.isFetching}
                  onClick={handleSubmit}
                  ripple={true}
                  raised={true}
                  colored={true}
                  style={{ height: "auto" }}
                >
                  <FontAwesomeIcon icon={faStarO} />
                  <FormattedMessage
                    id="abc_tracker_button_save"
                    description="Submit the ABC tracker form to save an ABC"
                    defaultMessage="Save"
                  />
                </ButtonSpinner>
              </div>
              <div className="clearfix" />
            </form>
          </div>
        </div>
      </div>
    );
  },
});

const formFields = [
  "title",
  "activating_event",
  "beliefs",
  "consequences",
  "dispute",
  "effective_new_belief",
  "emotional_consequence",
];
let form = reduxForm({
  form: "abcTracker",
  fields: formFields,
  validate: validator({
    title: { required: "Required" },
    activating_event: { required: "Required" },
    beliefs: { required: "Required" },
    consequences: { required: "Required" },
    dispute: { required: "Required" },
    effective_new_belief: { required: "Required" },
    emotional_consequence: { required: "Required" },
  }),
})(ABCTracker);

const selector = formValueSelector("abcTracker"); // <-- same as form name
form = connect((state) => {
  return formFields.reduce(
    (dict, fieldName) =>
      Object.assign({ [fieldName]: selector(state, fieldName) }, dict),
    {},
  );
})(form);

export default injectIntl(form);
