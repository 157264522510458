"use strict";

import Cookies from "cookies-js";
import {
  REQUEST_LOG_OUT_USER,
  REQUEST_LOG_OUT_USER_SUCCESS,
  REQUEST_LOG_OUT_USER_FAILURE,
} from "actions/types";
const dataLayer = [];

function requestLogOutUser() {
  return {
    type: REQUEST_LOG_OUT_USER,
  };
}

function requestLogOutUserSuccess() {
  return {
    type: REQUEST_LOG_OUT_USER_SUCCESS,
  };
}

function requestLogOutUserFailure(error) {
  return {
    type: REQUEST_LOG_OUT_USER_FAILURE,
    payload: error,
    error: true,
  };
}

export default function logOutUser() {
  return (dispatch) => {
    dispatch(requestLogOutUser());
    return new Promise((resolve, reject) => {
      Cookies.expire("userData");
      resolve();
    })
      .then(() => {
        window.dataLayer.push({
          event: "logout",
          userId: "",
          groupCode: CUAC_SETTINGS.GROUP.code,
        });
        return dispatch(requestLogOutUserSuccess());
      })
      .catch((error) => {
        return dispatch(requestLogOutUserSuccess(error));
      });
  };
}
