"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import MediaQuery from "react-responsive";
import { Field } from "components/fields/field";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

export const MultipleChoiceListForm = React.createClass({
  //    mixins: [],
  //    statics: {},

  propTypes: {
    dataKeyPrefix: React.PropTypes.string.isRequired,
    fieldOptions: React.PropTypes.object.isRequired,
    fields: React.PropTypes.object.isRequired,
    rowHeaders: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    updateResponse: React.PropTypes.func.isRequired,
  },

  //    getInitialState() {},
  //    getDefaultProps() {},
  //    componentWillMount() {},
  //    componentDidMount() {},
  //    componentWillReceiveProps(nextProps) {},
  //    shouldComponentUpdate(nextProps, nextState) {},
  //    componentWillUpdate(nextProps, nextState) {},
  //    componentDidUpdate(prevProps, prevState) {},
  //    componentWillUnmount() {},

  renderHeader(options, index) {
    const headers = [];
    for (let i = 0; i < options.length; i++) {
      headers.unshift(options[i]);
    }
    return (
      <div className="header-row" key={"header-row-" + index}>
        {headers.map((fieldOption, index) => (
          <div
            className={"header position-" + index}
            key={"option_header_" + index}
          >
            <FontAwesomeIcon icon={faChevronCircleRight} />
            <FormattedMessage {...fieldOption.text} />
          </div>
        ))}
      </div>
    );
  },

  render() {
    const {
      dataKeyPrefix,
      fieldOptions: { options },
      fields,
      rowHeaders,
      updateResponse,
    } = this.props;
    const renderHeader = this.renderHeader;
    const selectionCount = options.length;
    return (
      <form className="multiple-choice-table">
        <MediaQuery minWidth={701}>
          {rowHeaders.map((rowHeader, index) => {
            const responseKey = dataKeyPrefix + "_" + rowHeader.id;
            const headerRow = index % 8 ? null : renderHeader(options, index);
            const field = _.get(fields, responseKey);
            const fieldMetadata = {
              id: responseKey,
              field_type: "multiple_choice_table",
              text: rowHeader,
              options,
            };

            return (
              <MultiChoiceRow
                key={"multi-choice-row" + index}
                headerRow={headerRow}
                selectionCount={selectionCount}
                fieldMetadata={fieldMetadata}
                field={field}
                updateResponse={updateResponse}
              />
            );
          })}
        </MediaQuery>
        <MediaQuery maxWidth={700}>
          {rowHeaders.map((rowHeader, i) => {
            const responseKey = dataKeyPrefix + "_" + rowHeader.id;
            const field = _.get(fields, responseKey);
            const fieldMetadata = {
              id: responseKey,
              field_type: "multiple_choice",
              text: rowHeader,
              options,
            };

            return (
              <MultiChoiceBasic
                key={"multi-choice-basic" + i}
                fieldMetadata={fieldMetadata}
                field={field}
                updateResponse={updateResponse}
              />
            );
          })}
        </MediaQuery>
      </form>
    );
  },
});

const MultiChoiceRow = React.createClass({
  shouldComponentUpdate: function (nextProps, nextState) {
    const next = _.get(nextProps, "field.value"),
      current = _.get(this.props, "field.value");
    return next !== current;
  },

  render() {
    const { headerRow, selectionCount, fieldMetadata, field, updateResponse } =
      this.props;
    return (
      <div>
        {headerRow}
        <div className={"row selection-count-" + selectionCount}>
          <Field
            fieldMetadata={fieldMetadata}
            field={field}
            updateResponse={updateResponse}
          />
        </div>
      </div>
    );
  },
});

const MultiChoiceBasic = React.createClass({
  shouldComponentUpdate: function (nextProps, nextState) {
    const next = _.get(nextProps, "field.value"),
      current = _.get(this.props, "field.value");
    return next !== current;
  },

  render() {
    const { fieldMetadata, field, updateResponse } = this.props;
    return (
      <Field
        fieldMetadata={fieldMetadata}
        field={field}
        updateResponse={updateResponse}
      />
    );
  },
});
