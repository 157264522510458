"use strict";

import React, { findDOMNode } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import DateInputReduxForm from "components/ux/input-date-redux-form";
import { NumberQuestion } from "components/fields/number-question";
import { faStar as faStarO } from "@fortawesome/free-solid-svg-icons";
import InfoBug from "components/popover/infobug";
import ButtonSpinner from "components/ux/button-spinner";
import TextFieldReduxForm from "components/ux/text-field-redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { validator } from "utils/tools";

const messages = defineMessages({
  agreement_tracker_title_label: {
    id: "agreement_tracker_title_label",
    description: "Agreement Name",
    defaultMessage: "Agreement Name",
  },
  agreement_tracker_start_date_label: {
    id: "agreement_tracker_start_date_label",
    description: "When will you start your break?",
    defaultMessage: "When will you start your break?",
  },
  agreement_tracker_how_long_label: {
    id: "agreement_tracker_how_long_label",
    description: "How long will your break be?",
    defaultMessage: "How long will your break be?",
  },
  date_input_year_label: {
    id: "date_input_year_label",
    description: "date choose label -- Year",
    defaultMessage: "Year",
  },
  date_input_month_label: {
    id: "date_input_month_label",
    description: "date choose label -- Month",
    defaultMessage: "Month",
  },
  date_input_day_label: {
    id: "date_imput_day_label",
    description: "date choose label -- Day",
    defaultMessage: "Day",
  },
});

const AgreementTracker = React.createClass({
  propTypes: {
    fields: React.PropTypes.object.isRequired,
    handleSubmit: React.PropTypes.func.isRequired,
  },

  onChange(fieldName, event) {
    this.props.change(fieldName, event.target.value);
  },

  render() {
    const {
      handleSubmit,
      intl: { formatMessage },
    } = this.props;
    return (
      <div className="section section-account-overview col-1-1">
        <div
          className="contain-box-wrap"
          style={{ maxWidth: "690px", marginTop: "52px" }}
        >
          <div className="contain-box-main">
            <h1 className="page-title contain-box-title">
              <FormattedMessage
                id="agreement_tracker_title"
                description="Title for the Agreement Tracker form"
                defaultMessage="Your Agreement"
              />
            </h1>

            <form
              id="agreement_tracker"
              name="agreement_tracker"
              className="stack-input"
              style={{ margin: "0 auto 40px auto" /*, maxWidth: '290px'*/ }}
            >
              <div>
                <TextFieldReduxForm
                  name="title"
                  floatingLabel={true}
                  labelText={formatMessage(
                    messages.agreement_tracker_title_label,
                  )}
                />

                <DateInputReduxForm
                  name="dt_start_date"
                  floatingLabel={true}
                  yearLabel={formatMessage(messages.date_input_year_label)}
                  monthLabel={formatMessage(messages.date_input_month_label)}
                  dayLabel={formatMessage(messages.date_input_day_label)}
                  labelText={formatMessage(
                    messages.agreement_tracker_start_date_label,
                  )}
                />

                <NumberQuestion
                  className="no-chrome"
                  key="how_long"
                  field="how_long"
                  fieldName="how_long"
                  formatMessage={formatMessage}
                  noButtons={false}
                  min="0"
                  step="1"
                  placeholder_text={{
                    id: "agreement_tracker_how_long_days",
                    defaultMessage: "# of Days",
                  }}
                  updateResponse={function () {}}
                />

                <ButtonSpinner
                  spinner={this.props.isFetching}
                  onClick={handleSubmit}
                  ripple={true}
                  raised={true}
                  colored={true}
                >
                  <FontAwesomeIcon icon={faStarO} />
                  <FormattedMessage
                    id="agreement_tracker_button_save"
                    description="Submit the Agreement"
                    defaultMessage="Save"
                  />
                </ButtonSpinner>
              </div>
              <div className="clearfix" />
            </form>
          </div>
        </div>
      </div>
    );
  },
});

const formFields = ["title", "dt_start_date", "how_long"];
let form = reduxForm({
  form: "agreementTracker",
  fields: formFields,
  validate: validator({
    title: { required: "Required" },
    dt_start_date: { required: "Required" },
    how_long: { required: "Required" },
  }),
})(AgreementTracker);

const selector = formValueSelector("agreementTracker"); // <-- same as form name
form = connect((state) => {
  return formFields.reduce(
    (dict, fieldName) =>
      Object.assign({ [fieldName]: selector(state, fieldName) }, dict),
    {},
  );
})(form);

export default injectIntl(form);
