"use strict";

export const bacFeedback = (
  inputHours,
  inputDrinks,
  table,
  findDrinks,
  findHours,
) => {
  const hours =
    typeof inputHours === "number"
      ? inputHours
      : typeof findHours === "number"
        ? findHours
        : 0;
  const drinks =
    typeof inputDrinks === "number"
      ? inputDrinks
      : typeof findDrinks === "number"
        ? findDrinks
        : 0;

  if (!table) {
    return "";
  }

  return table[Number(drinks)][Number(hours)];
};
