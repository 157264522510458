/* globals _, CUAC_SETTINGS, DEBUG, PROD, console */
"use strict";

import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import Actions from "actions";
import { connect } from "react-redux";
import { TrackerTypes } from "api/tracker-entries";
import Paginator from "lib/Paginator";
import momentTZ from "lib/moment-timezone-with-data";
import { itemsPerPage } from "utils/pagination";
import TrackerNav from "../blocks/TrackerNav.component.web";
import TrackerEntryTable from "../blocks/TrackerEntryTable.component.web";
import BSButton from "../blocks/BSButton.component.web";
import {
  Entypo,
  FontAwesome,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { FOREST_GREEN, LIGHTER_FOREST_GREEN } from "../../stylesheets/colors";
import { Dropdown, Modal } from "react-bootstrap";
import UrgeTracker from "components/forms/urge-tracker";
import Moment from "moment";
import { combineDateAndTime, extractDateAndTime } from "../../utils/timezone";
import ConfirmModal from "../blocks/ConfirmModal.component.web";

const COLUMNS = [
  { label: "Date/Time", key: "date" },
  { label: "Intensity", key: "intensity" },
  { label: "Type", key: "type" },
  { label: "Duration", key: "duration" },
  { label: "Where", key: "where", collapse: true },
  { label: "With", key: "with", collapse: true },
  { label: "Strategy", key: "strategy", collapse: true },
  { label: "", key: "actions" },
];

const UrgeList = React.createClass({
  propTypes: {
    tag: React.PropTypes.string,
    urgeListClassName: React.PropTypes.string,
    trackerEntries: React.PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      showModal: false,
      showConfirmModal: false,
      onConfirmDelete: undefined,
      displayedEntries: {
        start: 0,
        end: 5,
      },
      entryToEdit: undefined,
    };
  },

  deleteUrgeTrackerEntry(trackerEntryId, currentPage, totalEntries) {
    this.props.dispatch(Actions.updateDisplay("appSpinner", "PLEASE_WAIT"));
    const { auth, dispatch } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    dispatch(
      Actions.deleteTrackerEntry(
        token,
        userId,
        trackerEntryId,
        TrackerTypes.URGE,
      ),
    ).then(() => {
      this.updateCurrentPage(currentPage, totalEntries);
      dispatch(Actions.updateDisplay("appSpinner", "STOP"));
      this.setState({ showConfirmModal: false, onConfirmDelete: undefined });
    });
  },

  updateCurrentPage(currentPage, totalEntries) {
    if (currentPage.start >= itemsPerPage && totalEntries - 1 === 0) {
      this.setState({
        displayedEntries: {
          start: this.state.displayedEntries.start - itemsPerPage,
          end: this.state.displayedEntries.end - itemsPerPage,
        },
      });
    }
  },

  onChangePage(page) {
    var end = page * itemsPerPage;
    var start = end - itemsPerPage;
    this.setState({
      displayedEntries: {
        start: start,
        end: end,
      },
      openEntries: [],
    });
  },

  rowData() {
    const { tag, trackerEntries, params } = this.props;
    const fullOrder = _.get(trackerEntries, TrackerTypes.URGE + "Order", []);
    const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
      return _.get(trackerEntries, trackerEntryId, {});
    });
    const user_timezone = _.get(this.props.auth, "user.user_timezone");

    const toolSlug = typeof params !== "undefined" ? params.toolSlug : null;
    let filteredEntries =
      typeof tag !== "undefined"
        ? _.filter(fullOrderEntries, (trackerEntry) => {
            return _.get(trackerEntry, "tag") === tag;
          })
        : fullOrderEntries;

    if (toolSlug !== null) {
      var slugSplit = toolSlug.split("-");
      var tool = slugSplit[1];
      if (typeof tool != "undefined") {
        filteredEntries = _.filter(fullOrderEntries, {
          data: { urge_type: tool },
        });
      } else {
        filteredEntries = _.filter(fullOrderEntries, {
          data: { urge_type: "drink" },
        });
      }
    } else {
      filteredEntries =
        typeof tag !== "undefined"
          ? _.filter(fullOrderEntries, (trackerEntry) => {
              return _.get(trackerEntry, "tag") === tag;
            })
          : fullOrderEntries;
    }

    //sort the entries by urge type
    const sortedEntries = _.sortByOrder(
      filteredEntries,
      ["data.dt_occurred"],
      ["desc"],
    );
    const page = {
      start: this.state.displayedEntries.start,
      end: this.state.displayedEntries.end,
    };
    const orderEntries = sortedEntries.slice(page.start, page.end);
    return orderEntries.map((fullTrackerEntry) => {
      const trackerEntry = _.get(fullTrackerEntry, "data", {});
      const { dt_occurred, tm_occurred } = extractDateAndTime(
        trackerEntry.dt_occurred,
        user_timezone,
      );
      const ret = {
        date: `${dt_occurred} ${tm_occurred}`,
        intensity: trackerEntry.intensity,
        type: trackerEntry.urge_type,
        duration: trackerEntry.duration,
        where: trackerEntry.where,
        with: trackerEntry.with_whom,
        strategy: trackerEntry.strategy,
        actions: (
          <Dropdown>
            <Dropdown.Toggle variant="light" size="sm">
              <MaterialCommunityIcons
                name="dots-vertical"
                size={24}
                color="black"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ entryToEdit: fullTrackerEntry }, () => {
                    this.setState({ showModal: true });
                  });
                }}
                className="d-flex align-items-center"
              >
                <FontAwesome name="edit" size={24} color="black" />
                <span className="ms-2">Edit</span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({
                    showConfirmModal: true,
                    onConfirmDelete: () =>
                      this.deleteUrgeTrackerEntry(
                        fullTrackerEntry._id,
                        page,
                        orderEntries.length,
                      ),
                  });
                }}
                className="d-flex align-items-center"
              >
                <FontAwesome name="trash" size={24} color="black" />
                <span className="ms-2">Delete</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      };
      return ret;
    });
  },

  serialize(data) {
    const user_timezone = _.get(this.props.auth, "user.user_timezone");
    const date = combineDateAndTime(
      data.dt_occurred,
      data.tm_occurred,
      user_timezone,
    );
    return {
      dt_occurred: date.format("YYYY-MM-DDTHH:mm:ss"),
      with_whom: data.with_whom,
      intensity: parseInt(data.intensity),
      where: data.where,
      mood: data.mood,
      strategy: data.strategy,
      duration: data.duration,
      urge_type: data.urge_type,
    };
  },

  async onSubmit(data) {
    const { auth } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    const payload = this.serialize(data);
    if (this.state.entryToEdit)
      await this.editUrge(token, userId, payload, this.state.entryToEdit._id);
    else await this.addUrge(token, userId, payload, TrackerTypes.URGE);
  },

  async addUrge(token, userId, data) {
    return this.props.dispatch(
      Actions.addTrackerEntry(token, userId, data, TrackerTypes.URGE),
    );
  },

  async editUrge(token, userId, data, trackerEntryId) {
    return this.props
      .dispatch(Actions.updateTrackerEntry(token, userId, trackerEntryId, data))
      .then(() => this.setState({ entryToEdit: null }));
  },

  initValues() {
    const user_timezone = _.get(this.props.auth, "user.user_timezone");
    if (this.state.entryToEdit) {
      let tmOccurred = Moment(
        this.state.entryToEdit.data.dt_occurred.split("+")[0],
      ).format("YYYY-MM-DD HH:mm:ss");
      tmOccurred = momentTZ.tz(tmOccurred, "UTC");
      const userTzTmOccurred = user_timezone
        ? tmOccurred.clone().tz(user_timezone)
        : tmOccurred.clone();
      return {
        dt_occurred: userTzTmOccurred.format("YYYY-MM-DD"), //YYYY-MM-DD
        tm_occurred: userTzTmOccurred.format("hh:mm A"),
        with_whom: this.state.entryToEdit.data.with_whom,
        intensity: this.state.entryToEdit.data.intensity,
        where: this.state.entryToEdit.data.where,
        mood: this.state.entryToEdit.data.mood,
        duration: this.state.entryToEdit.data.duration,
        urge_type: this.state.entryToEdit.data.urge_type,
        strategy: this.state.entryToEdit.data.strategy,
      };
    }
    const initialDateTime = momentTZ.tz(new Date(), user_timezone);
    return {
      dt_occurred: initialDateTime.format("YYYY-MM-DD"),
      tm_occurred: initialDateTime.format("hh:mm A"),
    };
  },

  pagination() {
    const { tag, trackerEntries, params } = this.props;
    const fullOrder = _.get(trackerEntries, TrackerTypes.URGE + "Order", []);
    const fullOrderEntries = _.map(fullOrder, (trackerEntryId) => {
      return _.get(trackerEntries, trackerEntryId, {});
    });

    const toolSlug = typeof params !== "undefined" ? params.toolSlug : null;
    let filteredEntries =
      typeof tag !== "undefined"
        ? _.filter(fullOrderEntries, (trackerEntry) => {
            return _.get(trackerEntry, "tag") === tag;
          })
        : fullOrderEntries;

    if (toolSlug !== null) {
      var slugSplit = toolSlug.split("-");
      var tool = slugSplit[1];
      if (typeof tool != "undefined") {
        filteredEntries = _.filter(fullOrderEntries, {
          data: { urge_type: tool },
        });
      } else {
        filteredEntries = _.filter(fullOrderEntries, {
          data: { urge_type: "drink" },
        });
      }
    } else {
      filteredEntries =
        typeof tag !== "undefined"
          ? _.filter(fullOrderEntries, (trackerEntry) => {
              return _.get(trackerEntry, "tag") === tag;
            })
          : fullOrderEntries;
    }
    if (!filteredEntries.length) return null;
    return (
      <Paginator
        className={"pagination"}
        page={this.state.displayedEntries.end / itemsPerPage}
        maxVisible={5}
        max={Math.ceil(fullOrder.length / itemsPerPage)}
        onChange={this.onChangePage}
      />
    );
  },

  render() {
    const confirmMessage = {
      id: "delete_urge_tracker_confirm_instructions",
      description: "Urge Tracker -- Delete entry, confirmation message",
      defaultMessage: "Are you sure you want to delete this tracker entry?",
    };

    const rows = this.rowData();

    return (
      <>
        <div className="card mt-4" style={{ border: "none" }}>
          <div className="card-body">
            <TrackerNav />
            <h3 className="card-title my-2">
              <FormattedMessage
                id="urge_list_title"
                defaultMessage="Your Recent Urges"
              />
            </h3>
            <TrackerEntryTable
              columns={COLUMNS}
              rows={rows}
              className={this.props.urgeListClassName}
            >
              <div className="w-100 d-flex flex-column-reverse flex-lg-row align-items-lg-center">
                <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <BSButton
                      variant="green"
                      onClick={() =>
                        this.setState({ entryToEdit: null, showModal: true })
                      }
                    >
                      <FontAwesome name="plus" color="white" size={20} />
                      <div className="ms-2">
                        <FormattedMessage
                          id="button_add"
                          description="Add Drink Tracker Entry"
                          defaultMessage="Add New"
                        />
                      </div>
                    </BSButton>
                    {rows.length > 0 && (
                      <BSButton
                        variant="link-green"
                        className="ms-3"
                        linkTo="/urge-feedback"
                      >
                        <Entypo
                          name="bar-graph"
                          size={20}
                          color={FOREST_GREEN}
                        />
                        <div className="ms-2">Urge Feedback</div>
                      </BSButton>
                    )}
                    {rows.length === 0 && (
                      <p
                        style={{
                          marginLeft: "4em",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        Once you begin adding entries, you can see feedback
                        here.
                      </p>
                    )}
                  </div>
                </div>
                <div className="my-2 my-lg-0">{this.pagination()}</div>
              </div>
            </TrackerEntryTable>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: LIGHTER_FOREST_GREEN }}
          >
            <Modal.Title>New Urge Tracker</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UrgeTracker
              isFetching={this.props.isFetching}
              onSubmit={this.onSubmit}
              initialValues={this.initValues()}
              hideModal={() => this.setState({ showModal: false })}
              editing={!!this.state.entryToEdit}
            />
          </Modal.Body>
        </Modal>
        <ConfirmModal
          show={this.state.showConfirmModal}
          onConfirm={() => this.state.onConfirmDelete()}
          onCancel={() => {
            this.setState({
              showConfirmModal: false,
              onConfirmDelete: undefined,
            });
          }}
          message={<FormattedMessage {...confirmMessage} />}
        />
      </>
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
  tools: state.tools,
  responses: state.responses,
  isFetching: state.trackerEntries?.isFetching,
}))(UrgeList);
