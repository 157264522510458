"use strict";
import React from "react";
import TextField from "components/ux/text-field-redux-form";
import { Field } from "redux-form";
import BSInput from "../blocks/BSInput.component.web";
import FieldLabel from "../blocks/FieldLabel.component.web";

const fieldLabel = {
  id: "long_text_field_label",
  defaultMessage: "Type your answer...",
};

export const LongTextQuestion = React.createClass({
  propTypes: {
    field: React.PropTypes.object.isRequired,
    fieldName: React.PropTypes.string.isRequired,
    formatMessage: React.PropTypes.func.isRequired,
    text: React.PropTypes.object.isRequired,
    updateResponse: React.PropTypes.func.isRequired,
    bootstrap: React.PropTypes.bool,
  },

  shouldComponentUpdate: function (nextProps, nextState) {
    const next = _.get(nextProps, "field.value"),
      current = _.get(this.props, "field.value");
    return next !== current;
  },

  render() {
    const { field, fieldName, style, text, formatMessage, responses } =
      this.props;
    return (
      <div className="question-block long-text" style={style}>
        <div className="question">
          {text && (
            <FieldLabel responses={responses} text={text} id={fieldName} />
          )}
          {this.props.bootstrap ? (
            <Field
              component={BSInput}
              as="textarea"
              name={fieldName}
              placeholder={formatMessage(fieldLabel)}
              style={{ border: "none", backgroundColor: "white" }}
              onChange={(e) =>
                this.props.updateResponse({ [fieldName]: e.target.value })
              }
              initialValue={field ? field.value : ""}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <TextField
                {...field}
                value={field.value || ""}
                textArea={true}
                save={(value) =>
                  this.props.updateResponse({ [fieldName]: value })
                }
                labelText={formatMessage(fieldLabel)}
              />
            </div>
          )}
        </div>
      </div>
    );
  },
});
