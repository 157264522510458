"use strict";

import { SHOW_MODAL, CLOSE_MODAL } from "actions/types";

export function reducer(state = {}, action = { type: "" }) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        ...action.payload,
        previousModalNotifications: state.modalNotifications,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
