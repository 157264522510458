"use strict";

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Actions from "actions";
import SolutionTrackerAddOutcomeForm from "components/forms/solution-tracker-add-outcome-form";
import Moment from "moment";
Moment.locale("en");

const SolutionTrackerOutcomeModal = React.createClass({
  propTypes: {
    auth: React.PropTypes.object.isRequired,
    dispatch: React.PropTypes.func.isRequired,
  },

  getInitialState: function () {
    const { auth } = this.props;
    const user_timezone = _.get(auth, "user.user_timezone");
    var isNew = false;
    var solutionTrackerEntry = this.props.solutionTrackerEntry;
    var initialValues = solutionTrackerEntry.entry;

    return {
      initialValues: initialValues,
    };
  },

  solutionTrackerAddOutcome(data, trackerEntryId) {
    const { auth, dispatch } = this.props;
    const token = _.get(auth, "user.token");
    const userId = _.get(auth, "user._id");
    dispatch(
      Actions.updateTrackerEntry(token, userId, trackerEntryId, data),
    ).then(() => {
      this.closeModal();
    });
  },

  closeModal() {
    this.props.dispatch(
      Actions.closeModal("appModal", {
        modalNotifications: "",
        modalContent: false,
      }),
    );
  },

  onFormSubmit(data) {
    let mergedEntries = this.props.solutionTrackerEntry.entry;
    for (var key in data) {
      mergedEntries[key] = data[key];
    }
    const { auth } = this.props;
    const user_timezone = _.get(auth, "user.user_timezone");
    this.solutionTrackerAddOutcome(
      mergedEntries,
      this.props.solutionTrackerEntry._id,
    );
  },

  render() {
    const { trackerEntries } = this.props;
    const isFetching = _.get(trackerEntries, "isFetching");

    return (
      <div className="container">
        <SolutionTrackerAddOutcomeForm
          onSubmit={this.onFormSubmit}
          isFetching={isFetching}
          initialValues={this.state.initialValues}
        />
      </div>
    );
  },
});

export default connect((state) => ({
  auth: state.auth,
  trackerEntries: state.trackerEntries,
  solutionTrackerEntry: state.modal.solutionTrackerEntry,
}))(SolutionTrackerOutcomeModal);
