import React, { useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import Actions from "actions";
import Cookies from "cookies-js";
import Loading from "components/blocks/Loading.component.web";
import { addUnregisteredUser, getUser } from "api/users";

async function initializeUser() {
  try {
    let userData = Cookies.get("userData");
    let userId;
    let token;

    if (userData) {
      userData = userData.split(":");
      userId = userData[0];
      token = userData[1];
    }
    if (typeof userId !== "undefined" && typeof token !== "undefined") {
      return getUser(token, userId)
        .then((json) => {
          Cookies.set("userData", json._id + ":" + json.token);
          return json;
        })
        .catch(() => {
          userId = undefined;
          token = undefined;
          return addUnregisteredUser();
        });
    } else {
      return addUnregisteredUser().then((json) => {
        Cookies.set("userData", json._id + ":" + json.token);

        return json;
      });
    }
  } catch (e) {
  }
}

export const AuthProvider = ({ children }) => {
  const store = useStore();
  const dispatch = useDispatch();

  const hydrated = useSelector((state) => state._persist?.rehydrated ?? false);
  const user = useSelector((state) =>
    !!state.auth.user?._id ? state.auth.user : null
  );

  useEffect(() => {
    if (hydrated && !user) {
      initializeUser().then((initializedUser) => {
        store.dispatch(Actions.setUser(initializedUser));
      });
    }else {
      store.dispatch(Actions.setUser(user));
    }
  }, [user, hydrated]);

  useEffect(() => {
    if (!!user?._id) {
      const { _id: userId, token: userToken, status } = user;

      if (status === "unregistered") {
        dispatch(
          Actions.getTool(userToken, CUAC_SETTINGS.GROUP.screener_tool_id)
        );
      } else {
        dispatch(Actions.getSimplifiedTools(userToken));
        dispatch(Actions.getTrackerEntries(userToken, userId));
        dispatch(Actions.getTrackerEntryStats(userToken, userId));
        dispatch(Actions.getAllReminders(userId, userToken));
      }

      dispatch(Actions.getSubscriptions(userToken));
      dispatch(Actions.getPlans(userToken, CUAC_SETTINGS.GROUP._id))
      dispatch(Actions.getNorms(userToken))
      dispatch(Actions.getResponses(userToken, userId));
      
    }
  }, [user]);

  useEffect(() => {
    dispatch(Actions.getTerms(_.get(CUAC_SETTINGS, "GROUP.terms_id")));
  }, []);

  if (!hydrated || !user) {
    return (
      <Loading
        loading
        text={<h4 className="mb-0 ms-2 fw-bold">Loading...</h4>}
      />
    );
  }

  return children;
};
